import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from './serviceWorker';
import {createStore} from "redux";
import reducer from './store/index';
import {Provider} from "react-redux";
import AppRoutes from "./routes";
import axios, {AxiosRequestConfig} from 'axios';
import {toast} from "react-toastify";
import history from "./history";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
//import Cookies from "universal-cookie";

const store = createStore(reducer);
//const cookie = new Cookies();

axios.interceptors.response.use(
    (response:any) => {
        return response;
    },
    (error:any) => {
        error.response.data.badToken = false;
        let code = error.response.status;
        switch(code) {
            case 403:
                history.push({
                    pathname: '/accessdenied',
                    state: {from: window.location !== undefined ? (window.location.search !== undefined ? {pathname: window.location.pathname, search: window.location.search} : {pathname: window.location.pathname}) : {pathname: '/'}}
                });
                break;
            case 401:
                //toast.warn('Not Authenticated, please login through portal first.');
                error.response.data.badToken = true;
                history.push({
                    pathname: '/',
                    state: {from: window.location !== undefined ? (window.location.search !== undefined ? {pathname: window.location.pathname, search: window.location.search} : {pathname: window.location.pathname}) : {pathname: '/'}}
                });
                break;
            case 500:
                toast.error(error.response.data.message);
                break;
        }

        return Promise.reject(error);
    }
);

/*axios.interceptors.request.use((request:AxiosRequestConfig) => {
    request.xsrfCookieName = 'XSRF-TOKEN';
    request.xsrfHeaderName = 'X-XSRF-TOKEN';
    request.headers['X-XSRF-TOKEN'] = store.getState().defaultReducer.csrfToken;

    return request;
});*/

ReactDOM.render(
    <Provider store={store}><AppRoutes /></Provider>,
    document.getElementById('root') as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
