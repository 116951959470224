import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import {Container, Header} from "semantic-ui-react";
import TopLevelUpgradeForm from "./UpgradeForms/TopLevelUpgradeForm";

interface CreateUpgradeFormProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
}

interface CreateUpgradeFormState {
}

class CreateUpgradeForm extends React.Component<CreateUpgradeFormProps,CreateUpgradeFormState> {

    constructor(props: CreateUpgradeFormProps) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <Container fluid={true}>
                <TopLevelUpgradeForm location={this.props.location} />
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(CreateUpgradeForm);