import * as React from 'react';
import {
    Button,
    ButtonProps,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownProps,
    Form, Header, Icon,
    TextAreaProps
} from "semantic-ui-react";
import generalApi from "../../services/general-services";
import {toast} from "react-toastify";
import TracModal from "../GenericComponents/TracModal";

interface NotificationPostProps {
    notificationInModal?: boolean;
    replyNotificationModal?:boolean;
    parentNotificationId?:number | null;
    toUser?: string;
    saveEndpoint: string;
    notificationPostModalOpen?:boolean;
    setPostModalState?: (modalOpen:boolean) => void;
}

interface NotificationPostState {
    notificationRecipients: DropDownItem[];
    multiSelectedRecipient: string[],
    chosenRecipients: string;
    componentLoaded: boolean;
    notificationText: string;
    publicChecked: boolean;
    modalOpen: boolean;
}

class NotificationPost extends React.Component<NotificationPostProps, NotificationPostState> {
    private client: generalApi;

    constructor(props: NotificationPostProps, state: NotificationPostState) {
        super(props, state);
        this.state = {
            notificationRecipients: [],
            componentLoaded: false,
            chosenRecipients: '',
            multiSelectedRecipient:[],
            notificationText: '',
            publicChecked: false,
            modalOpen: false
        };

        this.client = new generalApi();
    }

    componentDidMount(): void {
        if(this.props.replyNotificationModal === undefined || this.props.replyNotificationModal === false) {
            this.getAvailableRecipients();
        }
    }

    onClickTextField =(event:any) =>{
        event.stopPropagation();

        if(this.props.setPostModalState !== undefined) {
            this.props.setPostModalState(true);
        }
      
     
    }
    getAvailableRecipients = () => {
        this.client.getNotificationRecipients()
            .then((response:DropDownItem[]) => {
                console.log(response);
                this.setState({notificationRecipients:response, componentLoaded: true});
            });
    };

    onDropDownChange = (event: React.SyntheticEvent<HTMLElement> | any, data: DropdownProps) => {
        if(data.value !== undefined) {         
          this.setState({
            multiSelectedRecipient: [
              ...this.state.multiSelectedRecipient,
              data.value.toString()
            ]
          })
         
        }   
    };
   
    textAreaChange = (event:any, data: TextAreaProps) => {
      
      
         let value = data.value as string;
         this.setState({notificationText:value});
    
    };

    publicCheckChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let value = data.checked as boolean;
        this.setState({publicChecked:value});
    };

    canPostNotification = () => {
        let canPost = false;

        if(/\S/.test(this.state.notificationText)) {
            if(this.state.notificationRecipients.length === 0) {
                canPost = true;
            }
            else {
                if(!this.state.publicChecked) {
                    
                        if(this.state.multiSelectedRecipient.length>0) {
                        canPost = true;
                    }
                    else {
                        toast.info('Must choose recipients if not marking notification as public.');
                    }
                }
                else {
                    canPost = true;
                }
            }
        }
        else {
            toast.warn('Nothing to save in notification.  Please input content then post again.');
        }

        return canPost;
    };

    postNotification = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
       
        if(this.canPostNotification()) {
            const updatedArray=this.state.multiSelectedRecipient.pop();
            let notificationRequest: NotificationRequest = {
                content: this.state.notificationText,
                subject: null,
                toUsers: this.props.toUser !== undefined ? this.props.toUser : updatedArray!==undefined ? updatedArray.toString(): '',
                parentNotificationId: this.props.parentNotificationId !== undefined ? this.props.parentNotificationId : null,
                publicNotification: this.state.publicChecked
            };
            console.log(this.props.saveEndpoint)
            this.client.postNotification(this.props.saveEndpoint, notificationRequest)
                .then((response: any) => {
                    toast.success('Successfully posted notification');
                    if(this.props.setPostModalState !== undefined) {
                        this.props.setPostModalState(false);
                    }
                    this.setState({notificationText:'', multiSelectedRecipient:[], publicChecked:false});
                });
        }
    };

    notificationPostContent = () => {
        return (
            <Form id ="formTempId" className={this.props.notificationInModal ? 'notificationPostInModal' : 'notificationPost'} reply={true}  onClick={this.onClickTextField}>
                <Form.TextArea rows={8} onChange={this.textAreaChange }  onClick={this.onClickTextField} value={this.state.notificationText}/>
                {this.state.componentLoaded && this.state.notificationRecipients.length > 0 && (this.props.replyNotificationModal === undefined || this.props.replyNotificationModal === false) &&
                    <div className={'notificationPostOptions'}>
                    <Dropdown className={'notificationRecipientsDropDown'} multiple={true} selection={true} search={true}
                                clearable={true} placeholder={'Select Recipients'}
                                onChange={this.onDropDownChange}
                                options={this.state.notificationRecipients}/>
                    <Checkbox className={'publicNotificationCheckBox'} label={'Public'} checked={this.state.publicChecked} onChange={this.publicCheckChange} />
                </div>
                }
                <Button className={'notificationPostButton'} content='Post Notification' labelPosition='left' icon='edit' primary={true} onClick={this.postNotification} />
            </Form>
        )
    };
    
    ModalClose = () => {
        this.setState({notificationText: ''}, () => {
            if(this.props.setPostModalState !== undefined) {
                this.props.setPostModalState(false);
            }                    
        })
    };

    ModalHeader = (header:string) => {
        return (
            <Header as='h3'>
                <Icon name={'comment'} />
                {header}
            </Header>
        )
    };

    ModalActions = () => {
        return (
            <div>
                <Button content={'Cancel'} color={'red'} onClick={this.cancelButtonClick}/>
            </div>
        )
    };

    cancelButtonClick = () => {
        this.ModalClose();
        // if(this.props.setPostModalState !== undefined) {
        //     this.props.setPostModalState(false);
        // }
    };

    notificationPostModal = () => {
        return (
            <TracModal header={this.ModalHeader('Post Notification')} content={this.notificationPostContent} actions={this.ModalActions}
                       open={this.props.notificationPostModalOpen} closeOnDimmerClick={true} closeIcon={true}
                       onClose={this.ModalClose}
            />
        )
    };

    public render() {
        return (
            <div>
                {this.props.notificationInModal &&
                    this.notificationPostModal()
                }
                {!this.props.notificationInModal &&
                    this.notificationPostContent()
                }
            </div>
        )
    }
}

export default NotificationPost;
