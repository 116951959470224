import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import TracLetterRecordPage from "../TracLetters/TracLetterRecordPage";
import {
    ButtonProps,
    CheckboxProps,
    Dropdown,
    DropdownOnSearchChangeData,
    DropdownProps,
    Form,
    Radio
} from "semantic-ui-react";
import GeneralApi from "../../services/general-services";
import {toast} from "react-toastify";

interface PaginatedDropDownProps {
    dropDownInfo: any;
    text: string;
    value: string;
    disabled: boolean;
    metaData: any;
    error: boolean;
    idForDropDown?:number
    multiple: boolean
    onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
}

interface PaginatedDropDownState {
    dropDownPageSize: number;
    dropDownPageNum: number;
    dropDownFilter: string;
    preFilteredPageSize: number;
    preFilteredPageNum: number;
    dropDownOptions: DropDownItem[]
    preFilteredOptions: DropDownItem[]
    dropDownLoading: boolean
    filterKeys: string[]
    selectedFilterKey: string
    previousFilterValue: string
    scrollBottomSeen: boolean
    noMoreRecords: boolean
    selectedValuesAddedToOptions: DropDownItem[]
}

const cloneDeep = require('lodash/cloneDeep');
class PaginatedDropDown extends React.Component<PaginatedDropDownProps,PaginatedDropDownState> {
    private generalClient: GeneralApi;

    constructor(props: PaginatedDropDownProps) {
        super(props);
        this.state = {
            dropDownPageSize: 50,
            dropDownPageNum: 0,
            dropDownFilter: '',
            preFilteredPageSize: 0,
            preFilteredPageNum: 0,
            dropDownOptions: [],
            preFilteredOptions: [],
            dropDownLoading: false,
            filterKeys: [],
            selectedFilterKey: '',
            previousFilterValue: '',
            scrollBottomSeen: false,
            noMoreRecords: false,
            selectedValuesAddedToOptions: []
        };

        this.generalClient = new GeneralApi();
    }

    registerEventListenersForDropDown = () => {
        const dropDownArea = document.querySelector(this.props.idForDropDown !== undefined ? '#paginatedDropDownList' + this.props.idForDropDown : '#paginatedDropDownList');
        if(dropDownArea !== null) {
            dropDownArea.addEventListener('scroll', this.scrollMethod);
        }
    };

    unRegisterEventListenersForDropDown = () => {
        document.removeEventListener('scroll', this.scrollMethod);
    }

    scrollMethod = () => {
        const wrappedElement = document.querySelector('div' + this.props.idForDropDown !== undefined ? '#paginatedDropDownList' + this.props.idForDropDown : '#paginatedDropDownList' + '.visible.menu.transition');
        if(this.isBottom(wrappedElement) && !this.state.scrollBottomSeen) {
            this.setState({scrollBottomSeen: true});
            if((this.state.dropDownOptions.length - this.state.selectedValuesAddedToOptions.length) >= this.state.dropDownPageSize && !this.state.noMoreRecords) {
                toast.info('Loading more records to dropdown...');
                this.makeCallAndLoadData(this.state.dropDownFilter, this.state.dropDownPageNum + 1);
            }
            if(this.state.noMoreRecords) {
                toast.info('No more records to load');
            }
        }
    };

    isBottom = (e:any) => {
        if(e !== null) {
            if (e !== undefined) {
                if(e.scrollHeight - Math.round(e.scrollTop) === e.clientHeight
                || e.scrollHeight - Math.round(e.scrollTop) <= e.clientHeight + 5) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    componentDidMount(): void {
        this.setIdOfDropDown();
        this.registerEventListenersForDropDown();
        this.prepareComponent();
        this.makeCallAndLoadData('');
    }

    setIdOfDropDown = () => {
        let scrollableElement:any;
        const wrappedElement = document.querySelector(this.props.idForDropDown !== undefined ? '#paginatedDropDown' + this.props.idForDropDown : '#paginatedDropDown');
        if(wrappedElement !== null) {
            scrollableElement = wrappedElement.lastElementChild;
            scrollableElement.id = this.props.idForDropDown !== undefined ? 'paginatedDropDownList' + this.props.idForDropDown : 'paginatedDropDownList';
        }
    };

    componentWillUnmount() {
        this.unRegisterEventListenersForDropDown();
    }

    prepareComponent = () => {
        let filterKeys:string[] = [];
        let sortAndFilterKeys:string = this.props.dropDownInfo.pageableSortAndFilterKey;
        filterKeys = sortAndFilterKeys.split(',');
        this.setState({filterKeys: filterKeys, selectedFilterKey: filterKeys[0]});
    };

    makeCallAndLoadData = (searchString: string, incrementedPageNum?: number, filterKeyFromCall?: string) => {
        this.setState({dropDownLoading:true})
        let filterKey:string = '';
        let filter:any = {};
        let { dropDownOptions, dropDownFilter, dropDownPageNum, dropDownPageSize, preFilteredOptions, preFilteredPageNum, preFilteredPageSize, previousFilterValue, noMoreRecords, selectedFilterKey, selectedValuesAddedToOptions } = this.state;
        if(incrementedPageNum !== undefined) {
            dropDownPageNum = incrementedPageNum;
        }
        if(searchString !== '') {
            filter[this.state.selectedFilterKey] = searchString;
            if(searchString !== previousFilterValue) {
                dropDownPageNum = 0;
            }
        }
        if(this.state.selectedFilterKey === '' || this.state.selectedFilterKey === undefined) {
            let filterKeys:string[] = [];
            let sortAndFilterKeys:string = this.props.dropDownInfo.pageableSortAndFilterKey;
            filterKeys = sortAndFilterKeys.split(',');
            filterKey = filterKeys[0];
        }
        else {
            filterKey = this.state.selectedFilterKey;
        }
        const params = new URLSearchParams();
        params.append('page', (searchString !== '' && preFilteredOptions.length === 0) ? Number(0).toString() : dropDownPageNum.toString());
        params.append('size', dropDownPageSize.toString());
        params.append('sort', `${filterKey},${this.props.dropDownInfo.pageableSortDirection}`)

        if(searchString !== previousFilterValue) {
            noMoreRecords = false;
        }

        if((searchString === '' && previousFilterValue !== '') || filterKeyFromCall !== undefined) {
            if(preFilteredOptions.length > 0) {
                dropDownOptions = preFilteredOptions;
                dropDownPageNum = preFilteredPageNum;
            }
            preFilteredOptions = [];
            preFilteredPageNum = 0;
            preFilteredPageSize = dropDownPageSize;
            dropDownFilter = searchString;
            previousFilterValue = searchString;
            noMoreRecords = false;
            selectedFilterKey = filterKeyFromCall !== undefined ? filterKeyFromCall : selectedFilterKey;
            this.setState({
                dropDownOptions,
                dropDownFilter,
                dropDownPageNum,
                preFilteredOptions,
                preFilteredPageNum,
                preFilteredPageSize,
                previousFilterValue,
                noMoreRecords,
                selectedFilterKey,
                dropDownLoading: false,
                scrollBottomSeen: false
            });
        }
        else {
            this.generalClient.getPaginatedDropDownData(this.props.dropDownInfo.callbackRoute, filter, params)
                .then((response: DropDownItem[]) => {
                    if(response.length < dropDownPageSize) {
                        noMoreRecords = true;
                    }
                    if (searchString !== '') {
                        dropDownFilter = searchString;
                        if (preFilteredOptions.length === 0) {
                            preFilteredOptions = cloneDeep(dropDownOptions);
                            preFilteredPageNum = dropDownPageNum;
                            preFilteredPageSize = dropDownPageSize;
                            if(this.props?.multiple) {
                                dropDownOptions.splice(dropDownOptions.length - selectedValuesAddedToOptions.length, selectedValuesAddedToOptions.length);
                                dropDownOptions = response;
                                dropDownOptions.push(...selectedValuesAddedToOptions);
                            }
                            else {
                                dropDownOptions = response;
                            }
                            dropDownPageNum = 0;
                            previousFilterValue = searchString;
                            this.setState({
                                dropDownOptions,
                                dropDownFilter,
                                dropDownPageNum,
                                preFilteredOptions,
                                preFilteredPageNum,
                                preFilteredPageSize,
                                previousFilterValue,
                                noMoreRecords,
                                dropDownLoading: false,
                                scrollBottomSeen: false
                            });
                        } else {
                            if(this.props?.multiple) {
                                dropDownOptions.splice(dropDownOptions.length - selectedValuesAddedToOptions.length, selectedValuesAddedToOptions.length);
                            }
                            if(response.length < dropDownPageSize && response.length !== 0 && dropDownPageNum === 0) {
                                dropDownOptions = response;
                            }
                            else {
                                if(searchString.length < previousFilterValue.length) {
                                    dropDownOptions = response;
                                }
                                else {
                                    if(incrementedPageNum !== undefined) {
                                        dropDownOptions.push(...response);
                                    }
                                    else {
                                        dropDownOptions = response;
                                    }
                                }
                            }
                            if(this.props?.multiple) {
                                dropDownOptions.push(...selectedValuesAddedToOptions);
                            }
                            previousFilterValue = searchString;
                            this.setState({
                                dropDownOptions,
                                dropDownFilter,
                                dropDownPageNum,
                                previousFilterValue,
                                noMoreRecords,
                                dropDownLoading: false,
                                scrollBottomSeen: false
                            });
                        }
                    } else {
                        if(this.props?.multiple) {
                            dropDownOptions.splice(dropDownOptions.length - selectedValuesAddedToOptions.length, selectedValuesAddedToOptions.length);
                        }
                        dropDownOptions.push(...response);
                        if(this.props?.multiple) {
                            dropDownOptions.push(...selectedValuesAddedToOptions);
                        }
                        dropDownFilter = searchString;
                        previousFilterValue = searchString;
                        this.setState({
                            dropDownOptions,
                            dropDownFilter,
                            dropDownPageNum,
                            previousFilterValue,
                            noMoreRecords,
                            dropDownLoading: false,
                            scrollBottomSeen: false
                        });
                    }
                });
        }
    }

    handleDropDownSearchChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownOnSearchChangeData) => {
        if(!this.state.dropDownLoading) {
            this.makeCallAndLoadData(data.searchQuery);
        }
    };

    handleRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.makeCallAndLoadData('', 0, data.value as string);
    };

    loopAndDisplayFilterKeys = () => {
        let radioArrayToRender:any[] = [];
        this.state.filterKeys.map((value:string, index:number) => {
            radioArrayToRender.push(
                <Form.Field className={'inlineFormField'}>
                    <Radio
                        label={value}
                        name='filterGroup'
                        value={value}
                        checked={this.state.selectedFilterKey === value}
                        onChange={this.handleRadioChange}
                    />
                </Form.Field>
            )
        })
        return radioArrayToRender;
    };

    appendChosenValuesToOptionsSoToSeeInMultiple = (values:string[]) => {
        let array:DropDownItem[] = [];
        let item:DropDownItem;

        values?.map((value:string, index:number) => {
            item = {
                key: value,
                value: value,
                text: value
            };
            array.push(item);
        });

        return array;
    };

    onDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let { dropDownOptions, dropDownFilter, dropDownPageNum, preFilteredOptions, preFilteredPageNum, noMoreRecords, previousFilterValue, selectedValuesAddedToOptions } = this.state;
        if(this.state.dropDownFilter !== '') {
            noMoreRecords = false;
            dropDownOptions = preFilteredOptions;
            if(data.multiple) {
                dropDownOptions.splice(dropDownOptions.length - selectedValuesAddedToOptions.length, selectedValuesAddedToOptions.length);
                let tempArray:DropDownItem[] = [];
                tempArray = this.appendChosenValuesToOptionsSoToSeeInMultiple(data.value as string[]);
                dropDownOptions.push(...tempArray);
                selectedValuesAddedToOptions = tempArray;
            }
            dropDownPageNum = preFilteredPageNum;
            dropDownFilter = '';
            previousFilterValue = '';
            this.setState({
                dropDownOptions,
                dropDownFilter,
                dropDownPageNum,
                noMoreRecords,
                previousFilterValue,
                selectedValuesAddedToOptions,
                dropDownLoading: false,
                scrollBottomSeen: false
            });
        }
        else {
            if(data.multiple) {
                dropDownOptions.splice(dropDownOptions.length - selectedValuesAddedToOptions.length, selectedValuesAddedToOptions.length);
                let tempArray:DropDownItem[] = [];
                tempArray = this.appendChosenValuesToOptionsSoToSeeInMultiple(data.value as string[]);
                dropDownOptions.push(...tempArray);
                selectedValuesAddedToOptions = tempArray;
            }
            this.setState({selectedValuesAddedToOptions});
        }
        this.props.onChange(event, data);
    };

    render() {
        return (
            <React.Fragment>
                {this.state.filterKeys.length > 1 &&
                    <Form>
                        <Form.Field>
                            Selected Key to filter against: <b>{this.state.selectedFilterKey}</b>
                        </Form.Field>
                        {this.loopAndDisplayFilterKeys()}
                    </Form>
                }
                <Dropdown
                    className={'paginatedDropDown'}
                    id={this.props.idForDropDown !== undefined ? 'paginatedDropDown' + this.props.idForDropDown : 'paginatedDropDown'}
                    search={true}
                    selection={true}
                    multiple={this.props.multiple}
                    loading={this.state.dropDownLoading}
                    options={this.state.dropDownOptions}
                    text={this.props.text}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    onSearchChange={this.handleDropDownSearchChange}
                    scrolling={true}
                    searchQuery={this.state.dropDownFilter}
                    onChange={this.onDropDownChange}
                    metaData={this.props.metaData}
                    clearable={true}
                    error={this.props.error}
                />
            </React.Fragment>
        );
    }
}

export default connect(null, null)(PaginatedDropDown);