import * as React from 'react';
import {Container, Dimmer, Header, Label, Loader, Segment} from "semantic-ui-react";
import ReportsApi from "../../services/reports-service";
import {toast} from "react-toastify";
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {ExportButton} from "../GenericComponents/ExportButton";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {Redirect} from "react-router";

interface ReportDataPageProps {
    location: any;
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    setLoggedIn: (loggedIn:boolean) => void;
}

interface ReportDataPageState {
    reportPageHeader: string;
    reportDetails: TracLink;
    userAuthorized: boolean;
    pageLoading: boolean;
}

class ReportDataPage extends React.Component<ReportDataPageProps, ReportDataPageState> {
    private reportsClient: ReportsApi;

    constructor(props: ReportDataPageProps) {
        super(props);
        this.state = {
            reportPageHeader: '',
            reportDetails: {
                linkDisplayName: '',
                linkName: '',
                linkUrl: ''
            },
            userAuthorized: false,
            pageLoading: true
        };

        this.reportsClient = new ReportsApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        let reportTitle = this.getReportTitle();
        /*if(reportTitle) {
            this.getReportData(reportTitle);
        }*/
    }

    getReportTitle = () => {
        let pathList = null;
        let {reportDetails} = this.state;
        if(this.props.location !== undefined) {
            pathList = this.props.location.pathname.split('/');
            if(this.props.location.reportDisplayName !== undefined) {
                reportDetails.linkDisplayName = this.props.location.reportDisplayName;
                reportDetails.linkName = this.props.location.reportName;
                reportDetails.linkUrl = this.props.location.reportLink;
                this.setState({reportPageHeader:this.props.location.reportDisplayName, reportDetails, userAuthorized:true, pageLoading: false});
            }
            else {
                this.setState({reportPageHeader: ''});
                this.reportsClient.getReportPageInfo(pathList[pathList.length - 1])
                    .then((response:TracLink) => {
                        this.setState({reportPageHeader:response.linkDisplayName, reportDetails:response, userAuthorized:true, pageLoading: false});
                    })
                    .catch((error:any) => {
                        if(error.response.status === 403) {
                            this.setState({userAuthorized:false, pageLoading: false});
                        }
                        if(!error.response.data.badToken) {
                            toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                        }
                        else {
                            this.props.setLoggedIn(false);
                        }
                    });
            }
            //this.setState({reportPageHeader:this.props.location.reportDisplayName});
            return(pathList[pathList.length - 1]);
        }
        else {
            this.setState({reportPageHeader:'Error Encountered', userAuthorized:true, pageLoading: false});
        }

        return pathList;
    };

    getReportData = (reportTitle:string) => {
        this.reportsClient.getReportData(reportTitle)
            .then((response:string) => {
                toast.info('testing getting report data for ' + reportTitle + ' report');
            });
    };

    public render() {
        /*if(!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }*/

        return (
            <Container fluid={true}>
                <Segment basic={true}>
                    {/*<Header>{this.state.reportPageHeader}</Header>*/}
                    {this.state.reportDetails.linkUrl !== '' &&
                        <GridContainer getGridDataUrl={'/api/auth' + this.state.reportDetails.linkUrl} gridPaginated={true}
                                       gridTitle={this.state.reportPageHeader} gridId={'qptReportGrid'} exportLink={this.state.reportDetails.linkUrl.replace('/','')} />
                    }
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDataPage);