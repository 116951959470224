import * as actionTypes from './action';

const initialState = {
    upgrade: '',
    loggedIn: false,
    breadCrumbPath: [],
    visibleColumnCount: 0,
    utilityColumnCount: 0,
    csrfToken: ''
};

// TODO - adding our actions for udpating the token in our state.
export const defaultReducer = (state = initialState, action:any) => {
    switch(action.type) {
        case actionTypes.GET_UPGRADE:
            return {
                ...state,
                creatingForm: action.payload
            };
        case actionTypes.LOGGED_IN:
            return {
                ...state,
                loggedIn: action.payload
            };
        case actionTypes.SET_BREADCRUMB_PATH:
            return {
                ...state,
                breadCrumbPath: action.payload
            };
        case actionTypes.SET_VISIBLE_COLUMN_COUNT:
            return {
                ...state,
                visibleColumnCount: action.payload
            };
        case actionTypes.SET_UTILITY_COLUMN_COUNT:
            return {
                ...state,
                utilityColumnCount: action.payload
            };
        case actionTypes.SET_CSRF_TOKEN:
            return {
                ...state,
                csrfToken: action.payload
            }
    }
    return state;
};

