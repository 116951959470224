import * as React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from './history';
import Layout from "./components/Layout";
import Home from "./components/Home";
import PrivateRoute from "./components/PrivateRoute";
import AccessDenied from "./components/AccessDenied";
import Profile from "./components/Profile";
import ViewUpgrade from "./components/Upgrades/ViewUpgrade";
import ViewProjects from "./components/Projects/ViewProjects";
import NtcTracking from "./components/NTC/NtcTracking";
import RfpTracking from "./components/RFP/RfpTracking";
import Login from "./components/Access/Login";
import ReportsLandingPage from "./components/Reports/ReportsLandingPage";
import ReportDataPage from "./components/Reports/ReportDataPage";
import AdminPage from "./components/Admin/AdminPage";
import TransmissionOwnerInfo from "./components/FacilityAndTransmissionOwner/TransmissionOwnerInfo";
import NotificationPage from "./components/Notification/NotificationPage";
import ViewStudies from "./components/Study/ViewStudies";
import UpgradeCostInfo from "./components/Upgrades/UpgradeDetails/UpgradeCostInfo";
import UpgradeBus from "./components/Upgrades/UpgradeDetails/UpgradeBus";
import UpgradeDetailsPage from "./components/Upgrades/UpgradeDetails/UpgradeDetailsPage";
import ViewOasisTsrs from "./components/OasisTsr/ViewOasisTsrs";
import NtcContacts from "./components/NTC/NtcContacts";
import NtcUpgradeInfo from "./components/NTC/NtcUpgradeInfo";
import RfpUpgradeInfo from "./components/RFP/RfpUpgradeInfo";
import Zone from "./components/FacilityAndTransmissionOwner/Zone";
import ScertRecordsPage from "./components/External/ScertRecordsPage";
import ScertForm from "./components/External/ScertForm";
import ExternalNtcTracking from "./components/External/ExternalNtcTracking";
import CreateRfpForm from "./components/RFP/CreateRfpForm";
import CreateNtcForm from "./components/NTC/CreateNtcForm";
import CreateUpgradeForm from "./components/Upgrades/CreateUpgradeForm";
import GenerationInterconnectionUpgradeForm
    from "./components/Upgrades/UpgradeForms/GenerationInterconnectionUpgradeForm";
import ReliabilityUpgradeForm from "./components/Upgrades/UpgradeForms/ReliabilityUpgradeForm";
import TransmissionServicesUpgradeForm from "./components/Upgrades/UpgradeForms/TransmissionServicesUpgradeForm";
import TsrExpandedInfo from "./components/OasisTsr/TsrExpandedInfo";
import TsrCostAllocation from "./components/OasisTsr/TsrCostAllocation";
import BreadCrumbDetailPage from "./components/GenericComponents/BreadCrumbDetailPage";
import AssocStudyUpgrade from "./components/Study/AssocStudyUpgrade";
import NtcServiceAgreementsPage from "./components/NTC/NtcServiceAgreementsPage";
import NtcLetterEdit from "./components/NTC/NtcLetterEdit";
//import ScertConnectivityTestPage from "./components/External/ScertConnectivityTestPage";

export default () => {
    return (
        <Router history={history}>
            <Switch>
                <Layout location={window.location}>
                    <Route exact={true} path={'/'} component={Home}/>
                    <Route exact={true} path={'/accessDenied'} component={AccessDenied} />
                    <Route exact={true} path={'/profile'} component={Profile} />
                    <Route exact={true} path={'/upgrades'} component={ViewUpgrade} />
                    <Route exact={true} path={'/upgradeDetails'} render={(props) => (
                        <BreadCrumbDetailPage {...props} breadCrumbPath={'Upgrades,UpgradeDetails'} />
                    )} />
                    <Route exact={true} path={'/upgradeDetails/upgradeCostInfo'} component={UpgradeCostInfo} />
                    <Route exact={true} path={'/upgradeDetails/upgradeBus'} component={UpgradeBus} />
                    <Route exact={true} path={'/upgrades/createUpgrade'} component={CreateUpgradeForm} />
                    <Route exact={true} path={'/upgrades/createUpgrade/generationInterconnection'} component={GenerationInterconnectionUpgradeForm} />
                    <Route exact={true} path={'/upgrades/createUpgrade/reliability'} component={ReliabilityUpgradeForm} />
                    <Route exact={true} path={'/upgrades/createUpgrade/transmissionService'} component={TransmissionServicesUpgradeForm} />
                    <Route exact={true} path={'/projects'} component={ViewProjects} />
                    <Route exact={true} path={'/NTC'} component={NtcTracking} />
                    <Route exact={true} path={'/NTC/:letterId'} component={NtcTracking} />
                    <Route exact={true} path={'/ntcLetterEdit'} render={(props) => <NtcLetterEdit {...props}/>} />
                    <Route exact={true} path={'/ntcUpgradeInfo'} component={NtcUpgradeInfo} />
                    <Route exact={true} path={'/ntcContacts'} component={NtcContacts} />
                    <Route exact={true} path={'/ntcServiceAgreements'} component={NtcServiceAgreementsPage} />
                    <Route exact={true} path={'/createNtc'} component={CreateNtcForm} />
                    <Route exact={true} path={'/RFP'} component={RfpTracking} />
                    <Route exact={true} path={'/rfpUpgradeInfo'} component={RfpUpgradeInfo} />
                    <Route exact={true} path={'/createRfp'} component={CreateRfpForm} />
                    <Route exact={true} path={'/oasisTsr'} render={(props) => (
                        <BreadCrumbDetailPage {...props} breadCrumbPath={'OasisTsr'} />
                    )} />
                    <Route exact={true} path={'/oasisTsr/viewTsrs'} component={ViewOasisTsrs} />
                    <Route exact={true} path={'/oasisTsr/tsrExpandedInfo'} component={TsrExpandedInfo} />
                    <Route exact={true} path={'/oasisTsr/tsrCostAllocation'} component={TsrCostAllocation} />
                    <Route exact={true} path={'/study'} component={ViewStudies} />
                    <Route exact={true} path={'/study/assocStudyUpgrade'} component={AssocStudyUpgrade} />
                    <Route exact={true} path={'/reports'} component={ReportsLandingPage} />
                    <Route exact={true} path={'/reports/:report'} component={ReportDataPage} />
                    <Route exact={true} path={'/admin'} component={AdminPage} />
                    <Route exact={true} path={'/facilityOwnerInfo'} component={TransmissionOwnerInfo} />
                    <Route exact={true} path={'/zone'} component={Zone} />
                    <Route exact={true} path={'/notifications'} component={NotificationPage} />
                    <Route exact={true} path={'/SCERT'} component={ScertRecordsPage} />
                    <Route exact={true} path={'/SCERTWebForm'} component={ScertForm} />
                    <Route exact={true} path={'/transmissionOwnerNtcs'} component={ExternalNtcTracking} />
                    <Route exact={true} path={'/transmissionOwnerNtcs/:letterId'} component={ExternalNtcTracking} />
                </Layout>
            </Switch>
        </Router>
    )
}