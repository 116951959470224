import * as React from 'react';
import {connect} from "react-redux";
import {CheckboxProps, Grid, Input, InputOnChangeData, Label, Radio, TextAreaProps} from "semantic-ui-react";

interface CostAssumptionsProps {
    scertState: ScertFormObject;
    inputChange: (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => void;
    textAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => void;
    radioChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
    updateTotalNetworkUpgradeCostEstimate : (value: number) => void;
}

interface CostAssumptionsState {
    scertState: ScertFormObject;
}

class CostAssumptions extends React.Component<CostAssumptionsProps,CostAssumptionsState> {

    constructor(props: CostAssumptionsProps) {
        super(props);
        this.state = {
            scertState: {
                scert: null,
                summaryLineAssumption: null,
                costAssumption: null,
                lineAssumption: null,
                segment: null,
                stationAssumption: null,
                mitigationPlanCloseout: null,
                isSppAdmin: false,
                scertActionsResponse: {
                    canSubmit: false,
                    canSave: false,
                    canApprove: false,
                    canReject: false,
                    notification: ''
                },
                scertActionStatus: ''
            }
        };
    }

    componentDidMount(): void {

    }

    componentDidUpdate(prevProps: Readonly<CostAssumptionsProps>, prevState: Readonly<CostAssumptionsState>) {
        if (prevProps.scertState !== this.props.scertState) {
            let lineSubTotal = 0, stationSubTotal = 0, networkUpgradeCost = 0;
            lineSubTotal = Number(this.props.scertState.costAssumption?.data['lineEnggLaborAmt']) + Number(this.props.scertState.costAssumption?.data['lineConstructLaborAmt'])
            + Number(this.props.scertState.costAssumption?.data['lineRightOfWayAmt']) + Number(this.props.scertState.costAssumption?.data['lineReactiveCompLaborMatrlAmt'])
            + Number(this.props.scertState.costAssumption?.data['lineMaterialAmt']);
            this.props.scertState.costAssumption.data['lineSubtotalAmt'] = lineSubTotal;
            stationSubTotal = Number(this.props.scertState.costAssumption?.data['stationEnggLaborAmt']) + Number(this.props.scertState.costAssumption?.data['stationConstructLaborAmt'])
                + Number(this.props.scertState.costAssumption?.data['stationSitePropRightsAmt']) + Number(this.props.scertState.costAssumption?.data['stationReactiveCompLaborMatrlAmt'])
                + Number(this.props.scertState.costAssumption?.data['stationMaterialAmt']);
            this.props.scertState.costAssumption.data['stationSubtotalAmt'] = stationSubTotal;
            networkUpgradeCost = lineSubTotal + stationSubTotal + Number(this.props.scertState.costAssumption?.data['afudcAmt']) + Number(this.props.scertState.costAssumption?.data['contingencyAmt']);
            this.props.scertState.costAssumption.data['totalProjCostEstimateAmt'] = networkUpgradeCost;
            this.setState({scertState: this.props.scertState});
        }
    }

    setFieldBasedOnFormSectionType = (scertForm:any, value:any, inputType:string, name:string) => {
        scertForm.costAssumption.data[name] = value;
    };

    updateTotalFields = (scertForm:any, group:string, value:any, name:string) => {
        let sectionTotalValue = 0;
        let initialTotal = 0;
        switch(group) {
            case "costLineSubTotal":
                initialTotal = scertForm.costAssumption.data['lineSubtotalAmt'] === null ? 0 : scertForm.costAssumption.data['lineSubtotalAmt'];
                sectionTotalValue = initialTotal;
                sectionTotalValue -= Number(scertForm.costAssumption.data[name]);
                sectionTotalValue += Number(value);
                scertForm.costAssumption.data['lineSubtotalAmt'] = sectionTotalValue;

                sectionTotalValue = scertForm.costAssumption.data['totalProjCostEstimateAmt'] === null ? 0 : scertForm.costAssumption.data['totalProjCostEstimateAmt'];
                sectionTotalValue -= Number(initialTotal);
                sectionTotalValue += Number(scertForm.costAssumption.data['lineSubtotalAmt']);
                scertForm.costAssumption.data['totalProjCostEstimateAmt'] = sectionTotalValue;
                this.props.updateTotalNetworkUpgradeCostEstimate(sectionTotalValue);
                break;
            case "costStationSubTotal":
                initialTotal = scertForm.costAssumption.data['stationSubtotalAmt'] === null ? 0 : scertForm.costAssumption.data['stationSubtotalAmt'];
                sectionTotalValue = initialTotal;
                sectionTotalValue -= Number(scertForm.costAssumption.data[name]);
                sectionTotalValue += Number(value);
                scertForm.costAssumption.data['stationSubtotalAmt'] = sectionTotalValue;

                sectionTotalValue = scertForm.costAssumption.data['totalProjCostEstimateAmt'] === null ? 0 : scertForm.costAssumption.data['totalProjCostEstimateAmt'];
                sectionTotalValue -= Number(initialTotal);
                sectionTotalValue += Number(scertForm.costAssumption.data['stationSubtotalAmt']);
                scertForm.costAssumption.data['totalProjCostEstimateAmt'] = sectionTotalValue;
                this.props.updateTotalNetworkUpgradeCostEstimate(sectionTotalValue);
                break;
            case "costNetworkUpgradeCost":
                sectionTotalValue = scertForm.costAssumption.data['totalProjCostEstimateAmt'] === null ? 0 : scertForm.costAssumption.data['totalProjCostEstimateAmt'];
                sectionTotalValue -= Number(scertForm.costAssumption.data[name]);
                sectionTotalValue += Number(value);
                scertForm.costAssumption.data['totalProjCostEstimateAmt'] = sectionTotalValue;
                this.props.updateTotalNetworkUpgradeCostEstimate(sectionTotalValue);
                break;
        }
    }

    handleRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let scertState = this.props.scertState;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertState, data.value, data.inputType, data.name);
        }

        this.setState({scertState});
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        let scertState = this.props.scertState;

        this.updateTotalFields(scertState, data.group, event.target.value, data.name);

        this.setFieldBasedOnFormSectionType(scertState, event.target.value, data.inputType, data.name);

        this.setState({scertState});
    };

    render() {
        const { scertState } = this.props;

        return (
            <Grid columns={'equal'} celled={true}>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Line Costs</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Current Year $</h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineEnggLaborAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Engineering Labor</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['lineEnggLaborAmt'].editable} value={scertState.costAssumption?.data['lineEnggLaborAmt']} inputType={'costAssumption'} group={'costLineSubTotal'} onChange={this.handleInputChange} name={'lineEnggLaborAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineConstructLaborAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Construction Labor</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['lineConstructLaborAmt'].editable} value={scertState.costAssumption?.data['lineConstructLaborAmt']} inputType={'costAssumption'} group={'costLineSubTotal'} onChange={this.handleInputChange} name={'lineConstructLaborAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineRightOfWayAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Right-of-Way</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['lineRightOfWayAmt'].editable} value={scertState.costAssumption?.data['lineRightOfWayAmt']} inputType={'costAssumption'} group={'costLineSubTotal'} onChange={this.handleInputChange} name={'lineRightOfWayAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineReactiveCompLaborMatrlAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Reactive Compensation (Labor & Materials)</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['lineReactiveCompLaborMatrlAmt'].editable} value={scertState.costAssumption?.data['lineReactiveCompLaborMatrlAmt']} inputType={'costAssumption'} group={'costLineSubTotal'} onChange={this.handleInputChange} name={'lineReactiveCompLaborMatrlAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineMaterialAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Material</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['lineMaterialAmt'].editable} value={scertState.costAssumption?.data['lineMaterialAmt']} inputType={'costAssumption'} group={'costLineSubTotal'} onChange={this.handleInputChange} name={'lineMaterialAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Line Sub-Total</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} readOnly={true} disabled={true} value={scertState.costAssumption?.data['lineSubtotalAmt']} inputType={'costAssumption'} name={'lineSubtotalAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Station Costs</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationEnggLaborAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Engineering Labor</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['stationEnggLaborAmt'].editable} value={scertState.costAssumption?.data['stationEnggLaborAmt']} inputType={'costAssumption'} group={'costStationSubTotal'} onChange={this.handleInputChange} name={'stationEnggLaborAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationConstructLaborAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Construction Labor</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['stationConstructLaborAmt'].editable} value={scertState.costAssumption?.data['stationConstructLaborAmt']} inputType={'costAssumption'} group={'costStationSubTotal'} onChange={this.handleInputChange} name={'stationConstructLaborAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationSitePropRightsAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Site Property Rights</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['stationSitePropRightsAmt'].editable} value={scertState.costAssumption?.data['stationSitePropRightsAmt']} inputType={'costAssumption'} group={'costStationSubTotal'} onChange={this.handleInputChange} name={'stationSitePropRightsAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationReactiveCompLaborMatrlAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Reactive Compensation (Labor & Materials)</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['stationReactiveCompLaborMatrlAmt'].editable} value={scertState.costAssumption?.data['stationReactiveCompLaborMatrlAmt']} inputType={'costAssumption'} group={'costStationSubTotal'} onChange={this.handleInputChange} name={'stationReactiveCompLaborMatrlAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationMaterialAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Material</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['stationMaterialAmt'].editable} value={scertState.costAssumption?.data['stationMaterialAmt']} inputType={'costAssumption'} group={'costStationSubTotal'} onChange={this.handleInputChange} name={'stationMaterialAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Station Sub-Total</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} readOnly={true} disabled={true} value={scertState.costAssumption?.data['stationSubtotalAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'stationSubtotalAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Summary Info</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['lineSubtotalAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Line Sub-Total</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} readOnly={true} disabled={true} value={scertState.costAssumption?.data['lineSubtotalAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'lineSubTotal'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['stationSubtotalAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Station Sub-Total</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} readOnly={true} disabled={true} value={scertState.costAssumption?.data['stationSubtotalAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'stationSubTotal'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['afudcAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>AFUDC</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['afudcAmt'].editable} value={scertState.costAssumption?.data['afudcAmt']} inputType={'costAssumption'} group={'costNetworkUpgradeCost'} onChange={this.handleInputChange} name={'afudcAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['contingencyAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Contingency</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['contingencyAmt'].editable} value={scertState.costAssumption?.data['contingencyAmt']} inputType={'costAssumption'} group={'costNetworkUpgradeCost'} onChange={this.handleInputChange} name={'contingencyAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Total Network Upgrade Cost Estimate</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} readOnly={true} disabled={true} value={scertState.costAssumption?.data['totalProjCostEstimateAmt']} inputType={'costAssumption'} onChange={this.props.inputChange} name={'totalProjCostEstimateAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Miscellaneous Cost Info</h4>
                    </Grid.Column>
                    <Grid.Column />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['cwipInd'].editable ? {color: 'lightgrey'}: {color: 'black'}}>CWIP (Y/N)</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Radio className={'scertRadioStyles'} name={'cwipInd'} label={'Y'} disabled={!scertState.costAssumption?.columnMetaData['cwipInd'].editable} checked={scertState.costAssumption?.data['cwipInd'] === 'Y'} value={'Y'} inputType={'costAssumption'} onChange={this.handleRadioChange} />
                        <Radio className={'scertRadioStyles'} name={'cwipInd'} label={'N'} disabled={!scertState.costAssumption?.columnMetaData['cwipInd'].editable} checked={scertState.costAssumption?.data['cwipInd'] === 'N' || scertState.costAssumption?.data['cwipInd'] === null} value={'N'} inputType={'costAssumption'} onChange={this.handleRadioChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['nonTrsmCostAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Non-Transmission Cost</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['nonTrsmCostAmt'].editable} value={scertState.costAssumption?.data['nonTrsmCostAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'nonTrsmCostAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Expenditure Info</h4>
                    </Grid.Column>
                    <Grid.Column />
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['expendituresToDtAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Expenditures To Date</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['expendituresToDtAmt'].editable} value={scertState.costAssumption?.data['expendituresToDtAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'expendituresToDtAmt'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.costAssumption?.columnMetaData['bindingFinancialCommitmentAmt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Contractually Binding Financial Commitment</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Input fluid={true} type={'number'} disabled={!scertState.costAssumption?.columnMetaData['bindingFinancialCommitmentAmt'].editable} value={scertState.costAssumption?.data['bindingFinancialCommitmentAmt']} inputType={'costAssumption'} onChange={this.handleInputChange} name={'bindingFinancialCommitmentAmt'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(null, null)(CostAssumptions);