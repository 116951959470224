import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";

interface RfpUpgradeInfoProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface RfpUpgradeInfoState {
}

class RfpUpgradeInfo extends React.Component<RfpUpgradeInfoProps,RfpUpgradeInfoState> {

    constructor(props: RfpUpgradeInfoProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/rfpUpgradeInfo'}
                addRecordUrl={'/api/auth/saveGridRecord/rfpUpgradeInfo'}
                editRecordUrl={'/api/auth/editGridRecord/rfpUpgradeInfo'}
                deleteRecordUrl={'/api/auth/removeGridRecord/rfpUpgradeInfo'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/rfpUpgradeInfo'}
                gridTitle={'View RFP Upgrade Info'}
                gridId={'rfpUpgradeInfoGrid'}
                typeForSuperClass={'rfpUpgradeInfo'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(RfpUpgradeInfo);