import axios from "axios";
import {toast} from "react-toastify";
import JWT from 'jwt-decode';

export default class GeneralService {

    constructor() {
    }

    getAppInfo = () => {
        const url = `/api/auth/app-info`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching app information'));
                    }
                });
        });
    };

    postNotification = (url:string, notification:NotificationRequest) => {
        return new Promise((resolve, reject) => {
            axios.post(url, notification)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving notification'));
                    }
                });
        });
    };

    getNotificationRecipients = () => {
        const url = `/api/auth/notificationRecipients`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching list of available recipients'));
                    }
                });
        });
    };

    getNotificationRecords = (page:number, size:number, notificationRoute:string) => {
        let url = `/api/auth/viewNotifications?page=${page}&size=${size}&route=${notificationRoute}`;

        return new Promise<NotificationFeed>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching notification feed'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    markNotificationSeen = (notificationId:number) => {
        const url = '/api/auth/markNotificationRead';

        return new Promise<boolean>((resolve, reject) => {
            axios.patch(url, {notificationUserId:notificationId})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error marking notification record seen'));
                    }
                });
        });
    };

    getCsrf = () => {
        const url = `/api/auth/csrf`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching csrf'));
                    }
                });
        });
    };

    getDropDownData = (callbackRoute:string) => {
        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(callbackRoute)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching records for dropdown'));
                    }
                });
        });
    };

    getPaginatedDropDownData = (callbackRoute:string, filter:any, params:URLSearchParams) => {
        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.post(callbackRoute,{filter: filter}, {params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching records for dropdown'));
                    }
                });
        });
    };

    getDropDownTextFromValue = (route: string) => {
        return new Promise<string>((resolve, reject) => {
            axios.get(route)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching text for dropdown value'));
                    }
                })
                .catch((error:any) => {
                    reject(new Error(`Error saving data to the server - ${error.response.data.message}`));
                })
        });
    };

    getUpgradeDetailInfo = () => {
        let url = '/api/auth/getUpgradeDetailInfo';

        return new Promise<MenuItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching links for UpgradeDetails'));
                    }
                });
        });
    };

    getDetailPageInfo = (breadCrumbPath:string) => {
        let url = `/api/auth/getDetailPathInfo/${breadCrumbPath}`;

        return new Promise<MenuItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching links for detail page'));
                    }
                });
        });
    };

    getAppLongName = () => {
        const url = `/api/getAppLongName`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching app long name'));
                    }
                });
        });
    };

    getAppShortName = () => {
        const url = `/api/getAppShortName`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching app short name'));
                    }
                });
        });
    };

    getUserLongName = () => {
        const url = `/api/auth/userLongName`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching user name'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    getUserProfileInfo = () => {
        const url = `/api/auth/getProfileInfo`;

        return new Promise<UserProfile>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching user profile info'));
                    }
                });
        });
    };

    getMenuItems = () => {
        const url = `/api/auth/getMenuItems`;

        return new Promise<MenuItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching list of menu items'));
                    }
                });
        });
    };

    getDropDownOfType = (typeToGet: string) => {
        const url = `/api/auth/crudTableOptions/${typeToGet}`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching list of ' + typeToGet));
                    }
                });
        });
    };

    getTypeTableOptions = (typeTable: string) => {
        const url = `/api/auth/typeTableOptions/${typeTable}`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching options for ' + typeTable));
                    }
                });
        });
    };

    validateAndCreateNtc = (validateAndCreateRequest:ValidateNtcUpgradeRequest) => {
        const url = `/api/auth/createNtc`;
        return new Promise<NtcRfpCreateResponse>((resolve, reject) => {
            axios.post(url, validateAndCreateRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving NTC'));
                    }
                });
        });
    };

    validateAndCreateRfp = (validateAndCreateRequest:ValidateRfpUpgradeRequest) => {
        const url = `/api/auth/createRfp`;
        return new Promise<NtcRfpCreateResponse>((resolve, reject) => {
            axios.post(url, validateAndCreateRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving RFP'));
                    }
                });
        });
    };

    addUpgradeToNtc = (addRequest:NtcOrRfpUpgradeRequest) => {
        const url = `/api/auth/addUpgradeToNtc`;
        return new Promise<boolean>((resolve, reject) => {
            axios.patch(url, addRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error adding upgrade to ntc'));
                    }
                });
        });
    };

    removeUpgradeFromNtc = (removeRequest:NtcOrRfpUpgradeRequest) => {
        const url = `/api/auth/removeUpgradeFromNtc`;
        return new Promise<boolean>((resolve, reject) => {
            axios.patch(url, removeRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error removing upgrade from ntc'));
                    }
                });
        });
    };

    addUpgradeToRfp = (addRequest:NtcOrRfpUpgradeRequest) => {
        const url = `/api/auth/addUpgradeToRfp`;
        return new Promise<boolean>((resolve, reject) => {
            axios.patch(url, addRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error adding upgrade to rfp'));
                    }
                });
        });
    };

    removeUpgradeFromRfp = (removeRequest:NtcOrRfpUpgradeRequest) => {
        const url = `/api/auth/removeUpgradeFromRfp`;
        return new Promise<boolean>((resolve, reject) => {
            axios.patch(url, removeRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error removing upgrade from rfp'));
                    }
                });
        });
    };

    getUpgradePrevStatus = (upgradeId:string) => {
        const url = `/api/auth/getUpgradePrevStatus/${upgradeId}`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting upgrade previous status'));
                    }
                });
        });
    };

    getRfpFormDetails = (study: string) => {
        const url = `/api/auth/getCreateRfpDetails/${study}`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching data to build form'));
                    }
                });
        });
    };

    getCallOnChangeFields = (url:string, searchParam:string) => {
        url = url + searchParam;
        return new Promise<CallOnChangeResponse[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching callOnChangeResponse'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    }

    logout =() => {
        // Clear user token and profile data from localStorage
        return new Promise((resolve, reject) => {
            localStorage.removeItem('trac_token');
            localStorage.removeItem('userName');
            sessionStorage.removeItem('uuid');
            resolve(true);
        }) as any;
    };
}