import * as React from "react";
import {Divider, Dropdown, DropdownProps, Icon, Menu, Table} from "semantic-ui-react";
import NumberFormat from "react-number-format";

interface TableFooterProps {
    totalRows: number
    currentPage: number
    totalPages: number
    onPageChanged: (currentPage:number) => void
    onPageSizeChanged: (pageSize:number) => void
    pageSize?: number
}

interface TableFooterState {
    pageSize: number
}

const pagerOptions = [
    {
        key: '5',text:'5',value:'5'
    },
    {
        key: '10',text:'10',value:'10'
    },
    {
        key: '25',text:'25',value:'25'
    },
    {
        key: '50',text:'50',value:'50'
    },
    {
        key: '100',text:'100',value:'100'
    }
];

export class TableFooter extends React.Component<TableFooterProps, TableFooterState> {
    constructor(props: TableFooterProps, state: TableFooterState) {
        super(props, state);
        this.state = {
            pageSize: 5
        };
    }

    incrementPage = () => {
        this.props.onPageChanged(this.props.currentPage+1);
    };

    decrementPage = () => {
        this.props.onPageChanged(this.props.currentPage-1);
    };

    goToLastPage = () => {
        this.props.onPageChanged(this.props.totalPages);
    };

    goToFirstPage = () => {
        this.props.onPageChanged(1);
    };

    updateCurrentPage = (event:any) => {
        this.props.onPageChanged(+event.currentTarget.value);
    };

    onPageSizeChanged = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        if (data.value !== undefined) {
            this.setState({pageSize:+data.value});
            this.props.onPageSizeChanged(+data.value);
        }
    };

    render() {
        return (
            <Table attached={'bottom'} celled={false} style={{overflow:'scroll'}}>
                <Table.Footer fullWidth={true}>
                    <Table.Row textAlign={"center"}>
                        <Table.HeaderCell>
                            Total Pages: <NumberFormat value={this.props.totalPages} displayType={'text'} thousandSeparator={','}/>
                            <Divider hidden={true}/>
                            Total Rows: <NumberFormat value={this.props.totalRows} displayType={'text'} thousandSeparator={','}/>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <Menu pagination={true} compact={true} borderless={true}>
                                <Menu.Item as='a' icon={true} disabled={this.props.currentPage === 1} onClick={this.goToFirstPage}>
                                    <Icon name='angle double left' />
                                </Menu.Item>
                                <Menu.Item as='a' icon={true} disabled={this.props.currentPage === 1} onClick={this.decrementPage}>
                                    <Icon name='chevron left' />
                                </Menu.Item>
                                <Menu.Item>
                                    <input type={'number'} value={this.props.currentPage} onChange={this.updateCurrentPage} style={{textAlign:'center',border:'none',outline:'none'}} />
                                </Menu.Item>
                                <Menu.Item as='a' icon={true} onClick={this.incrementPage} disabled={this.props.currentPage === this.props.totalPages}>
                                    <Icon name='chevron right'/>
                                </Menu.Item>
                                <Menu.Item as='a' icon={true} onClick={this.goToLastPage} disabled={this.props.currentPage === this.props.totalPages}>
                                    <Icon name='angle double right' />
                                </Menu.Item>
                            </Menu>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            <span>Page Size</span>
                            <Divider hidden={true}/>
                            <Dropdown compact={true}
                                      options={pagerOptions} selection={true}
                                      defaultValue={this.props.pageSize ? this.props.pageSize.toString() : this.state.pageSize.toString()}
                                      onChange={this.onPageSizeChanged}/>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        )
    }
}
