import * as React from 'react';
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {Button, ButtonProps, Container, Dimmer, Icon, Loader, Segment} from "semantic-ui-react";
import jsPDF from "jspdf";
import LetterApi from "../../services/letter-service";
import {toast} from "react-toastify";

interface TracLetterProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    tracRecord: any;
    tracRecordIndex: number;
    letterType: string;
    letterLoading ?: boolean
    setLetterLoading: (loadingStatus:boolean) => void;
}

interface TracLetterState {
    letter:string
}

let fileDownload = require('js-file-download');
class TracLetter extends React.Component<TracLetterProps,TracLetterState> {
    private client: LetterApi;

    constructor(props: TracLetterProps) {
        super(props);
        this.state = {
            letter: ''
        };

        this.client = new LetterApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getLetter();
    }

    componentDidUpdate(prevProps: Readonly<TracLetterProps>, prevState: Readonly<TracLetterState>) {
        if(this.props.letterType !== prevProps.letterType) {
            this.getLetter();
        }
    }

    getLetter = () => {
        let letterId = this.getLetterId();
        if(letterId !== -1) {
            this.client.getLetter(this.props.letterType, letterId)
                .then((response: string) => {
                    this.setState({letter: response});
                    this.props.setLetterLoading(false);
                });
        }
    };

    getLetterId = ():number => {
        let letterId:number = -1;
        switch(this.props.letterType) {
            case 'ntc':
                letterId = this.props.tracRecord.ntcId;
                break;
            case 'rfp':
                letterId = this.props.tracRecord.rfpId;
                break;
            case 'NTC Acknowledgement':
                letterId = this.props.tracRecord.ntcId;
                break;
        }

        return letterId;
    };

    doNothing = () => {

    };

    downloadFile = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        let letterId = this.getLetterId();
        if(letterId !== -1) {
            /*switch (data.downloadType) {
                case 'word':
                    this.client.downloadLetter(this.props.letterType, letterId, data.downloadType)
                        .then((response:any) => {
                            fileDownload(response.data,response.headers["content-disposition"].split("=")[1]);
                        });
                    //fileDownload(this.state.letter, this.props.letterType.toUpperCase() + '_Document_' + letterId + '.doc', 'application/msword');
                    break;
                case 'pdf':
                    this.client.downloadLetter(this.props.letterType, letterId, data.downloadType)
                        .then((response:any) => {
                            fileDownload(response.data,response.headers["content-disposition"].split("=")[1]);
                            toast.success('Successfully downloaded letter as ' + data.downloadType + " document");
                        });
                    break;
            }*/

            this.client.downloadLetter(this.props.letterType, letterId, data.downloadType)
                .then((response:any) => {
                    fileDownload(response.data,response.headers["content-disposition"].split("=")[1]);
                    toast.success('Successfully downloaded letter ' + response.headers["content-disposition"].split("=")[1]);
                });
        }
    };

    getLetterTypeForDisplay = (currentLetterType:string) => {
        let displayValue = currentLetterType;
        if(currentLetterType === 'NTC Acknowledgement') {
            displayValue = 'ntc';
        }

        return displayValue.toUpperCase();
    };

    makeLetterTypeHumanReadable = (letterType:string) => {
        let displayValue = letterType;
        displayValue = displayValue.toUpperCase();
        /*if(letterType === 'ntcAcknowledgementLetter') {
            displayValue = 'NTC Acknowledgement';
        }*/

        return displayValue;
    };

    render() {
        let letterId = this.getLetterId();
        return (
            <React.Fragment>
                <div className={'letterHeaderDiv'}>
                    <div className={'letterHeaderTitleDiv'}>
                        <h2>{this.makeLetterTypeHumanReadable(this.props.letterType)} Letter for {this.getLetterTypeForDisplay(this.props.letterType)}_ID {letterId}</h2>
                    </div>
                    <Button.Group floated={'right'}>
                        <Button icon={true} labelPosition='left' downloadType={'word'} onClick={this.downloadFile}>
                            <Icon name={'download'} />
                            Word
                        </Button>
                        <Button.Or />
                        <Button positive={true} icon={true} labelPosition='right' downloadType={'pdf'} onClick={this.downloadFile}>
                            PDF
                            <Icon name={'download'} />
                        </Button>
                    </Button.Group>
                </div>
                <Segment raised={true}>
                    <Container fluid={true}>
                        <div>
                            <div id={'letter'}
                                dangerouslySetInnerHTML={{
                                    __html: this.state.letter
                                }}>
                            </div>
                        </div>
                    </Container>
                    <Dimmer className={'letterDimmer'} active={this.props.letterLoading} inverted={true}>
                        <Loader className={'gridLoading'} content='Loading'/>
                    </Dimmer>
                </Segment>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(TracLetter);