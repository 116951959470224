import * as React from 'react';
import {
    Dropdown, DropdownProps,
    Input,
    Form,
    InputOnChangeData, Checkbox, CheckboxProps, TextArea, TextAreaProps
} from "semantic-ui-react";
import {connect} from "react-redux";
import ReactDatePicker from "react-datepicker";
import GeneralApi from "../../services/general-services";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import moment from "moment";
import {SyntheticEvent} from "react";
import NumberInput from "./NumberInput";
import PaginatedDropDown from "./PaginatedDropDown";

interface TracWebFormElementProps {
    metaData: any
    data: any
    elementError:boolean
    formSaveAttempted: boolean
    idForObjects: number
    setElementError: (metaData:any, errorState:boolean) => void;
    handleSpecializedClickLogic: (metaData:any) => void;
}

interface TracWebFormElementState {
    dropDownLoading: boolean
    dropDownOptions: DropDownItem[]
    elementValue: any,
    elementText: any
    dropDownOption: DropDownItem
}

const includes = require('lodash/includes');
class TracWebFormElement extends React.Component<TracWebFormElementProps,TracWebFormElementState> {
    private generalClient: GeneralApi;

    constructor(props: TracWebFormElementProps) {
        super(props);
        this.state = {
            dropDownLoading: false,
            dropDownOptions: [],
            elementValue: '',
            elementText: '',
            dropDownOption: {
                key: '',
                text: '',
                value: ''
            }
        };

        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        let elementValue, elementText = '';
        elementValue = this.props.data[this.props.metaData.accessor];
        elementText = this.props.metaData.DropDown !== undefined ? this.props.metaData.DropDown.chosenText : '';

        if(elementValue !== null && elementValue !== undefined && elementValue !== '') {
            this.setState({elementValue, elementText});
        }
    }

    componentDidUpdate(prevProps: Readonly<TracWebFormElementProps>, prevState: Readonly<TracWebFormElementState>) {
        if(this.props.data !== prevProps.data) {
            let elementValue, elementText = '';
            elementValue = this.props.data[this.props.metaData.accessor];
            elementText = this.props.metaData.DropDown !== undefined ? this.props.metaData.DropDown.chosenText : '';

            if(elementValue !== undefined) {
                this.setState({elementValue, elementText});
            }
        }
    }

    fetchDropDownData = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let dropDownOptions:DropDownItem[];
        this.generalClient.getDropDownData(data.metaData.DropDown.callbackRoute)
            .then((response:DropDownItem[]) => {
                this.setState({dropDownOptions:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };

    getEditOrAddElement = () => {
        return this.props.data;
    };

    onDateChange = (e:any,c:any,metaData:any) => {
        let record;
        this.props.data[metaData.accessor] = e;

        record = this.props.data[metaData.accessor];
        let errorState = false;
        if((e === '' || e === null || e === undefined) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
        this.setState({elementValue:record});
    };

    onDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let textValue = '';
        this.props.data[this.props.metaData.accessor] = data.value;
        if(!data.multiple) {
            if (data.options !== undefined) {
                let dropDownOption: any = data.options.filter(obj => {
                    return obj.value === data.value
                });

                if(Array.isArray(dropDownOption) && dropDownOption.length > 0) {
                    textValue = dropDownOption[0].text;
                }
            }
        }
        this.props.metaData.DropDown.chosenText = textValue;
        let errorState = false;
        if((data.value === '' || data.value === null || data.value === undefined || (data.multiple && (!Array.isArray(data.value) || !data.value.length))) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
        this.props.handleSpecializedClickLogic(this.props.metaData);
        this.setState({elementValue:data.value, elementText:textValue});
    };

    onInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        /*if((this.props.metaData.type === 'Integer' || this.props.metaData.type === 'BigDecimal' || this.props.metaData.type === 'Long') && this.props.metaData.format !== '') {
            let formattedNumberData:any = {};
            formattedNumberData.metaValue = this.props.metaData;
            formattedNumberData.value = event.target.value;
            this.props.addElementChange(event, formattedNumberData);
            data = formattedNumberData;
        }
        else {
            this.props.addElementChange(event, data);
        }*/
        this.props.data[this.props.metaData.accessor] = event.target.value;
        this.setState({elementValue:event.target.value});
        let errorState = false;
        if((data.value === '' || data.value === null || data.value === undefined) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
    };

    handleCheckBoxChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.props.data[this.props.metaData.accessor] = data.checked;
        let errorState = false;
        if((data.checked === null || data.checked === undefined) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
        this.setState({elementValue:data.checked, elementText:data.text});
    };

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        this.props.data[this.props.metaData.accessor] = event.target.value;
        this.setState({elementValue:event.target.value});
        let errorState = false;
        if((data.value === '' || data.value === null || data.value === undefined) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
    };

    getPlaceHolderForNumberInput = () => {
        let placeholder = '';
        if(this.props.metaData.format !== '') {
            switch(this.props.metaData.format) {
                case 'currency':
                    placeholder = '$0.00';
                    break;
                case 'percentage':
                    placeholder = '0%';
                    break;
            }
        }

        return placeholder;
    };

    returnReactElementBasedOnType = () => {
        let readOnly = false;
        readOnly = !this.props.metaData.editable;
        if(this.props.metaData.DropDown && this.props.metaData.DropDown.callbackRoute) {
            if(!this.props.metaData.DropDown.pageable) {
                return (
                    <Form.Dropdown
                        className={'gridFormDropdown'}
                        onClick={this.fetchDropDownData}
                        search={true}
                        selection={true}
                        multiple={this.props.metaData.DropDown.multipleSelection !== undefined ? this.props.metaData.DropDown.multipleSelection : false}
                        onFocus={this.fetchDropDownData}
                        loading={this.state.dropDownLoading}
                        options={this.state.dropDownOptions}
                        metaData={this.props.metaData}
                        onChange={this.onDropDownChange}
                        text={this.state.elementText}
                        value={this.state.elementValue}
                        error={this.props.elementError}
                        clearable={true}
                        disabled={readOnly}
                    />
                )
            }
            else {
                return (
                    <PaginatedDropDown
                        dropDownInfo={this.props.metaData.DropDown}
                        onChange={this.onDropDownChange}
                        metaData={this.props.metaData}
                        multiple={this.props.metaData.DropDown.multipleSelection !== undefined ? this.props.metaData.DropDown.multipleSelection : false}
                        text={this.state.elementText}
                        value={this.state.elementValue}
                        error={this.props.elementError}
                        disabled={readOnly}
                        idForDropDown={this.props.idForObjects}
                    />
                )
            }
        }
        else {
            switch (this.props.metaData.type) {
                case "LocalDateTime":
                    let date:any = this.state.elementValue;
                    if(moment(date).isValid()) {
                        date = moment(this.state.elementValue, "MM/DD/YYYY").toDate();
                    }
                    else {
                        date = null;
                    }
                    return (
                        <Form.Input error={this.props.elementError}>
                            <ReactDatePicker
                                readOnly={readOnly}
                                className={'datepickerinput'}
                                selected={date}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, this.props.metaData)}
                                placeholderText={'Select Date'}
                                autoComplete={'off'}
                                isClearable={!!this.getEditOrAddElement()[this.props.metaData.accessor]}
                                todayButton={'Today'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                            />
                        </Form.Input>
                    );
                case 'BigDecimal':
                case 'Integer':
                case 'Long':
                    let placeholder = this.getPlaceHolderForNumberInput();
                    return (
                        <NumberInput
                            metaData={this.props.metaData}
                            placeholder={placeholder}
                            type={'text'}
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={this.state.elementValue}
                            onChange={this.onInputChange}
                            metaValue={this.props.metaData}
                            error={this.props.elementError}
                        />
                    );
                case 'checkbox':
                case 'boolean':
                    return (
                        <Checkbox
                            className={'tracWebFormCheckBox'}
                            onChange={this.handleCheckBoxChange}
                            checked={this.state.elementValue}
                            readOnly={readOnly}
                            disabled={readOnly}
                        />
                    )
                case 'textarea':
                    return (
                        <TextArea
                            className={'scertTextArea'}
                            value={this.state.elementValue}
                            onChange={this.handleTextAreaChange}
                            readOnly={readOnly}
                            disabled={readOnly}
                        />
                    )
                default:
                    return (
                        <Form.Input
                            readOnly={readOnly}
                            disabled={readOnly}
                            value={this.state.elementValue}
                            onChange={this.onInputChange}
                            metaValue={this.props.metaData}
                            error={this.props.elementError}
                        />
                    )
            }
        }
    };

    render() {
        return (
            this.returnReactElementBasedOnType()
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(TracWebFormElement);