import axios from "axios";
import {toast} from "react-toastify";

export default class UpgradeService {

    constructor() {
    }

    submitUpgradeRequest = (createUpgradeRequest: CreateUpgradeRequest) => {
        const url = `/api/auth/createUpgrade`;

        return new Promise((resolve, reject) => {
            axios.post(url, createUpgradeRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error submitting upgrade request'));
                    }
                });
        });
    };

    getInitialUpgradeCreateForm = () => {
        const url = `/api/auth/getInitialUpgradeCreateDetails`;
        let params = new URLSearchParams();
        params.append("ui","true");

        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching initial upgrade create form details'));
                    }
                })
                .catch((error:any) => {
                    toast.error(JSON.stringify(error));
                })
        });
    };

    getGiUpgradeCreateForm = () => {
        const url = `/api/auth/getGiUpgradeFormDetails`;
        let params = new URLSearchParams();
        params.append("ui","true");

        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching generation interconnection upgrade create form details'));
                    }
                });
        });
    };

    getReliabilityUpgradeCreateForm = () => {
        const url = `/api/auth/getReliabilityUpgradeFormDetails`;
        let params = new URLSearchParams();
        params.append("ui","true");

        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching reliability/economic upgrade create form details'));
                    }
                });
        });
    };

    getTssUpgradeCreateForm = () => {
        const url = `/api/auth/getTssUpgradeFormDetails`;
        let params = new URLSearchParams();
        params.append("ui","true");

        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching transmission service upgrade create form details'));
                    }
                });
        });
    };

    getUpgradeFormSectionMetaData = (endpoint: string) => {
        const url = `/api/auth${endpoint}`;
        let params = new URLSearchParams();
        params.append("ui","true");

        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching form section metaData'));
                    }
                });
        });
    };

    downloadUpgradeTemplate = (templateType:string) => {
        const url = `/api/auth/downloadUpgradeTemplate/${templateType}`;

        return new Promise((resolve, reject) => {
            axios.get(url, {
                responseType: 'arraybuffer'
            }).then(resp => {
                resolve(resp);
            }).catch(err => {
                if(!err.response.data.badToken) {
                    reject(new Error(`Error downloading file - ${err.response.data.message}`));
                }
            });
        });
    };
}