import axios from "axios";
import {toast} from "react-toastify";
import Cookies from "universal-cookie";

const cookie = new Cookies();
export default class OpenService {

    constructor() {
    }

    login = (credentials:LoginRequestBody) => {
        const url = `/api/login`;

        return new Promise<LoginResponse>((resolve, reject) => {
            axios.post(url, credentials)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error posting login data'));
                    }
                });
        });
    };

    getCsrf = () => {
        const url = `/api/csrf`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching csrf'));
                    }
                });
        });
    };

    getEnvironment = () => {
        const url = `/api/getEnvironment`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        //this.environment = data;
                        resolve(data);
                    } else {
                        reject(new Error('error getting app environment'));
                    }
                });
        });
    };

    getClientCert = () => {
        const url = `/api/getClientCert`;

        return new Promise<Certificate>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting client cert'));
                    }
                });
        });
    };

    getPrincipal = () => {
        const url = `/api/principal`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        console.log(data);
                        resolve(data);
                    } else {
                        reject(new Error('error getting principal'));
                    }
                });
        });
    };

    getPortalLink = () => {
        const url = `/api/getPortalLink`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        console.log(data);
                        resolve(data);
                    } else {
                        reject(new Error('error getting MarketPlace portal address'));
                    }
                });
        });
    };

    loggedIn = (getEnvironment?:boolean) => {
        /*if(getEnvironment !== undefined && getEnvironment) {
            this.getEnvironment()
                .then((response: string) => {
                    return (cookie.get('sppauth' + response));
                });
        }
        return false;*/

        /*if(getEnvironment !== undefined && getEnvironment) {
            return (sessionStorage.getItem('sppauth'));
        }
        else {
            return (sessionStorage.getItem('sppauth'));
        }*/

        return true;
    };
}