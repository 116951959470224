import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import {Button, ButtonProps, Dropdown, Header, Icon, Input, Popup} from "semantic-ui-react";
import TracModal from "../GenericComponents/TracModal";
import ExternalApi from "../../services/external-service";

interface ScertRecordsPageProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface ScertRecordsPageState {
    scertRecordPageModalOpen: boolean
    refreshGrid: boolean
    loading: boolean
}

class ScertRecordsPage extends React.Component<ScertRecordsPageProps,ScertRecordsPageState> {
    private externalClient: ExternalApi;

    constructor(props: ScertRecordsPageProps) {
        super(props);
        this.state = {
            scertRecordPageModalOpen: false,
            refreshGrid: false,
            loading: false
        }

        this.externalClient = new ExternalApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    getExtraHeaderOptions = () => {
        return [
            this.getBulkMarkQuarterlyReviewButton()
        ];
    };

    openAllQuarterlyReviewModal = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        this.setState({scertRecordPageModalOpen: true, refreshGrid: false});
    };

    getBulkMarkQuarterlyReviewButton = () => {
        return (
            <Popup trigger={<Button icon='checkmark box' color={'blue'} onClick={this.openAllQuarterlyReviewModal}/>} content='Mark All Quarterly Review Indicators' />
        )
    };

    ModalHeader = () => {
        return (
            <Header as='h3'>
                <Icon name={'checkmark box'} />
                Confirm Quarterly Review Bulk Update
            </Header>
        )
    };

    ModalContent = () => {
        return (
            <div>Are you sure you want to update Quarterly Review Indicator for all Scert records available?</div>
        )
    };

    ModalActions = () => {
        return (
            <div>
                <Button content={'OK'} color={'green'} loading={this.state.loading} onClick={this.bulkUpdateQuarterlyReviewInd}/>
                <Button content={'Cancel'} color={'red'} onClick={this.ModalClose}/>
            </div>
        )
    };

    ModalClose = () => {
        this.setState({scertRecordPageModalOpen: false});
    };

    bulkUpdateQuarterlyReviewInd = () => {
        this.externalClient.bulkUpdateScertQuarterlyReviewInd()
            .then((resp:string) => {
                toast.success("successfully updated all Quarterly Review Indicators");
                this.setState({scertRecordPageModalOpen: false, refreshGrid: true, loading: false});
            });
        this.setState({loading: true});
    }

    render() {
        return (
            <React.Fragment>
                <TracModal header={this.ModalHeader()} content={this.ModalContent} actions={this.ModalActions}
                           open={this.state.scertRecordPageModalOpen} closeOnDimmerClick={true} closeIcon={true}
                           onClose={this.ModalClose}
                />
                <GridContainer
                    getGridDataUrl={'/api/auth/getScertRecords'}
                    gridPaginated={true}
                    showUtilityColumns={false}
                    exportLink={'getScertRecords'}
                    gridTitle={'SCERT Records'}
                    gridId={'scertRecordGrid'}
                    checkBoxEndpoint={'/api/auth/updateQuarterlyReview'}
                    extraHeaderOptions={this.getExtraHeaderOptions()}
                    externalGridRefresh={this.state.refreshGrid}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ScertRecordsPage);