import * as React from "react";
import {Button, ButtonProps, Icon, Popup} from "semantic-ui-react";
import GridApi from "../../services/grid-service";

interface ExportButtonProps {
    exportType: 'excel' | 'csv'
    pageName: string
    queryString?: string
}

interface ExportButtonState {
    exporting:boolean
}

let fileDownload = require('js-file-download');
export class ExportButton extends React.Component<ExportButtonProps, ExportButtonState> {
    private client: GridApi;

    constructor(props: ExportButtonProps, state: ExportButtonState) {
        super(props, state);
        this.state = {
            exporting: false
        };

        this.client = new GridApi();
    }

    export = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.client.export(this.props.exportType,this.props.pageName,this.props.queryString)
            .then((response:any) => {
                fileDownload(response.data,`${response.headers.filename}`);
                this.setState({exporting:false})
            });
        this.setState({exporting:true})
    };

    render() {
        return (
            <Popup basic={true}
                   trigger={
                       <Button color={this.props.exportType === 'csv' ? 'orange' : 'olive'} onClick={this.export} loading={this.state.exporting}
                       >
                           <Icon name={this.props.exportType === 'csv' ? 'download' :'file excel'}/>
                           {this.props.exportType === 'csv' ? 'CSV' : 'Excel'}
                       </Button>}
                   content={`Export to ${this.props.exportType}`} />
        )
    }
}
