import * as React from 'react';
import {Container, Divider, Grid, Header, Segment} from "semantic-ui-react";
import GeneralApi from "../services/general-services";
import InitialsSegment from "./GenericComponents/InitialsSegment";
import {connect} from "react-redux";
import * as actionTypes from "../store/action";
import {createBreadCrumbBasedOnPage} from "../Utils/HelperFunctions";

interface ProfileProps {
    location: any;
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface ProfileState {
    userProfileInfo: UserProfile;
    dataLoaded: boolean;
    userAuthorized: boolean;
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    private client: GeneralApi;

    constructor(props: ProfileProps) {
        super(props);
        this.state = {
            userProfileInfo: {
                userName: '',
                avatarColor: null,
                userLongName: '',
                firstName: '',
                lastName: '',
                company: '',
                phoneNumber: '',
                email: ''
            },
            dataLoaded: false,
            userAuthorized: false
        };

        this.client = new GeneralApi();
    }

    componentDidMount() {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getUserProfile();
    }

    getUserProfile = () => {
        this.client.getUserProfileInfo()
            .then((response:UserProfile) => {
                this.setState({userProfileInfo: response, dataLoaded: true});
            });
    };

    public render() {
        let { userProfileInfo, dataLoaded, userAuthorized } = this.state;

        return (
            <Container fluid={true} className={'profilePage'}>
                {this.props.loggedIn &&
                    <div className={'profileHeader'}>
                        <Header as={'h2'}>
                            User Profile
                        </Header>
                    </div>
                }
                {dataLoaded && this.props.loggedIn &&
                    <div>
                        <div className={'userInfoSection'}>
                            <InitialsSegment
                                className={'inlineSegment'}
                                avatarColor={userProfileInfo.avatarColor}
                                userFirstName={userProfileInfo.firstName}
                                userLastName={userProfileInfo.lastName}
                                size={'huge'}
                            />
                            <Segment basic={true} id={'profileInfo'} className={'inlineSegment'}>
                                <p id={'profileName'}>
                                    {userProfileInfo.userLongName}
                                </p>
                                <Divider className={'profileDivider'}/>
                                <Grid columns={'equal'}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            Username
                                        </Grid.Column>
                                        <Grid.Column>
                                            {userProfileInfo.userName}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            LongName
                                        </Grid.Column>
                                        <Grid.Column>
                                            {userProfileInfo.userLongName}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            Email
                                        </Grid.Column>
                                        <Grid.Column>
                                            {userProfileInfo.email === '' ? 'null' : userProfileInfo.email}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            Company
                                        </Grid.Column>
                                        <Grid.Column>
                                            {userProfileInfo.company === '' ? 'null' : userProfileInfo.company}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            Phone Number
                                        </Grid.Column>
                                        <Grid.Column>
                                            {userProfileInfo.phoneNumber === '' ? 'null' : userProfileInfo.phoneNumber}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        </div>
                    </div>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);