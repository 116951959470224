import * as React from 'react';
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {Button, ButtonProps, Container, Form, Header, Icon, Segment, TextArea, TextAreaProps} from "semantic-ui-react";
import TracLetter from "./TracLetter";
import ApprovalApi from "../../services/approval-service";
import {toast} from "react-toastify";
import TracModal from "../GenericComponents/TracModal";
import {Redirect} from "react-router";
import {AccordionSegment} from "../GenericComponents/AccordianSegment";
import GridContainer from "../GenericComponents/Grids/GridContainer";
import history from "../../history";

interface TracLetterRecordPageProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    TracLetterRecordPageOpen: boolean;
    tracRecord: any;
    //location:any;
    tracRecordIndex: number;
    letterType: string;
    backToGrid: (gridVisible:boolean, TracLetterRecordPageVisible:boolean) => void;
    setLetterType: (letterType:string) => void;
    location: any;
}

interface TracLetterRecordPageState {
    approvalRequest: ApprovalRequest;
    ntcLetterEditPageOpen: boolean;
    tracRecord: any;
    userCanUpdate: boolean
    userCanEdit: boolean
    approveOrReject: string
    approveOrRejectComment: string
    modalOpen: boolean
    letterLoading: boolean
    currentStageOfNtc: string
    externalUser: boolean
}

class TracLetterRecordPage extends React.Component<TracLetterRecordPageProps,TracLetterRecordPageState> {
    private client: ApprovalApi;

    constructor(props: TracLetterRecordPageProps) {
        super(props);
        this.state = {
            approvalRequest: {
                approvalObject: null,
                approvalTrackerId: -1,
                approvalFlowName: '',
                approvalFlowType: '',
                approvalComment: ''
            },
            ntcLetterEditPageOpen:false,
            tracRecord: '',
            userCanUpdate: false,
            userCanEdit: false,
            approveOrReject: 'Approval',
            approveOrRejectComment: '',
            modalOpen: false,
            letterLoading: false,
            currentStageOfNtc: '',
            externalUser: true
        };

        this.client = new ApprovalApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    componentDidUpdate(prevProps: Readonly<TracLetterRecordPageProps>, prevState: Readonly<TracLetterRecordPageState>) {
        if(this.props.TracLetterRecordPageOpen !== prevProps.TracLetterRecordPageOpen) {
            if(this.state.userCanUpdate) {
                this.setState({userCanUpdate: false});
            }
            if(this.props.tracRecord.approvalTrackerId !== null) {
                this.client.checkUserCanUpdate(this.props.tracRecord.approvalTrackerId.id)
                    .then((response: NtcDetailsResponse) => {
                        this.setState({userCanUpdate: response.userHasNeededRole, externalUser: response.externalUser, userCanEdit: response.userCanEdit, currentStageOfNtc: response.stageOfNtc});
                    });
            }
        }
    };

    backToGrid = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        let letterType = this.props.letterType;
        if(this.props.letterType === 'NTC Acknowledgement') {
            this.props.setLetterType('ntc');
            letterType = 'ntc';
        }

        if(letterType === 'ntc') {
            const currentPath = this.props.location.pathname;
            let urlParts = currentPath.split('/');
            let stateObj = {from: urlParts[1]};
            window.history.replaceState(stateObj, "NTC", '/' + urlParts[1]);
        }

        this.props.backToGrid(true, false);
    };

    editLetter = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        this.setState({ntcLetterEditPageOpen:true, tracRecord:this.props.tracRecord});
        //this.props.editLetter(true, false);
    };

    setUpAndOpenComment = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        let statusType = '';
        if(data.status === 'Approve' || data.status === 'PartialAcceptance') {
            if(data.status === 'Approve') {
                statusType = 'Approval';
            }
            else {
                statusType = 'Conditional Approval';
            }
        }
        else {
            statusType = 'Rejection';
        }
        let record = this.props.tracRecord;
        record['classType'] = this.props.letterType;
        let approvalObject:ApprovalRequest = {
            approvalObject: record,
            approvalTrackerId: this.props.tracRecord.approvalTrackerId.id,
            approvalFlowName: this.props.letterType,
            approvalFlowType: data.status,
            approvalComment: ''
        };

        console.log(approvalObject);

        this.setState({modalOpen: true, approvalRequest: approvalObject, approveOrReject: statusType});
    };

    standardApproval = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        let statusType = '';
        if(data.status === 'PartialAcceptance') {
            statusType = 'Conditional Approval';
        }
        else {
            if(data.status === 'Restart') {
                statusType = 'Restart';
            }
            else {
                statusType = 'Approval';
            }
        }
        let record = this.props.tracRecord;
        record['classType'] = this.props.letterType;
        let approvalObject:ApprovalRequest = {
            approvalObject: record,
            approvalTrackerId: this.props.tracRecord.approvalTrackerId.id,
            approvalFlowName: this.props.letterType,
            approvalFlowType: data.status,
            approvalComment: ''
        };

        this.setState({approvalRequest: approvalObject, approveOrReject: statusType});

        this.updateStatus(approvalObject);
    };

    makeCallToUpdateLetter = () => {
        let {approvalRequest} = this.state;
        approvalRequest.approvalComment = this.state.approveOrRejectComment;
        this.updateStatus(approvalRequest);
    };

    updateStatus = (approvalObject?:ApprovalRequest) => {
        this.client.updateRecordApprovalStatus(approvalObject !== undefined ? approvalObject : this.state.approvalRequest)
            .then((response:any) => {
                let currentStage = response.approvalStage !== '' ? response.approvalStage : this.state.currentStageOfNtc;
                //toast.success('Successfully updated status of ' + this.props.letterType.toUpperCase());
                //let extraToastContent = '';
                let toastMessage = 'Successful ' + this.state.approveOrReject + ' of ' + this.props.letterType.toUpperCase() + '. ';
                if(this.state.approveOrReject !== 'Rejection') {
                    if(currentStage === 'MEMBER_APPROVED') {
                        toastMessage += 'Acknowledgment letter has been loaded for viewing, and sent in email.  Thank you.';
                        //extraToastContent = 'Acknowledgment letter has been loaded for viewing, and sent in email.  Thank you.';
                        //toast.success('Successful ' + this.state.approveOrReject + ' of ' + this.props.letterType.toUpperCase() + '. ' + extraToastContent);
                        this.setState({modalOpen: false, letterLoading: true, currentStageOfNtc: currentStage});
                        this.props.setLetterType('NTC Acknowledgement');
                    }
                    else {
                        this.setState({modalOpen: false, currentStageOfNtc: currentStage});
                    }
                }
                else {
                    //toast.success('Successful ' + this.state.approveOrReject + ' of ' + this.props.letterType.toUpperCase() + '. ' + extraToastContent);
                    this.setState({modalOpen: false, currentStageOfNtc: currentStage});
                }
                if(response.status !== 'SUCCESS') {
                    this.setState({modalOpen: false, letterLoading: false, currentStageOfNtc: currentStage});
                    toast.error(response.message);
                }
                else {
                    toast.success(toastMessage);
                }
                if(currentStage !== 'MEMBER_APPROVED') {
                    this.props.backToGrid(true, false);
                }
            })
            .catch((error:any) => {
                toast.error('Error updating status of ' + this.props.letterType.toUpperCase());
            });
    };

    ModalHeader = () => {
        let iconName:any, header;
        if(this.state.approveOrReject === 'Approval') {
            iconName = 'thumbs up';
            header = 'Confirm Approval';
        }
        else {
            if(this.state.approveOrReject === 'Conditional Approval') {
                iconName = 'thumbs up';
                header = 'Confirm Conditional Approval';
            }
            else {
                if(this.state.approveOrReject === 'Rejection') {
                    iconName = 'thumbs down';
                    header = 'Confirm Rejection';
                }
            }
        }
        return (
            <Header as='h3'>
                <Icon name={iconName} />
                {header}
            </Header>
        )
    };

    onCommentTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        this.setState({approveOrRejectComment: event.target.value});
    };

    ModalContent = () => {
        if(this.state.currentStageOfNtc === 'ISSUED') {
            return (
                <div>
                    <p>By clicking this button, you accept this as your written commitment in compliance with BP 7060 and Attachment Y.</p>
                    <p>Add comment for {this.state.approveOrReject.toLowerCase()}:</p>
                    <Form>
                        <TextArea placeholder='Comment' value={this.state.approveOrRejectComment} onChange={this.onCommentTextAreaChange} />
                    </Form>
                </div>
            )
        }
        else {
            return (
                <div>
                    <p>Are you sure you want to perform {this.state.approveOrReject.toLowerCase()}?</p>
                </div>
            )
        }
    };

    ModalActions = () => {
        return (
            <div>
                <Button content={'Ok'} color={'green'} onClick={this.makeCallToUpdateLetter}/>
                <Button content={'Cancel'} color={'red'} onClick={this.ModalClose}/>
            </div>
        )
    };

    ModalClose = () => {
        this.setState({modalOpen: false});
    };

    setLetterLoading = (loadingStatus: boolean) => {
        this.setState({letterLoading: loadingStatus});
    }

    getLetterTypeForDisplay = (currentLetterType:string) => {
        let displayValue = currentLetterType;
        if(currentLetterType === 'NTC Acknowledgement') {
            displayValue = 'ntc';
        }

        return displayValue.toUpperCase();
    };

    render() {
        if (this.state.ntcLetterEditPageOpen) {
            return (
                <Redirect to={{
                    pathname: '/ntcLetterEdit',
                    state: {data: this.state.tracRecord}
                }}/>)
        } else {
            return (
                <React.Fragment>
                    {this.props.TracLetterRecordPageOpen &&
                    <Container fluid={true}>
                        <TracModal header={this.ModalHeader()} content={this.ModalContent} actions={this.ModalActions}
                                   open={this.state.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                                   onClose={this.ModalClose}
                        />
                        <Button icon={true} labelPosition='left' onClick={this.backToGrid}>
                            <Icon name={'arrow left'}/>
                            Back to {this.getLetterTypeForDisplay(this.props.letterType)} Grid
                        </Button>
                        {this.props.letterType === 'ntc' && (this.state.currentStageOfNtc !== 'MEMBER_APPROVED' && this.state.currentStageOfNtc !== 'REJECTED') && (this.state.userCanEdit) &&
                            <Button icon={true} labelPosition='left'
                                    onClick={this.editLetter}>
                                <Icon name={'edit'}/>
                                Edit Letter
                            </Button>
                        }
                        <TracLetter
                            tracRecord={this.props.tracRecord}
                            tracRecordIndex={this.props.tracRecordIndex}
                            letterType={this.props.letterType}
                            letterLoading={this.state.letterLoading}
                            setLetterLoading={this.setLetterLoading}
                        />
                        {!this.state.externalUser && this.props.letterType === 'ntc' && this.state.currentStageOfNtc !== 'ISSUED' && this.state.currentStageOfNtc !== 'MEMBER_APPROVED' && this.state.currentStageOfNtc !== '' &&
                            <Container fluid={true}>
                                <AccordionSegment segmentHeader={'Letter Comments'}>
                                    <GridContainer
                                        getGridDataUrl={'/api/auth/getLetterComments/' + this.props.tracRecord.approvalTrackerId.id}
                                        gridTitle={'View Letter Comments'}
                                        gridId={'letterCommentGrid'}
                                    />
                                </AccordionSegment>
                            </Container>
                        }
                        {this.props.letterType !== 'NTC Acknowledgement' && (this.state.currentStageOfNtc !== 'MEMBER_APPROVED' && this.state.currentStageOfNtc !== 'REJECTED') &&
                        <Container fluid={true}>
                            <Button floated={'left'} icon={true} labelPosition='left' status={'Approve'}
                                    disabled={this.state.userCanUpdate} onClick={this.setUpAndOpenComment}>
                                <Icon name={'thumbs up'}/>
                                {this.state.currentStageOfNtc === 'ISSUE_PENDING' ? 'Issue ' : 'Approve '}{this.props.letterType.toUpperCase().includes('NTC') ? 'NTC' : this.props.letterType.toUpperCase()}
                            </Button>
                            {this.state.currentStageOfNtc !== '' && this.state.currentStageOfNtc === 'ISSUED' &&
                            <Button floated={'left'} icon={true} labelPosition='left' status={'PartialAcceptance'}
                                    disabled={this.state.userCanUpdate} onClick={this.setUpAndOpenComment}>
                                <Icon name={'thumbs up'}/>
                                Conditionally
                                Approve {this.props.letterType.toUpperCase().includes('NTC') ? 'NTC' : this.props.letterType.toUpperCase()}
                            </Button>
                            }
                            <Button floated={'right'} icon={true} labelPosition='left' status={'Reject'}
                                    disabled={this.state.userCanUpdate} onClick={this.setUpAndOpenComment}>
                                <Icon name={'thumbs down'}/>
                                Reject {this.props.letterType.toUpperCase().includes('NTC') ? 'NTC' : this.props.letterType.toUpperCase()}
                            </Button>
                        </Container>
                        }
                        {this.state.currentStageOfNtc === 'REJECTED' &&
                        <Container fluid={true}>
                            {!this.state.userCanUpdate ?
                                <Button floated={'right'} icon={true} labelPosition='left' status={'Restart'}
                                        disabled={this.state.userCanUpdate} onClick={this.standardApproval}>
                                    <Icon name={'thumbs up'}/>
                                    Restart {this.props.letterType.toUpperCase().includes('NTC') ? 'NTC' : this.props.letterType.toUpperCase()}
                                </Button>
                                :
                                <div style={{color: 'red'}}>Letter is currently in status of REJECTED. Please contact
                                    Admin in order to re-initiate letter, if interested.</div>
                            }
                        </Container>
                        }
                    </Container>
                    }
                </React.Fragment>
            );
        }
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(TracLetterRecordPage);