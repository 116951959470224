import * as React from 'react';

interface FooterProps {
}

interface FooterState {
}

export default class Footer extends React.Component<FooterProps,FooterState> {

    constructor(props: FooterProps, state: FooterState) {
        super(props, state);
    }

    render() {
        return (
            <footer className={'footer'}>
                <div className={'footerPadding'}>
                    <p>Copyright &copy; 2020 Southwest Power Pool, Inc. All rights reserved</p>
                    <p> Confidential Information Authorized Access Only</p>
                </div>
            </footer>
        );
    }
}