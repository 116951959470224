import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import TracLetterRecordPage from "../TracLetters/TracLetterRecordPage";
import {ButtonProps} from "semantic-ui-react";

interface RfpTrackingProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
}

interface RfpTrackingState {
    tracLetterRecordPageOpen: boolean;
    gridVisible: boolean;
    tracRecord: any;
    tracRecordIndex: number;
    letterType: string;
}

class RfpTracking extends React.Component<RfpTrackingProps,RfpTrackingState> {

    constructor(props: RfpTrackingProps) {
        super(props);
        this.state = {
            tracLetterRecordPageOpen: false,
            gridVisible: true,
            tracRecord: null,
            tracRecordIndex: -1,
            letterType: 'rfp'
        };
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    viewRecord = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        this.setState({tracLetterRecordPageOpen:true, gridVisible: false, tracRecord:data.row, tracRecordIndex: data.rowIndex});
    };

    getViewButton = () => {
        return {
            iconName: 'eye',
            iconColor: 'blue',
            onClick: this.viewRecord,
            popupContent: 'View/Approve RFP'
        }
    };

    getExtraUtilityColumns = () => {
        return [
            this.getViewButton()
        ];
    };

    setRfpGridOpen = (gridVisible:boolean, tracLetterRecordPageOpen:boolean) => {
        this.setState({gridVisible, tracLetterRecordPageOpen});
    };

    setLetterType = (letterType:string) => {
        this.setState({letterType});
    };

    render() {
        return (
            <React.Fragment>
                <GridContainer
                    getGridDataUrl={'/api/auth/viewGridData/rfp'}
                    addRecordUrl={'/api/auth/saveGridRecord/rfp'}
                    editRecordUrl={'/api/auth/editGridRecord/rfp'}
                    deleteRecordUrl={'/api/auth/removeGridRecord/rfp'}
                    gridPaginated={true}
                    formEditing={true}
                    showUtilityColumns={true}
                    exportLink={'viewGridData/rfp'}
                    gridTitle={'View RFP'}
                    gridId={'rfpGrid'}
                    typeForSuperClass={'rfp'}
                    gridVisible={this.state.gridVisible}
                    extraUtilityColumns={this.getExtraUtilityColumns()}
                    complexAddForm={'/createRfp'}
                />
                <TracLetterRecordPage
                    TracLetterRecordPageOpen={this.state.tracLetterRecordPageOpen}
                    tracRecord={this.state.tracRecord}
                    tracRecordIndex={this.state.tracRecordIndex}
                    letterType={'rfp'}
                    backToGrid={this.setRfpGridOpen}
                    setLetterType={this.setLetterType}
                    location={this.props.location}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(RfpTracking);