import * as React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";

interface AccessDeniedProps {

}

interface AccessDeniedState {

}

class AccessDenied extends React.Component<AccessDeniedProps, AccessDeniedState> {
    constructor(props: AccessDeniedProps, state: AccessDeniedState) {
        super(props, state);
        this.state = {
        };
    }

    public render() {

        return (
            <Container fluid={true}>
                <Header as={'h1'} content={'Access Denied'} color={'red'} />
                <Container fluid={true} color={'red'}>
                    <div>
                        It appears you do not have appropriate permissions to view this page.  Please contact <a href={'mailto:SppTracSupport@spp.org?subject=Permissions%20Issue%20in%20TRAC'}>SPP TRAC Support</a> if you believe this message to be in error.
                    </div>
                </Container>
            </Container>
        )
    }
}

export default AccessDenied;