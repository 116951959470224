import * as React from 'react';
import GridContainer from "../../GenericComponents/Grids/GridContainer";
import {toast} from "react-toastify";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../../Utils/HelperFunctions";
import {
    Button,
    ButtonProps,
    Container,
    Dimmer, Dropdown, Form,
    Grid,
    Header,
    Icon,
    Input, Label,
    Loader, Radio,
    Segment,
    Tab, TextArea
} from "semantic-ui-react";
import history from "../../../history";
import {Redirect} from "react-router";
import UpgradeApi from "../../../services/upgrade-service";
import UpgradeFormSection from "./UpgradeFormSection";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

interface TransmissionServicesUpgradeFormProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
    setLoggedIn: (loggedIn:boolean) => void;
    loggedIn: boolean;
    backToMainUpgradeForm: (savedTopLevelForm:boolean) => void;
    topLevelUpgradeFormObject: InitialUpgradeCreateResponse;
    topLevelFormMetaData ?: any;
}

interface TransmissionServicesUpgradeFormState {
    pageLoading: boolean
    userAuthorized: boolean
    tssFormObject: TransmissionServiceForm;
    formMetaData: any
    submitClicked: boolean
    canSubmit: boolean
    requiredArray: Map<string, boolean>
    activeMainTab: number;
}

class TransmissionServicesUpgradeForm extends React.Component<TransmissionServicesUpgradeFormProps,TransmissionServicesUpgradeFormState> {
    private upgradeClient: UpgradeApi;

    constructor(props: TransmissionServicesUpgradeFormProps) {
        super(props);
        this.state = {
            pageLoading: true,
            userAuthorized: false,
            submitClicked: false,
            tssFormObject: {
                transmissionServiceDetailRequest: {
                    facilityOwnerCode: '',
                    networkUpgradeType: '',
                    transmissionServiceNeedDate: null,
                    upgradeCategoryType: '',
                    newFacilityOwner: '',
                    transmissionServiceEstimatedEocDate: null,
                    upgradeSubcategory: '',
                    fundingType: '',
                    scertViewType: '',
                    upgradeGroup: '',
                    upgradeStatus: '',
                    idevFileName: '',
                    overloads: false,
                    dependencyOnReliabilityUpgrade: false,
                    transmissionServiceAgreementIssued: false,
                    ntcSent: false,
                    constructionLeadOnly: 0,
                    upgradeLeadTime: 0,
                    authorizationToPlanDescription: '',
                    upgradeBoardActionDescription: '',
                    upgradeJustificationComment: '',
                    ntcSentComment: '',
                    upgradeSpecificationDescription: '',
                    upgradeSpecificationComment: ''
                },
                upgradeModInfoRequest: {
                    modProjectPhaseId: 0,
                    submittedOrModifiedDate: null,
                    modProjectId: 0,
                    originalModProjectId: 0,
                    modEffectiveDate: null,
                    modeledInType: '',
                    sppProjectPhaseComment: ''
                },
                upgradeBusRequest: {
                    fromBusNumber: '',
                    fromBusTempInd: false,
                    toBusNumber: '',
                    toBus1TempInd: false,
                    tertiaryBusNumber: '',
                    toBus2TempInd: false,
                    circuitCode: '',
                    designKvRate: 0,
                    highVoltageKvRate: 0,
                    lowVoltageKvRate: 0,
                    newMilesCount: 0,
                    operatingKvRate: 0,
                    reconductorMiles: 0,
                    summerEmergencyRatingMvaRate: 0,
                    summerNormalRatingMvaRate: 0,
                    tertiaryVoltageKvRate: 0,
                    voltageConversionMiles: 0,
                    winterEmergencyRatingMvaRate: 0,
                    winterNormalRatingMvaRate: 0,
                    totalRatingDescription: ''
                },
                upgradeCostInfoRequest: {
                    upgradeCostEstimateDate: null,
                    upgradeEstimatedCostAmount: 0,
                    upgradeEstimatedCostSourceDescription: '',
                    upgradeFinalCostAmount: 0,
                    sourceOfFinalCost: '',
                    finalCostReceivedDate: null,
                    requestForFinalCostSentDate: null,
                    originalEstimatedCost: 0,
                    sppEstimatedCost: 0
                },
                costFlowgateInfoRequest: {
                    costFlowgateFromBusNumber: '',
                    costFlowgateToBusNumber: '',
                    costFlowgateTertiaryBus: '',
                    originalStudyId: 0,
                    cfgCktCode: '',
                    cfgFacilityName: '',
                    flowgateUpgradeName: '',
                    biDirectionalDescription: '',
                    monitoredDescription: '',
                    monitoredTypeDescription: '',
                    studyDescription1: '',
                    studyDescription2: '',
                    studyDescription3: '',
                    upgradeCktDescription: '',
                    upgradeTransformerDescription: ''
                },
                upgradeRedispatchInfoRequest: {
                    generatorNumber: '',
                    generatorOwnerName: '',
                    generatorUnitName: '',
                    generatorRedispatchCostAmount: 0,
                    generatorRedispatchStartDate: null,
                    generatorRedispatchEndDate: null
                }
            },
            formMetaData: null,
            canSubmit: false,
            requiredArray: new Map(),
            activeMainTab: 0
        };

        this.upgradeClient = new UpgradeApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));

        this.getTssUpgradeFormDetails();
        let { requiredArray } = this.state;
        requiredArray.set('tssForm', false);
        requiredArray.set('upgradeBusForm', false);
        requiredArray.set('upgradeCostInfoForm', false);
        requiredArray.set('upgradeModInfoForm', false);
        requiredArray.set('upgradeCostFlowgateInfoForm', false);
        requiredArray.set('upgradeRedispatchInfoForm', false);
        this.setState({requiredArray});
    }

    componentDidUpdate(prevProps: Readonly<TransmissionServicesUpgradeFormProps>, prevState: Readonly<TransmissionServicesUpgradeFormState>) {
        if(this.state.canSubmit && this.state.submitClicked) {
            let readyToSubmit = true;
            this.state.requiredArray.forEach(function(value, key) {
                if(value === false) {
                    readyToSubmit = false;
                }
            });
            if(readyToSubmit) {
                let upgradeCreateRequest: CreateUpgradeRequest = {
                    initialUpgradeCreateResponse: this.props.location.state.topLevelUpgradeFormObject,
                    generationInterconnectionRequest: null,
                    reliabilityExpansionRequest: null,
                    transmissionServiceRequest: this.state.tssFormObject
                }

                this.upgradeClient.submitUpgradeRequest(upgradeCreateRequest)
                    .then((response:any) => {
                        if(response === 'Success') {
                            toast.success('Successfully created TSS upgrade');
                            const currentPath = this.props.location.pathname;
                            history.push({
                                pathname: '/upgrades',
                                state: {from: currentPath}
                            });
                        }
                        else {
                            toast.warn('Issue creating upgrade.  Please contact IT.');
                        }
                    })
                this.setState({canSubmit: false});
            }
            else {
                this.setState({submitClicked: false});
            }
        }
    }

    getTssUpgradeFormDetails = () => {
        this.upgradeClient.getTssUpgradeCreateForm()
            .then((response: any) => {
                this.setState({
                    tssFormObject: response.data[0],
                    formMetaData: response.columnMetaDataList,
                    userAuthorized: true,
                    pageLoading: false
                });
            })
            .catch((error: any) => {
                if (error.response.status === 403) {
                    this.setState({userAuthorized: false, pageLoading: false});
                }
                if (!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                } else {
                    this.props.setLoggedIn(false);
                }
            });
    };

    handleMainTabChanges = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        this.setState({ activeMainTab: data.activeIndex });
    };

    backToMainForm = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        const currentPath = this.props.location.pathname;
        history.push({
            //pathname: this.props.location.state !== undefined ? this.props.location.state.from : '/upgrades/createUpgrade/',
            pathname: '/upgrades/createUpgrade',
            state: {from: currentPath, topLevelUpgradeFormObject: this.props.location.state.topLevelUpgradeFormObject, topLevelFormMetaData: this.props.location.state.topLevelFormMetaData}
        });
        //this.props.backToMainUpgradeForm(false);
    };

    saveWebForm = () => {
        let { requiredArray } = this.state;
        requiredArray.set('tssForm', false);
        requiredArray.set('upgradeBusForm', false);
        requiredArray.set('upgradeCostInfoForm', false);
        requiredArray.set('upgradeModInfoForm', false);
        requiredArray.set('upgradeCostFlowgateInfoForm', false);
        requiredArray.set('upgradeRedispatchInfoForm', false);
        this.setState({submitClicked:true, requiredArray, canSubmit: true});
    };

    resetWebForm = () => {
        let { tssFormObject } = this.state;

        tssFormObject = {
            transmissionServiceDetailRequest: {
                facilityOwnerCode: '',
                networkUpgradeType: '',
                transmissionServiceNeedDate: null,
                upgradeCategoryType: '',
                newFacilityOwner: '',
                transmissionServiceEstimatedEocDate: null,
                upgradeSubcategory: '',
                fundingType: '',
                scertViewType: '',
                upgradeGroup: '',
                upgradeStatus: '',
                idevFileName: '',
                overloads: false,
                dependencyOnReliabilityUpgrade: false,
                transmissionServiceAgreementIssued: false,
                ntcSent: false,
                constructionLeadOnly: 0,
                upgradeLeadTime: 0,
                authorizationToPlanDescription: '',
                upgradeBoardActionDescription: '',
                upgradeJustificationComment: '',
                ntcSentComment: '',
                upgradeSpecificationDescription: '',
                upgradeSpecificationComment: ''
            },
            upgradeModInfoRequest: {
                modProjectPhaseId: 0,
                submittedOrModifiedDate: null,
                modProjectId: 0,
                originalModProjectId: 0,
                modEffectiveDate: null,
                modeledInType: '',
                sppProjectPhaseComment: ''
            },
            upgradeBusRequest: {
                fromBusNumber: '',
                fromBusTempInd: false,
                toBusNumber: '',
                toBus1TempInd: false,
                tertiaryBusNumber: '',
                toBus2TempInd: false,
                circuitCode: '',
                designKvRate: 0,
                highVoltageKvRate: 0,
                lowVoltageKvRate: 0,
                newMilesCount: 0,
                operatingKvRate: 0,
                reconductorMiles: 0,
                summerEmergencyRatingMvaRate: 0,
                summerNormalRatingMvaRate: 0,
                tertiaryVoltageKvRate: 0,
                voltageConversionMiles: 0,
                winterEmergencyRatingMvaRate: 0,
                winterNormalRatingMvaRate: 0,
                totalRatingDescription: ''
            },
            upgradeCostInfoRequest: {
                upgradeCostEstimateDate: null,
                upgradeEstimatedCostAmount: 0,
                upgradeEstimatedCostSourceDescription: '',
                upgradeFinalCostAmount: 0,
                sourceOfFinalCost: '',
                finalCostReceivedDate: null,
                requestForFinalCostSentDate: null,
                originalEstimatedCost: 0,
                sppEstimatedCost: 0
            },
            costFlowgateInfoRequest: {
                costFlowgateFromBusNumber: '',
                costFlowgateToBusNumber: '',
                costFlowgateTertiaryBus: '',
                originalStudyId: 0,
                cfgCktCode: '',
                cfgFacilityName: '',
                flowgateUpgradeName: '',
                biDirectionalDescription: '',
                monitoredDescription: '',
                monitoredTypeDescription: '',
                studyDescription1: '',
                studyDescription2: '',
                studyDescription3: '',
                upgradeCktDescription: '',
                upgradeTransformerDescription: ''
            },
            upgradeRedispatchInfoRequest: {
                generatorNumber: '',
                generatorOwnerName: '',
                generatorUnitName: '',
                generatorRedispatchCostAmount: 0,
                generatorRedispatchStartDate: null,
                generatorRedispatchEndDate: null
            }
        };

        this.setState({tssFormObject});
        toast.info('Cleared All Transmission Service Form Fields');
    };

    updateAccordionContent = (sectionContent:any, sectionName:string) => {
        let { tssFormObject } = this.state;
        switch(sectionName) {
            case 'tssForm':
                tssFormObject.transmissionServiceDetailRequest = sectionContent;
                break;
            case 'upgradeBusForm':
                tssFormObject.upgradeBusRequest = sectionContent;
                break;
            case 'upgradeCostInfoForm':
                tssFormObject.upgradeCostInfoRequest = sectionContent;
                break;
            case 'upgradeModInfoForm':
                tssFormObject.upgradeModInfoRequest = sectionContent;
                break;
            case 'upgradeCostFlowgateInfoForm':
                tssFormObject.costFlowgateInfoRequest = sectionContent;
                break;
            case 'upgradeRedispatchInfoForm':
                tssFormObject.upgradeRedispatchInfoRequest = sectionContent;
                break;
        }

        this.setState({tssFormObject});
    };

    setRequiredFieldsInSectionFilledIn = (missingRequired:boolean, sectionName:string) => {
        let { requiredArray } = this.state;
        if(missingRequired) {
            requiredArray.set(sectionName, false);
        }
        else {
            requiredArray.set(sectionName, true);
        }
        this.setState({requiredArray});
    };

    getTssFormTabs = () => {
        return [
            {
                menuItem: "Project Source",
                pane: (
                    <Tab.Pane>
                        <UpgradeFormSection
                            location={this.props.location}
                            sectionMetaDataEndpoint={'/getTssUpgradeFormMetaData'}
                            sectionName={'tssForm'}
                            segmentHeader={'Transmission Service Upgrade Form'}
                            accordionContent={this.state.tssFormObject.transmissionServiceDetailRequest}
                            formMetaData={this.state.formMetaData}
                            resetWebForm={this.resetWebForm}
                            updateAccordionContent={this.updateAccordionContent}
                            submissionClicked={this.state.submitClicked}
                            setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                        />
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Project Specifications",
                pane: (
                    <Tab.Pane>
                        <div>
                            <UpgradeFormSection
                                location={this.props.location}
                                sectionMetaDataEndpoint={'/getUpgradeModInfoMetaData'}
                                sectionName={'upgradeModInfoForm'}
                                segmentHeader={'TSS Upgrade MOD Info'}
                                accordionContent={this.state.tssFormObject.upgradeModInfoRequest}
                                formMetaData={this.state.formMetaData}
                                resetWebForm={this.resetWebForm}
                                updateAccordionContent={this.updateAccordionContent}
                                submissionClicked={this.state.submitClicked}
                                setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                            />
                            <UpgradeFormSection
                                location={this.props.location}
                                sectionMetaDataEndpoint={'/getUpgradeBusMetaData'}
                                sectionName={'upgradeBusForm'}
                                segmentHeader={'TSS Upgrade Bus Info'}
                                accordionContent={this.state.tssFormObject.upgradeBusRequest}
                                formMetaData={this.state.formMetaData}
                                resetWebForm={this.resetWebForm}
                                updateAccordionContent={this.updateAccordionContent}
                                submissionClicked={this.state.submitClicked}
                                setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                            />
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Cost Information",
                pane: (
                    <Tab.Pane>
                        <div>
                            <UpgradeFormSection
                                location={this.props.location}
                                sectionMetaDataEndpoint={'/getUpgradeCostInfoMetaData'}
                                sectionName={'upgradeCostInfoForm'}
                                segmentHeader={'TSS Upgrade Cost Info'}
                                accordionContent={this.state.tssFormObject.upgradeCostInfoRequest}
                                formMetaData={this.state.formMetaData}
                                resetWebForm={this.resetWebForm}
                                updateAccordionContent={this.updateAccordionContent}
                                submissionClicked={this.state.submitClicked}
                                setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                            />
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Credits",
                pane: (
                    <Tab.Pane>
                        <div>
                            <UpgradeFormSection
                                location={this.props.location}
                                sectionMetaDataEndpoint={'/getUpgradeCostFlowgateInfoMetaData'}
                                sectionName={'upgradeCostFlowgateInfoForm'}
                                segmentHeader={'Cost Flowgate Info'}
                                accordionContent={this.state.tssFormObject.costFlowgateInfoRequest}
                                formMetaData={this.state.formMetaData}
                                resetWebForm={this.resetWebForm}
                                updateAccordionContent={this.updateAccordionContent}
                                submissionClicked={this.state.submitClicked}
                                setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                            />
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Redispatch",
                pane: (
                    <Tab.Pane>
                        <UpgradeFormSection
                            location={this.props.location}
                            sectionMetaDataEndpoint={'/getUpgradeRedispatchInfoMetaData'}
                            sectionName={'upgradeRedispatchInfoForm'}
                            segmentHeader={'Upgrade Redispatch Info'}
                            accordionContent={this.state.tssFormObject.upgradeRedispatchInfoRequest}
                            formMetaData={this.state.formMetaData}
                            resetWebForm={this.resetWebForm}
                            updateAccordionContent={this.updateAccordionContent}
                            submissionClicked={this.state.submitClicked}
                            setRequiredFieldsMissingInForm={this.setRequiredFieldsInSectionFilledIn}
                        />
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Finalize",
                pane: (
                    <Tab.Pane>
                        <div className={'tracWebFormButtonDiv'}>
                            <Button content={'Submit'} primary={true} onClick={this.saveWebForm}/>
                            <Button content={'Reset'} negative={true} onClick={this.resetWebForm}/>
                        </div>
                    </Tab.Pane>
                )
            }
        ];
    }

    render() {
        if(this.props.location.state === undefined || this.props.location.state.topLevelUpgradeFormObject === undefined || this.props.location.state.topLevelUpgradeFormObject.upgradeType === '') {
            return (
                <Redirect to={'/upgrades/createUpgrade'} />
            )
        }

        return (
            <Container fluid={true}>
                <Button icon={true} labelPosition='left' onClick={this.backToMainForm}>
                    <Icon name={'arrow left'} />
                    Back to Main Upgrade Form
                </Button>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                        <React.Fragment>
                            <Header as='h2' block={true}>
                                Add New Transmission Service Upgrade
                            </Header>
                            <Tab
                                panes={this.getTssFormTabs()}
                                activeIndex={this.state.activeMainTab}
                                renderActiveOnly={false}
                                onTabChange={this.handleMainTabChanges}
                            />
                        </React.Fragment>
                    }
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransmissionServicesUpgradeForm);