import axios from "axios";

export default class AdminService {

    constructor() {
    }

    getTypeTableOptions = () => {
        const url = `/api/auth/admin/getTypeTables`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching type table options'));
                    }
                });
        });
    };
}