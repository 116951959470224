import * as React from 'react';
import {
    Button,
    Container, Dropdown, DropdownItemProps, DropdownProps,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    InputOnChangeData, Label,
    Message, Popup,
    SemanticWIDTHS
} from "semantic-ui-react";
import {connect} from "react-redux";
import ReactDatePicker from "react-datepicker";
import GeneralApi from "../../services/general-services";
import TracWebFormElement from "./TracWebFormElement";
import {toast} from "react-toastify";

interface TracWebFormProps {
    loggedIn: boolean
    data: any
    formMetaData: any
    outsideSubmitClicked ?: boolean
    formColumnSize: SemanticWIDTHS | 'equal'
    saveFormRecord: () => void;
    resetWebForm: () => void;
    setRequiredFieldsMissingInForm ?: (missingRequired:boolean) => void;
    handleSpecializedClickLogic: (metaData:any) => void;
    showButtons: boolean;
    addButton?: boolean;
}

interface TracWebFormState {
    error: any
    elementChange: any
    dropDownLoading: boolean
    dropDownOptions: DropDownItem[]
    TracWebFormAttemptedToSave: boolean
}

const includes = require('lodash/includes');
class TracWebForm extends React.Component<TracWebFormProps,TracWebFormState> {
    private generalClient: GeneralApi;

    constructor(props: TracWebFormProps) {
        super(props);
        this.state = {
            error: {},
            elementChange: {},
            dropDownLoading: false,
            dropDownOptions: [],
            TracWebFormAttemptedToSave: false
        };

        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        let error = this.state.error;
        {this.props.formMetaData.map((metaData:any) => {
            error[metaData.accessor] = false;
        })};

        this.setState({error});
    }

    componentDidUpdate(prevProps: Readonly<TracWebFormProps>, prevState: Readonly<TracWebFormState>) {
        if(this.props.outsideSubmitClicked !== undefined && this.props.outsideSubmitClicked) {
            if(!this.state.TracWebFormAttemptedToSave) {// || (prevState.TracWebFormAttemptedToSave !== this.state.TracWebFormAttemptedToSave)) {
                this.saveWebForm();
            }
        }
        else {
            if(this.props.outsideSubmitClicked !== undefined && !this.props.outsideSubmitClicked) {
                if(this.state.TracWebFormAttemptedToSave) {
                    this.setState({TracWebFormAttemptedToSave: false});
                }
            }
        }
    }

    checkIfRequiredFieldsFilledOut = (metaData:any) => {
        if(metaData.required) {
            let fieldValue = this.props.data[metaData.accessor];
            if (fieldValue === '' || fieldValue === null || fieldValue === undefined || (Array.isArray(fieldValue) && !fieldValue.length)) {
                return false;
            }
        }

        return true;
    };

    saveWebForm = () => {
        let canSubmitForm = true, error = this.state.error;

        {this.props.formMetaData.map((metaData:any) => {
            if(!this.checkIfRequiredFieldsFilledOut(metaData)) {
                error[metaData.accessor] = true;
                canSubmitForm = false;
            }
            else {
                error[metaData.accessor] = false;
            }
        })}

        if(canSubmitForm && this.props.outsideSubmitClicked === undefined) {
            //Make prop method call to save
            this.props.saveFormRecord();
        }
        else {
            this.setState({error, TracWebFormAttemptedToSave: true});
            if(!canSubmitForm) {
                if (this.props.setRequiredFieldsMissingInForm !== undefined) {
                    toast.error('Please fill in all required fields before submitting');
                    this.props.setRequiredFieldsMissingInForm(true);
                }
            }
            else {
                if (this.props.setRequiredFieldsMissingInForm !== undefined) {
                    this.props.setRequiredFieldsMissingInForm(false);
                }
            }
        }
    };

    resetWebForm = () => {
        let error = this.state.error;
        {this.props.formMetaData.map((metaData:any) => {
            error[metaData.accessor] = false;
        })};

        this.props.resetWebForm();
        this.setState({error});
    };

    setElementError = (metaData:any, errorState:boolean) => {
        let error = this.state.error;
        error[metaData.accessor] = errorState;
        this.setState({error});
    };

    generateFormFromMetaData = () => {
        let {error} = this.state;

        this.props.formMetaData.sort(function (a: any, b: any) {
            return a.order - b.order;
        });

        return (
            <Form>
                <Grid columns={this.props.formColumnSize}>
                    {this.props.formMetaData.map((metaData:any, index: number) => {
                        if(metaData.visible) {
                            return (
                                <Grid.Column verticalAlign={'top'} key={metaData.accessor}>
                                    <Form.Field error={error[metaData.accessor]} required={metaData.required}>
                                        <label className={'tracWebFormLabel'}>{metaData.header}</label>
                                        {metaData.tooltip !== '' &&
                                            <Popup
                                                className={'tracWebFormToolTip'}
                                                on={'hover'}
                                                trigger={<Icon color={'blue'} name={'question circle'}/>}
                                                content={metaData.tooltip}
                                            />
                                        }
                                        <TracWebFormElement
                                            data={this.props.data}
                                            metaData={metaData}
                                            elementError={error[metaData.accessor]}
                                            setElementError={this.setElementError}
                                            formSaveAttempted={this.state.TracWebFormAttemptedToSave}
                                            handleSpecializedClickLogic={this.props.handleSpecializedClickLogic}
                                            idForObjects={index}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            )
                        }
                    })}
                </Grid>
                <Message visible={includes(error,true)} error={true} header={'Required fields are missing!'} content={'Please fill in all required fields'}/>
                {this.props.showButtons &&
                    <div className={'tracWebFormButtonDiv'}>
                        <Button content={'Continue'} primary={true} onClick={this.saveWebForm}/>
                        <Button content={'Reset'} negative={true} onClick={this.resetWebForm}/>
                    </div>
                }
                {this.props.addButton &&
                    <div className={'tracWebFormButtonDiv'}>
                        <Button content={'Add'} primary={true} onClick={this.saveWebForm}/>
                    </div>
                }
            </Form>
        )
    };

    render() {
        return (
            <Container fluid={true}>
                {this.props.loggedIn &&
                    this.generateFormFromMetaData()
                }
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(TracWebForm);