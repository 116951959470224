import * as React from 'react';
import GridContainer from "../../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../../Utils/HelperFunctions";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";

interface UpgradeCostInfoProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface UpgradeCostInfoState {
}

class UpgradeCostInfo extends React.Component<UpgradeCostInfoProps,UpgradeCostInfoState> {

    constructor(props: UpgradeCostInfoProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/upgradeCostInfo'}
                addRecordUrl={'/api/auth/saveGridRecord/upgradeCostInfo'}
                editRecordUrl={'/api/auth/editGridRecord/upgradeCostInfo'}
                deleteRecordUrl={'/api/auth/removeGridRecord/upgradeCostInfo'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/upgradeCostInfo'}
                gridTitle={'View Upgrade Cost Info'}
                gridId={'upgradeCostInfoGrid'}
                typeForSuperClass={'upgradeCostInfo'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(UpgradeCostInfo);