import * as React from "react";

export const createBreadCrumbBasedOnPage = (pagePath:string[]) => {
    let breadCrumbPath:BreadCrumb[] = [];
    if(pagePath.length > 0) {
        let homeBreadCrumb:BreadCrumb = {
            breadCrumbTitle: 'Home',
            breadCrumbLink: '/',
            active: false
        };
        breadCrumbPath.push(homeBreadCrumb);
        pagePath?.map((value:any, index:any) => {
            let breadCrumb:BreadCrumb = {
                breadCrumbTitle: '',
                breadCrumbLink: '',
                active: false
            };
            if(value !== '') {
                breadCrumb.breadCrumbTitle = value.charAt(0).toUpperCase() + value.slice(1);
                let pathIndex = 0;
                while(pathIndex <= index) {
                    if (index === pagePath.length - 1) {
                        breadCrumb.active = true;
                    }
                    else {
                        breadCrumb.active = false;
                    }
                    breadCrumb.breadCrumbLink += '/' + pagePath[pathIndex];
                    pathIndex++;
                }
                breadCrumbPath.push(breadCrumb);
            }
        });
    }
    return breadCrumbPath;
};