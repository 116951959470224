import axios from "axios";
import {toast} from "react-toastify";

export default class ReportsService {

    getReportLinks = () => {
        const url = `/api/auth/reports/getReportLinks`;

        return new Promise<TracLink[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching report links'));
                    }
                });
        });
    };

    getReportPageInfo = (report:string) => {
        const url = `/api/auth/reports/${report}/getReportDetails`;

        return new Promise<TracLink>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching details for report ' + report));
                    }
                });
        });
    };

    getReportData = (report:string) => {
        const url = `/api/auth/reports/${report}`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching data for report - ' + report));
                    }
                });
        });
    };
}