import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {ButtonProps, Container} from "semantic-ui-react";
import TracLetterRecordPage from "../TracLetters/TracLetterRecordPage";

interface NtcServiceAgreementsPageProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface NtcServiceAgreementsPageState {
    gridVisible: boolean;
}

class NtcServiceAgreementsPage extends React.Component<NtcServiceAgreementsPageProps,NtcServiceAgreementsPageState> {

    constructor(props: NtcServiceAgreementsPageProps) {
        super(props);
        this.state = {
            gridVisible: true,
        };
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <React.Fragment>
                <GridContainer
                    getGridDataUrl={'/api/auth/viewNtcServiceAgreements'}
                    addRecordUrl={'/api/auth/createNtcServiceAgreement'}
                    editRecordUrl={'/api/auth/editNtcServiceAgreement'}
                    deleteRecordUrl={'/api/auth/deleteNtcServiceAgreement'}
                    gridPaginated={false}
                    formEditing={true}
                    showUtilityColumns={true}
                    exportLink={'viewNtcServiceAgreements'}
                    gridTitle={'View NTC Service Agreements'}
                    gridId={'ntcServiceAgreementsGrid'}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(NtcServiceAgreementsPage);