import * as React from 'react';

import NumberFormat from 'react-number-format';

import {
    Icon,
    Table,
    Popup,
    Input,
    InputOnChangeData,
    Checkbox,
    Loader,
    Container,
    Dimmer,
    Segment,
    /*Header, TableHeader,*/ TableCellProps, Button, ButtonProps, Header, Dropdown, Divider, DropdownProps
} from 'semantic-ui-react';
import {toast} from "react-toastify";
import {TableFooter} from "./TableFooter";
import GridApi from "../../../services/grid-service";
import {ExportButton} from "../ExportButton";
import ReactDatePicker from "react-datepicker";
import TracModal from "../TracModal";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";
import GridForm from "./GridForm";
import GridContainer from "./GridContainer";
import {Link, LinkProps} from "react-router-dom";


interface Filters {
    equals: {}
    notEquals: {}
    greaterThanEqual: {}
    lessThanEqual: {}
}

interface TracGridProps {
    dataUrl: string
    paginated?: boolean
    displayPager?: boolean
    utilityColumn?: TableCellProps[]
    showUtilityColumns?: boolean
    gridId: string
    exportLink?: string;
    addRecordUrl?:string;
    editRecordUrl?:string;
    deleteRecordUrl?:string;
    typeForSuperClass?:string;
    loggedIn: boolean
    formEditing?: boolean;
    freezeColumn?: boolean;
    setLoggedIn: (loggedIn:boolean) => void;
    bulkUpload?:boolean;
    specialtyBulkUpload?: any;
    setVisibleColumnCount: (visibleColumnCount: number) => void;
    setUtilityColumnCount: (utilityColumnCount: number) => void;
    handleLinkClick?: (rowData:any) => void;
    getDataFromGrid?: (gridData:any[], gridId:string) => void;
    extraUtilityColumns?: any[];
    userAuthorized: (authorized: boolean) => void;
    addForm?:any;
    checkBoxEndpoint?:string;
    complexAddForm?:string;
    externalGridRefresh?: boolean
    externalNonPaginatedRefresh?: boolean;
    externalData?: any[];
    specialtyUploadClicked?: boolean
    extraHeaderOptions?: any[]
    updateBulkUploadLoadingStatus?: (specialityBulkUploadLoadingStatus:boolean) => void;
    passDataToParent?: (dataToPass:any[]) => void;
}

interface TracGridState {
    originalData: any
    booleanAllValues:DropDownItem[],
    booleanDefaultValue:string[],
    filters: any
    file:any
    uploadPercentage: 0,
    gridCrudAbilities:CrudAbilities,
    openFilterModal: boolean
    currentFilterColumn: any
    complexFilters: Filters
    currentPage: number
    pageSize: number
    sortKeys: string[]
    sortDirection: string[]
    metaData: any
    dataLoading: boolean
    data: any
    updatedData: any
    edit: boolean
    redirect: boolean
    pages: number
    totalRows: number
    rowMetaData: any
    status: number
    userAuthorized: boolean
    mousePressed: boolean
    handle: any
    table: any
    startX: number
    startWidth: number
    columnWidth: number
    addingRow: boolean
    addElement: any//Map<string,any>
    modalOpen: boolean
    gridFormOpen: boolean
    editIndex: number
    delete: boolean
    deleteRow: any
    lastClickedUtilityIndex: number
    visibleColumnCount: number
    utilityColumnCount: number
    uploadFile:any
    uploading: boolean
    pageLoading: boolean
    checkboxselected:boolean
    selectedCheckboxes:any
}

const cloneDeep = require('lodash/cloneDeep');
const filter = require('lodash/filter');
const orderBy = require('lodash/orderBy');
const some = require('lodash/some');
let fileDownload = require('js-file-download');
class TracGrid extends React.Component<TracGridProps,TracGridState> {
    private gridClient: GridApi;
     private map : any = {};


    constructor(props: TracGridProps) {
        super(props);
        this.state = {

            checkboxselected:false,
            selectedCheckboxes:'',
            uploadPercentage:0,
            booleanAllValues:[],
            booleanDefaultValue:[],
            gridCrudAbilities: {
                canEdit: false,
                canDelete: false,
                canAdd: false
            },
            file: null,
            filters: {},
            openFilterModal: false,
            currentFilterColumn: [],
            complexFilters: {
                equals: {},
                notEquals: {},
                greaterThanEqual: {},
                lessThanEqual: {}
            },
            sortKeys: [],
            sortDirection: [],
            pageSize: 25,
            currentPage: 1,
            metaData: [],
            dataLoading: false,
            data: [],
            updatedData: [],
            edit: false,
            redirect: false,
            originalData: [],
            pages: 0,
            totalRows: 0,
            status: 200,
            rowMetaData: [],
            userAuthorized: false,
            mousePressed: false,
            handle: null,
            table: null,
            startX: 0,
            startWidth: 0,
            columnWidth: 0,
            addingRow: false,
            addElement: {},//new Map<string,any>(),
            modalOpen: false,
            gridFormOpen: false,
            editIndex: -1,
            delete: false,
            deleteRow: {},
            lastClickedUtilityIndex: -1,
            visibleColumnCount: 0,
            utilityColumnCount: 0,
            uploadFile: {},
            uploading: false,
            pageLoading: true
        };

        this.gridClient = new GridApi();
    };

    componentDidMount() {
        this.registerEventListenersForGrid();
        this.determineCrudAbilitiesForUser();
        this.getData();
        this.getCheckBoxValues();
    }

    componentWillMount() {
        this.unRegisterEventListenersForGrid();
    }

    determineCrudAbilitiesForUser = () => {
        this.gridClient.getCrudAbilities()
            .then((response:CrudAbilities) => {
                this.setState({gridCrudAbilities:response});
            });
    };

    registerEventListenersForGrid = () => {
        document.addEventListener('mousemove', this.mouseMoveMethod);
        document.addEventListener('mouseup', this.mouseUpMethod);
        window.addEventListener("resize", this.updateGridColumns);
    };

    unRegisterEventListenersForGrid = () => {
        document.removeEventListener('mousemove', this.mouseMoveMethod);
        document.removeEventListener('mouseup', this.mouseUpMethod);
        window.removeEventListener("resize", this.updateGridColumns);
        //window.removeEventListener('resize', this.updateGridColumns);
    };

    updateGridColumns = () => {
        let gridWidth = 0;
        let gridContainer = document.querySelectorAll(".gridContainer");
        if (gridContainer.length > 0) {
            gridWidth = (gridContainer.item(0) as HTMLElement).offsetWidth;
            if(gridWidth > ((this.state.visibleColumnCount * 150) + (this.getEditUtilityColumns().length * 75))) {
                let tableHeaderCell = document.querySelectorAll(".tableHeaderCell");
                if(tableHeaderCell.length > 0) {
                    for(let i = 0; i < tableHeaderCell.length; i++) {
                        (tableHeaderCell.item(i) as HTMLElement).style.width = ((gridWidth-(this.getEditUtilityColumns().length * 75))/this.state.visibleColumnCount) + 'px';
                    }
                }
            }
        }
    };

    mouseMoveMethod = (event:any) => {
        let {mousePressed,handle,startX,startWidth} = this.state;
        if(mousePressed) {
            // handle.style.minWidth = (startWidth + (event.pageX - startX)).toString() + 'px';
            handle.style.setProperty('min-width',  (startWidth + (event.pageX - startX)).toString() + 'px', 'important');
            this.setState({handle});
        }
    };

    mouseUpMethod = () => {
        let {mousePressed} = this.state;
        console.log('mouse up');
        if(mousePressed) {
            mousePressed = false;
            this.setState({mousePressed});
        }
    };

    mouseDownMethod = (event:any) => {
        let {mousePressed,handle,startX,startWidth} = this.state;
        if(!mousePressed) {
            handle = event.target;
            mousePressed = true;
            startX = event.pageX;
            startWidth = handle.offsetWidth;
            console.log(handle);
            console.log(startX);
            console.log(startWidth);
            console.log("mouse is down");
            this.setState({mousePressed, handle, startX, startWidth});
        }
    };

    doubleClickMethod = (event:any) => {
        console.log('table header double clicked');
        const element = document.querySelectorAll("#" + this.props.gridId + " th[style]");
        if(element.length > 0) {
            for(let i = 0; i< element.length; i++) {
                (element.item(i) as HTMLElement).style.width = '';
            }
        }

    };

    componentDidUpdate(prevProps: Readonly<TracGridProps>, prevState: Readonly<TracGridState>) {
        if((prevProps.dataUrl !== this.props.dataUrl) || (this.props.externalGridRefresh !== undefined && prevProps.externalGridRefresh !== this.props.externalGridRefresh)) {
            this.setState({
                filters: {},
                openFilterModal: false,
                currentFilterColumn: [],
                complexFilters: {
                    equals: {},
                    notEquals: {},
                    greaterThanEqual: {},
                    lessThanEqual: {}
                },
                sortKeys: [],
                sortDirection: [],
                //pageSize: 5,
                currentPage: 1,
                //metaData: [],
                dataLoading: false,
                data: [],
                updatedData: [],
                edit: false,
                redirect: false,
                originalData: [],
                pages: 0,
                totalRows: 0,
                status: 200,
                rowMetaData: [],

                //userAuthorized: false,
                mousePressed: false,
                handle: null,
                table: null,
                startX: 0,
                startWidth: 0,
                columnWidth: 0,
                addingRow: false,
                addElement: {},//new Map<string,any>(),
                modalOpen: false,
                gridFormOpen: false,
                editIndex: -1,
                delete: false,
                deleteRow: {},
                lastClickedUtilityIndex: -1,
                visibleColumnCount: 0,
                utilityColumnCount: 0,
                uploadFile: {},
                uploading: false
            });
            this.getData();
        }

        if(this.props.externalNonPaginatedRefresh !== undefined && prevProps.externalNonPaginatedRefresh !== this.props.externalNonPaginatedRefresh) {
            this.setState({
                data: this.props.externalData,
                edit: false,
                editIndex: -1,
                lastClickedUtilityIndex: -1,
                modalOpen: false
            });
        }

        if(this.props.specialtyUploadClicked !== undefined && this.props.specialtyUploadClicked !== prevProps.specialtyUploadClicked) {
            if(this.props.specialtyUploadClicked) {
                if (document.getElementById('bulkUpload') !== undefined && document.getElementById('bulkUpload') !== null) {
                    this.chooseUploadFile();
                }
            }
        }
    };

    sort = async (event:any) => {

        let columnName = event.target.dataset.header;
        let sortKeys = [...this.state.sortKeys];
        let sortDirection = [...this.state.sortDirection];
        let sortIndex = sortKeys.indexOf(columnName);
        let data = [...this.state.data];
        await this.setState({dataLoading:true});

        if (sortIndex > -1) {
            if (sortDirection[sortIndex] === 'asc') {
                sortDirection[sortIndex] = 'desc';
            } else if (sortDirection[sortIndex] === 'desc') {
                sortKeys.splice(sortIndex,1);
                sortDirection.splice(sortIndex,1);
            }
        } else {
            sortKeys.push(columnName);
            sortDirection.push('asc');
        }
        if (sortKeys.length > 0) {
            data = orderBy(data,sortKeys,sortDirection);
        }
        await this.setState({sortKeys,sortDirection,data,dataLoading:false});

        if (this.props.paginated) {
            this.getPaginatedData();
        }
    };

    getData = (filter?:any) => {
        if (this.props.paginated) {
            console.log('paginated')
            this.getPaginatedData(filter);
        } else {
            let params = new URLSearchParams();
            params.append("ui","true");

            this.gridClient.getGridData(this.props.dataUrl, params)
                .then((response:any) => {
                    console.log(response);
                    if((response.columnMetaDataList !== null) && (typeof response.columnMetaDataList !== "undefined")) {
                        let data = response.data;

                        let visibleColumnCount = 0;
                        response.columnMetaDataList?.map((m:any) => {
                            if(m.visible) {
                                visibleColumnCount++;
                            }
                        });

                        this.setState({
                            data,
                            originalData: data,
                            rowMetaData: response.uiRow,
                            metaData: response.columnMetaDataList,
                            totalRows: data.length,
                            pages: this.getTotalPages(),
                            status : response.status,
                            dataLoading:false,
                            columnWidth: (visibleColumnCount * 150),
                            visibleColumnCount: visibleColumnCount,
                            userAuthorized: true,
                            pageLoading: false
                        });
                        if(this.props.passDataToParent !== undefined) {
                            this.props.passDataToParent(data);
                        }
                        this.props.userAuthorized(true);
                        this.props.setLoggedIn(true);
                        this.props.setVisibleColumnCount(visibleColumnCount);
                        this.props.setUtilityColumnCount(this.getEditUtilityColumns().length);
                        if(this.props.getDataFromGrid !== undefined) {
                            this.props.getDataFromGrid(data, this.props.gridId);
                        }
                        this.setLoaderToVisibleCenter();
                    }
                })
                .catch((error:any) => {
                    if(error.response.status === 403) {
                        this.props.userAuthorized(false);
                    }
                    if(error.response.data.badToken) {
                        this.props.setLoggedIn(false);
                    }
                    this.setState({dataLoading:false, pageLoading: false});
                });

            this.setState({dataLoading:true})
        }
    };

    getTotalPages = () => {
        if(this.props.paginated) {
            let {pages} = this.state;
            return pages;
        }
        else {
            let {data, pageSize} = this.state;
            let pages = data.length / pageSize;
            if (data.length % pageSize > 0) {
                pages++;
            }
            return Math.floor(pages);
        }
    };

    getPaginatedData = (filter?:any, currentPage?:number, pageSize?:number, editTypeComplete?:string) => {
        if (this.props.paginated) {
            /*if (!filter) {
                filter = this.state.complexFilters;
            }*/

            const params = new URLSearchParams();
            params.append('page', currentPage === undefined ? (this.state.currentPage - 1).toString() : (currentPage - 1).toString());
            params.append('size', pageSize === undefined ? this.state.pageSize.toString() : pageSize.toString());
            params.append("ui","true");
            for(let i=0;i < this.state.sortKeys.length; i++){
                params.append('sort',`${this.state.sortKeys[i]},${this.state.sortDirection[i]}`);
            }

            this.gridClient.getPaginatedGridData(this.props.dataUrl, filter ? filter: this.state.filters, params)
                .then((response:any) => {
                    if (response.columnMetaDataList.length === 0) {
                        response.columnMetaDataList = this.state.metaData;
                    }

                    this.setState({
                        originalData: response.page.content,
                        data: response.page.content,
                        updatedData: cloneDeep(response.page.content),
                        pages: response.page.totalPages,
                        dataLoading: false,
                        metaData: response.columnMetaDataList,
                        totalRows: response.page.totalElements,
                        userAuthorized: true,
                        currentPage: currentPage !== undefined ? currentPage : this.state.currentPage,
                        pageSize: pageSize !== undefined ? pageSize : this.state.pageSize,
                        filters: filter !== undefined ? filter : this.state.filters,
                        edit: editTypeComplete === 'edit' ? false : this.state.edit,
                        editIndex: editTypeComplete === 'edit' ? -1 : this.state.editIndex,
                        addingRow: editTypeComplete === 'add' ? false : this.state.addingRow,
                        addElement: editTypeComplete === 'add' ? {} : this.state.addElement,
                        delete: editTypeComplete === 'delete' ? false : this.state.delete,
                        deleteRow: editTypeComplete === 'delete' ? {} : this.state.deleteRow,
                        lastClickedUtilityIndex: -1,
                        uploading: editTypeComplete === 'add' ? false : this.state.uploading,
                        modalOpen: false,
                        pageLoading: false
                    });

                    this.props.userAuthorized(true);
                    this.setLoaderToVisibleCenter();
                })
                .catch((error:any) => {
                    this.setState({dataLoading:false, pageLoading: false});
                    if(error.response.status === 403) {
                        this.props.userAuthorized(false);
                    }
                    if(!error.response.data.badToken) {
                        toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                    }
                    else {
                        this.props.setLoggedIn(false);
                    }
                });

            this.setState({dataLoading:true});
        }
    };

    format = (type: any, format: any, returnVal: any) => {
        if(format !== '' && returnVal !== null) {
            if (format === "percentage") {
                return (
                    <div>
                        <NumberFormat value={returnVal} displayType={'text'} suffix={'%'} decimalScale={2} fixedDecimalScale={true}/>
                    </div>);
            } else if (format === "currency"){
                if (returnVal >= 0) {
                    return (
                        <div>
                            <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
                        </div>);
                } else {
                    returnVal = -returnVal;
                    return(
                        <div>
                            <NumberFormat value={returnVal} displayType={'text'} thousandSeparator={','} prefix={'($'} suffix={')'} decimalScale={2} fixedDecimalScale={true}/>
                        </div>);
                }
            }
        }
        else {
            return returnVal;
        }
    };

    getSortIcon = (columnHeader:string) => {
        let sortIndex = this.state.sortKeys.indexOf(columnHeader);
        if (sortIndex > -1) {
            if (this.state.sortDirection[sortIndex] === 'asc') {
                return <Icon name={'angle up'}/>
            } else if (this.state.sortDirection[sortIndex] === 'desc') {
                return <Icon name={'angle down'}/>
            }
        }
        return <Icon/>
    };

    handleFilter = async (event: any | null, data: any) => {
        if(!this.state.dataLoading) {
            let filters = this.state.filters;

            let filteredData = [];

            if (data.value !== '') {
                if (!this.props.paginated) {
                    filteredData = filter(this.state.originalData, function (o: any) {
                        if (o[data.accessor] !== undefined && o[data.accessor] !== null) {
                            return o[data.accessor].toString().toLowerCase().indexOf(data.value.toLowerCase()) > -1;
                        }
                        return;
                    });
                } else {
                    //todo: Might need to look at this, come up with better way of handling boolean filter when paginated
                    if (data.value === 'false') {
                        data.value = '0';
                    } else {
                        if (data.value === 'true') {
                            data.value = '1';
                        }
                    }
                }

                filters[data.accessor] = data.value;
            } else {
                if (filters.length > 0) {
                    filteredData = this.filterData(this.state.originalData, filters.key, filters.value);
                } else {
                    filteredData = this.state.originalData;
                    filters = {};
                }
            }

            if (!this.props.paginated) {
                await this.setState({data: filteredData, filters, totalRows: filteredData.length});
                this.setLoaderToVisibleCenter();
            }

            if (this.props.paginated) {
                this.getPaginatedData(filters, 1);
            }
        }
    };

    filterData = (dataToFilter:any[],key:string,value:any) => {
        return filter(dataToFilter, function(o:any) {
            if (o[key]) {
                return o[key].toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1;
            } return;
        });
    };

    onDateChange = (e:any,c:any,cellInfo:any) => {
        /*console.log(e);
        console.log(c.target.value);
        console.log(new Date(c.target.value));
        console.log(new Date(c.target.value).toLocaleDateString());
        console.log(cellInfo);*/
        this.updateDateFilter(cellInfo,e);
    };

    updateDateFilter = (cellInfo:any,value:string) => {
        if(!this.state.dataLoading) {
            let filteredData = [];
            let {filters} = this.state;

            if (value !== '' && value !== null) {
                if (!this.props.paginated) {
                    filteredData = filter(this.state.originalData, function (o: any) {
                        if (o[cellInfo.accessor]) {
                            return o[cellInfo.accessor].indexOf(value) > -1;
                        }
                        return;
                    });
                }

                filters[cellInfo.accessor] = value;
            } else {
                if (!this.props.paginated) {
                    if (filters.length > 0) {
                        filteredData = this.filterData(this.state.originalData, filters.key, filters.value);
                    } else {
                        filteredData = this.state.originalData;
                        filters = {};
                    }
                } else {
                    if (value === null) {
                        delete filters[cellInfo.accessor];
                    }
                }
            }

            if (!this.props.paginated) {
                this.setState({data: filteredData, filters})
            }

            if (this.props.paginated) {
                this.getPaginatedData(filters, 1);
            }
        }
    };

    getFilter = (filterType: string, dataType: string, accessor?: string, realTimeFiltering?: boolean) => {
        if (!accessor) {
            accessor = this.state.currentFilterColumn.accessor;
        }

        if (dataType !== undefined && (dataType.toUpperCase() === 'DOUBLE' || dataType.toUpperCase() === 'CURRENCY')) {
            dataType = 'number';
        }

        return (
            <p style={{textAlign: 'center'}}>
                // @ts-ignore
                <Input onChange={this.handleFilter} filtertype={filterType}
                       realtimefilter={realTimeFiltering ? realTimeFiltering : false} type={dataType}
                       accessor={this.state.currentFilterColumn.accessor}/>
            </p>
        )
    };

    blur = (event:any, metaData:any, index:number) => {
        let updatedData = [...this.state.updatedData];

        if (this.state.data[index] !== event.target.value) {
            if (!updatedData[index]) {
                updatedData[index] = cloneDeep(this.state.data[index]);
            }
            updatedData[index][metaData.accessor] = event.target.value;
            this.setState({updatedData});
        }
    };

    getFilterValue = (accessor:string) => {
        //let filter = find(this.state.filters,{key:accessor});
        let filter = this.state.filters[accessor];
        if (filter !== undefined) {
            //return filter.value;
            /*if(this.state.metaData[accessor].type.toLowerCase() === 'boolean') {

            }*/
            return filter;
        }
        return '';
    };

    doubleClick = (event:any) => {
        let metaData = JSON.parse(event.currentTarget.dataset.metadata);
        if(metaData.extraMeta && metaData.extraMeta.drillDownPath) {
            let row = JSON.parse(event.currentTarget.dataset.row);
            let complexFilters:Filters = {equals: {}, greaterThanEqual: {}, lessThanEqual: {}, notEquals: {}};
            metaData.extraMeta.drillDownMappings.map((meta:any) => {
                // @ts-ignore
                return complexFilters.equals[meta.destinationColumn] = row[meta.sourceColumn]
            });
            this.setState({redirect: true, complexFilters })
        }
    };

    addElementChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        let {addElement} = this.state;

        if(addElement.hasOwnProperty(data.metaValue.accessor)) {
            addElement[data.metaValue.accessor] = data.value;
            //addElement.set(data.metaValue.accessor, data.value);
        }

        this.setState({addElement});
    };

    buildCell = (dataRow:any, metaData:any, index:number) => {
        let updatedData = [...this.state.updatedData];
        //let type = metaData.type.toLowerCase();
        let drillDown = metaData.extraMeta && metaData.extraMeta.drillDownPath;
        if (this.state.edit && !this.props.formEditing && (index === this.state.editIndex && this.state.editIndex !== -1) && metaData.editable) {
            return (
                <div key={Date.now()}>
                    <Input fluid={true} style={{fontWeight: drillDown ? 'bold' : 'normal',cursor: drillDown ? 'pointer' : 'inherit'}}
                           defaultValue={updatedData[index] ? updatedData[index][metaData.accessor] : dataRow[metaData.accessor]} ondoubleclick={this.doubleClick}
                           onBlur={(e:any) => this.blur(e, metaData, index)}/>
                </div>
            )
        }
        else if((this.state.addingRow && !this.props.formEditing) && index === -1) {
            if(metaData.editable) {
                return (
                    <div>
                        <Input fluid={true} style={{
                            fontWeight: drillDown ? 'bold' : 'normal',
                            cursor: drillDown ? 'pointer' : 'inherit'
                        }} value={this.state.addElement[metaData.accessor] ? this.state.addElement[metaData.accessor] : ''} onChange={this.addElementChange} metaValue={metaData}/>
                    </div>
                )
            }
            else {
                return (
                    <div style={{fontWeight: drillDown ? 'bold' : 'normal',cursor: drillDown ? 'pointer' : 'inherit'}}
                         data-metadata={JSON.stringify(metaData)}/>
                )
            }
        }
        else if (metaData.type.toLowerCase() === 'checkbox') {
            return (
                <Checkbox checked={dataRow[metaData.accessor] === true || (dataRow[metaData.accessor] !== null && dataRow[metaData.accessor].toLowerCase().startsWith('y'))} />
            )
        }
        else if(metaData.Link) {
            let searchMaterial = '';
            if(metaData.Link.searchParams !== "") {
                searchMaterial = '?';// + metaData.searchParams + '=' + dataRow[metaData.searchParams];
                let searchArray = [];
                searchArray = metaData.Link.searchParams.split(',');
                let searchParamValue = '';
                for(let i = 0; i < searchArray.length; i++) {
                    if(i < searchArray.length - 1) {
                        searchParamValue = dataRow[searchArray[i]] === null ? '' : dataRow[searchArray[i]];
                        if(searchParamValue !== '') {
                            searchMaterial += searchArray[i] + '=' + searchParamValue + '&';
                        }
                    }
                    else {
                        searchParamValue = dataRow[searchArray[i]] === null ? '' : dataRow[searchArray[i]];
                        if(searchParamValue !== '') {
                            searchMaterial += searchArray[i] + '=' + searchParamValue;
                        }
                    }
                }

                if(searchMaterial === '?') {
                    searchMaterial = '';
                }
            }
            if(searchMaterial === '') {
                return (
                    <Link className={'gridLink'} onClick={metaData.Link.samePageLink ? (e:any) => this.samePageLinkClick(e, dataRow) : undefined} to={{
                        pathname: metaData.Link.baseUrl,
                        state: {from: window.location.pathname}
                    }}>
                        {this.format(metaData.type, metaData.format, dataRow[metaData.accessor])}
                    </Link>
                )
            }
            else {
                return (
                    <Link className={'gridLink'} onClick={metaData.Link.samePageLink ? (e:any) => this.samePageLinkClick(e, dataRow) : undefined} to={{
                        pathname: metaData.Link.baseUrl,
                        search: searchMaterial !== '' ? searchMaterial : '',
                        state: {from: window.location.pathname}
                    }}>
                        {this.format(metaData.type, metaData.format, dataRow[metaData.accessor])}
                    </Link>
                )
            }
        }
        else if(dataRow[metaData.accessor] !== null && metaData.type.toLowerCase() === 'boolean' || metaData.type.toLowerCase() === 'char'){
            return (
                <>
                    <div>
                        <Checkbox
                            id="customCheckbox"
                            checked={(dataRow[metaData.accessor])}
                            rowData={dataRow}
                            accessor={metaData.accessor}
                            disabled={!metaData.editable}
                            value={dataRow[metaData.accessor]}
                            index={index}
                            onChange={this.handleCheckBoxChange}
                        />
                    </div>
                </>
            );
        }
        return (
            <div style={{fontWeight: drillDown ? 'bold' : 'normal',cursor: drillDown ? 'pointer' : 'inherit'}}
                 data-row={JSON.stringify(dataRow)}
                 data-metadata={JSON.stringify(metaData)}
                 onDoubleClick={this.doubleClick}>{this.format(metaData.type, metaData.format, dataRow[metaData.accessor])}</div>
        )
    };

    samePageLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, rowData:any) => {
        if(this.props.handleLinkClick !== undefined) {
            this.props.handleLinkClick(rowData);
        }
    };

    handleCheckBoxChange = (e :any, tempData :any) => {
        const {rowData,accessor,value,checked,index}=tempData;
        //rowData[accessor] = checked;
        this.setState({
            selectedCheckboxes: {"dataRow":rowData,"isChecked":checked,"index":index},
            modalOpen: true,
            checkboxselected:true,
        })
    };

    displayFiltersInPopup = (metaData: any) => {
        return (
            <div>
                {this.getFilter('equals', metaData.type, metaData.accessor,true)}
                {this.getFilter('notEquals', metaData.type, metaData.accessor,true)}
            </div>
        )
    };

    buildBodyEditUtilityCells = (dataRow:any, rowIndex:number) => {
        return (
            <React.Fragment>
                {this.getEditUtilityColumns(rowIndex, dataRow)?.map((uc:any) =>
                    <Table.Cell style={{maxWidth:'75px'}} row={JSON.stringify(dataRow)}>
                        <Popup trigger={<Button icon={<Icon name={uc.iconName} color={uc.iconColor}/>}  disabled={uc.buttonDisabled} onClick={uc.onClick} className={'nonWhiteTransparentButton'} row={dataRow} rowIndex={rowIndex}/>} content={uc.popupContent} />
                    </Table.Cell>
                )}
            </React.Fragment>
        );
    };

    buildBodyAddRowUtilityCells = (rowIndex:number) => {
        return (
            <React.Fragment>
                {this.getAddRowUtilityColumns()?.map((uc:any) =>
                    <Table.Cell style={{maxWidth:'75px'}}>
                        <Popup trigger={<Button icon={<Icon name={uc.iconName} color={uc.iconColor}/>} onClick={uc.onClick} className={'nonWhiteTransparentButton'} rowIndex={rowIndex} />} content={uc.popupContent} />
                    </Table.Cell>
                )}
            </React.Fragment>
        );
    };

    buildTableBody = () => {
        if(this.state.metaData.length > 0) {
            let columnCount = 0;
            let metaData = this.state.metaData.filter((m: any) => {
                return m.visible
            });

            //columnCount = metaData.length > 0 ? metaData.length : 1;
            if (this.props.utilityColumn) {
                if (this.props.utilityColumn.length > 0) {
                    columnCount = columnCount + this.props.utilityColumn.length;
                }
            }

            if(this.getEditUtilityColumns().length > 0) {
                columnCount = columnCount + this.getEditUtilityColumns().length;
            }

            metaData.sort(function (a: any, b: any) {
                return a.order - b.order;
            });

            let startIndex = this.props.paginated ? 0 : (this.state.currentPage - 1) * this.state.pageSize;

            if (this.state.data.length === 0) {
                return (
                    <Table.Row className={'gridNoDataRow'}>
                        <Table.Cell colSpan={columnCount.toString()}>
                            <div className={'gridNoRows'}>
                                <p>No rows returned</p>
                            </div>
                        </Table.Cell>
                    </Table.Row>
                )
            }

            let retObject = [] as any;

            if(this.state.addingRow && !this.props.formEditing) {
                retObject.push(
                    <React.Fragment key={-1}>
                        <Table.Row textAlign={'center'} className="gridBodyRow">
                            {this.buildBodyAddRowUtilityCells(-1)}
                            {metaData.map((c: any) =>
                                    <Table.Cell singleLine={true}
                                                key={c.accessor}>{this.buildCell(null,c,-1)}</Table.Cell>
                                // On any given page the first record has an index of 0, to get the true index of the record in the entire data set we need to
                                // first subtract 1 from the page number and then multiple by page size
                            )}
                            {/*singleLine={dataRow[c.accessor] !== undefined && dataRow[c.accessor] !== null && dataRow[c.accessor].length < 30}*/}
                        </Table.Row>
                    </React.Fragment>
                );
            }

            retObject.push(this.state.data.slice(startIndex, startIndex + this.state.pageSize).map((dataRow: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <Table.Row textAlign={'center'} className={"gridBodyRow " +
                        (this.state.data.length < 5  && this.props.gridId ==='upgradeGrid' ? 'upgradeGridProperty' :
                            this.state.data.length < 5 ? 'changeTableRowProperty' : '' )}>
                            {this.buildBodyEditUtilityCells(dataRow, (index + ((this.state.currentPage - 1) * this.state.pageSize)))}
                            {metaData.map((c: any) =>
                                <Table.Cell singleLine={true}
                                            key={c.accessor}>{this.buildCell(dataRow, c, (index + ((this.state.currentPage - 1) * this.state.pageSize)))}
                                            {this.renderPopup(dataRow, c, (index + ((this.state.currentPage - 1) * this.state.pageSize)))}
                                </Table.Cell>
                                // On any given page the first record has an index of 0, to get the true index of the record in the entire data set we need to
                                // first subtract 1 from the page number and then multiple by page size
                            )}
                            {/*singleLine={dataRow[c.accessor] !== undefined && dataRow[c.accessor] !== null && dataRow[c.accessor].length < 30}*/}
                        </Table.Row>
                    </React.Fragment>
                )
            }));

            return retObject;
        }
    };

    renderPopup = (dataRow:any, metaData:any, index:number) => {
     
        if (dataRow[metaData.accessor] !== null && dataRow[metaData.accessor] !== undefined ) {
            const value= dataRow[metaData.accessor].toString().split(" ").join('');
            if(value.length >100){
              
                return (
                    <Popup trigger={<Button className={'navLinkButton'}>Show More</Button>} header={''} content={dataRow[metaData.accessor]}/>
                );
            }else return null;
            
        }
        else {
            return null;
        }
    };

    onPageChanged = (currentPage:number) => {
        if(this.props.paginated) {
            this.getPaginatedData(undefined, currentPage);
        }
        else {
            this.setState({currentPage});
        }
    };

    onPageSizeChanged = (pageSize:number) => {
        if(this.props.paginated) {
            this.getPaginatedData(undefined, undefined, pageSize);
        }
        else {
            this.setState({pageSize});
        }
    };

    buildHeaderUtilityCells = () => {
        if (this.state.data.length > 0) {
            return (
                <React.Fragment>
                    {this.getEditUtilityColumns()?.map(() =>
                        <Table.HeaderCell className={'utilityColumn'} style={{maxWidth:'75px'}} />
                    )}
                </React.Fragment>
            );
        }
    };

    getCellMinWidth = (count:number,utilityCount:number,gridWidth:number,headerValue:string) => {
        if(this.state.data.length > 0) {
            if(((count*150) + (utilityCount*75)) < gridWidth) {
                return ((gridWidth-(utilityCount*75))/count) + 'px';
            }
            else {
                if(this.map[headerValue]==="yes"){                  
                    return '166px';
                }else
                return '307px';
            }
        }
        else {
            if((count*150) < gridWidth) {
               
                return (gridWidth/count) - 1 + 'px';
            }
            else {
                return '307px';
            }
        }
    };

    prepareMap = () => {
        const data = this.state.data;
        if(this.state.data.length > 1) {
            const tempMetaData = this.state.metaData;
            data.map((dataRow: any) => {
                tempMetaData.map((metaData: any) => {

                    if (metaData.visible) {
                        if (!this.map.hasOwnProperty(metaData.header)) {
                            this.map[metaData.header] = dataRow[metaData.accessor];
                        } else {
                            let existingValue = this.map[metaData.header];
                            existingValue += "," + dataRow[metaData.accessor];

                            this.map[metaData.header] = existingValue;

                        }
                    }
                })
            });

            if (this.map !== undefined && this.map !== '{}') {
                for (let i in this.map) {
                    let tempStr = this.map[i]
                    if (tempStr !== undefined && tempStr !== null) {
                        let array = tempStr.split(",")
                        let longest = array.reduce(function (a: any, b: any) {
                            return a.length > b.length ? a : b;
                        });

                        let maxLength = longest.length;
                        if (maxLength < 40) {
                            this.map[i] = "yes"
                        }
                    }
                }
            }
        }
    };

    buildHeaderRow = () => {
        
        let gridWidth = 0;
        let gridContainer = document.querySelectorAll(".siteContent");
        if (gridContainer.length > 0) {
            gridWidth = (gridContainer.item(0) as HTMLElement).offsetWidth;
        }

        let count = 0;
        let utilityCount = 0;
        this.prepareMap();
        let metaData = this.state.metaData.filter((m:any) => {
            if(m.visible) {
                count++;
            }
            return m.visible
        });

        if(this.getEditUtilityColumns().length > 0) {
            utilityCount = this.getEditUtilityColumns().length;
        }

        metaData.sort(function (a: any, b: any) {
            return a.order - b.order;
        });

        return (
            <React.Fragment>
                {this.buildHeaderUtilityCells()}
                {metaData.map((column:any) =>
                    <Popup key={column.header} trigger={
                        <Table.HeaderCell className={'tableHeaderCell headerCell'} style={{minWidth:this.getCellMinWidth(count,utilityCount,gridWidth,column.header)}} onMouseDown={this.mouseDownMethod}
                                          onMouseMove={this.mouseMoveMethod} onMouseUp={this.mouseUpMethod}
                                          onDoubleClick={this.doubleClickMethod} onClick={this.sort}
                                          data-header={column.accessor}>
                            {column.header}
                            {this.getSortIcon(column.accessor)}
                        </Table.HeaderCell>
                    } content={this.displayFiltersInPopup(column)} hoverable={true} basic={true}
                           hideOnScroll={true}
                           disabled={!some(this.state.complexFilters, column.accessor)}
                    />
                )}
            </React.Fragment>
        )
    };

    buildFilterUtilityCells = () => {
        if (this.state.data.length > 0) {
            return (
                <React.Fragment>
                    {this.getEditUtilityColumns()?.map(() =>
                        <Table.HeaderCell className={'utilityColumn'} style={{maxWidth:'75px'}} />
                    )}
                </React.Fragment>
            );
        }
    };

    buildFilterRow = () => {
        let metaData = this.state.metaData.filter((m:any) => {
            return m.visible
        });

        metaData.sort(function (a: any, b: any) {
            return a.order - b.order;
        });

        return (
            <React.Fragment>
                {this.buildFilterUtilityCells()}
                {metaData.map((column: any) => {
                    if(column.type === 'LocalDateTime') {
                        return (
                            <Table.HeaderCell className={'gridFilterCell gridDropDownFilter headerCell'} data-metadata={JSON.stringify(column)}>
                                <ReactDatePicker
                                    className={'datepickerinput'}
                                    selected={this.getFilterValue(column.accessor)}
                                    onChange={(event:any,c:any) => this.onDateChange(event,c,column)}
                                    placeholderText={'Select Date'} autoComplete={'off'} isClearable={!!this.state.filters[column.accessor]}
                                    todayButton={'Today'}
                                    peekNextMonth={true}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    dropdownMode="select"
                                />
                            </Table.HeaderCell>
                        )
                    }
                    else {
                        return (
                            <Table.HeaderCell className={'gridFilterCell headerCell'} data-metadata={JSON.stringify(column)}>
                                 <React.Fragment>
                                    {(column.type.toLowerCase() === 'boolean' ||  column.type.toLowerCase() === 'char') ?(
                                        <div  id="checkBixRowId">
                                            <Dropdown options={this.state.booleanAllValues}
                                                      accessor={column.accessor}
                                                      onChange={this.handleFilter}
                                                      value={this.getFilterValue(column.accessor)}
                                                      search={true}
                                                      selection={true} clearable={true} placeholder={'Show All'}/>
                                        </div>
                                    ) :(
                                        <div>
                                            <Input fluid={true} value={this.getFilterValue(column.accessor)}
                                                   accessor={column.accessor} onChange={this.handleFilter}/>
                                        </div>
                                    )}
                                 </React.Fragment>
                            </Table.HeaderCell>
                        )
                    }
                })
                }
            </React.Fragment>
        )
    };

    getCheckBoxValues=()=>{
        const defaultValues=
        [
            { key: '', value: '',  text: 'Show All'},
            {key: '1', value: 'true',  text: 'True'},
            {key: '0', value: 'false',  text: 'False' }
        ]
        this.setState({
            booleanAllValues:defaultValues,
            booleanDefaultValue:[''],
        })
    }
    clearFilters = () => {
        if(Object.entries(this.state.filters).length > 0) {
            if (this.props.paginated) {
                let filters = {};
                this.getPaginatedData(filters);
            }
            else {
                this.setState({data: this.state.originalData, filters: {}, totalRows: this.state.originalData.length});
            }
        }
    };

    setLoaderToVisibleCenter = () => {
        let gridLoader = document.querySelectorAll(".gridLoading");
        let gridContainer = document.querySelectorAll(".gridContainer");
        if (gridContainer.length > 0) {
            if (gridLoader.length > 0) {
                (gridLoader.item(0) as HTMLElement).style.top = ((gridContainer.item(0) as HTMLElement).scrollTop + ((gridContainer.item(0) as HTMLElement).offsetHeight / 3)) + 'px';
                (gridLoader.item(0) as HTMLElement).style.left = ((gridContainer.item(0) as HTMLElement).scrollLeft + ((gridContainer.item(0) as HTMLElement).offsetWidth / 2)) + 'px';
            }
            if(this.state.data.length === 0) {
                let gridNoRows = document.querySelectorAll(".gridNoRows");

                if (gridNoRows.length > 0) {
                    (gridNoRows.item(0) as HTMLElement).style.top = ((gridContainer.item(0) as HTMLElement).scrollTop + ((gridContainer.item(0) as HTMLElement).offsetHeight / 2)) + 'px';
                    (gridNoRows.item(0) as HTMLElement).style.left = ((gridContainer.item(0) as HTMLElement).scrollLeft + ((gridContainer.item(0) as HTMLElement).offsetWidth / 2)) + 'px';
                }
            }
        }
    };

    addRowToGrid = () => {
        let {metaData,addElement} = this.state;
        metaData.forEach((value:any) => {
            switch(value.type) {
                case 'Integer':
                    addElement[value.accessor] = 0;
                    //addElement.set(value.accessor, 0);
                    break;
                case 'Long':
                    addElement[value.accessor] = 0;
                    //addElement.set(value.accessor, 0);
                    break;
                case 'String':
                    addElement[value.accessor] = '';
                    break;
                case 'LocalDateTime':
                    addElement[value.accessor] = null;
                    break;
                default:
                    addElement[value.accessor] = null;
                    //addElement.set(value.accessor, '');
                    break;
            }
        });
        this.setState({addingRow:true, addElement, gridFormOpen: this.props.formEditing ? true : false});
    };

    editRowOnGrid = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        let updatedData = this.state.updatedData;
        if(this.props.formEditing) {
            if(updatedData.length === 0) {
                updatedData = cloneDeep(this.state.data);
            }
        }

        this.setState({edit:true, editIndex: this.props.paginated ? (data.rowIndex - ((this.state.currentPage - 1) * this.state.pageSize)) : data.rowIndex, gridFormOpen: this.props.formEditing ? true : false, updatedData });
    };

    cancelEdit = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        if(data.rowIndex === this.state.editIndex && this.state.editIndex !== -1) {
            this.setState({edit:false, editIndex: -1});
        }
        else {
            if(this.state.addingRow) {
                this.setState({addingRow:false, addElement: {}});
            }
        }
    };

    updateRecord = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        this.setState({modalOpen:true, lastClickedUtilityIndex: this.props.paginated ? (data.rowIndex - ((this.state.currentPage - 1) * this.state.pageSize)) : data.rowIndex});
    };

    openGridFormValidationCheck = (lastClickedIndex:number) => {
        this.setState({modalOpen:true, gridFormOpen: false, lastClickedUtilityIndex:lastClickedIndex});
    };

    deleteRecord = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        console.log(data.row);
        this.setState({modalOpen:true, delete:true, deleteRow:data.row, lastClickedUtilityIndex: this.props.paginated ? (data.rowIndex - ((this.state.currentPage - 1) * this.state.pageSize)) : data.rowIndex});
    };

    getButtonDisabledValue(dataRow:any){
        if(typeof dataRow != 'undefined' && 'userCanNotEdit' in dataRow) {
            return dataRow.userCanNotEdit
        }
        return false
    }

    getEditButton = (dataRow:any) => {

        return {
            iconName: 'edit',
            iconColor: 'blue',
            onClick: this.editRowOnGrid,
            popupContent: 'Edit Row',
            buttonDisabled: this.getButtonDisabledValue(dataRow)
        }
    };

    getCheckButton = () => {
        return {
            iconName: 'check',
            iconColor: 'green',
            onClick: this.updateRecord,
            popupContent: 'Update'
        }
    };

    getCancelButton = () => {
        return {
            iconName: 'undo',
            iconColor: 'red',
            onClick: this.cancelEdit,
            popupContent: 'Cancel'
        }
    };

    getDeleteButton = (dataRow:any) => {
        return {
            iconName: 'cancel',
            iconColor: 'red',
            onClick: this.deleteRecord,
            popupContent: 'Delete Row',
            buttonDisabled: this.getButtonDisabledValue(dataRow)
        }
    };

    getBlankButton = () => {
        return {
            iconName: '',
            iconColor: '',
            onClick: '',
            popupContent: ''
        }
    };

    getAddRowUtilityColumns = () => {
        let utilityColumnList:any[] = [];
        if(this.props.showUtilityColumns) {
            if (this.state.addingRow) {
                utilityColumnList = [
                    this.getCheckButton(),
                    this.getCancelButton()
                ];
            }
        }

        return utilityColumnList;
    };

    getEditUtilityColumns = (rowIndex?:number, dataRow?:any) => {
        let utilityColumnList:any[] = [];
        if(this.props.showUtilityColumns) {
            if (this.state.edit && !this.props.formEditing) {
                if(this.state.editIndex === rowIndex) {
                    utilityColumnList = [
                        this.getCheckButton(),
                        this.getCancelButton()
                    ];
                }
                else {
                    utilityColumnList = [
                        this.getEditButton(dataRow),
                        this.getBlankButton()
                    ];
                }
            }
            else {
                if(this.state.gridCrudAbilities.canEdit && this.state.gridCrudAbilities.canDelete) {
                    utilityColumnList = [
                        this.getEditButton(dataRow),
                        this.getDeleteButton(dataRow)
                    ];
                }
                else {
                    if(this.state.gridCrudAbilities.canEdit) {
                        utilityColumnList.push(this.getEditButton(dataRow));
                    }
                    if(this.state.gridCrudAbilities.canDelete) {
                        utilityColumnList.push(this.getDeleteButton(dataRow));
                    }
                }
            }
        }

        if(this.props.editRecordUrl === undefined || this.props.deleteRecordUrl === undefined) {
            for(let i = 0; i < utilityColumnList.length; i++) {
                if (this.props.editRecordUrl === undefined && utilityColumnList[i].iconName === 'edit') {
                    utilityColumnList.splice(i, 1);
                    i--;
                }
                else {
                    if(this.props.deleteRecordUrl === undefined && utilityColumnList[i].iconName === 'cancel') {
                        utilityColumnList.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        if (this.state.addingRow) {
            if(utilityColumnList.length < 2) {
                utilityColumnList.push(this.getBlankButton());
            }
        }

        if(this.props.extraUtilityColumns !== undefined && this.props.extraUtilityColumns.length > 0) {
            utilityColumnList.push.apply(utilityColumnList, this.props.extraUtilityColumns);
        }

        return utilityColumnList;
    };

    ModalHeader = () => {
        let iconName:any, header;
        if(this.state.edit && this.state.lastClickedUtilityIndex === this.state.editIndex) {
            iconName = 'edit';
            header = 'Edit Record';
        }
       else  if(this.state.checkboxselected){
            iconName = 'comment';
            header = 'Update checkbox';
        }
        else {
            if(this.state.addingRow && this.state.lastClickedUtilityIndex === -1) {
                iconName = 'add';
                header = 'Add Record';
            }
            else {
                iconName = 'cancel';
                header = 'Delete Record';
            }
        }
        return (
            <Header as='h3'>
                <Icon name={iconName} />
                {header}
            </Header>
        )
    };

    ModalContent = () => {
        let questionType;
        if(this.state.edit && this.state.lastClickedUtilityIndex === this.state.editIndex) {
            questionType = 'edit';
        }
        else if(this.state.checkboxselected){
            questionType='update checkbox'
        }
        else {
            if(this.state.addingRow && this.state.lastClickedUtilityIndex === -1) {
                questionType = 'add';
            }
            else {
                questionType = 'delete'
            }
        }
        return (
            <div>
                <p>Are you sure you want to {questionType} record?</p>
            </div>
        )
    };

    getTypeAndCheckIfEditableRequiredCellFilledIn = (addElement:any, notFilledInArray:string[], type:string, key:any) => {
        let comparisonValue:any;
        switch(type) {
            case 'Integer' || 'Long':
                comparisonValue = null;
                break;
            case 'String':
                comparisonValue = '';
                break;
            case 'LocalDateTime':
                comparisonValue = null;
                break;
            default:
                comparisonValue = '';
        }
        if(addElement.hasOwnProperty(this.state.metaData[key].accessor)) {
            if (addElement[this.state.metaData[key].accessor] === comparisonValue) {
                notFilledInArray.push(this.state.metaData[key].header);
            }
        }
    };

    styleNullColumnOnSaveAndEditToast = (notFilledInArray:string[]) => {
        return (
            <div>
                <Header content={'Fields Required'}/>
                <ul>
                    {notFilledInArray?.map((value:any) => {
                        return (
                            <li>{value}</li>
                        )
                    })}
                </ul>
                <p>Please fill in required fields before saving</p>
            </div>
        )
    };

    removeNumberMaskings = (element:any, key:any) => {
        if((this.state.metaData[key].type === 'Integer' || this.state.metaData[key].type === 'BigDecimal' || this.state.metaData[key].type === 'Long') && this.state.metaData[key].format !== '') {
            if(element.hasOwnProperty(this.state.metaData[key].accessor)) {
                if(element[this.state.metaData[key].accessor] !== null && typeof element[this.state.metaData[key].accessor] === 'string') {
                    element[this.state.metaData[key].accessor] = element[this.state.metaData[key].accessor].replace(/,/g, '');
                    element[this.state.metaData[key].accessor] = element[this.state.metaData[key].accessor].replace(/\$/g, '');
                    element[this.state.metaData[key].accessor] = element[this.state.metaData[key].accessor].replace(/%/g, '');
                    element[this.state.metaData[key].accessor] = Number(element[this.state.metaData[key].accessor]);
                }
            }
        }
    };

    ifChangeNotFromMasking = (element:any, key:any):boolean => {
        if((this.state.metaData[key].type === 'Integer' || this.state.metaData[key].type === 'BigDecimal' || this.state.metaData[key].type === 'Long') && this.state.metaData[key].format !== '') {
            if(element.hasOwnProperty(this.state.metaData[key].accessor)) {
                if(element[this.state.metaData[key].accessor] !== null) {
                    let checkElement = element[this.state.metaData[key].accessor];
                    checkElement = checkElement.replace(/,/g, '');
                    checkElement = checkElement.replace(/\$/g, '');
                    checkElement = checkElement.replace(/%/g, '');

                    if(this.state.data[this.state.editIndex][this.state.metaData[key].accessor] === null && checkElement === '') {
                        return false;
                    }

                    checkElement = Number(checkElement);

                    if(checkElement === this.state.data[this.state.editIndex][this.state.metaData[key].accessor]) {
                        if(checkElement !== 0 && this.state.data[this.state.editIndex][this.state.metaData[key].accessor] !== 0) {
                            return false;
                        }
                        else {
                            element[this.state.metaData[key].accessor] = null;
                        }
                    }
                }
            }
        }

        return true;
    };

    confirmButtonClick = () => {
        if(this.props.addRecordUrl || this.props.editRecordUrl || this.props.deleteRecordUrl || this.props.checkBoxEndpoint) {
            if(this.props.addRecordUrl && this.state.addingRow && this.state.lastClickedUtilityIndex === -1) {
                let {addElement} = this.state;
                let notFilledInArray:string[] = [];
                Object.keys(this.state.metaData).forEach((key:any) => {
                    if(this.state.metaData[key].editable) {
                        if(this.state.metaData[key].required) {
                            this.getTypeAndCheckIfEditableRequiredCellFilledIn(addElement, notFilledInArray, this.state.metaData[key].type, key);
                        }

                        this.removeNumberMaskings(addElement, key);
                    }
                });
                if(this.props.typeForSuperClass !== undefined) {
                    if(!addElement.hasOwnProperty('classType')) {
                        addElement['classType'] = this.props.typeForSuperClass;
                    }
                }
                if(notFilledInArray.length === 0) {
                    this.gridClient.saveGridRecord(this.props.addRecordUrl, addElement)
                        .then((response: GridFormSaveResponse) => {
                            if(response.saveSuccessful) {
                                toast.success(response.saveMessage);
                                if (!this.props.paginated) {
                                    let {data} = this.state;
                                    data.push(response.crudEntity);
                                    data.sort((a: any, b: any) => (a.id > b.id) ? 1 : -1);
                                    this.setState({
                                        data,
                                        addingRow: false,
                                        edit: false,
                                        totalRows: data.length,
                                        pages: this.getTotalPages(),
                                        lastClickedUtilityIndex: -1,
                                        modalOpen: false
                                    });
                                }
                                else {
                                    this.getPaginatedData(undefined, undefined, undefined, 'add');
                                }
                            }
                            else {
                                //toast.error(response.saveMessage);
                                toast(this.displayStringOfHtml(response.saveMessage), {
                                    autoClose: 20000,
                                    draggable: true,
                                    pauseOnFocusLoss: true,
                                    pauseOnHover: true,
                                    className: 'custom-info-toast',
                                });
                                if (!this.props.paginated) {
                                    this.setState({
                                        addingRow: false,
                                        edit: false,
                                        lastClickedUtilityIndex: -1,
                                        modalOpen: false
                                    });
                                }
                            }
                        })
                        .catch((error:any) => {
                            if(error.response.data.status === 400) {
                                let message = '';
                                error.response.data.errors?.map((value:any, key:number) => {
                                    message = value.defaultMessage;
                                });

                                toast.warn(message);
                            }
                        });
                }
                else {
                    toast.warn(this.styleNullColumnOnSaveAndEditToast(notFilledInArray));
                    this.setState({modalOpen:false});
                }
            }
            else if(this.props.editRecordUrl && this.state.edit) {
                let {updatedData} = this.state;
                let updateObject = updatedData[this.state.editIndex];
                let notFilledInArray:string[] = [];
                let rowUpdated = false;
                if(updateObject !== undefined) {
                    Object.keys(this.state.metaData).forEach((key: any) => {
                        if (this.state.metaData[key].editable) {
                            if (this.state.data[this.state.editIndex][this.state.metaData[key].accessor] !== this.state.updatedData[this.state.editIndex][this.state.metaData[key].accessor]) {
                                if(this.ifChangeNotFromMasking(updateObject, key)) {
                                    rowUpdated = true;
                                }
                            }
                        }
                    });
                }
                if(rowUpdated) {
                    Object.keys(this.state.metaData).forEach((key: any) => {
                        if (this.state.metaData[key].editable) {
                            if (this.state.metaData[key].required) {
                                this.getTypeAndCheckIfEditableRequiredCellFilledIn(updateObject, notFilledInArray, this.state.metaData[key].type, key);
                            }

                            this.removeNumberMaskings(updateObject, key);
                        }
                    });
                    if (this.props.typeForSuperClass !== undefined) {
                        if (!updateObject.hasOwnProperty('classType')) {
                            updateObject['classType'] = this.props.typeForSuperClass;
                        }
                    }
                    if (notFilledInArray.length === 0) {
                        this.gridClient.updateGridRecord(this.props.editRecordUrl, updateObject)
                            .then((response: any) => {
                                if(response.saveSuccessful) {
                                    toast.success(response.saveMessage);
                                    if (!this.props.paginated) {
                                        let {data, updatedData} = this.state;
                                        data[this.state.editIndex] = response.crudEntity;//cloneDeep(updatedData[this.state.editIndex]);
                                        this.setState({
                                            data,
                                            edit: false,
                                            editIndex: -1,
                                            lastClickedUtilityIndex: -1,
                                            modalOpen: false
                                        });
                                    } else {
                                        this.getPaginatedData(undefined, undefined, undefined, 'edit');
                                    }
                                }
                                else {
                                    toast(this.displayStringOfHtml(response.saveMessage), {
                                        autoClose: 20000,
                                        draggable: true,
                                        pauseOnFocusLoss: true,
                                        pauseOnHover: true,
                                        className: 'custom-info-toast',
                                    });
                                    if (!this.props.paginated) {
                                        this.setState({
                                            edit: false,
                                            editIndex: -1,
                                            lastClickedUtilityIndex: -1,
                                            modalOpen: false
                                        });
                                    }
                                }
                            })
                            .catch((error:any) => {
                                this.setState({modalOpen: false});
                            });
                    }
                    else {
                        toast.warn(this.styleNullColumnOnSaveAndEditToast(notFilledInArray));
                        this.setState({modalOpen: false});
                    }
                }
                else {
                    toast.warn('Nothing updated to save');
                    this.setState({
                        modalOpen: false,
                        edit: false,
                        editIndex: -1,
                        lastClickedUtilityIndex: -1
                    });
                }
            }
            else if(this.props.deleteRecordUrl && this.state.delete) {
                let {deleteRow} = this.state;
                if(this.props.typeForSuperClass !== undefined) {
                    deleteRow['classType'] = this.props.typeForSuperClass;
                }
                this.gridClient.deleteGridRecord(this.props.deleteRecordUrl, deleteRow)
                    .then((response: any) => {
                        if(response.saveSuccessful) {
                            toast.success(response.saveMessage);
                            if (!this.props.paginated) {
                                let {data} = this.state;
                                data.splice(data.findIndex((v:any) => v.id === deleteRow.id), 1);
                                this.setState({
                                    data,
                                    delete: false,
                                    deleteRow: null,
                                    totalRows: data.length,
                                    pages: this.getTotalPages(),
                                    lastClickedUtilityIndex: -1,
                                    modalOpen: false
                                });
                            }
                            else {
                                this.getPaginatedData(undefined, undefined, undefined, 'delete');
                            }
                        }
                        else {
                            toast(this.displayStringOfHtml(response.saveMessage), {
                                autoClose: 20000,
                                draggable: true,
                                pauseOnFocusLoss: true,
                                pauseOnHover: true,
                                className: 'custom-info-toast',
                            });
                            if (!this.props.paginated) {
                                this.setState({
                                    delete: false,
                                    deleteRow: null,
                                    lastClickedUtilityIndex: -1,
                                    modalOpen: false
                                });
                            }
                        }
                    })
                    .catch((error:any) => {
                        this.setState({modalOpen: false});
                    });
            }
            else if(this.props.checkBoxEndpoint && this.state.checkboxselected){
                this.gridClient.updateGridRecordBasedOnCheckBoxUpdate(this.props.checkBoxEndpoint, this.state.selectedCheckboxes['dataRow'])
                .then((response:any) => {
                    //toast.success(response + ' records updated');
                    toast.success('Successfully updated record');
                    if(!this.props.paginated) {
                        let {data, selectedCheckboxes} = this.state;
                        data[selectedCheckboxes.index] = response;//selectedCheckboxes.isChecked;
                        this.setState({
                            data,
                            userAuthorized: true,
                            pageLoading: false,
                            modalOpen: false,
                            selectedCheckboxes: null,
                            checkboxselected: false
                        });
                    }
                    else {
                        this.getPaginatedData(undefined, undefined, undefined, 'edit');
                    }
                })
                .catch((error:any) => {
                    if(error.response.status === 403) {
                        this.props.userAuthorized(false);
                    }
                    if(error.response.data.badToken) {
                        this.props.setLoggedIn(false);
                    }

                    this.setState({dataLoading:false, pageLoading: false});
                })
            }
        }
    };
    
    displayStringOfHtml = (input:string) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: input }} />
        )
    };

    htmlDecode(input:string){
        let html:string | null = '';
        let e = document.createElement('div');
        e.innerHTML = input;
        html = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        if(html === null) {
            html = '';
        }
        return html;
    }

    cancelButtonClick = () => {
        if(this.state.checkboxselected) {
            this.setState({modalOpen: false, selectedCheckboxes: null, checkboxselected: false});
        }
        else {
            this.setState({modalOpen: false});
        }
    };

    backToGridForm = () => {
        this.setState({modalOpen: false, gridFormOpen:true});
    };

    ModalActions = () => {
        if(this.props.formEditing && !this.state.checkboxselected) {
            return (
                <div className={'gridFormButtonsDiv'}>
                    {this.props.formEditing && !this.state.delete &&
                        <div className={'gridFormBackDiv'}>
                            <Button content={'Back'} color={'yellow'} onClick={this.backToGridForm}/>
                        </div>
                    }
                    <div className={'gridFormActionDiv'}>
                        <Button content={'Ok'} color={'green'} onClick={this.confirmButtonClick}/>
                        <Button content={'Cancel'} color={'red'} onClick={this.cancelButtonClick}/>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div>
                    <Button content={'Ok'} color={'green'} onClick={this.confirmButtonClick}/>
                    <Button content={'Cancel'} color={'red'} onClick={this.cancelButtonClick}/>
                </div>
            )
        }
    };

    ModalClose = () => {
        if(this.props.formEditing) {
            this.setState({modalOpen: false, addElement:{}, addingRow: false, edit: false, editIndex: -1});
        }
       else {
            this.setState({modalOpen: false});
        }
    };

    closeGridFormModal = (addingRow:boolean, addElement:any, editStatus:boolean, editIndex:number) => {
        this.setState({addingRow, addElement, edit: editStatus, editIndex, gridFormOpen: false, updatedData: []});
    };

    /*fileChange = (e :any)=> {
        this.setState({ file: e.target.files }, () => {

            this.uploadFile(this.state.file);
        });

    };*/

    chooseUploadFile = () => {
        if(this.props.specialtyBulkUpload !== undefined && this.props.updateBulkUploadLoadingStatus !== undefined) {
            this.props.updateBulkUploadLoadingStatus(false);
        }
        document.getElementById('bulkUpload')!.click();
    };

    fileUploaded = (e: any) => {
        if (e.target.files.length > 0) {
            if(this.props.specialtyBulkUpload !== undefined && this.props.updateBulkUploadLoadingStatus !== undefined) {
                this.props.updateBulkUploadLoadingStatus(true);
            }

            let fileExtension = e.target.files[0].name.toLowerCase();
            if (e.target.files.length !== 0) {
                if (fileExtension.endsWith('xlsx')) {
                    this.setState({uploadFile: e.target.files[0], uploading:true});
                    this.uploadFile(e);
                } else {
                    toast.warn('You can only upload XLSX files')
                }
            }
        }

        /*if(this.props.specialtyBulkUpload !== undefined && this.props.updateBulkUploadLoadingStatus !== undefined) {
            this.props.updateBulkUploadLoadingStatus(false);
        }*/
    };

    uploadFile = (event:any) => {
        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.set('resource', this.props.typeForSuperClass !== undefined ? this.props.typeForSuperClass : '');
        if(this.props.specialtyBulkUpload !== undefined) {
            this.gridClient.uploadUpgradeFile(formData)
                .then((response:any) => {
                    let errorSeen = response.headers["error-seen"];
                    let uploadResult = response.headers["message"];
                    let fileDownloadName = response.headers["content-disposition"];
                    if (errorSeen === 'false') {
                        toast.success(uploadResult);
                    }
                    else {
                        try {
                            let errorFile = new Blob([response.data], { type: 'application/xlsx' });
                            fileDownload(errorFile,fileDownloadName.split("=")[1]);
                        }catch{
                            toast.error("Error workbook could not be retrieved");
                        }finally{
                            toast.error(`Error: ${uploadResult}`);
                        }
                    }
                    if (!this.props.paginated) {
                        /*let {data} = this.state;
                        data.push(response.data);
                        data.sort((a: any, b: any) => (a.id > b.id) ? 1 : -1);*/
                        /*this.setState({
                            data,
                            addingRow: false,
                            edit: false,
                            totalRows: data.length,
                            pages: this.getTotalPages(),
                            lastClickedUtilityIndex: -1,
                            modalOpen: false,
                            uploading: false
                        });*/
                    } else {
                        this.getPaginatedData(undefined, undefined, undefined, 'add');
                    }

                    if (this.props.specialtyBulkUpload !== undefined && this.props.updateBulkUploadLoadingStatus !== undefined) {
                        this.props.updateBulkUploadLoadingStatus(false);
                    }
                });
        }
        else {
            this.gridClient.uploadFile(formData)
                .then((response: any) => {
                    if (response.errors === {}) {
                        toast.success('Error');
                    } else {
                        toast.success('Successfully uploaded ' + response.data.length + ' ' + this.props.typeForSuperClass + ' records');
                    }
                    if (!this.props.paginated) {
                        let {data} = this.state;
                        data.push(response.data);
                        data.sort((a: any, b: any) => (a.id > b.id) ? 1 : -1);
                        this.setState({
                            data,
                            addingRow: false,
                            edit: false,
                            totalRows: data.length,
                            pages: this.getTotalPages(),
                            lastClickedUtilityIndex: -1,
                            modalOpen: false,
                            uploading: false
                        });
                    } else {
                        this.getPaginatedData(undefined, undefined, undefined, 'add');
                    }
                });
        }

        event.target.value = '';
    };

    /*uploadFile = (files :any) =>{
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append(`file[${i}]`, files[i])
        }
        console.log(formData.values)


        let url ='';
        this.gridClient.uploadBulkCsv(url,formData).then((response:any) => {

            toast.info("File has been upload succesfully")

        }).catch((error:any) => {

            toast.info("Something went wrong")
        });
    };*/

    gridFormDateChange = (data:any, index:number) => {
        if(this.state.edit) {
            let updatedData = this.state.updatedData;
            updatedData[index] = data;
            this.setState({updatedData});
        }
        else {
            if(this.state.addingRow) {
                this.setState({addElement:data});
            }
        }
    };

    displayExtraHeaderOptions = () => {
        if(this.props.extraHeaderOptions !== null && this.props.extraHeaderOptions !== undefined) {
            return (
                <React.Fragment>
                    {this.props.extraHeaderOptions?.map((value:any) =>
                        value
                    )}
                </React.Fragment>
            );
        }
    };

    updateFieldsOnDropChange = (response:CallOnChangeResponse[], editing:boolean) => {
        let {addElement, editIndex} = this.state;
        let tempArray = [...this.state.updatedData];

        response.map((fieldToChange:CallOnChangeResponse) => {
            if(editing) {
                tempArray[editIndex][fieldToChange.accessor] = fieldToChange.value;
            }
            else {
                addElement[fieldToChange.accessor] = fieldToChange.value;
            }
        });

        if(editing) {
            this.setState({updatedData: tempArray, addElement, gridFormOpen: true, edit: true});
        }
        else {
            this.setState({addElement, gridFormOpen: true});
        }
    }

    render() {
        if(this.props.loggedIn && this.state.userAuthorized) {
            return (
                <Container fluid={true} className={'tracGrid'}>
                    <TracModal className="modalProp" header={this.ModalHeader()} content={this.ModalContent} actions={this.ModalActions}
                               open={this.state.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                               onClose={this.ModalClose}
                    />
                    <div className={'gridHeaderDiv'}>
                        <Popup trigger={<Button icon='filter' onClick={() => this.clearFilters()}/>} content='Clear Filters' />
                        {this.state.gridCrudAbilities.canAdd && (this.props.addRecordUrl !== undefined && this.props.complexAddForm === undefined) &&
                            <Popup trigger={<Button color={'twitter'} icon={'add'} onClick={this.addRowToGrid}/>}
                                   content={'Add Row'}/>
                        }
                        {this.state.gridCrudAbilities.canAdd && this.props.complexAddForm !== undefined &&
                            <Link to={this.props.complexAddForm}>
                                <Popup trigger={<Button color={'twitter'} icon={'add'} />}
                                       content={'Add Row'}/>
                            </Link>
                        }
                        {this.props.exportLink && this.state.data.length > 0 &&
                        <div className={'gridExportDiv'}>
                            <ExportButton exportType={'excel'} pageName={this.props.exportLink}/>
                            <ExportButton exportType={'csv'} pageName={this.props.exportLink}/>
                        </div>
                        }
                        {this.props.bulkUpload &&
                            <React.Fragment>
                                {this.props.specialtyBulkUpload !== undefined &&
                                    <React.Fragment>
                                        {this.props.specialtyBulkUpload}
                                        <input type="file" id="bulkUpload" hidden={true} onChange={this.fileUploaded} accept={'.xlsx'}/>
                                    </React.Fragment>
                                }
                                {this.props.specialtyBulkUpload === undefined &&
                                    <React.Fragment>
                                        <Button onClick={this.chooseUploadFile} type="button" loading={this.state.uploading}
                                                className={"blue"}>
                                            Import
                                        </Button>
                                        <input type="file" id="bulkUpload" hidden={true} onChange={this.fileUploaded} accept={'.xlsx'}/>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {this.displayExtraHeaderOptions()}
                    </div>
                    <Container fluid={true} className={['gridContainer' , ' view'].join('')} onScroll={this.setLoaderToVisibleCenter}>
                        <Segment basic={true} compact={true} className={['gridSegment',' wrapper'].join('')}>
                            <Dimmer className={'gridDimmer'} active={this.state.dataLoading} inverted={true}>
                                <Loader className={'gridLoading'} content='Loading'/>
                            </Dimmer>
                            <Table compact={true} celled={true} striped={true} sortable={true} attached={"top"} size={'small'}
                                   padded={"very"} id={this.props.gridId}
                                   className={"topTable " + (this.state.data.length < 5 ? 'changeTableProperty' : '') + (this.props.freezeColumn && 'freezeColumn')}
                            >
                                <Table.Header fullWidth={true} className={'gridHeaders'}>
                                    <Table.Row textAlign={'center'} className={'gridRow'} id="first">
                                        {this.buildHeaderRow()}
                                    </Table.Row>
                                    <Table.Row textAlign={'center'} className={'gridRow'} id="second">
                                        {this.buildFilterRow()}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body className={'gridBody'}>
                                    {this.buildTableBody()}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Container>
                    {this.props.displayPager ?
                        <TableFooter totalRows={this.state.totalRows} currentPage={this.state.currentPage}
                                     totalPages={this.getTotalPages()} onPageChanged={this.onPageChanged}
                                     onPageSizeChanged={this.onPageSizeChanged} pageSize={this.state.pageSize}/>
                        : <div/>
                    }
                    {this.props.formEditing &&
                        <GridForm
                            formColumnSize={2}
                            gridMetaData={this.state.metaData}
                            addElement={this.state.addElement}
                            updatedData={this.state.updatedData}
                            addRow={this.state.addingRow}
                            editRow={this.state.edit}
                            editIndex={this.state.editIndex}
                            editData={this.state.data[this.state.editIndex]}
                            lastClickedUtilityIndex={this.state.lastClickedUtilityIndex}
                            modalOpen={this.state.gridFormOpen}
                            modalClose={this.closeGridFormModal}
                            saveFormRecord={this.openGridFormValidationCheck}
                            blurRecord={this.blur}
                            addElementChange={this.addElementChange}
                            handleGridFormDateChange={this.gridFormDateChange}
                            updateFieldsOnDropChange={this.updateFieldsOnDropChange}
                        />
                    }
                    {this.props.addForm !== undefined &&
                        this.props.addForm
                    }
                </Container>
            );
        }
        else {
            return (
                <Segment basic={true}>
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            )
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setVisibleColumnCount: (visibleColumnCount: number) => dispatch(
            {type: actionTypes.SET_VISIBLE_COLUMN_COUNT, payload: visibleColumnCount}),
        setUtilityColumnCount: (utilityColumnCount: number) => dispatch(
            {type: actionTypes.SET_UTILITY_COLUMN_COUNT, payload: utilityColumnCount})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TracGrid);