import * as React from 'react';
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {
    Button, Checkbox, CheckboxProps,
    Container,
    Dimmer,
    Dropdown,
    DropdownProps, Form,
    Header, Icon, Input, List,
    Loader, Message,
    Segment, Tab,
    TextArea,
    TextAreaProps
} from "semantic-ui-react";
import LetterApi from "../../services/letter-service";
import GeneralApi from "../../services/general-services";
import {toast} from "react-toastify";
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {SyntheticEvent} from "react";
import CostAssumptions from "../External/Assumptions/CostAssumptions";
import LineAssumptions from "../External/Assumptions/LineAssumptions";
import StationAssumptions from "../External/Assumptions/StationAssumptions";
import TracModal from "../GenericComponents/TracModal";
import history from "../../history";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import ServiceAgreementComponent from "./ServiceAgreementComponent";

interface CreateNtcFormProps {
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    setLoggedIn: (loggedIn:boolean) => void;
    location: any;
}

interface CreateNtcFormState {
    ntcStudies: DropDownItem[]
    ntcNetworkUpgradeSourceTypes: DropDownItem[]
    ntcNetworkUpgradeSourceTypeCallBack: string
    ntcStudyChosen: string
    ntcNetworkUpgradeSourceTypeChosen: string
    ntcTrackingComments: string[]
    userAuthorized: boolean
    pageLoading: boolean
    dropDownLoading: boolean
    ntcSectionLoading: boolean
    activeTabIndex: number
    facilityOwnerList:string[]
    networkUpgradeSourceType: string
    ntcTemplateType: string
    letterTemplateOptions: DropDownItem[]
    ntcQualifiedRecord: any
    ntcSelectedRecord: any
    modalOpen: boolean
    ntcStatusOptions: DropDownItem[]
    upgradeNtcStatus: string
    upgradeStatusOptions: DropDownItem[]
    prevUpgradeStatus: string
    listSelectedNtcUpgrades: any[]
    listQualifiedNtcUpgrades: any[]
    ntcConditionalArray: boolean[]
    validationMessages: string[]
    validationErrorSeen: boolean
    refreshGrids: boolean
    boardApprovedDate: any
    agreementExecutionDate: any
    ntcServiceAgreements: NtcServiceAgreement[]
}

class CreateNtcForm extends React.Component<CreateNtcFormProps,CreateNtcFormState> {
    private letterClient: LetterApi;
    private generalClient: GeneralApi;

    constructor(props: CreateNtcFormProps) {
        super(props);
        this.state = {
            ntcStudies: [],
            ntcNetworkUpgradeSourceTypes: [],
            ntcNetworkUpgradeSourceTypeCallBack: '',
            ntcStudyChosen: '',
            ntcNetworkUpgradeSourceTypeChosen: '',
            ntcTrackingComments: [],
            userAuthorized: false,
            pageLoading: true,
            dropDownLoading: false,
            ntcSectionLoading: false,
            activeTabIndex: 0,
            facilityOwnerList: [],
            networkUpgradeSourceType: '',
            ntcTemplateType: 'Regional Reliability',
            letterTemplateOptions: [],
            ntcQualifiedRecord: null,
            ntcSelectedRecord: null,
            modalOpen: false,
            ntcStatusOptions: [],
            upgradeNtcStatus: '',
            upgradeStatusOptions: [],
            prevUpgradeStatus: '',
            listQualifiedNtcUpgrades: [],
            listSelectedNtcUpgrades: [],
            ntcConditionalArray: [],
            validationMessages: [],
            validationErrorSeen: false,
            refreshGrids: false,
            boardApprovedDate: null,
            agreementExecutionDate: null,
            ntcServiceAgreements: []
        };

        this.letterClient = new LetterApi();
        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getStudiesForForm();
        this.getLetterTemplates();
        this.getNtcStatusTypes();
        this.getApprovalStatusOptions();
        console.log('hello');
    }

    getApprovalStatusOptions = () => {
        this.generalClient.getTypeTableOptions('approvalStatusType')
            .then((response:DropDownItem[]) => {
                this.setState({upgradeStatusOptions: response});
            });
    };

    getNtcStatusTypes = () => {
        this.generalClient.getTypeTableOptions('ntcStatusType')
            .then((response:DropDownItem[]) => {
                this.setState({ntcStatusOptions: response});
            });
    };

    getLetterTemplates = () => {
        this.letterClient.getLetterTemplateOptions()
            .then((response:DropDownItem[]) => {
                this.setState({letterTemplateOptions:response});
            });
    };

    getStudiesForForm = () => {
        this.generalClient.getDropDownOfType('study')
            .then((response:DropDownItem[]) => {
                this.setState({ntcStudies:response, userAuthorized: true, pageLoading: false, ntcSectionLoading: false});
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
                    this.setState({userAuthorized:false, pageLoading: false});
                }
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                else {
                    this.props.setLoggedIn(false);
                }
            });

        this.setState({ntcSectionLoading: true});
    };

    studyChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let study = data.value as string;
        if(this.state.ntcStudyChosen !== study) {
            if(this.state.ntcTemplateType !== '') {
                this.updateFormDetails(study);
                this.setState({ntcStudyChosen: study, ntcSectionLoading: true});
            }
            else {
                this.setState({ntcStudyChosen: study});
            }
        }
        /*if(this.state.ntcStudyChosen !== study && this.state.ntcTemplateType !== '') {
            this.updateFormDetails(study);
            this.setState({ntcStudyChosen: study, ntcSectionLoading: true});
        }*/
    };

    updateFormDetails = (study: string) => {
        this.letterClient.getNtcInitialFormDetails(study)
            .then((response:any) => {
                this.setState({ntcServiceAgreements: [], facilityOwnerList: response.ntcFacilityOwners, ntcTrackingComments: response.ntcTrackingComment, ntcNetworkUpgradeSourceTypeCallBack: response.networkUpgradeSourceTypes, ntcSectionLoading: false});
            });
    }

    networkUpgradeSourceTypeChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let upgradeSourceType = data.value as string;
        if(this.state.ntcNetworkUpgradeSourceTypeChosen !== upgradeSourceType) {
            this.setState({ntcNetworkUpgradeSourceTypeChosen: upgradeSourceType});
        }
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({[data.name.toString()]: data.value} as any);
    };

    templateTypeChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let templateType = data.value as string;
        if(this.state.ntcTemplateType !== templateType) {
            if(this.state.ntcStudyChosen !== '') {
                this.updateFormDetails(this.state.ntcStudyChosen);
                this.setState({ntcTemplateType: templateType, ntcSectionLoading: true});
            }
            else {
                this.setState({ntcTemplateType: templateType});
            }
        }
        /*if(this.state.ntcTemplateType !== templateType && this.state.ntcStudyChosen !== '') {
            this.updateFormDetails(this.state.ntcStudyChosen);
            this.setState({ntcTemplateType: templateType, ntcSectionLoading: true});
        }*/
    };

    fetchDropDownData = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.generalClient.getDropDownData(this.state.ntcNetworkUpgradeSourceTypeCallBack)
            .then((response:DropDownItem[]) => {
                this.setState({ntcNetworkUpgradeSourceTypes:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };

    ntcCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        if(data.value !== this.state.ntcTrackingComments[this.state.activeTabIndex]) {
            let { ntcTrackingComments } = this.state;
            ntcTrackingComments[this.state.activeTabIndex] = data.value as string;
            this.setState({ntcTrackingComments});
        }
    };

    createNtc = () => {
        if(this.state.networkUpgradeSourceType !== '') {
            let validateAndCreateRequest: ValidateNtcUpgradeRequest = {
                upgrades: this.state.listSelectedNtcUpgrades,
                templateType: this.state.ntcTemplateType,
                networkUpgradeSourceType: this.state.networkUpgradeSourceType,
                trackingComment: this.state.ntcTrackingComments[this.state.activeTabIndex],
                conditional: this.state.ntcConditionalArray[this.state.activeTabIndex] === undefined ? false : this.state.ntcConditionalArray[this.state.activeTabIndex],
                facilityOwner: this.state.facilityOwnerList[this.state.activeTabIndex],
                studyId: this.state.ntcStudyChosen,
                boardApprovedDate: this.state.boardApprovedDate,
                agreementExecutionDate: this.state.agreementExecutionDate,
                serviceAgreementRequests: this.state.ntcServiceAgreements
            };
            this.generalClient.validateAndCreateNtc(validateAndCreateRequest)
                .then((response: NtcRfpCreateResponse) => {
                    if (response.success) {
                        toast.success("successfully created NTC");
                        const currentPath = this.props.location.pathname;
                        history.push({
                            pathname: this.props.location.state !== undefined ? this.props.location.state.from : '/NTC',
                            state: {from: currentPath}
                        });
                    } else {
                        toast.warn('Issue creating NTC');
                        this.setState({validationMessages: response.messages, validationErrorSeen: true});
                    }
                });

            this.setState({validationMessages: [], validationErrorSeen: false});
        }
        else {
            toast.warn('Network Upgrade Source Type required.  Please fill out');
        }
    };

    handleTabChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        this.setState({ activeTabIndex: data.activeIndex, validationErrorSeen: false, validationMessages: [] });
    };

    getTab = (ownerName:string, index:number) => {
        return {
            menuItem: ownerName,
            render: () => (
                <Tab.Pane>
                    <div>
                        {this.renderNtcFormForFacOwner(ownerName)}
                    </div>
                </Tab.Pane>
            )
        }
    };

    getFacilityOwnerTabs = () => {
        let ownerTabArray:any[] = [];
        this.state.facilityOwnerList.map((value: string, key: number) => {
            ownerTabArray.push(this.getTab(value, key));
        });

        return ownerTabArray;
    };

    checkUpgradeAndAddToNtc = () => {
        let addRequest: NtcOrRfpUpgradeRequest = {
            upgradeId: this.state.ntcQualifiedRecord.upgradeId,
            approvalStatus: this.state.upgradeNtcStatus
        };
        this.generalClient.addUpgradeToNtc(addRequest)
            .then((response:boolean) => {
                if(response) {
                    toast.success('Successfully added Upgrade to NTC');
                    this.setState({refreshGrids: true});
                    this.ModalClose();
                }
                else {
                    toast.error('Error adding Upgrade to NTC');
                }
            });
    };

    removeUpgradeFromNtc = () => {
        let removeRequest: NtcOrRfpUpgradeRequest = {
            upgradeId: this.state.ntcSelectedRecord.upgradeId,
            approvalStatus: this.state.upgradeNtcStatus
        };
        this.generalClient.removeUpgradeFromNtc(removeRequest)
            .then((response:boolean) => {
                if(response) {
                    toast.success('Successfully removed upgrade from NTC');
                    this.setState({refreshGrids: true});
                    this.ModalClose();
                }
                else {
                    toast.error('Error removing upgrade from NTC');
                }
            })
    };

    getUpgradePrevStatus = (rowData:any) => {
        this.generalClient.getUpgradePrevStatus(rowData.upgradeId)
            .then((response:string) => {
                this.setState({prevUpgradeStatus:response, ntcSelectedRecord: rowData, modalOpen: true, refreshGrids: false});
            });
    };

    handleLinkClick = (rowData:any) => {
        if(rowData.ntcLink === 'Add to NTC') {
            //Make Call to validate Upgrade
            this.setState({ntcQualifiedRecord: rowData, modalOpen: true, refreshGrids: false});
        }
        else {
            this.getUpgradePrevStatus(rowData);
        }
    };

    ModalHeader = () => {
        let iconName:any, header;
        if(this.state.ntcQualifiedRecord !== null) {
            iconName = 'add';
            header = 'Add Upgrade to NTC';
        }
        else {
            if(this.state.ntcSelectedRecord !== null) {
                iconName = 'remove';
                header = 'Remove Upgrade from NTC';
            }
        }
        return (
            <Header as='h3'>
                <Icon name={iconName} />
                {header}
            </Header>
        )
    };

    ModalContent = () => {
        if(this.state.ntcQualifiedRecord !== null) {
            return (
                <div>
                    <p>Select the Status for the Upgrade to be included in this NTC</p>
                    <Input
                        label="NTC Status"
                        fluid={true}
                        input={
                            <Dropdown
                                placeholder="Select NTC Status"
                                name={'upgradeNtcStatus'}
                                fluid={true}
                                search={true}
                                selection={true}
                                clearable={true}
                                onChange={this.dropDownChange}
                                options={this.state.ntcStatusOptions}
                                style={{
                                    borderRadius: "0 4px 4px 0"
                                }}
                            />
                        }
                    />
                </div>
            )
        }
        else {
            if(this.state.ntcSelectedRecord !== null) {
                return (
                    <div>
                        <p>Select the Status that you would like to change this Upgrade to:</p>
                        <p>NOTE: The previous status for this Upgrade was {this.state.prevUpgradeStatus}</p>
                        <Input
                            label="NTC Status"
                            fluid={true}
                            input={
                                <Dropdown
                                    placeholder="Select NTC Status"
                                    name={'upgradeNtcStatus'}
                                    fluid={true}
                                    search={true}
                                    selection={true}
                                    clearable={true}
                                    onChange={this.dropDownChange}
                                    options={this.state.upgradeStatusOptions}
                                    style={{
                                        borderRadius: "0 4px 4px 0"
                                    }}
                                />
                            }
                        />
                    </div>
                )
            }
        }
    };

    ModalActions = () => {
        return (
            <div>
                {this.state.ntcQualifiedRecord !== null &&
                    <Button content={'OK'} color={'green'} onClick={this.checkUpgradeAndAddToNtc}/>
                }
                {this.state.ntcSelectedRecord !== null &&
                    <Button content={'OK'} color={'green'} onClick={this.removeUpgradeFromNtc}/>
                }
                <Button content={'Cancel'} color={'red'} onClick={this.ModalClose}/>
            </div>
        )
    };

    ModalClose = () => {
        this.setState({modalOpen: false, ntcSelectedRecord: null, ntcQualifiedRecord: null});
    };

    getGridData = (gridData:any[], gridId:string) => {
        if(gridId === 'ntcSelectedUpgrades') {
            this.setState({listSelectedNtcUpgrades:gridData});
        }
        else {
            this.setState({listQualifiedNtcUpgrades:gridData});
        }
    };

    conditionalChange = (event: React.FormEvent<HTMLInputElement>, data:CheckboxProps) => {
        let { ntcConditionalArray } = this.state;
        ntcConditionalArray[this.state.activeTabIndex] = data.checked as boolean;
        this.setState({ntcConditionalArray});
    };

    getWarningAndErrorMessages = () => {
        let items: any[] = [];
        this.state.validationMessages?.map((value:string, index: number) => {
            items.push(<List.Item content={value} />);
        });
        if(items.length > 0) {
            let list = <List bulleted={true} content={items}/>;
            return <Message visible={this.state.validationErrorSeen} error={true} header={'NTC Warnings/Errors:'}
                            content={list}/>;
        }
        else {
            return <div />;
        }
    };

    onDateChange = (e:any,c:any,recordName:string) => {
        this.setState({[recordName]: e} as any);
    };

    updateServiceAgreement = (startingAgreement: NtcServiceAgreement[]) =>{
        if(startingAgreement !== this.state.ntcServiceAgreements) {
            this.setState({ntcServiceAgreements: startingAgreement});
        }
    }

    renderNtcFormForFacOwner = (ownerName:string) => {
        return (
            <Container fluid={true}>
                <TracModal header={this.ModalHeader()} content={this.ModalContent} actions={this.ModalActions}
                           open={this.state.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                           onClose={this.ModalClose}
                />
                <Segment raised={true}>
                    {this.state.ntcStudyChosen !== '' && this.state.ntcTemplateType !== '' &&
                    <Container className={'rfpNtcFormArea'} fluid={true}>
                        <Header as='h2' block={true}>
                            NTC Info for Transmission Owner {ownerName}
                        </Header>
                        <div>
                            <Dropdown
                                className={'ntcDropDown'}
                                onClick={this.fetchDropDownData}
                                onFocus={this.fetchDropDownData}
                                name={'networkUpgradeSourceType'}
                                selection={true}
                                search={true}
                                clearable={true}
                                placeholder={'Select Network Upgrade Source Type'}
                                options={this.state.ntcNetworkUpgradeSourceTypes}
                                onChange={this.dropDownChange}
                                loading={this.state.dropDownLoading}
                            />
                        </div>
                        <div>
                            <ReactDatePicker
                                className={'datepickerinput'}
                                selected={moment(this.state.boardApprovedDate).isValid() ? moment(this.state.boardApprovedDate, "MM/DD/YYYY").toDate() : null}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'boardApprovedDate')}
                                placeholderText={'Select Board Approved Date'}
                                autoComplete={'off'}
                                isClearable={!!this.state.boardApprovedDate}
                                todayButton={'Today'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                            />
                        </div>
                        <div className={'rfpNtcFormDiv'}>
                            <ReactDatePicker
                                className={'datepickerinput'}
                                selected={moment(this.state.agreementExecutionDate).isValid() ? moment(this.state.agreementExecutionDate, "MM/DD/YYYY").toDate() : null}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'agreementExecutionDate')}
                                placeholderText={'Select Agreement Execution Date'}
                                autoComplete={'off'}
                                isClearable={!!this.state.agreementExecutionDate}
                                todayButton={'Today'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                            />
                        </div>
                        <div style={{paddingTop: '10px'}}>
                        {this.state.ntcTemplateType === 'Generation Interconnection' &&
                            //Putting in Service Agreement fields only when Generation Interconnection
                            <ServiceAgreementComponent updateServiceAgreement={this.updateServiceAgreement} startingAgreement={this.state.ntcServiceAgreements}/>
                        }
                        </div>
                        <TextArea className={'rfpNtcTextArea'} value={this.state.ntcTrackingComments[this.state.activeTabIndex]} onChange={this.ntcCommentChange} />
                        <Checkbox className={'conditionalNtcCheckbox'} label={'Conditional NTC'} onChange={this.conditionalChange} checked={this.state.ntcConditionalArray[this.state.activeTabIndex]}/>
                        <br/>
                        <GridContainer
                            getGridDataUrl={'/api/auth/getSelectedNtcUpgradesForStudy/' + this.state.ntcStudyChosen + '/' + this.state.facilityOwnerList[this.state.activeTabIndex]}
                            gridPaginated={false}
                            handleLinkClick={this.handleLinkClick}
                            gridTitle={'Upgrades selected for NTC'}
                            gridId={'ntcSelectedUpgrades'}
                            getDataFromGrid={this.getGridData}
                            externalGridRefresh={this.state.refreshGrids}
                        />
                        <br/><br/>
                        <GridContainer
                            getGridDataUrl={'/api/auth/getQualifyingNtcUpgradesForStudy/' + this.state.ntcStudyChosen + '/' + this.state.facilityOwnerList[this.state.activeTabIndex]}
                            gridPaginated={false}
                            handleLinkClick={this.handleLinkClick}
                            gridTitle={'Upgrades Qualifying for NTC'}
                            gridId={'ntcQualifyingUpgrades'}
                            externalGridRefresh={this.state.refreshGrids}
                        />
                        {this.getWarningAndErrorMessages()}
                        <Button content={'Create NTC'} positive={true} onClick={this.createNtc} />
                    </Container>
                    }

                    <Dimmer className={'pageLoader'} active={this.state.ntcSectionLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        )
    };

    render() {
        const { activeTabIndex } = this.state;

        return (
            <Container fluid={true}>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                        <React.Fragment>
                            <Dropdown
                                className={'ntcDropDown'}
                                selection={true}
                                search={true}
                                clearable={true}
                                placeholder={'Select a study'}
                                options={this.state.ntcStudies}
                                onChange={this.studyChange}
                            />
                            <div>
                                <Dropdown
                                    className={'ntcDropDown'}
                                    name={'ntcTemplateType'}
                                    selection={true}
                                    search={true}
                                    clearable={true}
                                    value={this.state.ntcTemplateType}
                                    placeholder={'Select NTC Letter Type'}
                                    options={this.state.letterTemplateOptions}
                                    onChange={this.templateTypeChange}
                                />
                            </div>
                        </React.Fragment>
                    }
                    {this.state.ntcStudyChosen !== '' && this.state.ntcTemplateType !== '' && this.state.facilityOwnerList.length > 0 &&
                        <Tab
                            className={'ntcMainFormSection'}
                            panes={this.getFacilityOwnerTabs()}
                            activeIndex={activeTabIndex}
                            onTabChange={this.handleTabChange}
                        />
                    }
                    {this.state.ntcStudyChosen !== '' && this.state.ntcTemplateType !== '' && this.state.facilityOwnerList.length === 0 && !this.state.ntcSectionLoading &&
                        <div className={'ntcMainFormSection'}>No results found for Study</div>
                    }
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNtcForm);