import * as React from 'react';
import {NavLink, Link} from 'react-router-dom';
import {
    Container,
    Dropdown, Icon,
    Menu
} from "semantic-ui-react";
import generalApi from "../../services/general-services";
import {toast} from "react-toastify";
import {connect} from "react-redux";

interface MenuItemComponentProps {
    appName: string
    loggedIn: boolean
    activeMenuItem: string
    handleActiveOnMenuItemClick: (activeProp:string) => void;
    scrolledTo: any
}

interface MenuItemComponentState {
    menuItems: MenuItem[]
    activeItem: string
    upgradesOpen: boolean
    subMenus: Map<string, boolean>,
    activeItemName: string
}

class MenuItemComponent extends React.Component<MenuItemComponentProps, MenuItemComponentState> {
    private client: generalApi;

    constructor(props: MenuItemComponentProps) {
        super(props);
        this.state = {
            menuItems: [],
            activeItem: '',
            upgradesOpen: false,
            subMenus: new Map(),
            activeItemName: ''
        };

        this.client = new generalApi();
    }

    componentDidMount(): void {
        this.getMenuItems();
    }

    componentDidUpdate(prevProps: Readonly<MenuItemComponentProps>, prevState: Readonly<MenuItemComponentState>) {
        if(prevProps.scrolledTo !== this.props.scrolledTo) {
            this.handleNavDropClose(null,this.state.activeItemName);
        }
    }

    getMenuItems = () => {
        this.client.getMenuItems()
            .then((response:MenuItem[]) => {
                let localMap = new Map();
                for(let i = 0; i < response.length; i++) {
                    if(response[i].subMenuItems.length > 0) {
                        localMap.set(response[i].menuName, false);
                    }
                }
                this.setState({menuItems:response});
            })
    };

    handleItemClick = (e:any, { name }:any) => {
        console.log(name);
        this.props.handleActiveOnMenuItemClick(name);
        this.setState({ activeItem: name });
    };

    appLinkClick = () => {
        this.setState({activeItem: 'home'});
    };

    /*displayMenuItems = () => {
        let menu;
        if(this.state.menuItems?.length > 0) {
            menu = this.state.menuItems.map((item: MenuItem, key: any) => {
                if(item.subMenuItems.length === 0) {
                    return (
                        <Menu.Item
                            className={'sideMenuItem'}
                            name={item.menuName}
                            active={this.state.activeItem === item.menuName}
                            as={NavLink}
                            to={item.menuLink}
                            exact={true}
                            onClick={this.handleItemClick}
                        >
                            {item.menuDisplayName}
                        </Menu.Item>
                    )
                }
                else {
                    return (
                        <Dropdown className={this.state.activeItem === item.menuName ? 'sideSubMenuTopChosen' : 'sideSubMenuTop'} id={item.menuName} icon={null} pointing={'left'} name={item.menuName} open={this.state.subMenus.get(item.menuName)} onMouseEnter={(e:any) => this.handleNavDropOpen(e,item.menuName)} onMouseLeave={this.handleNavDropClose} item={true} text={item.menuDisplayName}>
                            <Dropdown.Menu className={'subMenuBox'} id={item.menuName + "subMenu"}>
                                {item.subMenuItems.map((subItem: MenuItem, key: any) => {
                                    return (
                                        <Dropdown.Item className={'subMenuItem'} as={NavLink} to={subItem.menuLink} onClick={() => this.closeDropDownOnClick(item.menuName)} exact={true} icon='edit' text={subItem.menuDisplayName} />
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }
            })
        }

        return menu;
    };*/

    /* Recursive method for creating SideNav SubMenus */
    recursivelyGetSubMenus = (item:MenuItem, topLevel:boolean) => {
        return (
            <Dropdown className={!topLevel ? 'subMenuChosen' : (this.props.activeMenuItem ? this.props.activeMenuItem : this.state.activeItem) === item.menuName ? 'sideSubMenuTopChosen' : 'sideSubMenuTop'} 
            id={item.menuName} icon={topLevel ? null : <Icon className={'dropDownCaret'} name={'caret right'} />} pointing={'left'} name={item.menuName} open={this.state.subMenus.get(item.menuName)} 
            onMouseEnter={(e:any) => this.handleNavDropOpen(e,item.menuName)} onMouseLeave={(e:any) => this.handleNavDropClose(e, item.menuName)} item={true} text={item.menuDisplayName}>
                <Dropdown.Menu className={'subMenuBox'} id={item.menuName + "subMenu"}>
                    {item.subMenuItems.map((subItem: MenuItem, key: any) => {
                        if(subItem.subMenuItems.length === 0) {
                            return (
                                <Dropdown.Item className={'subMenuItem'} as={NavLink} to={subItem.menuLink}
                                               onClick={() => this.closeDropDownOnClick(item.menuName)} exact={true}
                                               icon={'edit'} text={subItem.menuDisplayName}/>
                            )
                        }
                        else {
                            /*return (
                                <Dropdown.Item className={'subMenuItem'}>
                                    <div>
                                        <Icon name={'edit'} />
                                        <div className={'subItemWithSubItems'}>
                                        {this.recursivelyGetSubMenus(subItem, false)}
                                        </div>
                                    </div>
                                </Dropdown.Item>
                            )*/
                            return this.recursivelyGetSubMenus(subItem, false);
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    };

    displayMenu = () => {
        let menu;
        if(this.state.menuItems?.length > 0) {
            menu = this.state.menuItems.map((item: MenuItem, key: any) => {
                if(item.subMenuItems.length === 0) {
                    return (
                        <Menu.Item
                            className={'sideMenuItem'}
                            name={item.menuName}
                            active={(this.props.activeMenuItem ? this.props.activeMenuItem : this.state.activeItem) === item.menuName}
                            as={NavLink}
                            to={item.menuLink}
                            exact={true}
                            onClick={this.handleItemClick}
                        >
                            {item.menuDisplayName}
                        </Menu.Item>
                    )
                }
                else {
                    return (
                        this.recursivelyGetSubMenus(item, true)
                    )
                }
            })
        }

        return menu;
    };

    test = () => {
        toast.info('hel');
    };

    handleNavDropOpen = (e:any, name:string) => {
        let localMap = this.state.subMenus;
        localMap.set(name, true);

        //Handle positioning subMenus
        let position;
        const parentDom = (document.getElementById('sideNavMenu') as HTMLElement).getBoundingClientRect();
        let menuItemWithDropdown = document.querySelectorAll("#" + name);
        let subMenu = document.querySelectorAll("#" + name + "subMenu");
        if (menuItemWithDropdown.length > 0) {
            position = (menuItemWithDropdown.item(0) as HTMLElement).getBoundingClientRect().top;
            position = position - parentDom.top;
            if (subMenu.length > 0) {
                (subMenu.item(0) as HTMLElement).style.top = position.toString() + 'px';
            }
        }

        this.setState({subMenus: localMap, activeItemName: name});
    };

    handleNavDropClose = (event:any, name:string) => {
        let localMap = this.state.subMenus;
        if(localMap.has(name)) {
            localMap.set(name, false);
        }
        /*localMap.forEach((value:boolean, key:string) => {
            if(localMap.has(name)) {
                localMap.set(key, false);
            }
        });*/
        this.setState({subMenus: localMap});
    };

    closeDropDownOnClick = (menuName:string) => {
        let localMap = this.state.subMenus;
        localMap.forEach((value:boolean, key:string) => {
            localMap.set(key, false);
        });
        this.props.handleActiveOnMenuItemClick('');
        this.setState({subMenus: localMap, activeItem:menuName});
        //this.setState({upgradesOpen: false});
    };

    public render() {
        return (
            <Menu inverted={true} pointing={true} vertical={true} className={'sideMenu'}>
                <Menu.Item
                    name={'trac'}
                    className={'sideMenuItem'}
                >
                    <Link to={'/'} onClick={this.appLinkClick} className={'sideNavAppName'}>
                        {this.props.appName}
                    </Link>
                </Menu.Item>
                {this.props.loggedIn &&
                    this.displayMenu()
                }
            </Menu>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(MenuItemComponent);