import * as React from 'react';
import MaskedInput, {MaskedInputProps} from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {FormInputProps, HtmlInputrops, InputProps, Form} from "semantic-ui-react";

interface NumberInputProps extends HtmlInputrops {
    metaData: any;
}

interface NumberInputState {
    mask: any;
}

class NumberInput extends React.Component<NumberInputProps, NumberInputState> {

    constructor(props: NumberInputProps, state: NumberInputState) {
        super(props,state);
        this.state = {
            mask: null
        };
    }

    componentDidMount(): void {
        let mask, options:any;
        if(this.props.metaData.format !== '') {
            switch (this.props.metaData.format) {
                case 'currency':
                    options = this.getCurrencyMaskOptions();
                    break;
                case 'percentage':
                    options = this.getPercentageMaskOptions();
                    break;
            }

            mask = createNumberMask({
                ...options
            });

            this.setState({mask});
        }
    }

    getCurrencyMaskOptions = () => {
        return {
            prefix: '$',
            suffix: '',
            includeThousandsSeparator: true,
            thousandsSeparatorSymbol: ',',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 10, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        };
    };

    getPercentageMaskOptions = () => {
        return {
            prefix: '',
            suffix: '%',
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: 2, // how many digits allowed after the decimal
            integerLimit: 2, // limit length of integer numbers
            allowNegative: false,
            allowLeadingZeroes: false,
        };
    };

    public render() {
        if(this.state.mask !== null) {
            return (
                <MaskedInput inputMode={'numeric'} mask={this.state.mask} {...this.props} />
            );
        }
        else {
            return (
                <Form.Input {...this.props} />
            );
        }
    }
}

export default NumberInput;