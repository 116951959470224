import * as React from 'react';
import {Segment} from "semantic-ui-react";

interface InitialsSegmentProps {
    className: any;
    avatarColor: any;
    userFirstName?: string;
    userLastName?: string;
    userInitials?: string;
    size: any;
}

interface InitialsSegmentState {
}

class InitialsSegment extends React.Component<InitialsSegmentProps, InitialsSegmentState> {

    constructor(props: InitialsSegmentProps, state: InitialsSegmentState) {
        super(props,state);
        this.state = {
        };
    }

    getUserInitials = (): string => {
        if(this.props.userFirstName !== undefined && this.props.userLastName !== undefined) {
            return this.props.userFirstName.charAt(0).toUpperCase() + this.props.userLastName.charAt(0).toUpperCase();
        }
        else {
            if(this.props.userInitials !== undefined) {
                return this.props.userInitials;
            }
            else {
                return 'NA';
            }
        }
    };

    public render() {
        return (
            <Segment
                content={this.getUserInitials()}
                inverted={true}
                circular={true}
                size={this.props.size}
                color={this.props.avatarColor}
                className={this.props.className}
            />
        );
    }
}

export default InitialsSegment;