import axios from "axios";

export default class ExternalService {

    constructor() {
    }

    getScertForm = (scertRequest:ScertRequest) => {
        //const url = `/api/auth/getScertProperties`;
        const url = `/api/auth/loadScertForm`;

        return new Promise<ScertFormObject>((resolve, reject) => {
            axios.post(url, scertRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting SCERT form'));
                    }
                });
        });
    };

    saveMitigationCloseoutChanges = (scertOwnerGuid:any, mitigationCloseoutInfo:any) => {
        const url = `/api/auth/saveMitigationCloseoutInfo`;

        return new Promise((resolve, reject) => {
            axios.post(url, mitigationCloseoutInfo, { params: {
                    scertOwnerGuid
                }})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving Mitigation/CloseOut Information'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    bulkUpdateScertQuarterlyReviewInd = () => {
        const url = `/api/auth/bulkUpdateScertRecordsQuarterlyReviewInd`;

        return new Promise<string>((resolve, reject) => {
            axios.patch(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error performing bulk update of Quarterly Review Indicator'));
                    }
                });
        });
    };

    getMitigationCloseoutStatusOptions = () => {
        const url = `/api/auth/getMitigationCloseoutStatusOptions`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting mitigation/closeout status options'));
                    }
                });
        });
    };

    uploadMitigationCloseoutDoc = (scertOwnerGuid: any, formData:FormData, fileName:string) => {
        const url = `/api/auth/uploadMitigationCloseoutFile`;

        return new Promise((resolve, reject) => {
            axios.post(url, formData, { params: {
                    scertOwnerGuid
                }})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error uploading document: ' + fileName));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    downloadMitigationCloseoutFile = (fileId: string) => {
        const url = `/api/auth/downloadMitigationCloseoutFile`;

        return new Promise((resolve, reject) => {
            axios.post(url, {fileId: fileId}, {
                responseType: 'arraybuffer'
            }).then(resp => {
                resolve(resp);
            }).catch(err => {
                if(!err.response.data.badToken) {
                    reject(new Error(`Error downloading file - ${err.response.data.message}`));
                }
            });
        });
    };

    deleteMitigationCloseoutFile = (scertOwnerGuid: string, fileId: string) => {
        const url = `/api/auth/deleteMitigationCloseoutFile`;

        return new Promise((resolve, reject) => {
            axios.delete(url, {
                data: {fileId: fileId, scertOwnerGuid: scertOwnerGuid}
            })
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error deleting file'));
                    }
                });
        });
    };

    updateScertForm = (scertForm: ScertFormObject) => {
        const url = `/api/auth/updateScertRecord`;

        return new Promise<ScertFormObject>((resolve, reject) => {
            axios.post(url, scertForm)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error updating SCERT form'));
                    }
                })
                .catch((error:any) => {
                    if(!error.response.data.badToken) {
                        reject(error);
                    }
                });
        });
    }

    modProjectIdExists = (modProjectId: number) => {
        const url = `/api/auth/validateModProjectId?modProjectId=${modProjectId}`;

        return new Promise <boolean>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting MOD project Id'));
                    }
                })
                .catch((error:any) => {
                    if(!error.response.data.badToken) {
                        reject(error);
                    }
                });
        });
    }

    getNtcRecordById = (ntcId:string) => {
        const url = `/api/auth/getNtcRecordForTransmissionOwner/${ntcId}`;

        return new Promise<NtcRecordResponse>((resolve, reject) => {
            axios.get(url)
                .then(({status, data}) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting ntc record for NtcId ' + ntcId));
                    }
                })
                .catch((error:any) => {
                    if(!error.response.data.badToken) {
                        reject(error);
                    }
                });
        });
    };
}