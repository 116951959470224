import * as React from 'react';
import {toast} from "react-toastify";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";
import {Accordion, Icon} from "semantic-ui-react";
import TracWebForm from "../../GenericComponents/TracWebForm";
import UpgradeApi from "../../../services/upgrade-service";
import history from "../../../history";

interface UpgradeFormSectionProps {
    location: any;
    sectionMetaDataEndpoint: string
    sectionName: string
    segmentHeader: string
    accordionContent: any
    formMetaData: any
    contentHidden?: boolean
    resetWebForm: () => void;
    submissionClicked?: boolean
    setLoggedIn: (loggedIn:boolean) => void;
    updateAccordionContent: (content:any, section:string) => void;
    setRequiredFieldsMissingInForm : (missingRequired:boolean, sectionName:string) => void;
    loggedIn: boolean;
}

interface UpgradeFormSectionState {
    active: boolean
    pageLoading: boolean
    userAuthorized: boolean
    sectionObject: any
    formMetaData: any
}

class UpgradeFormSection extends React.Component<UpgradeFormSectionProps,UpgradeFormSectionState> {
    private upgradeClient: UpgradeApi;

    constructor(props: UpgradeFormSectionProps) {
        super(props);
        this.state = {
            pageLoading: true,
            userAuthorized: false,
            active: true,
            sectionObject: null,
            formMetaData: null
        };

        this.upgradeClient = new UpgradeApi();
    }

    componentDidMount(): void {
        this.getUpgradeFormSectionMetaData();
    }

    /*componentDidUpdate(prevProps: Readonly<UpgradeFormSectionProps>, prevState: Readonly<UpgradeFormSectionState>) {
        if(this.props.tabIndexForSection !== undefined) {
            if(this.props.tabIndexForSection !== prevProps.tabIndexForSection) {
                this.getUpgradeFormSectionMetaData();
            }
        }
    }*/

    getUpgradeFormSectionMetaData = () => {
        this.upgradeClient.getUpgradeFormSectionMetaData(this.props.sectionMetaDataEndpoint)
            .then((response: any) => {
                this.setState({
                    sectionObject: response.data[0],
                    formMetaData: response.columnMetaDataList,
                    userAuthorized: true,
                    pageLoading: false
                });
                this.props.updateAccordionContent(response.data[0], this.props.sectionName);
            })
            .catch((error: any) => {
                if (error.response.status === 403) {
                    this.setState({userAuthorized: false, pageLoading: false});
                }
                if (!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                } else {
                    this.props.setLoggedIn(false);
                }
            });
    };

    handleClick = (e:any) => {
        if (!this.props.contentHidden) {
            this.setState({active:!this.state.active})
        }
    };

    updateForm = () => {

    };

    resetWebForm = () => {
        this.props.resetWebForm();
    };

    handleSpecializedClickLogic = (metaDataClicked:any) => {

    };

    requiredFieldsMissingInSection = (missingRequired:boolean) => {
        this.props.setRequiredFieldsMissingInForm(missingRequired, this.props.sectionName);
    }

    render() {
        if(this.props.loggedIn && this.state.userAuthorized) {
            return (
                <div style={{paddingBottom: '20px'}}>
                    <Accordion styled={true} fluid={this.state.active}>
                        <Accordion.Title active={this.state.active} icon={'plus'}
                                         onClick={this.handleClick}
                                         style={{backgroundColor: '#f3f4f5'}}>
                            <Icon name={this.state.active ? 'minus' : 'plus'}
                                  style={{float: 'right', cursor: 'pointer'}}
                            />
                            {this.props.segmentHeader}
                        </Accordion.Title>
                        <Accordion.Content active={this.state.active && !this.props.contentHidden}>
                            <TracWebForm
                                formColumnSize={2}
                                data={this.props.accordionContent}
                                formMetaData={this.state.formMetaData}
                                saveFormRecord={this.updateForm}
                                resetWebForm={this.resetWebForm}
                                handleSpecializedClickLogic={this.handleSpecializedClickLogic}
                                showButtons={false}
                                outsideSubmitClicked={this.props.submissionClicked}
                                setRequiredFieldsMissingInForm={this.requiredFieldsMissingInSection}
                            />
                        </Accordion.Content>
                    </Accordion>
                </div>
            );
        }
        else {
            return (
                <div/>
            )
        }
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeFormSection);