import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {toast} from "react-toastify";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import {Button, ButtonProps, CheckboxProps, Form, Icon, Popup, Radio} from "semantic-ui-react";
import UpgradeApi from "../../services/upgrade-service";

interface ViewUpgradeProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface ViewUpgradeState {
    buttonUploading: Map<string, boolean>
    bulkUploadTemplateChoice: string
    uploadFile: any
    uploadButtonClicked: boolean
}

let fileDownload = require('js-file-download');
class ViewUpgrade extends React.Component<ViewUpgradeProps,ViewUpgradeState> {
    private upgradeClient: UpgradeApi;

    constructor(props: ViewUpgradeProps) {
        super(props);
        this.state = {
            buttonUploading: new Map<string, boolean>(),
            bulkUploadTemplateChoice: '',
            uploadFile: {},
            uploadButtonClicked: false
        };

        this.upgradeClient = new UpgradeApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        let { buttonUploading } = this.state;
        buttonUploading.set('bulkUpload', false);
        this.setState({buttonUploading, bulkUploadTemplateChoice: 'gi'});
    };

    /*chooseUploadFile = () => {
        document.getElementById('bulkUpload')!.click();
    };

    fileUploaded = (e: any) => {
        if (e.target.files.length > 0) {
            let fileExtension = e.target.files[0].name.toLowerCase();
            if (e.target.files.length !== 0) {
                if (fileExtension.endsWith('xlsx')) {
                    let { buttonUploading } = this.state;
                    if(buttonUploading.has('bulkUpload')) {
                        buttonUploading.set('bulkUpload', true);
                    }
                    this.setState({uploadFile: e.target.files[0], buttonUploading});
                    //this.uploadFile(e);
                } else {
                    toast.warn('You can only upload XLSX files')
                }
            }
        }
    };*/

    /*uploadFile = (event:any) => {
        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.set('resource', 'upgrade');
        this.gridClient.uploadFile(formData)
            .then((response: any) => {
                if(response.errors === {}) {
                    toast.success('Error');
                }
                else {
                    toast.success('Successfully uploaded ' + response.data.length + ' upgrade records');
                }
                if (!this.props.paginated) {
                    let {data} = this.state;
                    data.push(response.data);
                    data.sort((a: any, b: any) => (a.id > b.id) ? 1 : -1);
                    this.setState({
                        data,
                        addingRow: false,
                        edit: false,
                        totalRows: data.length,
                        pages: this.getTotalPages(),
                        lastClickedUtilityIndex: -1,
                        modalOpen: false,
                        uploading: false
                    });
                }
                else {
                    this.getPaginatedData(undefined, undefined, undefined, 'add');
                }
            });

        event.target.value = '';
    };*/

    handleUploadTemplateChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        this.setState({bulkUploadTemplateChoice: data.value as string});
    };

    downloadOrUploadFile = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        if(data.actionType === 'download') {
            this.upgradeClient.downloadUpgradeTemplate(this.state.bulkUploadTemplateChoice)
                .then((response:any) => {
                    fileDownload(response.data,response.headers["content-disposition"].split("=")[1]);
                    toast.success('Successfully downloaded ' + response.headers["content-disposition"].split("=")[1]);
                });
        }
        else {
            /*let { buttonUploading } = this.state;
            if(buttonUploading.has('bulkUpload')) {
                buttonUploading.set('bulkUpload', true);
            }*/
            this.setState({uploadButtonClicked: true});
        }
    };

    updateBulkUploadLoadingStatus = (loadingStatus:boolean) => {
        let {buttonUploading} = this.state;
        if (buttonUploading.has('bulkUpload')) {
            buttonUploading.set('bulkUpload', loadingStatus);
        }
        this.setState({buttonUploading, uploadButtonClicked: loadingStatus});
    };

    getUpgradeIntermediateBulkUploadPopup = () => {
        return (
            <Form>
                <Form.Field>
                    <h4>Please select Upgrade Template to download or proceed to Upload Document:</h4>
                </Form.Field>
                <Form.Field>
                    <Radio
                        label='Generation Interconnection'
                        name='radioGroup'
                        value='gi'
                        checked={this.state.bulkUploadTemplateChoice === 'gi'}
                        onChange={this.handleUploadTemplateChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label='Reliability/Economic'
                        name='radioGroup'
                        value='re'
                        checked={this.state.bulkUploadTemplateChoice === 're'}
                        onChange={this.handleUploadTemplateChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label='Transmission Service'
                        name='radioGroup'
                        value='tss'
                        checked={this.state.bulkUploadTemplateChoice === 'tss'}
                        onChange={this.handleUploadTemplateChange}
                    />
                </Form.Field>
                <div className={'bulkUploadButtonSection'}>
                    <Button.Group floated={'right'}>
                        <Button icon={true} labelPosition='left' actionType={'download'} onClick={this.downloadOrUploadFile}>
                            <Icon name={'download'} />
                            Download {this.state.bulkUploadTemplateChoice.toUpperCase()} Template
                        </Button>
                        <Button.Or />
                        <Button positive={true} icon={true} labelPosition='right' actionType={'upload'} loading={this.state.buttonUploading.get('bulkUpload')} onClick={this.downloadOrUploadFile}>
                            Upload
                            <Icon name={'upload'} />
                        </Button>
                    </Button.Group>
                </div>
            </Form>
        );
    };

    specialUploadPopup = () => {
        return (
            <Popup
                trigger={<Button type="button" loading={this.state.buttonUploading.get('bulkUpload')} className={"blue"}>
                    Import
                </Button>}
                content={this.getUpgradeIntermediateBulkUploadPopup}
                on={'click'}
                wide={'very'}
            />
        );
    };

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/upgrade'}
                addRecordUrl={'/api/auth/saveGridRecord/upgrade'}
                editRecordUrl={'/api/auth/editGridRecord/upgrade'}
                deleteRecordUrl={'/api/auth/removeGridRecord/upgrade'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/upgrade'}
                gridTitle={'View Upgrades'}
                gridId={'upgradeGrid'}
                typeForSuperClass={'upgrade'}
                freezeColumn
                bulkUpload={true}
                specialtyBulkUpload={this.specialUploadPopup()}
                complexAddForm={'/upgrades/createUpgrade'}
                specialtyUploadClicked={this.state.uploadButtonClicked}
                updateBulkUploadLoadingStatus={this.updateBulkUploadLoadingStatus}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ViewUpgrade);