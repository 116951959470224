import * as React from 'react';
import {
    Dropdown, DropdownProps,
    Input,
    Form,
    InputOnChangeData, Checkbox
} from "semantic-ui-react";
import {connect} from "react-redux";
import ReactDatePicker from "react-datepicker";
import GeneralApi from "../../../services/general-services";
import {createBreadCrumbBasedOnPage} from "../../../Utils/HelperFunctions";
import moment from "moment";
import {SyntheticEvent} from "react";
import NumberInput from "../NumberInput";
import PaginatedDropDown from "../PaginatedDropDown";
import {toast} from "react-toastify";

interface GridFormElementProps {
    metaData: any
    blurRecord: (event:any, metaData:any, index:number) => void;
    addElementChange: (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => void;
    handleGridFormDateChange: (data:any, index:number) => void;
    addRow: boolean
    addElement: any
    editRow: boolean
    editIndex: number
    editData: any
    updatedData:any
    elementError:boolean
    formSaveAttempted: boolean
    idForObjects: number
    setElementError: (metaData:any, errorState:boolean) => void;
    updateFieldsOnDropChange: (response:CallOnChangeResponse[], editing:boolean) => void;
}

interface GridFormElementState {
    dropDownLoading: boolean
    dropDownOptions: DropDownItem[]
    elementValue: any,
    elementText: any
    dropDownOption: DropDownItem
}

const includes = require('lodash/includes');
class GridFormElement extends React.Component<GridFormElementProps,GridFormElementState> {
    private generalClient: GeneralApi;

    constructor(props: GridFormElementProps) {
        super(props);
        this.state = {
            dropDownLoading: false,
            dropDownOptions: [],
            elementValue: '',
            elementText: '',
            dropDownOption: {
                key: '',
                text: '',
                value: ''
            }
        };

        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        let elementValue, elementText;
        if(this.props.editRow) {
            elementValue = this.props.updatedData[this.props.editIndex][this.props.metaData.accessor];
        }
        else {
            elementValue = this.props.addElement[this.props.metaData.accessor];
        }

        elementText = this.props.metaData.DropDown !== undefined ? this.props.metaData.DropDown.chosenText : '';

        if(elementValue !== null && elementValue !== undefined && elementValue !== '') {
            if(this.props.metaData.DropDown) {
                this.getDropDownTextOnLoad(elementValue);
            }
            else {
                this.setState({elementValue, elementText});
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<GridFormElementProps>, prevState: Readonly<GridFormElementState>) {
        if((this.props.editRow && this.props.updatedData[this.props.editIndex][this.props.metaData.accessor] !== this.state.elementValue)) {
            let elementValue, elementText;
            if(this.props.editRow) {
                elementValue = this.props.updatedData[this.props.editIndex][this.props.metaData.accessor];
                elementText = this.props.updatedData[this.props.editIndex][this.props.metaData.accessor];
            }
            else {
                elementValue = this.props.addElement[this.props.metaData.accessor];
                elementText = this.props.addElement[this.props.metaData.accessor];
            }

            this.setState({elementValue, elementText});
        }
    }

    getDropDownTextOnLoad = (populatedDropDownValue: string) => {
        if(this.props.metaData.DropDown.getTextFromValueOnLoad && populatedDropDownValue !== '' && populatedDropDownValue !== null) {
            let route:string = this.props.metaData.DropDown.getTextFromValueOnLoad;
            let placeHolderArray:string[] = [],
                rxp = /{([^}]+)}/g,
                str = route,
                curMatch;

            while( curMatch = rxp.exec(str)) {
                placeHolderArray.push(curMatch[1]);
            };

            let valueToPass;
            if(placeHolderArray.length > 0) {
                placeHolderArray.map((value: string, index: number) => {
                    valueToPass = populatedDropDownValue;
                    route = route.replace(new RegExp('{' + value + '}', 'gi'), valueToPass);
                });
            }
            this.generalClient.getDropDownTextFromValue(route)
                .then((response: string) => {
                    this.props.metaData.DropDown.chosenText = response;
                    this.setState({elementValue: populatedDropDownValue, elementText: response, dropDownLoading: false});
                })
                .catch((error:any) => {
                    //toast.error(error.toString());
                    this.props.metaData.DropDown.chosenText = '';
                    this.setState({elementValue: populatedDropDownValue, elementText: '', dropDownLoading: false});
                })
            this.setState({dropDownLoading: true});
        }
        else {
            this.props.metaData.DropDown.chosenText = populatedDropDownValue;
            this.setState({elementValue: populatedDropDownValue, elementText: populatedDropDownValue});
        }
    };

    fetchDropDownData = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let dropDownOptions:DropDownItem[];
        this.generalClient.getDropDownData(data.metaData.DropDown.callbackRoute)
            .then((response:DropDownItem[]) => {
                this.setState({dropDownOptions:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };

    getEditOrAddElement = () => {
        if(this.props.editRow) {
            return this.props.updatedData[this.props.editIndex];
        }
        else {
            return this.props.addElement;
        }
    };

    onDateChange = (e:any,c:any,metaData:any) => {
        let record;
        if(this.props.editRow) {
            this.props.updatedData[this.props.editIndex][metaData.accessor] = e;
            record = this.props.updatedData[this.props.editIndex][metaData.accessor];
            //this.props.handleGridFormDateChange(this.props.updatedData[this.props.editIndex], this.props.editIndex);
        }
        else {
            this.props.addElement[metaData.accessor] = e;
            record = this.props.addElement[metaData.accessor];
            //this.props.handleGridFormDateChange(this.getEditOrAddElement(), this.props.editIndex);
        }
        this.setState({elementValue:record});
    };

    onDropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        /*if(this.props.editRow) {
            this.props.updatedData[this.props.editIndex][this.props.metaData.accessor] = data.value;
        }
        else {
            this.props.addElement[this.props.metaData.accessor] = data.value;
        }
        this.setState({elementValue:data.value});*/

        let textValue = '';
        if(this.props.editRow) {
            this.props.updatedData[this.props.editIndex][this.props.metaData.accessor] = data.value;
        }
        else {
            this.props.addElement[this.props.metaData.accessor] = data.value;
        }
        if(!data.multiple) {
            if (data.options !== undefined) {
                let dropDownOption: any = data.options.filter(obj => {
                    return obj.value === data.value
                });

                if(Array.isArray(dropDownOption) && dropDownOption.length > 0) {
                    textValue = dropDownOption[0].text;
                }
            }
        }
        this.props.metaData.DropDown.chosenText = textValue;
        let errorState = false;
        if((data.value === '' || data.value === null || data.value === undefined || (data.multiple && (!Array.isArray(data.value) || !data.value.length))) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
        if(!errorState && this.props.metaData.DropDown && this.props.metaData.DropDown.callOnChange) {
            this.generalClient.getCallOnChangeFields(this.props.metaData.DropDown.callOnChange, data.value!.toString())
                .then((response:CallOnChangeResponse[]) => {
                    this.props.updateFieldsOnDropChange(response, this.props.editRow);
                })
        }
        this.setState({elementValue:data.value, elementText:textValue});
    };

    onInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        if((this.props.metaData.type === 'Integer' || this.props.metaData.type === 'BigDecimal' || this.props.metaData.type === 'Long') && this.props.metaData.format !== '') {
            let formattedNumberData:any = {};
            formattedNumberData.metaValue = this.props.metaData;
            formattedNumberData.value = event.target.value;
            this.props.addElementChange(event, formattedNumberData);
            data = formattedNumberData;
        }
        else {
            this.props.addElementChange(event, data);
        }
        let errorState = false;
        if((data.value === '' || data.value === null || data.value === undefined) && this.props.formSaveAttempted) {
            errorState = true;
        }

        this.props.setElementError(this.props.metaData, errorState);
    };

    onInputBlur = (event:any) => {
        this.props.updatedData[this.props.editIndex][this.props.metaData.accessor] = event.target.value;
    };

    getPlaceHolderForNumberInput = () => {
        let placeholder = '';
        if(this.props.metaData.format !== '') {
            switch(this.props.metaData.format) {
                case 'currency':
                    placeholder = '$0.00';
                    break;
                case 'percentage':
                    placeholder = '0%';
                    break;
            }
        }

        return placeholder;
    };

    returnReactElementBasedOnType = () => {
        let readOnly = false;
        if(this.props.editRow) {
            readOnly = !this.props.metaData.editable;
        }
        else {
            readOnly = !this.props.metaData.addable;
        }
        if(this.props.metaData.DropDown && this.props.metaData.DropDown.callbackRoute) {
            if(!this.props.metaData.DropDown.pageable) {
                return (
                    <Form.Dropdown
                        className={'gridFormDropdown'}
                        onClick={this.fetchDropDownData}
                        search={true}
                        selection={true}
                        onFocus={this.fetchDropDownData}
                        loading={this.state.dropDownLoading}
                        options={this.state.dropDownOptions}
                        metaData={this.props.metaData}
                        onChange={this.onDropDownChange}
                        text={this.state.elementText}
                        value={this.state.elementValue}
                        error={this.props.elementError}
                        clearable={true}
                        disabled={readOnly}
                    />
                )
            }
            else {
                return (
                    <PaginatedDropDown
                        dropDownInfo={this.props.metaData.DropDown}
                        onChange={this.onDropDownChange}
                        metaData={this.props.metaData}
                        multiple={this.props.metaData.DropDown.multipleSelection !== undefined ? this.props.metaData.DropDown.multipleSelection : false}
                        text={this.state.elementText}
                        value={this.state.elementValue}
                        error={this.props.elementError}
                        disabled={readOnly}
                        idForDropDown={this.props.idForObjects}
                    />
                )
            }
        }
        else {
            switch (this.props.metaData.type) {
                case "LocalDateTime":
                    let date:any = this.state.elementValue;
                    if(moment(date).isValid()) {
                        date = moment(this.state.elementValue, "MM/DD/YYYY").toDate();
                    }
                    else {
                        date = null;
                    }
                    return (
                        <Form.Input error={this.props.elementError}>
                            <ReactDatePicker
                                readOnly={readOnly}
                                className={'datepickerinput'}
                                selected={date}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, this.props.metaData)}
                                placeholderText={'Select Date'}
                                autoComplete={'off'}
                                isClearable={!!this.getEditOrAddElement()[this.props.metaData.accessor] && !readOnly}
                                todayButton={'Today'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                            />
                        </Form.Input>
                    );
                    case 'boolean':
                        return (
                            <Checkbox
                                value={this.state.elementValue}
                                onChange={this.handleCheckBoxChange}
                                checked={this.getEditOrAddElement()[this.props.metaData.accessor]}
                                disabled={readOnly}
                                readOnly={readOnly}
                            />
                        )
                case 'BigDecimal':
                case 'Integer':
                case 'Long':
                    let placeholder = this.getPlaceHolderForNumberInput();
                    if(this.props.editRow) {
                        return (
                            <NumberInput
                                metaData={this.props.metaData}
                                placeholder={placeholder}
                                type={'text'}
                                readOnly={readOnly}
                                disabled={readOnly}
                                defaultValue={this.props.updatedData[this.props.editIndex][this.props.metaData.accessor]}
                                onBlur={this.onInputBlur}
                                error={this.props.elementError}
                            />
                        );
                    }
                    else {
                        return (
                            <NumberInput
                                metaData={this.props.metaData}
                                placeholder={placeholder}
                                type={'text'}
                                readOnly={readOnly}
                                disabled={readOnly}
                                defaultValue={this.props.addElement[this.props.metaData.accessor]}
                                onChange={this.onInputChange}
                                metaValue={this.props.metaData}
                                error={this.props.elementError}
                            />
                        );
                    }
                default:
                    let inputType;
                    if(this.props.metaData.type === 'String') {
                        inputType = 'text';
                    }
                    else {
                        inputType = this.props.metaData.type;
                    }
                    if (this.props.editRow) {
                        return (
                            <Form.Input
                                error={this.props.elementError}
                                readOnly={readOnly}
                                disabled={readOnly}
                                type={(this.props.metaData.type !== '' && this.props.metaData.type !== undefined && this.props.metaData.type !== null) ? inputType : 'text'}
                                defaultValue={this.props.updatedData[this.props.editIndex][this.props.metaData.accessor]}
                                onBlur={this.onInputBlur}
                            />
                        )
                    }
                    else {
                        return (
                            <Form.Input
                                error={this.props.elementError}
                                readOnly={readOnly}
                                disabled={readOnly}
                                type={(this.props.metaData.type !== '' && this.props.metaData.type !== undefined && this.props.metaData.type !== null) ? inputType : 'text'}
                                defaultValue={this.props.addElement[this.props.metaData.accessor]}
                                onChange={this.onInputChange}
                                metaValue={this.props.metaData}
                            />
                        )
                    }
            }
        }
    };

    handleCheckBoxChange = (e :any, tempData :any) => {
        const {name,value,checked}=tempData;
        this.getEditOrAddElement()[this.props.metaData.accessor] = tempData.checked;
        this.setState({elementValue:tempData.checked});
    }

    render() {
        return (
            this.returnReactElementBasedOnType()
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(GridFormElement);