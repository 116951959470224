import * as React from 'react';
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {
    Button, Checkbox,
    Container,
    Dimmer,
    Dropdown,
    DropdownProps,
    Header, Icon, Input, InputOnChangeData, List,
    Loader, Message,
    Segment, Tab,
    TextArea,
    TextAreaProps
} from "semantic-ui-react";
import GeneralApi from "../../services/general-services";
import {toast} from "react-toastify";
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {SyntheticEvent} from "react";
import TracModal from "../GenericComponents/TracModal";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import LetterApi from "../../services/letter-service";
import history from "../../history";

interface CreateRfpFormProps {
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    setLoggedIn: (loggedIn:boolean) => void;
    location: any;
}

interface CreateRfpFormState {
    rfpStudies: DropDownItem[]
    rfpNetworkUpgradeSourceTypes: DropDownItem[]
    rfpNetworkUpgradeSourceTypeCallBack: string
    rfpStudyChosen: string
    rfpNetworkUpgradeSourceTypeChosen: string
    rfpTrackingComments: string[]
    userAuthorized: boolean
    pageLoading: boolean
    dropDownLoading: boolean
    rfpSectionLoading: boolean
    activeTabIndex: number
    facilityOwnerList:string[]
    validationMessages: string[]
    validationErrorSeen: boolean
    refreshGrids: boolean
    boardApprovedDate: any
    rfpQualifiedRecord: any
    rfpSelectedRecord: any
    modalOpen: boolean
    listSelectedRfpUpgrades: any[]
    listQualifiedRfpUpgrades: any[]
    prevUpgradeStatus: string
    rfpStatusOptions: DropDownItem[]
    upgradeRfpStatus: string
    upgradeStatusOptions: DropDownItem[]
    projectShortName: string
    upgradeShortName: string
}

class CreateRfpForm extends React.Component<CreateRfpFormProps,CreateRfpFormState> {
    private letterClient: LetterApi;
    private generalClient: GeneralApi;

    constructor(props: CreateRfpFormProps) {
        super(props);
        this.state = {
            rfpStudies: [],
            rfpNetworkUpgradeSourceTypes: [],
            rfpNetworkUpgradeSourceTypeCallBack: '',
            rfpStudyChosen: '',
            rfpNetworkUpgradeSourceTypeChosen: '',
            rfpTrackingComments: [],
            userAuthorized: false,
            pageLoading: true,
            dropDownLoading: false,
            rfpSectionLoading: false,
            activeTabIndex: 0,
            facilityOwnerList: [],
            validationMessages: [],
            validationErrorSeen: false,
            refreshGrids: false,
            boardApprovedDate: null,
            rfpQualifiedRecord: null,
            rfpSelectedRecord: null,
            modalOpen: false,
            listQualifiedRfpUpgrades: [],
            listSelectedRfpUpgrades: [],
            prevUpgradeStatus: '',
            rfpStatusOptions: [],
            upgradeRfpStatus: '',
            upgradeStatusOptions: [],
            projectShortName: '',
            upgradeShortName: ''
        };

        this.letterClient = new LetterApi();
        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getStudiesForForm();
        this.getRfpStatusTypes();
        this.getApprovalStatusOptions();
    }

    getApprovalStatusOptions = () => {
        this.generalClient.getTypeTableOptions('approvalStatusType')
            .then((response:DropDownItem[]) => {
                this.setState({upgradeStatusOptions: response});
            });
    };

    getRfpStatusTypes = () => {
        this.generalClient.getTypeTableOptions('rfpStatusType')
            .then((response:DropDownItem[]) => {
                this.setState({rfpStatusOptions: response});
            });
    };

    getStudiesForForm = () => {
        this.generalClient.getDropDownOfType('study')
            .then((response:DropDownItem[]) => {
                this.setState({rfpStudies:response, userAuthorized: true, pageLoading: false, rfpSectionLoading: false});
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
                    this.setState({userAuthorized:false, pageLoading: false});
                }
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                else {
                    this.props.setLoggedIn(false);
                }
            });

        this.setState({rfpSectionLoading: true});
    };

    studyChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let study = data.value as string;
        if(this.state.rfpStudyChosen !== study) {
            this.updateFormDetails(study);
            this.setState({rfpStudyChosen: study, rfpSectionLoading: true});
            /*this.generalClient.getRfpFormDetails(study)
                .then((response:any) => {
                    this.setState({rfpTrackingComment: response.rfpTrackingComment, rfpNetworkUpgradeSourceTypeCallBack: response.networkUpgradeSourceTypes, rfpSectionLoading: false});
                });
            this.setState({rfpStudyChosen: study, rfpSectionLoading: true});*/
        }
    };

    updateFormDetails = (study: string) => {
        this.generalClient.getRfpFormDetails(study)
            .then((response:any) => {
                this.setState({facilityOwnerList: response.rfpFacilityOwners, rfpTrackingComments: response.rfpTrackingComment, rfpNetworkUpgradeSourceTypeCallBack: response.networkUpgradeSourceTypes, rfpSectionLoading: false});
            });
    }

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({[data.name.toString()]: data.value} as any);
    };

    networkUpgradeSourceTypeChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let upgradeSourceType = data.value as string;
        if(this.state.rfpNetworkUpgradeSourceTypeChosen !== upgradeSourceType) {
            this.setState({rfpNetworkUpgradeSourceTypeChosen: upgradeSourceType});
        }
    };

    fetchDropDownData = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.generalClient.getDropDownData(this.state.rfpNetworkUpgradeSourceTypeCallBack)
            .then((response:DropDownItem[]) => {
                this.setState({rfpNetworkUpgradeSourceTypes:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };

    rfpCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        if(data.value !== this.state.rfpTrackingComments[this.state.activeTabIndex]) {
            let { rfpTrackingComments } = this.state;
            rfpTrackingComments[this.state.activeTabIndex] = data.value as string;
            this.setState({rfpTrackingComments});
        }
    };

    handleTabChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        this.setState({ activeTabIndex: data.activeIndex, validationErrorSeen: false, validationMessages: [] });
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        /*if(data.name === 'projectShortName') {
            this.setState({projectShortName: data.value});
        }
        if(data.name === 'upgradeShortName') {
            this.setState({upgradeShortName: data.value});
        }*/
        this.setState({[data.name.toString()]: data.value} as any);
    };

    getTab = (ownerName:string, index:number) => {
        return {
            menuItem: ownerName,
            render: () => (
                <Tab.Pane>
                    <div>
                        {this.renderRfpFormForFacOwner(ownerName)}
                    </div>
                </Tab.Pane>
            )
        }
    };

    getFacilityOwnerTabs = () => {
        let ownerTabArray:any[] = [];
        this.state.facilityOwnerList.map((value: string, key: number) => {
            ownerTabArray.push(this.getTab(value, key));
        });

        return ownerTabArray;
    };

    createRfp = () => {
        if(this.state.rfpNetworkUpgradeSourceTypeChosen !== '') {
            let validateAndCreateRequest: ValidateRfpUpgradeRequest = {
                upgrades: this.state.listSelectedRfpUpgrades,
                networkUpgradeSourceType: this.state.rfpNetworkUpgradeSourceTypeChosen,
                trackingComment: this.state.rfpTrackingComments[this.state.activeTabIndex],
                facilityOwner: this.state.facilityOwnerList[this.state.activeTabIndex],
                studyId: this.state.rfpStudyChosen,
                projectShortName: this.state.projectShortName,
                upgradeShortName: this.state.upgradeShortName,
                boardApprovedDate: this.state.boardApprovedDate
            };
            this.generalClient.validateAndCreateRfp(validateAndCreateRequest)
                .then((response: NtcRfpCreateResponse) => {
                    if (response.success) {
                        toast.success("successfully created RFP");
                        const currentPath = this.props.location.pathname;
                        history.push({
                            pathname: this.props.location.state !== undefined ? this.props.location.state.from : '/RFP',
                            state: {from: currentPath}
                        });
                    } else {
                        toast.warn('Issue creating RFP');
                        this.setState({validationMessages: response.messages, validationErrorSeen: true});
                    }
                });

            this.setState({validationMessages: [], validationErrorSeen: false});
        }
        else {
            toast.warn('Network Upgrade Source Type required.  Please fill out');
        }
    };

    checkUpgradeAndAddToRfp = () => {
        let addRequest: NtcOrRfpUpgradeRequest = {
            upgradeId: this.state.rfpQualifiedRecord.upgradeId,
            approvalStatus: this.state.upgradeRfpStatus
        };
        this.generalClient.addUpgradeToRfp(addRequest)
            .then((response:boolean) => {
                if(response) {
                    toast.success('Successfully added Upgrade to RFP');
                    this.setState({refreshGrids: true});
                    this.ModalClose();
                }
                else {
                    toast.error('Error adding Upgrade to RFP');
                }
            });
    };

    removeUpgradeFromRfp = () => {
        let removeRequest: NtcOrRfpUpgradeRequest = {
            upgradeId: this.state.rfpSelectedRecord.upgradeId,
            approvalStatus: this.state.upgradeRfpStatus
        };
        this.generalClient.removeUpgradeFromRfp(removeRequest)
            .then((response:boolean) => {
                if(response) {
                    toast.success('Successfully removed upgrade from RFP');
                    this.setState({refreshGrids: true});
                    this.ModalClose();
                }
                else {
                    toast.error('Error removing upgrade from RFP');
                }
            })
    };

    getUpgradePrevStatus = (rowData:any) => {
        this.generalClient.getUpgradePrevStatus(rowData.upgradeId)
            .then((response:string) => {
                this.setState({prevUpgradeStatus:response, rfpSelectedRecord: rowData, modalOpen: true, refreshGrids: false});
            });
    };

    handleLinkClick = (rowData:any) => {
        if(rowData.rfpLink === 'Add to RFP') {
            //Make Call to validate Upgrade
            this.setState({rfpQualifiedRecord: rowData, modalOpen: true, refreshGrids: false});
        }
        else {
            this.getUpgradePrevStatus(rowData);
        }
    };

    ModalHeader = () => {
        let iconName:any, header;
        if(this.state.rfpQualifiedRecord !== null) {
            iconName = 'add';
            header = 'Add Upgrade to RFP';
        }
        else {
            if(this.state.rfpSelectedRecord !== null) {
                iconName = 'remove';
                header = 'Remove Upgrade from RFP';
            }
        }
        return (
            <Header as='h3'>
                <Icon name={iconName} />
                {header}
            </Header>
        )
    };

    ModalContent = () => {
        if(this.state.rfpQualifiedRecord !== null) {
            return (
                <div>
                    <p>Select the Status for the Upgrade to be included in this RFP</p>
                    <Input
                        label="RFP Status"
                        fluid={true}
                        input={
                            <Dropdown
                                placeholder="Select RFP Status"
                                name={'upgradeRfpStatus'}
                                fluid={true}
                                search={true}
                                selection={true}
                                clearable={true}
                                onChange={this.dropDownChange}
                                options={this.state.rfpStatusOptions}
                                style={{
                                    borderRadius: "0 4px 4px 0"
                                }}
                            />
                        }
                    />
                </div>
            )
        }
        else {
            if(this.state.rfpSelectedRecord !== null) {
                return (
                    <div>
                        <p>Select the Status that you would like to change this Upgrade to:</p>
                        <p>NOTE: The previous status for this Upgrade was {this.state.prevUpgradeStatus}</p>
                        <Input
                            label="RFP Status"
                            fluid={true}
                            input={
                                <Dropdown
                                    placeholder="Select RFP Status"
                                    name={'upgradeRfpStatus'}
                                    fluid={true}
                                    search={true}
                                    selection={true}
                                    clearable={true}
                                    onChange={this.dropDownChange}
                                    options={this.state.upgradeStatusOptions}
                                    style={{
                                        borderRadius: "0 4px 4px 0"
                                    }}
                                />
                            }
                        />
                    </div>
                )
            }
        }
    };

    ModalActions = () => {
        return (
            <div>
                {this.state.rfpQualifiedRecord !== null &&
                <Button content={'OK'} color={'green'} onClick={this.checkUpgradeAndAddToRfp}/>
                }
                {this.state.rfpSelectedRecord !== null &&
                <Button content={'OK'} color={'green'} onClick={this.removeUpgradeFromRfp}/>
                }
                <Button content={'Cancel'} color={'red'} onClick={this.ModalClose}/>
            </div>
        )
    };

    ModalClose = () => {
        this.setState({modalOpen: false, rfpSelectedRecord: null, rfpQualifiedRecord: null});
    };

    getGridData = (gridData:any[], gridId:string) => {
        if(gridId === 'rfpSelectedUpgrades') {
            this.setState({listSelectedRfpUpgrades:gridData});
        }
        else {
            this.setState({listQualifiedRfpUpgrades:gridData});
        }
    };

    getWarningAndErrorMessages = () => {
        let items: any[] = [];
        this.state.validationMessages?.map((value:string, index: number) => {
            items.push(<List.Item content={value} />);
        });
        if(items.length > 0) {
            let list = <List bulleted={true} content={items}/>;
            return <Message visible={this.state.validationErrorSeen} error={true} header={'RFP Warnings/Errors:'}
                            content={list}/>;
        }
        else {
            return <div />;
        }
    };

    onDateChange = (e:any,c:any,recordName:string) => {
        this.setState({[recordName]: e} as any);
    };

    renderRfpFormForFacOwner = (ownerName:string) => {
        return (
            <Container fluid={true}>
                <TracModal header={this.ModalHeader()} content={this.ModalContent} actions={this.ModalActions}
                           open={this.state.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                           onClose={this.ModalClose}
                />
                <Segment raised={true}>
                    {this.state.rfpStudyChosen !== '' &&
                    <Container className={'rfpNtcFormArea'} fluid={true}>
                        <Header as='h2' block={true}>
                            RFP Info for Transmission Owner {ownerName}
                        </Header>
                        <div>
                            <Dropdown
                                className={'rfpDropDown'}
                                onClick={this.fetchDropDownData}
                                onFocus={this.fetchDropDownData}
                                name={'networkUpgradeSourceType'}
                                selection={true}
                                search={true}
                                clearable={true}
                                placeholder={'Select Network Upgrade Source Type'}
                                options={this.state.rfpNetworkUpgradeSourceTypes}
                                onChange={this.networkUpgradeSourceTypeChange}
                                loading={this.state.dropDownLoading}
                            />
                        </div>
                        <div className={'rfpInputDiv'}>
                            <ReactDatePicker
                                className={'datepickerinput'}
                                selected={moment(this.state.boardApprovedDate).isValid() ? moment(this.state.boardApprovedDate, "MM/DD/YYYY").toDate() : null}
                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'boardApprovedDate')}
                                placeholderText={'Select Board Approved Date'}
                                autoComplete={'off'}
                                isClearable={!!this.state.boardApprovedDate}
                                todayButton={'Today'}
                                peekNextMonth={true}
                                showMonthDropdown={true}
                                showYearDropdown={true}
                                dropdownMode="select"
                            />
                        </div>
                        <div className={'rfpInputDiv'}>
                            <Input name={'projectShortName'} label={'Project Short Name'} value={this.state.projectShortName} onChange={this.handleInputChange} />
                        </div>
                        <div className={'rfpInputDiv'}>
                            <Input name={'upgradeShortName'} label={'Upgrade Short Name'} value={this.state.upgradeShortName} onChange={this.handleInputChange} />
                        </div>
                        <TextArea className={'rfpNtcTextArea'} value={this.state.rfpTrackingComments[this.state.activeTabIndex]} onChange={this.rfpCommentChange} />
                        <br/>
                        <GridContainer
                            getGridDataUrl={'/api/auth/getSelectedRfpUpgradesForStudy/' + this.state.rfpStudyChosen + '/' + this.state.facilityOwnerList[this.state.activeTabIndex]}
                            gridPaginated={false}
                            handleLinkClick={this.handleLinkClick}
                            gridTitle={'Upgrades selected for RFP'}
                            gridId={'rfpSelectedUpgrades'}
                            getDataFromGrid={this.getGridData}
                            externalGridRefresh={this.state.refreshGrids}
                        />
                        <br/><br/>
                        <GridContainer
                            getGridDataUrl={'/api/auth/getQualifyingRfpUpgradesForStudy/' + this.state.rfpStudyChosen + '/' + this.state.facilityOwnerList[this.state.activeTabIndex]}
                            gridPaginated={false}
                            handleLinkClick={this.handleLinkClick}
                            gridTitle={'Upgrades Qualifying for RFP'}
                            gridId={'rfpQualifyingUpgrades'}
                            externalGridRefresh={this.state.refreshGrids}
                        />
                        {this.getWarningAndErrorMessages()}
                        <Button content={'Create RFP'} positive={true} onClick={this.createRfp} />
                    </Container>
                    }

                    <Dimmer className={'pageLoader'} active={this.state.rfpSectionLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        )
    };

    render() {
        const { activeTabIndex } = this.state;

        return (
            <Container fluid={true}>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                    <React.Fragment>
                        <Dropdown
                            className={'rfpDropDown'}
                            selection={true}
                            search={true}
                            clearable={true}
                            placeholder={'Select a study'}
                            options={this.state.rfpStudies}
                            onChange={this.studyChange}
                        />
                    </React.Fragment>
                    }
                    {this.state.rfpStudyChosen !== '' && this.state.facilityOwnerList.length > 0 &&
                    <Tab
                        className={'rfpMainFormSection'}
                        panes={this.getFacilityOwnerTabs()}
                        activeIndex={activeTabIndex}
                        onTabChange={this.handleTabChange}
                    />
                    }
                    {this.state.rfpStudyChosen !== '' && this.state.facilityOwnerList.length === 0 && !this.state.rfpSectionLoading &&
                    <div className={'rfpMainFormSection'}>No results found for Study</div>
                    }
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateRfpForm);