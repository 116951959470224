import * as React from 'react';
import {Breadcrumb, BreadcrumbSectionProps, Container} from 'semantic-ui-react';
import 'react-toastify/dist/ReactToastify.min.css';
import {toast, ToastContainer} from "react-toastify";
import NavMenu from './NavMenus/NavMenu';
import Footer from './Footer';
import SideNav from "./NavMenus/SideNav";
import OpenApi from "../services/open-source-service";
import {connect} from "react-redux";
import history from "../history";
import WebSockService from "../services/websocket-service";
import * as actionTypes from "../store/action";

export interface LayoutProps {
    children?: React.ReactNode;
    location: any;
    breadCrumbPath: BreadCrumb[]
    setCsrfToken: (token:string) => void;
}

interface LayoutState {
    currentUser:string,
    roles: any
    sideNavOpen: boolean
    activeMenuFromBreadCrumb: string
}

class Layout extends React.Component<LayoutProps, LayoutState> {
    private client: OpenApi;
    private websockClient: WebSockService;

    constructor(props:LayoutProps) {
        super(props);
        this.state = {
            currentUser: '',
            roles: [],
            sideNavOpen: false,
            activeMenuFromBreadCrumb: ''
        };

        this.client = new OpenApi();
        this.websockClient = new WebSockService();
    }

    componentDidMount(): void {
        //this.getCsrfToken();
        this.websockClient.disconnectWebStomp();
        this.websockClient.connectSocket('newTest', this.checkSocketOnUserLongName);
    }

    getCsrfToken = () => {
        this.client.getCsrf()
            .then((response:string) => {
                this.props.setCsrfToken(response);
            });
    };

    checkSocketOnUserLongName = ({body = "{}"}) => {
        //const test: string = JSON.parse(body);
        toast.info(body);
    };

    changeToBreadCrumbLink = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>, data:BreadcrumbSectionProps) => {
        history.push({
            pathname: data.crumbLink,
            state: {from: window.location !== undefined ? (window.location.search !== undefined ? {pathname: window.location.pathname, search: window.location.search} : {pathname: window.location.pathname}) : {pathname: '/'}}
        });
        this.setState({activeMenuFromBreadCrumb: data.crumbTitle.charAt(0).toUpperCase() + data.crumbTitle.slice(1)});
    };

    handleActiveOnMenuItemClick = (activeProp:string) => {
        this.setState({activeMenuFromBreadCrumb:activeProp});
    };

    public render() {
        return (
            <Container fluid={true} className={'applicationContainer'}>
                <ToastContainer />
                {this.client.loggedIn() &&
                <React.Fragment>
                    <div id={'topNav'}>
                        <NavMenu location={this.props.location}/>
                    </div>
                    <div id={'sideNav'} className={'topSideNav disable-scrollbars'}>
                        <SideNav handleActiveOnMenuItemClick={this.handleActiveOnMenuItemClick} activeMenuItem={this.state.activeMenuFromBreadCrumb}/>
                    </div>
                    <div className={'siteTopLevel'}>
                        <div id={'mainContent'} className={'siteContent'}>
                            <div className={this.props.breadCrumbPath.length > 0 ? "breadcrumb-container" : 'noBreadCrumb-container'}>
                                <Breadcrumb size={'large'}>
                                    {this.props.breadCrumbPath?.map((item:BreadCrumb,index:any)=>{
                                        return (
                                            <div className={'breadCrumbContent'}>
                                                <Breadcrumb.Section onClick={this.changeToBreadCrumbLink} link={!item.active} content={item.breadCrumbTitle} active={item.active} crumbTitle={item.breadCrumbTitle} crumbLink={item.breadCrumbLink} />
                                                {(index < this.props.breadCrumbPath.length - 1) &&
                                                <Breadcrumb.Divider />
                                                }
                                            </div>
                                        )
                                    })}
                                </Breadcrumb>
                            </div>
                            {this.props.children}
                        </div>
                        <Footer />
                    </div>
                </React.Fragment>
                }
                {!this.client.loggedIn() &&
                <div>
                    <div className={'loginTopLevel'}>
                        <div className={'siteContent'}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        breadCrumbPath: state.defaultReducer.breadCrumbPath
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setCsrfToken: (csrfToken:string) => dispatch(
            {type: actionTypes.SET_CSRF_TOKEN, payload: csrfToken})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);