import * as React from 'react';
import {
    Button,
    Container, Dropdown, DropdownItemProps, DropdownProps,
    Form,
    Grid,
    Header,
    Icon,
    Input,
    InputOnChangeData,
    Message,
    SemanticWIDTHS
} from "semantic-ui-react";
import {connect} from "react-redux";
import TracModal from "../TracModal";
import ReactDatePicker from "react-datepicker";
import GeneralApi from "../../../services/general-services";
import GridFormElement from "./GridFormElement";

interface GridFormProps {
    loggedIn: boolean
    gridMetaData: any
    addRow: boolean
    addElement: any
    updatedData: any
    editRow: boolean
    editIndex: number
    editData: any
    lastClickedUtilityIndex: number
    formColumnSize: SemanticWIDTHS | 'equal'
    modalOpen: boolean
    modalClose: (addingRow:boolean, addElement:any, editStatus:boolean, editIndex:number) => void;
    saveFormRecord: (lastClickedIndex: number) => void;
    blurRecord: (event:any, metaData:any, index:number) => void;
    addElementChange: (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => void;
    handleGridFormDateChange: (data:any, index:number) => void;
    updateFieldsOnDropChange: (response:CallOnChangeResponse[], editing:boolean) => void;
}

interface GridFormState {
    error: any
    elementChange: any
    dropDownLoading: boolean
    dropDownOptions: DropDownItem[]
    gridFormAttemptedToSave: boolean
}

const includes = require('lodash/includes');
class GridForm extends React.Component<GridFormProps,GridFormState> {
    private generalClient: GeneralApi;

    constructor(props: GridFormProps) {
        super(props);
        this.state = {
            error: {},
            elementChange: {},
            dropDownLoading: false,
            dropDownOptions: [],
            gridFormAttemptedToSave: false
        };

        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        let error = this.state.error;
        {this.props.gridMetaData.map((metaData:any) => {
            error[metaData.accessor] = false;
        })};

        this.setState({error});
    }

    componentDidUpdate(prevProps: Readonly<GridFormProps>, prevState: Readonly<GridFormState>) {
        if(this.props.modalOpen !== prevProps.modalOpen) {
            let error = this.state.error;
            {this.props.gridMetaData.map((metaData:any) => {
                error[metaData.accessor] = false;
            })};

            this.setState({error, gridFormAttemptedToSave: false});
        }
    }

    /*fetchDropDownData = (event: React.KeyboardEvent<HTMLElement>, data: DropdownProps) => {
        let dropDownOptions:DropDownItem[];
        this.generalClient.getDropDownData(data.metaData.DropDown.callbackRoute)
            .then((response:DropDownItem[]) => {
                this.setState({dropDownOptions:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };*/

    ModalHeader = () => {
        let iconName:any, header;
        iconName = '';
        if(this.props.editRow) {
            iconName = 'edit';
            header = 'Edit Record';
        }
        else {
            if (this.props.addRow) {
                iconName = 'add';
                header = 'Add Record';
            }
        }
        return (
            <Header as='h3'>
                <Icon name={iconName} />
                {header}
            </Header>
        )
    };

    checkIfRequiredFieldsFilledOut = (metaData:any) => {
        if(metaData.required) {
            if (this.props.editRow) {
                let fieldValue = this.props.updatedData[this.props.editIndex][metaData.accessor];
                if (fieldValue === '' || fieldValue === null || fieldValue === undefined) {
                    return false;
                }
            }
            else {
                let fieldValue = this.props.addElement[metaData.accessor];
                if (fieldValue === '' || fieldValue === null || fieldValue === undefined) {
                    return false;
                }
            }
        }

        return true;
    };

    saveGridFormRecord = () => {
        let canSubmitForm = true, error = this.state.error;

        {this.props.gridMetaData.map((metaData:any) => {
            if(!this.checkIfRequiredFieldsFilledOut(metaData)) {
                error[metaData.accessor] = true;
                canSubmitForm = false;
            }
            else {
                error[metaData.accessor] = false;
            }
        })}

        if(canSubmitForm) {
            if (this.props.addRow) {
                this.props.saveFormRecord(-1);
            }
            else {
                this.props.saveFormRecord(this.props.editIndex);
            }
        }
        else {
            this.setState({error, gridFormAttemptedToSave:true});
        }
    };

    cancelButtonClick = () => {
        this.props.modalClose(false, {}, false, -1);
    };

    ModalActions = () => {
        return (
            <div>
                <Button content={'Save'} color={'green'} onClick={this.saveGridFormRecord}/>
                <Button content={'Cancel'} color={'red'} onClick={this.cancelButtonClick}/>
            </div>
        )
    };

    ModalClose = () => {
        this.props.modalClose(false, {}, false, -1);
    };

    setElementError = (metaData:any, errorState:boolean) => {
        let error = this.state.error;
        error[metaData.accessor] = errorState;
        this.setState({error});
    };

    updateFieldsOnDropChange = (response:CallOnChangeResponse[], editing:boolean) => {
        this.props.updateFieldsOnDropChange(response, editing);
    }

    generateFormFromMetaData = () => {
        let {error} = this.state;

        this.props.gridMetaData.sort(function (a: any, b: any) {
            return a.order - b.order;
        });

        return (
            <Form>
                <Grid columns={this.props.formColumnSize}>
                    {this.props.gridMetaData.map((metaData:any, index:number) => {
                        if(metaData.visible && ((this.props.editRow) || (metaData.addable && !this.props.editRow))) {
                            return (
                                <Grid.Column verticalAlign={'top'} key={metaData.accessor}>
                                    <Form.Field error={error[metaData.accessor]} required={metaData.required}>
                                        <label>{metaData.header}</label>
                                        <GridFormElement
                                            metaData={metaData}
                                            editRow={this.props.editRow}
                                            editIndex={this.props.editIndex}
                                            editData={this.props.editData}
                                            updatedData={this.props.updatedData}
                                            addRow={this.props.addRow}
                                            addElementChange={this.props.addElementChange}
                                            addElement={this.props.addElement}
                                            handleGridFormDateChange={this.props.handleGridFormDateChange}
                                            blurRecord={this.props.blurRecord}
                                            elementError={error[metaData.accessor]}
                                            setElementError={this.setElementError}
                                            formSaveAttempted={this.state.gridFormAttemptedToSave}
                                            idForObjects={index}
                                            updateFieldsOnDropChange={this.updateFieldsOnDropChange}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            )
                        }
                    })}
                </Grid>
                <Message visible={includes(error,true)} error={true} header={'Required fields are missing!'} content={'Please fill in all required fields'}/>
            </Form>
        )
    };

    render() {
        return (
            <Container fluid={true}>
                {this.props.loggedIn &&
                    <TracModal header={this.ModalHeader()} content={this.generateFormFromMetaData} actions={this.ModalActions}
                               open={this.props.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                               onClose={this.ModalClose}
                    />
                }
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(GridForm);