import * as React from 'react';
import GridContainer from "../../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../../Utils/HelperFunctions";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";

interface UpgradeBusProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface UpgradeBusState {
}

class UpgradeBus extends React.Component<UpgradeBusProps,UpgradeBusState> {

    constructor(props: UpgradeBusProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/upgradeBus'}
                addRecordUrl={'/api/auth/saveGridRecord/upgradeBus'}
                editRecordUrl={'/api/auth/editGridRecord/upgradeBus'}
                deleteRecordUrl={'/api/auth/removeGridRecord/upgradeBus'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/upgradeBus'}
                gridTitle={'View Upgrade Bus Info'}
                gridId={'upgradeBusGrid'}
                typeForSuperClass={'upgradeBus'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(UpgradeBus);