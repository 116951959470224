import * as React from 'react';
import {Container, Header} from "semantic-ui-react";
import TracGrid from "./TracGrid";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import {Redirect} from "react-router";

interface GridContainerProps {
    getGridDataUrl: string;
    gridTitle: string;
    gridId: string;
    exportLink?: string;
    gridPaginated?: boolean;
    showUtilityColumns?:boolean;
    addRecordUrl?:string;
    editRecordUrl?:string;
    deleteRecordUrl?:string;
    typeForSuperClass?:string;
    loggedIn: boolean
    formEditing?: boolean;
    freezeColumn?: boolean;
    extraUtilityColumns?: any[];
    gridVisible?: boolean;
    bulkUpload?:boolean;
    addForm?:any;
    checkBoxEndpoint?:string;
    complexAddForm?:string;
    handleLinkClick?: (rowData:any) => void;
    getDataFromGrid?: (gridData:any[], gridId:string) => void;
    specialtyBulkUpload?: any;
    externalGridRefresh?: boolean;
    externalNonPaginatedRefresh?: boolean;
    externalData?: any[];
    specialtyUploadClicked?: boolean
    extraHeaderOptions?: any[];
    updateBulkUploadLoadingStatus?: (specialityBulkUploadLoadingStatus:boolean) => void;
    passDataToParent?: (dataToPass:any[]) => void;
}

interface GridContainerState {
    userAuthorized: boolean
}

class GridContainer extends React.Component<GridContainerProps,GridContainerState> {

    constructor(props: GridContainerProps) {
        super(props);
        this.state = {
            userAuthorized: false
        }
    }

    userAuthorized = (authorized:boolean) => {
        toast.warn(authorized);
        this.setState({userAuthorized:authorized});
    };

    render() {
        /*if(!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }*/

        return (
            <Container fluid={true}>
                {((this.props.gridVisible !== undefined && this.props.gridVisible) || (this.props.gridVisible === undefined)) &&
                    <div>
                        {this.state.userAuthorized &&
                        <div>
                            <Header className={'tracGridHeader'} size={'medium'}>{this.props.gridTitle}</Header>
                        </div>
                        }
                        <TracGrid
                            dataUrl={this.props.getGridDataUrl}
                            paginated={this.props.gridPaginated}
                            exportLink={this.props.exportLink}
                            displayPager={true}
                            gridId={this.props.gridId}
                            addRecordUrl={this.props.addRecordUrl}
                            editRecordUrl={this.props.editRecordUrl}
                            deleteRecordUrl={this.props.deleteRecordUrl}
                            formEditing={this.props.formEditing}
                            showUtilityColumns={this.props.showUtilityColumns}
                            typeForSuperClass={this.props.typeForSuperClass}
                            extraUtilityColumns={this.props.extraUtilityColumns}
                            userAuthorized={this.userAuthorized}
                            freezeColumn={this.props.freezeColumn}
                            bulkUpload={this.props.bulkUpload}
                            specialtyBulkUpload={this.props.specialtyBulkUpload}
                            addForm={this.props.addForm}
                            checkBoxEndpoint={this.props.checkBoxEndpoint}
                            complexAddForm={this.props.complexAddForm}
                            handleLinkClick={this.props.handleLinkClick}
                            getDataFromGrid={this.props.getDataFromGrid}
                            externalGridRefresh={this.props.externalGridRefresh}
                            externalNonPaginatedRefresh={this.props.externalNonPaginatedRefresh}
                            externalData={this.props.externalData}
                            specialtyUploadClicked={this.props.specialtyUploadClicked}
                            updateBulkUploadLoadingStatus={this.props.updateBulkUploadLoadingStatus}
                            extraHeaderOptions={this.props.extraHeaderOptions}
                            passDataToParent={this.props.passDataToParent}
                        />
                    </div>
                }
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(GridContainer);