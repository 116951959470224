import * as React from 'react';
import {Button, Modal, Icon} from "semantic-ui-react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TracModal from "../GenericComponents/TracModal";
import styles from './NavMenu.module.css'; 

interface NavCalendarProps {
}

interface NavCalendarState {
    modalOpen: boolean;
    datePicked: Date;
}

class NavCalendar extends React.Component<NavCalendarProps, NavCalendarState> {

    constructor(props: NavCalendarProps, state: NavCalendarState) {
        super(props,state);
        this.state = {
            modalOpen: false,
            datePicked: new Date()
        };
    }

    componentDidMount(): void {

    }

    EventWindow = (Date:Date) => {
        console.log(Date);
        this.setState({modalOpen: true, datePicked:Date});
        //toast.info(event);
    };

    ModalHeader = (header:string) => {
        return (
            <div>
                <Icon name={'add to calendar'} />
                {header}
            </div>
        )
    };

    ModalContent = () => {
        return (
            <div>
                <p>User picked Date</p>
                <p>{this.state.datePicked.toLocaleDateString()}</p>
            </div>
        )
    };

    confirmButtonClick = () => {

    };

    cancelButtonClick = () => {
        this.setState({modalOpen: false});
    };

    ModalActions = () => {
        return (
            <div>
                <Button content={'Ok'} color={'green'} onClick={this.confirmButtonClick}/>
                <Button content={'Cancel'} color={'red'} onClick={this.cancelButtonClick}/>
            </div>
        )
    };

    ModalClose = () => {
        this.setState({modalOpen: false});
    };

    public render() {      
        return (
            <React.Fragment>
                <TracModal header={this.ModalHeader('Create Event')} content={this.ModalContent} actions={this.ModalActions}
                           open={this.state.modalOpen} closeOnDimmerClick={true} closeIcon={true}
                           onClose={this.ModalClose}
                />
                <Calendar className={"calendarBody"} onClickDay={this.EventWindow} />
            </React.Fragment>
        );
    }
}

export default NavCalendar;