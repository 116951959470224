import * as React from 'react';
import {Container, Dimmer, Header, Label, Loader, Segment} from "semantic-ui-react";
import ReportsApi from "../../services/reports-service";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {Redirect} from "react-router";

interface ReportsLandingPageProps {
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    setLoggedIn: (loggedIn:boolean) => void;
}

interface ReportsLandingPageState {
    reports: TracLink[]
    userAuthorized: boolean
    pageLoading: boolean
}

class ReportsLandingPage extends React.Component<ReportsLandingPageProps, ReportsLandingPageState> {
    private reportsClient: ReportsApi;

    constructor(props: ReportsLandingPageProps) {
        super(props);
        this.state = {
            reports: [],
            userAuthorized: false,
            pageLoading: true
        };

        this.reportsClient = new ReportsApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getUserReportLinks();
    }

    getUserReportLinks = () => {
        this.reportsClient.getReportLinks()
            .then((response:TracLink[]) => {
                this.setState({reports:response, userAuthorized: true, pageLoading: false});
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
                    this.setState({userAuthorized:false, pageLoading: false});
                }
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                else {
                    this.props.setLoggedIn(false);
                }
            });
    };

    public render() {
        /*if(!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }*/

        return (
            <Container fluid={true}>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                        <div>
                            <Header>TRAC Reports</Header>
                            <Container fluid={true}>
                                {this.state.reports.map((item: TracLink, key: any) => {
                                    return (
                                        <Label className={'reportLabel'} as={Link} to={{pathname: item.linkUrl, reportDisplayName: item.linkDisplayName, reportName: item.linkName, reportLink: item.linkUrl}} icon={'book'} content={item.linkDisplayName} />
                                    )
                                })
                                }
                            </Container>
                        </div>
                    }

                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsLandingPage);