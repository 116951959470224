import * as React from 'react';
import {connect} from "react-redux";
import {CheckboxProps, Grid, Input, InputOnChangeData, Label, TextArea, TextAreaProps} from "semantic-ui-react";

interface StationAssumptionsProps {
    scertState: ScertFormObject;
    inputChange: (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => void;
    textAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => void;
    radioChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

interface StationAssumptionsState {
    scertState: ScertFormObject;
}

class StationAssumptions extends React.Component<StationAssumptionsProps,StationAssumptionsState> {

    constructor(props: StationAssumptionsProps) {
        super(props);
        this.state = {
            scertState: {
                scert: null,
                summaryLineAssumption: null,
                costAssumption: null,
                lineAssumption: null,
                segment: null,
                stationAssumption: null,
                mitigationPlanCloseout: null,
                isSppAdmin: false,
                scertActionsResponse: {
                    canSubmit: false,
                    canSave: false,
                    canApprove: false,
                    canReject: false,
                    notification: ''
                },
                scertActionStatus: ''
            }
        };
    }

    componentDidMount(): void {

    }

    componentDidUpdate(prevProps: Readonly<StationAssumptionsProps>, prevState: Readonly<StationAssumptionsState>) {
        if (prevProps.scertState !== this.props.scertState) {
            this.setState({scertState: this.props.scertState});
        }
    }

    setFieldBasedOnFormSectionType = (scertForm:any, value:any, inputType:string, name:string, elementSideType:string | null, stationName:string) => {
        let sideType = '';
        if(inputType === 'station1Item' || inputType === 'station2Item') {
            if(elementSideType === 'high') {
                sideType = 'highSideValue';
            }
            else if(elementSideType === 'low') {
                sideType = 'lowSideValue';
            }
            else if(elementSideType === 'tertiary') {
                sideType = 'tertiaryValue';
            }
        }
        switch(inputType) {
            case 'station1Item':
                if (scertForm.stationAssumption.station1Data.some((e:any) => e['itemName'] === stationName)) {
                    scertForm.stationAssumption.station1Data.find((e: any) => e['itemName'] === stationName)[sideType] = value;
                }
                else {
                    let stationItem: StationAssumptionItem = {
                        summaryStationAssumptionId: null,
                        stationAssumptionItemId: null,
                        highSideValue: sideType === 'highSideValue' ? value : null,
                        lowSideValue: sideType === 'lowSideValue' ? value : null,
                        tertiaryValue: sideType === 'tertiaryValue' ? value : null,
                        itemKey: 'Station1_{0}_' + stationName,
                        groupName: null,
                        itemName: stationName,
                        stationName: 'Station1',
                        effStartDate: null,
                        editUserName: null,
                        effEndDate: null,
                        editDate: null,
                        id: null
                    }

                    scertForm.stationAssumption.station1Data.push(stationItem);
                }
                break;
            case 'station2Item':
                if (scertForm.stationAssumption.station2Data.some((e:any) => e['itemName'] === stationName)) {
                    scertForm.stationAssumption.station2Data.find((e: any) => e['itemName'] === stationName)[sideType] = value;
                }
                else {
                    let stationItem: StationAssumptionItem = {
                        summaryStationAssumptionId: null,
                        stationAssumptionItemId: null,
                        highSideValue: sideType === 'highSideValue' ? value : null,
                        lowSideValue: sideType === 'lowSideValue' ? value : null,
                        tertiaryValue: sideType === 'tertiaryValue' ? value : null,
                        itemKey: 'Station2_{0}_' + stationName,
                        groupName: null,
                        itemName: stationName,
                        stationName: 'Station2',
                        effStartDate: null,
                        editUserName: null,
                        effEndDate: null,
                        editDate: null,
                        id: null
                    }

                    scertForm.stationAssumption.station2Data.push(stationItem);
                }
                break;
            case 'stationAssumption':
                if (scertForm.stationAssumption.stationAssumptionList.some((e:any) => e['itemName'] === stationName)) {
                    scertForm.stationAssumption.stationAssumptionList.find((e:any) => e['itemName'] === stationName)['itemValue'] = value;
                }
                else {
                    let stationAssumption: StationAssumption = {
                        summaryStationAssumptionId: null,
                        stationAssumptionId: null,
                        itemKey: stationName,
                        itemValue: value,
                        groupName: null,
                        itemName: stationName,
                        effStartDate: null,
                        editUserName: null,
                        effEndDate: null,
                        editDate: null,
                        id: null
                    }

                    scertForm.stationAssumption.stationAssumptionList.push(stationAssumption);
                }
                break;
        }
    };

    handleRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let scertState = this.props.scertState;
        let fieldName;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertState, data.value, data.inputType, data.name, null, data.stationName);
        }

        this.setState({scertState});
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        let scertState = this.props.scertState;

        this.setFieldBasedOnFormSectionType(scertState, event.target.value, data.inputType, data.name, data.sideType, data.stationName);

        this.setState({scertState});
    };

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        let scertState = this.props.scertState;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertState, event.target.value, data.inputType, data.name, null, data.stationName);
        }

        this.setState({scertState});
    };

    //Disabling of inputs, textarea, labels and h4 elements are all done using metadata values from the line assumptions
    //state. This is because no columnMetadata is passed back from the stations assumptions API and we were on a time crunch
    //to get this, and other items out for the initial TRAC release.
    //TODO: update to pull metadata from station assumptions models

    render() {
        const { scertState } = this.props;

        return (
            <Grid columns={'equal'} celled={true}>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Current Station Assumptions</h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <h4>Station</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <h4>Assumptions</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <h4>Station 1</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <h4>Station 2</h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <div />
                    </Grid.Column>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Label style={{fontSize:'11px'}} content={'High Side'} />
                            </Grid.Column>
                            <Grid.Column>
                                <Label content={'Low Side'} />
                            </Grid.Column>
                            <Grid.Column>
                                <Label content={'Tertiary'} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Label style={{fontSize:'11px'}} content={'High Side'} />
                            </Grid.Column>
                            <Grid.Column>
                                <Label content={'Low Side'} />
                            </Grid.Column>
                            <Grid.Column>
                                <Label content={'Tertiary'} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Voltage</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltageHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['highSideValue']} stationName={'Voltage_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltageLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['lowSideValue']} stationName={'Voltage_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltatgeTertiaryStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['tertiaryValue']} stationName={'Voltage_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltageHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['highSideValue']} stationName={'Voltage_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltageLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['lowSideValue']} stationName={'Voltage_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'voltatgeTertiaryStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Voltage_Cnt')['tertiaryValue']} stationName={'Voltage_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Location</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'BUS Name'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'BUS Number'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['highSideValue']} stationName={'Location_BusName_Name'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['lowSideValue']} stationName={'Location_BusName_Name'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameTertiaryStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['tertiaryValue']} stationName={'Location_BusName_Name'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['highSideValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['lowSideValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['tertiaryValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['highSideValue']} stationName={'Location_BusName_Name'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['lowSideValue']} stationName={'Location_BusName_Name'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNameTertiaryStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusName_Name')['tertiaryValue']} stationName={'Location_BusName_Name'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['highSideValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['lowSideValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'locationBusNumHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Location_BusNumber_Cnt')['tertiaryValue']} stationName={'Location_BusNumber_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Transformers</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Rating (MVA)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['highSideValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['lowSideValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['tertiaryValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['highSideValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['lowSideValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['tertiaryValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['highSideValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['lowSideValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Quantity_Cnt')['tertiaryValue']} stationName={'Transformers_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['highSideValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['lowSideValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'transformersRatingTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Transformers_Rating_Cnt')['tertiaryValue']} stationName={'Transformers_Rating_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Breaker Scheme</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Configuration'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Ampacity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Interrupting Capacity Rating (KA)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['highSideValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['lowSideValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['highSideValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['lowSideValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['tertiaryValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['highSideValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['lowSideValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['highSideValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['lowSideValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['highSideValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['lowSideValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Quantity_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['highSideValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['lowSideValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeConfigurationTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Config_Desc')['tertiaryValue']} stationName={'BreakerScheme_Config_Desc'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['highSideValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['lowSideValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeAmpacityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Ampacity_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Ampacity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['highSideValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['lowSideValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'breakerSchemeInterruptingCapTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BreakerScheme_Interrupting_Cnt')['tertiaryValue']} stationName={'BreakerScheme_Interrupting_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Wave Traps</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Ampacity'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['highSideValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['lowSideValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['tertiaryValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['highSideValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['lowSideValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['tertiaryValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['highSideValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['lowSideValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Quantity_Cnt')['tertiaryValue']} stationName={'WaveTraps_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['highSideValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['lowSideValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'waveTrapsAmpacityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'WaveTraps_Ampacity_Cnt')['tertiaryValue']} stationName={'WaveTraps_Ampacity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Switches</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Ampacity'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['highSideValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['lowSideValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['tertiaryValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['highSideValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['lowSideValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['tertiaryValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['highSideValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['lowSideValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Quantity_Cnt')['tertiaryValue']} stationName={'Switches_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['highSideValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['lowSideValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'switchesAmpacityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Switches_Ampacity_Cnt')['tertiaryValue']} stationName={'Switches_Ampacity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Stand Alone CTs</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Ampacity'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['highSideValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['lowSideValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['tertiaryValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['highSideValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['lowSideValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['tertiaryValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['highSideValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['lowSideValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Quantity_Cnt')['tertiaryValue']} stationName={'StandAlone_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['highSideValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['lowSideValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'standAloneCtsAmpacityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'StandAlone_Ampacity_Cnt')['tertiaryValue']} stationName={'StandAlone_Ampacity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>PTs</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Voltage (kV)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['highSideValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['lowSideValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['tertiaryValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['highSideValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['lowSideValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['tertiaryValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['highSideValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['lowSideValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Quantity_Cnt')['tertiaryValue']} stationName={'PTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['highSideValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['lowSideValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ptsVoltageTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'PTs_Voltage_Cnt')['tertiaryValue']} stationName={'PTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>CCVTs</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Voltage (kV)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['highSideValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['lowSideValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['tertiaryValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['highSideValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['lowSideValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['tertiaryValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['highSideValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['lowSideValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Quantity_Cnt')['tertiaryValue']} stationName={'CCVTs_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['highSideValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['lowSideValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'ccvtsVoltageTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'CCVTs_Voltage_Cnt')['tertiaryValue']} stationName={'CCVTs_Voltage_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Capacitors</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Size (MVAR)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['highSideValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['lowSideValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['tertiaryValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['highSideValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['lowSideValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['tertiaryValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['highSideValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['lowSideValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Quantity_Cnt')['tertiaryValue']} stationName={'Capacitors_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['highSideValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['lowSideValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'capacitorsSizeTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Capacitors_Size_Cnt')['tertiaryValue']} stationName={'Capacitors_Size_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Reactors</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Size (MVAR)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['highSideValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['lowSideValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['tertiaryValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['highSideValue']} stationName={'Reactors_Size_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['lowSideValue']} stationName={'Reactors_Size_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['tertiaryValue']} stationName={'Reactors_Size_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['highSideValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['lowSideValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Quantity_Cnt')['tertiaryValue']} stationName={'Reactors_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['highSideValue']} stationName={'Reactors_Size_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['lowSideValue']} stationName={'Reactors_Size_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'reactorsSizeTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'Reactors_Size_Cnt')['tertiaryValue']} stationName={'Reactors_Size_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Control Panels</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Quantity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Voltage (kV)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['highSideValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['lowSideValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['tertiaryValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['highSideValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['lowSideValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageTertiarySideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['tertiaryValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['highSideValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['lowSideValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsQuantityTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Quantity_Cnt')['tertiaryValue']} stationName={'ControlPanels_Quantity_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['highSideValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['lowSideValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'controlPanelsVoltageTertiarySideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'ControlPanels_Voltage_Cnt')['tertiaryValue']} stationName={'ControlPanels_Voltage_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={2}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Environmental</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Study Req\'s.'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Wetland Req\'s./Mitigation'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Threatened and Endangered Species Mitigation'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Cultural/Historical Resource Req\'s.'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'environmentalStudyReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Study_Req_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Study_Req_Desc')['itemValue']} stationName={'Study_Req_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'environmentalWetlandReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Wetland_Req_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Wetland_Req_Desc')['itemValue']} stationName={'Wetland_Req_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'environmentalThreatenedSpeciesMitigation'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Threatened_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Threatened_Desc')['itemValue']} stationName={'Threatened_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'environmentalCulturalHistoricalReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Cultural_Historical_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'Cultural_Historical_Desc')['itemValue']} stationName={'Cultural_Historical_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>BIL Rating (kV Crest)</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingHighSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['highSideValue']} stationName={'BILRating_Cnt'} inputType={'station1Item'} sideType={'high'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingLowSideStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['lowSideValue']} stationName={'BILRating_Cnt'} inputType={'station1Item'} sideType={'low'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingTertiaryStation1'} value={scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station1Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['tertiaryValue']} stationName={'BILRating_Cnt'} inputType={'station1Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingHighSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['highSideValue']} stationName={'BILRating_Cnt'} inputType={'station2Item'} sideType={'high'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingLowSideStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['lowSideValue']} stationName={'BILRating_Cnt'} inputType={'station2Item'} sideType={'low'} onChange={this.handleInputChange} />
                            </Grid.Column>
                            <Grid.Column>
                                <Input disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} fluid={true} name={'bilRatingTertiaryStation2'} value={scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt') === undefined ? null : scertState.stationAssumption?.station2Data.find((e:any) => e['itemName'] === 'BILRating_Cnt')['tertiaryValue']} stationName={'BILRating_Cnt'} inputType={'station2Item'} sideType={'tertiary'} onChange={this.handleInputChange} />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Required Substation Property/Fence Expansions</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'reqSubStationPropFenceExpansions'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'RequiredSubstationProperty_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'RequiredSubstationProperty_Desc')['itemValue']} stationName={'RequiredSubstationProperty_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>SCADA Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'scadaReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'SCADAReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'SCADAReq_Desc')['itemValue']} stationName={'SCADAReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Mobile Substation Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'mobileSubStationReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'MobileSubstationReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'MobileSubstationReq_Desc')['itemValue']} stationName={'MobileSubstationReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Control Enclosure Expansions</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'controlEnclosureExpansions'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'ControlEnclosure_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'ControlEnclosure_Desc')['itemValue']} stationName={'ControlEnclosure_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Fiber Optic Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'fiberOpticReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'FiberOpticReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'FiberOpticReq_Desc')['itemValue']} stationName={'FiberOpticReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Remote End Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'remoteEndReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'RemoteEndReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'RemoteEndReq_Desc')['itemValue']} stationName={'RemoteEndReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Metering Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'meteringReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'MeteringReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'MeteringReq_Desc')['itemValue']} stationName={'MeteringReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Contamination Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'contaminationReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'ContaminationReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'ContaminationReq_Desc')['itemValue']} stationName={'ContaminationReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column width={6}>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Unusual Site Prep Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} className={'scertAssumptionsTextArea'} name={'unusualSitePrepReqs'} value={scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'UnusualSitePrepReq_Desc') === undefined ? null : scertState.stationAssumption?.stationAssumptionList.find((e:any) => e['itemName'] === 'UnusualSitePrepReq_Desc')['itemValue']} stationName={'UnusualSitePrepReq_Desc'} inputType={'stationAssumption'} sideType={'none'} onChange={this.handleTextAreaChange} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(null, null)(StationAssumptions);