import * as React from 'react';
import {connect} from "react-redux";
import {CheckboxProps, Grid, Input, InputOnChangeData, Label, Radio, TextArea, TextAreaProps} from "semantic-ui-react";

interface LineAssumptionsProps {
    scertState: ScertFormObject;
    inputChange: (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => void;
    textAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => void;
    radioChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

interface LineAssumptionsState {
    scertState: ScertFormObject;
}

class LineAssumptions extends React.Component<LineAssumptionsProps,LineAssumptionsState> {

    constructor(props: LineAssumptionsProps) {
        super(props);
        this.state = {
            scertState: {
                scert: null,
                summaryLineAssumption: null,
                costAssumption: null,
                lineAssumption: null,
                segment: null,
                stationAssumption: null,
                mitigationPlanCloseout: null,
                isSppAdmin: false,
                scertActionsResponse: {
                    canSubmit: false,
                    canSave: false,
                    canApprove: false,
                    canReject: false,
                    notification: ''
                },
                scertActionStatus: ''
            }
        };
    }

    componentDidMount(): void {

    }

    componentDidUpdate(prevProps: Readonly<LineAssumptionsProps>, prevState: Readonly<LineAssumptionsState>) {
        if (prevProps.scertState !== this.props.scertState) {
            this.setState({scertState: this.props.scertState});
        }
    }

    setFieldBasedOnFormSectionType = (scertForm:any, value:any, inputType:string, name:string) => {
        switch(inputType) {
            case 'lineAssumption':
                scertForm.lineAssumption.data[name] = value;
                break;
            case 'segment1':
                scertForm.segment.segment1Data[name] = value;
                break;
            case 'segment2':
                scertForm.segment.segment2Data[name] = value;
                break;
        }
    };

    handleRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let scertState = this.props.scertState;
        let fieldName;

        if(data.name !== undefined) {
            fieldName = data.name;
            if(data.name.includes('underbuildInd')) {
                fieldName = 'underbuildInd';
            }
            this.setFieldBasedOnFormSectionType(scertState, data.value, data.inputType, fieldName);
        }

        this.setState({scertState});
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        let scertState = this.props.scertState;

        this.setFieldBasedOnFormSectionType(scertState, event.target.value, data.inputType, data.name);

        this.setState({scertState});
    };

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        let scertState = this.state.scertState;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertState, event.target.value, data.inputType, data.name);
        }

        this.setState({scertState});
    };

    render() {
        const { scertState } = this.props;

        return (
            <Grid columns={'equal'} celled={true}>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={{textAlign:'center'}}>Current Approved Line Assumptions</h4>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                    <Grid.Column>
                        <h4>Line Assumptions</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <h4>Segment 1</h4>
                                </Grid.Column>
                                <Grid.Column>
                                    <h4>Segment 2</h4>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Number of Circuits</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <div />
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} value={scertState.segment?.segment1Data['numberOfCircuitsCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'numberOfCircuitsCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numberOfCircuitsCnt'].editable} value={scertState.segment?.segment2Data['numberOfCircuitsCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'numberOfCircuitsCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Line Length</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label  className={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'New Line (Miles)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['reconductorMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Reconductor (Miles)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['voltageConversionMilesCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Voltage Conversion (Miles)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input height={"100"} fluid={true} disabled={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable} value={scertState.segment?.segment1Data['newLineMilesCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'newLineMilesCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['newLineMilesCnt'].editable} value={scertState.segment?.segment2Data['newLineMilesCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'newLineMilesCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['reconductorMilesCnt'].editable} value={scertState.segment?.segment1Data['reconductorMilesCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'reconductorMilesCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['reconductorMilesCnt'].editable} value={scertState.segment?.segment2Data['reconductorMilesCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'reconductorMilesCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['voltageConversionMilesCnt'].editable} value={scertState.segment?.segment1Data['voltageConversionMilesCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'voltageConversionMilesCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['voltageConversionMilesCnt'].editable} value={scertState.segment?.segment2Data['voltageConversionMilesCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'voltageConversionMilesCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['toBusName'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Termination Points</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['toBusName'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'To BUS Name'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['toBusNumberDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'To BUS Number'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['fromBusName'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'From BUS Name'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['fromBusNumberDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'From BUS Number'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['toBusName'].editable} value={scertState.segment?.segment1Data['toBusName']} inputType={'segment1'} onChange={this.handleInputChange} name={'toBusName'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['toBusName'].editable} value={scertState.segment?.segment2Data['toBusName']} inputType={'segment2'} onChange={this.handleInputChange} name={'toBusName'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['toBusNumberDesc'].editable} value={scertState.segment?.segment1Data['toBusNumberDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'toBusNumberDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['toBusNumberDesc'].editable} value={scertState.segment?.segment2Data['toBusNumberDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'toBusNumberDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['fromBusName'].editable} value={scertState.segment?.segment1Data['fromBusName']} inputType={'segment1'} onChange={this.handleInputChange} name={'fromBusName'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['fromBusName'].editable} value={scertState.segment?.segment2Data['fromBusName']} inputType={'segment2'} onChange={this.handleInputChange} name={'fromBusName'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['fromBusNumberDesc'].editable} value={scertState.segment?.segment1Data['fromBusNumberDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'fromBusNumberDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['fromBusNumberDesc'].editable} value={scertState.segment?.segment2Data['fromBusNumberDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'fromBusNumberDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['typeDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Conductor</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['typeDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Type'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['sizeKcmilCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Size (kcmil)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['voltageKvRate'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Voltage (kV)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['ampacityCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Ampacity'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['ratingMvaRate'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Emergency Rating (MVA)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['numOfConductorPerPhaseCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'No. of Conductors per Phase'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['typeDesc'].editable} value={scertState.segment?.segment1Data['typeDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'typeDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['typeDesc'].editable} value={scertState.segment?.segment2Data['typeDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'typeDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['sizeKcmilCnt'].editable} value={scertState.segment?.segment1Data['sizeKcmilCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'sizeKcmilCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['sizeKcmilCnt'].editable} value={scertState.segment?.segment2Data['sizeKcmilCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'sizeKcmilCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['voltageKvRate'].editable} value={scertState.segment?.segment1Data['voltageKvRate']} inputType={'segment1'} onChange={this.handleInputChange} name={'voltageKvRate'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['voltageKvRate'].editable} value={scertState.segment?.segment2Data['voltageKvRate']} inputType={'segment2'} onChange={this.handleInputChange} name={'voltageKvRate'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['ampacityCnt'].editable} value={scertState.segment?.segment1Data['ampacityCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'ampacityCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['ampacityCnt'].editable} value={scertState.segment?.segment2Data['ampacityCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'ampacityCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} type={'number'} disabled={!scertState.segment?.columnMetaData['ratingMvaRate'].editable} value={scertState.segment?.segment1Data['ratingMvaRate']} inputType={'segment1'} onChange={this.handleInputChange} name={'ratingMvaRate'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} type={'number'} disabled={!scertState.segment?.columnMetaData['ratingMvaRate'].editable} value={scertState.segment?.segment2Data['ratingMvaRate']} inputType={'segment2'} onChange={this.handleInputChange} name={'ratingMvaRate'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numOfConductorPerPhaseCnt'].editable} value={scertState.segment?.segment1Data['numOfConductorPerPhaseCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'numOfConductorPerPhaseCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numOfConductorPerPhaseCnt'].editable} value={scertState.segment?.segment2Data['numOfConductorPerPhaseCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'numOfConductorPerPhaseCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['configurationDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Structure</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['configurationDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Configuration'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['foundationTypeDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Foundation Type'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['materialDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Material'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['nescAssumptionsDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'NESC Assumptions'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['deadEndsQtyCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Dead Ends (Qty)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['tangentsQtyCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Tangents (Qty)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['stormStructureQtyCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Storm Structures (Qty)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['runningCornersQtyCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Running Corners (Qty)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['underbuildInd'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Underbuild (Y/N)'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['configurationDesc'].editable} value={scertState.segment?.segment1Data['configurationDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'configurationDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['configurationDesc'].editable} value={scertState.segment?.segment2Data['configurationDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'configurationDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['foundationTypeDesc'].editable} value={scertState.segment?.segment1Data['foundationTypeDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'foundationTypeDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['foundationTypeDesc'].editable} value={scertState.segment?.segment2Data['foundationTypeDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'foundationTypeDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['materialDesc'].editable} value={scertState.segment?.segment1Data['materialDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'materialDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['materialDesc'].editable} value={scertState.segment?.segment2Data['materialDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'materialDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['nescAssumptionsDesc'].editable} value={scertState.segment?.segment1Data['nescAssumptionsDesc']} inputType={'segment1'} onChange={this.handleInputChange} name={'nescAssumptionsDesc'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['nescAssumptionsDesc'].editable} value={scertState.segment?.segment2Data['nescAssumptionsDesc']} inputType={'segment2'} onChange={this.handleInputChange} name={'nescAssumptionsDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['deadEndsQtyCnt'].editable} value={scertState.segment?.segment1Data['deadEndsQtyCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'deadEndsQtyCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['deadEndsQtyCnt'].editable} value={scertState.segment?.segment2Data['deadEndsQtyCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'deadEndsQtyCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['tangentsQtyCnt'].editable} value={scertState.segment?.segment1Data['tangentsQtyCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'tangentsQtyCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['tangentsQtyCnt'].editable} value={scertState.segment?.segment2Data['tangentsQtyCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'tangentsQtyCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['stormStructureQtyCnt'].editable} value={scertState.segment?.segment1Data['stormStructureQtyCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'stormStructureQtyCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['stormStructureQtyCnt'].editable} value={scertState.segment?.segment2Data['stormStructureQtyCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'stormStructureQtyCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['runningCornersQtyCnt'].editable} value={scertState.segment?.segment1Data['runningCornersQtyCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'runningCornersQtyCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['runningCornersQtyCnt'].editable} value={scertState.segment?.segment2Data['runningCornersQtyCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'runningCornersQtyCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Radio className={'scertRadioStyles'} name={'underbuildIndsegment1'} label={'Y'} disabled={!scertState.segment?.columnMetaData['underbuildInd'].editable} checked={scertState.segment?.segment1Data['underbuildInd'] === 'Y'} value={'Y'} inputType={'segment1'} onChange={this.handleRadioChange} />
                                    <Radio className={'scertRadioStyles'} name={'underbuildIndsegment1'} label={'N'} disabled={!scertState.segment?.columnMetaData['underbuildInd'].editable} checked={scertState.segment?.segment1Data['underbuildInd'] === 'N' || scertState.segment?.segment1Data['underbuildInd'] === null} value={'N'} inputType={'segment1'} onChange={this.handleRadioChange} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Radio className={'scertRadioStyles'} name={'underbuildIndsegment2'} label={'Y'} disabled={!scertState.segment?.columnMetaData['underbuildInd'].editable} checked={scertState.segment?.segment2Data['underbuildInd'] === 'Y'} value={'Y'} inputType={'segment2'} onChange={this.handleRadioChange} />
                                    <Radio className={'scertRadioStyles'} name={'underbuildIndsegment2'} label={'N'} disabled={!scertState.segment?.columnMetaData['underbuildInd'].editable} checked={scertState.segment?.segment2Data['underbuildInd'] === 'N' || scertState.segment?.segment2Data['underbuildInd'] === null} value={'N'} inputType={'segment2'} onChange={this.handleRadioChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['numberCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Shield Wire</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['numberCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Number'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['shieldWireType'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Type'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['shieldWireSize'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Size'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numberCnt'].editable} value={scertState.segment?.segment1Data['numberCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'numberCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['numberCnt'].editable} value={scertState.segment?.segment2Data['numberCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'numberCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['shieldWireType'].editable} value={scertState.segment?.segment1Data['shieldWireType']} inputType={'segment1'} onChange={this.handleInputChange} name={'shieldWireType'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['shieldWireType'].editable} value={scertState.segment?.segment2Data['shieldWireType']} inputType={'segment2'} onChange={this.handleInputChange} name={'shieldWireType'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['shieldWireSize'].editable} value={scertState.segment?.segment1Data['shieldWireSize']} inputType={'segment1'} onChange={this.handleInputChange} name={'shieldWireSize'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['shieldWireSize'].editable} value={scertState.segment?.segment2Data['shieldWireSize']} inputType={'segment2'} onChange={this.handleInputChange} name={'shieldWireSize'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.segment?.columnMetaData['widthFtCnt'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Right-Of-Way</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.segment?.columnMetaData['widthFtCnt'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Width (ft)'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['acquisitionDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Acquisition'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['clearingReqDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Clearing Requirements'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['widthFtCnt'].editable} value={scertState.segment?.segment1Data['widthFtCnt']} inputType={'segment1'} onChange={this.handleInputChange} name={'widthFtCnt'} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Input fluid={true} disabled={!scertState.segment?.columnMetaData['widthFtCnt'].editable} value={scertState.segment?.segment2Data['widthFtCnt']} inputType={'segment2'} onChange={this.handleInputChange} name={'widthFtCnt'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['acquisitionDesc'].editable} value={scertState.lineAssumption?.data['acquisitionDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'acquisitionDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['clearingReqDesc'].editable} value={scertState.lineAssumption?.data['clearingReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'clearingReqDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['weatherLoadingDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Design Criteria</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['weatherLoadingDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Weather Loading'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['liveLineMaintDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Live Line Maintenance'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['unbalancedStructLoadDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Unbalanced Structural Loads'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['weatherLoadingDesc'].editable} value={scertState.lineAssumption?.data['weatherLoadingDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'weatherLoadingDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['liveLineMaintDesc'].editable} value={scertState.lineAssumption?.data['liveLineMaintDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'liveLineMaintDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['unbalancedStructLoadDesc'].editable} value={scertState.lineAssumption?.data['unbalancedStructLoadDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'unbalancedStructLoadDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['trafficControlReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Permitting</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['trafficControlReqDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Traffic Control Req\'s.'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['faaReqDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'FAA Requirements'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['trafficControlReqDesc'].editable} value={scertState.lineAssumption?.data['trafficControlReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'trafficControlReqDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['faaReqDesc'].editable} value={scertState.lineAssumption?.data['faaReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'faaReqDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['studyReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Environmental</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['studyReqDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Study Requirements'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['wetlandReqMitigDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Wetland Req\'s./Mitigation'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['threatndEndngrdSpeciesMitigDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Threatened & Endangered Species Mitigation'} />
                            </Grid.Row>
                            <Grid.Row>
                                <Label className={!scertState.lineAssumption?.columnMetaData['culturalHistrcalResourceReqDesc'].editable ? 'ui disabled assumptionLabels': 'assumptionLabels'} basic={true} size={'large'} content={'Cultural/Historical Resource Req\'s.'} />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid columns={'equal'}>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['studyReqDesc'].editable} value={scertState.lineAssumption?.data['studyReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'studyReqDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['wetlandReqMitigDesc'].editable} value={scertState.lineAssumption?.data['wetlandReqMitigDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'wetlandReqMitigDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['threatndEndngrdSpeciesMitigDesc'].editable} value={scertState.lineAssumption?.data['threatndEndngrdSpeciesMitigDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'threatndEndngrdSpeciesMitigDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['culturalHistrcalResourceReqDesc'].editable} value={scertState.lineAssumption?.data['culturalHistrcalResourceReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'culturalHistrcalResourceReqDesc'} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['typeOfTerrainDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Type of Terrain</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['typeOfTerrainDesc'].editable} value={scertState.lineAssumption?.data['typeOfTerrainDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'typeOfTerrainDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['switchReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Switch Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['switchReqDesc'].editable} value={scertState.lineAssumption?.data['switchReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'switchReqDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['legalReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Legal Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['legalReqDesc'].editable} value={scertState.lineAssumption?.data['legalReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'legalReqDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['geotechnicalAssumptionDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Geotechnical Assumptions</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['geotechnicalAssumptionDesc'].editable} value={scertState.lineAssumption?.data['geotechnicalAssumptionDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'geotechnicalAssumptionDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['specialMaterialReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Special Material Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['specialMaterialReqDesc'].editable} value={scertState.lineAssumption?.data['specialMaterialReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'specialMaterialReqDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['prelimLineRouteDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Preliminary Line Route (Rough location when practical)</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['prelimLineRouteDesc'].editable} value={scertState.lineAssumption?.data['prelimLineRouteDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'prelimLineRouteDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['accessRoadReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Access Road Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['accessRoadReqDesc'].editable} value={scertState.lineAssumption?.data['accessRoadReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'accessRoadReqDesc'} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                    <Grid.Column>
                        <h4 style={!scertState.lineAssumption?.columnMetaData['distribJointUseReqDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Distribution/Joint Use Requirements</h4>
                    </Grid.Column>
                    <Grid.Column>
                        <TextArea className={'scertAssumptionsTextArea'} disabled={!scertState.lineAssumption?.columnMetaData['distribJointUseReqDesc'].editable} value={scertState.lineAssumption?.data['distribJointUseReqDesc']} inputType={'lineAssumption'} onChange={this.handleTextAreaChange} name={'distribJointUseReqDesc'} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default connect(null, null)(LineAssumptions);