import * as React from 'react';
import {
    Button, ButtonProps,
    Card,
    Container,
    Dimmer,
    Divider,
    Header,
    Icon,
    Label,
    Loader,
    Segment,
    Transition,
    Feed
} from "semantic-ui-react";
import {TransitionEventData} from "semantic-ui-react/dist/commonjs/modules/Transition/Transition";
import {toast} from "react-toastify";
import generalApi from "../../services/general-services";
import WebSockService from "../../services/websocket-service";
import InitialsSegment from "../GenericComponents/InitialsSegment";
import {NavLink} from "react-router-dom";
import NotificationPost from "./NotificationPost";
import moment from "moment";

interface NotificationFeedComponentProps {
    historyFeed: boolean
    cardClass: string
    cardContentClass: string
    feedTitle:string
    feedUser?:string
    updateNotificationCount?: (notificationNumber: number) => void;
    changeNotificationDropdownStatus?: (dropdownStatus: boolean) => void;
   
}

interface NotificationFeedComponentState {
    notificationFeed: NotificationFeed
    feedPage: number
    feedSize: number
    recordsSeen: number
    loadingRecords: boolean
    recordsComeInSinceLastFetch: number
    recordMarkedAsRead: number
    postNotificationOpen: boolean
}
class NotificationFeedComponent extends React.Component<NotificationFeedComponentProps, NotificationFeedComponentState> {
    private client: generalApi;
    private websockClient: WebSockService;

    constructor(props: NotificationFeedComponentProps, state: NotificationFeedComponentState) {
        super(props, state);
        this.state = {
            notificationFeed: {
                notificationFeedRecords: [],
                notificationRecordCount: 0
            },
            feedPage: 0,
            feedSize: 10,
            recordsSeen: 0,
            loadingRecords: false,
            recordsComeInSinceLastFetch: 0,
            recordMarkedAsRead: -1,
            postNotificationOpen: false
        };

        this.client = new generalApi();
        this.websockClient = new WebSockService();
    }

    componentDidMount(): void {
        this.getNotificationRecords(null);
    }

    componentDidUpdate(prevProps: Readonly<NotificationFeedComponentProps>, prevState: Readonly<NotificationFeedComponentState>) {
        if(prevProps.feedUser !== this.props.feedUser) {
            this.setState({
                notificationFeed: {
                    notificationFeedRecords: [],
                    notificationRecordCount: 0
                },
                feedPage: 0,
                feedSize: 10,
                recordsSeen: 0,
                loadingRecords: false,
                recordsComeInSinceLastFetch: 0,
                recordMarkedAsRead: -1
            });
            this.getNotificationRecords(null);
        }
    };

    displayToastMessage = (notificationRecord:NotificationFeedRecord) => {
        return (
            <Card className={'toastNotificationMessage'}>
                <Card.Content>
                    <Segment
                        content={this.getUserInitials(notificationRecord.notificationAuthor)}
                        inverted={true}
                        floated={'right'}
                        circular={true}
                        size={'mini'}
                        color={notificationRecord.userAvatar}
                        className={'inlineSegment'}
                    />
                    <Card.Header>{notificationRecord.notificationAuthor}</Card.Header>
                    <Card.Meta>{notificationRecord.notificationDate}</Card.Meta>
                    <Card.Description>
                        <div>
                            <strong>Posted notification:</strong>
                        </div>
                        <div>{notificationRecord.notificationText}</div>
                    </Card.Description>
                </Card.Content>
            </Card>
        )
    };

    getNotificationFeedRecord = ({body = "{}"}) => {
        const notificationRecord: NotificationFeedRecord = JSON.parse(body);

        let notificationFeed = this.state.notificationFeed;

        toast(this.displayToastMessage(notificationRecord), {
            autoClose: 20000,
            draggable: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
        });
        let feedContainsNewRecord = false;
        for(let i = 0; i < notificationFeed.notificationFeedRecords.length; i++) {
            if(notificationFeed.notificationFeedRecords[i].notificationId === notificationRecord.notificationId) {
                feedContainsNewRecord = true;
            }
        }
        if(!feedContainsNewRecord) {
            let recordsComeInSinceLastFetch = this.state.recordsComeInSinceLastFetch;
            let feedPage = this.state.feedPage;
            notificationFeed.notificationFeedRecords.unshift(notificationRecord);
            /*if(NotificationFeed.NotificationFeedRecords.length > 1) {
                NotificationFeed.NotificationFeedRecords.pop();
            }*/
            if(recordsComeInSinceLastFetch !== 0 && recordsComeInSinceLastFetch % 10 === 0) {
                recordsComeInSinceLastFetch = 0;
                feedPage += 1;
            }
            else {
                recordsComeInSinceLastFetch += 1;
            }
            notificationFeed.notificationRecordCount += 1;
            if(this.props.updateNotificationCount !== undefined) {
                this.props.updateNotificationCount(notificationFeed.notificationRecordCount);
            }
            this.setState({notificationFeed, recordsComeInSinceLastFetch, feedPage});
        }
    };

    getNotificationRecords = (e:any) => {
        if(e !== null) {
            e.stopPropagation();
        }
        const params = new URLSearchParams();
        params.append('page', this.state.feedPage !== null ? this.state.feedPage.toString() : '0');
        params.append('size', this.state.feedSize !== null ? this.state.feedSize.toString() : '10');
        params.append("ui","true");
        let filter = {};
        let page = this.state.feedPage !== null ? this.state.feedPage : 0;
        let size = this.state.feedSize !== null ? this.state.feedSize : 10;
        let user = this.props.feedUser !== undefined ? this.props.feedUser : '';
        this.client.getNotificationRecords(page, size, 'feed')
            .then((resp:NotificationFeed) => {
                console.log(resp);
                let currentFeed = this.state.notificationFeed;
                let recordsSeenSoFar = this.state.recordsSeen;
                if(currentFeed.notificationFeedRecords.length > 0) {
                    if(resp.notificationFeedRecords.length === size) {
                        page += 1;
                    }
                    for(let i = 0; i < resp.notificationFeedRecords.length; i++) {
                        if(i >= this.state.recordsComeInSinceLastFetch) {
                            currentFeed.notificationFeedRecords.push(resp.notificationFeedRecords[i]);
                        }
                    }
                    //currentFeed.NotificationFeedRecords.concat(resp.NotificationFeedRecords);
                    currentFeed.notificationRecordCount = resp.notificationRecordCount;
                    recordsSeenSoFar += resp.notificationFeedRecords.length;
                    if(this.props.updateNotificationCount !== undefined) {
                        this.props.updateNotificationCount(currentFeed.notificationRecordCount);
                    }
                    this.setState({notificationFeed:currentFeed, recordsSeen: recordsSeenSoFar, feedPage: page, feedSize: size, loadingRecords:false});
                }
                else {
                    if(resp.notificationFeedRecords !== null) {
                        if (resp.notificationFeedRecords.length === size) {
                            page += 1;
                        }
                    }
                    if(this.props.updateNotificationCount !== undefined) {
                        this.props.updateNotificationCount(resp.notificationFeedRecords !== null ? resp.notificationRecordCount : currentFeed.notificationRecordCount);
                    }
                    this.setState({
                        notificationFeed: resp.notificationFeedRecords !== null ? resp : currentFeed,
                        recordsSeen: resp.notificationFeedRecords !== null ? resp.notificationFeedRecords.length : 0,
                        feedPage: page,
                        feedSize: size,
                        loadingRecords:false
                    });
                }
            })
            .catch((error:any) => {
                toast.error(error);
                this.setState({loadingRecords: false});
            });
        this.setState({loadingRecords:true});
    };

    markRead = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        if(event !== null) {
            event.stopPropagation();
        }
        let notificationFeed = this.state.notificationFeed;
        let recordsSeen = this.state.recordsSeen;
        for(let i = 0; i < notificationFeed.notificationFeedRecords.length; i++) {
            if(notificationFeed.notificationFeedRecords[i].notificationId === data.notificationId) {
                this.client.markNotificationSeen(data.notificationId)
                    .then((resp:boolean) => {
                        if(resp) {
                            notificationFeed.notificationFeedRecords[i].hasRead = true;
                            notificationFeed.notificationRecordCount--;
                            recordsSeen--;
                            if(this.props.updateNotificationCount !== undefined) {
                                this.props.updateNotificationCount(notificationFeed.notificationRecordCount);
                            }
                            this.setState({notificationFeed, recordMarkedAsRead: i, recordsSeen});
                        }
                    });
                break;
            }
        }
    };

    removeRecord = (nothing:null, data: TransitionEventData) => {
        let recordMarkedAsRead = this.state.recordMarkedAsRead;
        if(recordMarkedAsRead !== -1) {
            console.log(data);
            let notificationFeed = this.state.notificationFeed;
            notificationFeed.notificationFeedRecords.splice(recordMarkedAsRead, 1);
            this.setState({notificationFeed, recordMarkedAsRead: -1});
        }
    };

    getUserInitials = (longName:string): string => {
        let initials = null;
        if(longName !== null && longName !== '') {
            initials = longName.match(/\b\w/g) || [];
            if(initials.length === 1) {
                initials.push('U');
            }
            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }
        else {
            initials = 'NA';
        }
        return initials;
    };

    openNotificationPost = () => {
        this.setState({postNotificationOpen:true}, () => {
            if(this.props.changeNotificationDropdownStatus !== undefined) {
                this.props.changeNotificationDropdownStatus(false);
            }
        });
    }

    setPostModalState = (modalOpen:boolean) => {    
        this.setState({postNotificationOpen:modalOpen});
        if(this.props.changeNotificationDropdownStatus !== undefined) {
            this.props.changeNotificationDropdownStatus(false);
          
        }
    };

    public render() {

        return (
            <div>
                <Card className={this.props.cardClass}>
                    <Card.Content className={'notificationHeader'}>
                        <Card.Header>{this.props.feedTitle}</Card.Header>
                    </Card.Content>
                    {this.state.notificationFeed.notificationFeedRecords?.length > 0 &&
                    <Card.Content className={this.props.cardContentClass}>
                        <Feed className={'notificationFeed'}>
                            {this.state.notificationFeed.notificationFeedRecords.map((item: NotificationFeedRecord, key: any) => {
                                if(!this.props.historyFeed) {
                                    return (
                                        <Transition onHide={this.removeRecord} visible={!item.hasRead}
                                                    animation={'slide down'} unmountOnHide={true} duration={200}>
                                            <Feed.Event>
                                                <Feed.Label className={'feedLabel'}>
                                                    <InitialsSegment
                                                        className={'inlineSegment'}
                                                        avatarColor={item.userAvatar}
                                                        userInitials={item.notificationFromInitials}
                                                        size={'mini'}
                                                    />
                                                </Feed.Label>
                                                <Feed.Content className={'feedContent'}>
                                                    <Feed.Date content={moment(item.notificationDate).format("MM/DD/YYYY h:mm:ss a")}/>
                                                    <Feed.Summary>
                                                        <div>
                                                            <a>{item.notificationAuthor}</a> posted notification
                                                        </div>
                                                    </Feed.Summary>
                                                    <Feed.Extra text={true}>
                                                        {item.notificationText}
                                                    </Feed.Extra>
                                                    <Feed.Meta>
                                                        <Feed.Like>
                                                            <Button className={'markReadButton'} onClick={this.markRead}
                                                                    notificationId={item.notificationId}>
                                                                <Icon className={'markReadIcon'} name={'check'}
                                                                      color={'green'}/> Mark as Read
                                                            </Button>
                                                        </Feed.Like>
                                                    </Feed.Meta>
                                                </Feed.Content>
                                            </Feed.Event>
                                        </Transition>
                                    )
                                }
                                else {
                                    return (
                                        <Feed.Event>
                                            <Feed.Label className={'historyFeedLabel'}>
                                                <InitialsSegment
                                                    className={'inlineSegment'}
                                                    avatarColor={item.userAvatar}
                                                    userInitials={item.notificationFromInitials}
                                                    size={'mini'}
                                                />
                                            </Feed.Label>
                                            <Feed.Content>
                                                <Feed.Date content={moment(item.notificationDate).format("MM/DD/YYYY h:mm:ss a")}/>
                                                <Feed.Summary>
                                                    <div>
                                                        <a>{item.notificationAuthor}</a> posted notification
                                                    </div>
                                                </Feed.Summary>
                                                <Feed.Extra text={true}>
                                                    {item.notificationText}
                                                </Feed.Extra>
                                            </Feed.Content>
                                        </Feed.Event>
                                    )
                                }
                            })
                            }
                        </Feed>
                        {this.state.recordsSeen < this.state.notificationFeed.notificationRecordCount && this.state.notificationFeed.notificationFeedRecords.length > 0 &&
                        <div>
                            <Divider />
                            <Segment textAlign={'center'} basic={true} className={'showMoreNotificationsSegment'}>
                                <Button onClick={(e:any) => this.getNotificationRecords(e)} content={'Show More'} />
                            </Segment>
                        </div>
                        }
                        {this.state.recordsSeen === this.state.notificationFeed.notificationRecordCount && this.state.notificationFeed.notificationFeedRecords.length > 0 &&
                        <div>
                            <Divider />
                            <Segment textAlign={'center'} basic={true} className={'showMoreNotificationsSegment'}>
                                <Label content={'No More Records Available'} />
                            </Segment>
                        </div>
                        }
                    </Card.Content>
                    }
                    {this.state.notificationFeed.notificationFeedRecords?.length === 0 &&
                    <Card.Content>
                        <Container textAlign={'center'} fluid={true}>
                            <Label>No Records Found.</Label>
                        </Container>
                    </Card.Content>
                    }

                    <Dimmer active={this.state.loadingRecords} inverted={true}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                </Card>
                <div>
                    <Button className={'feedNotificationOpen'} as={NavLink} to={'/notifications'} content={'Open Notifications'} basic={true} color={'red'} />
                    <Button className={'feedPostNotification'} content={'Post Notification'} basic={true} color={'green'} onClick={this.openNotificationPost} />
                </div>
                <NotificationPost saveEndpoint={'/api/auth/saveNotification'}
                                  notificationInModal={true}
                                  notificationPostModalOpen={this.state.postNotificationOpen}
                                  setPostModalState={this.setPostModalState}
                />
            </div>
        )
    }
}

export default NotificationFeedComponent;