import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";

interface ViewStudiesProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface ViewStudiesState {
}

class ViewStudies extends React.Component<ViewStudiesProps,ViewStudiesState> {

    constructor(props: ViewStudiesProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/study'}
                addRecordUrl={'/api/auth/saveGridRecord/study'}
                editRecordUrl={'/api/auth/editGridRecord/study'}
                deleteRecordUrl={'/api/auth/removeGridRecord/study'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/study'}
                gridTitle={'View Studies'}
                gridId={'studyGrid'}
                typeForSuperClass={'study'}
                bulkUpload={true}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ViewStudies);