import * as React from 'react';
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import GeneralApi from "../../services/general-services";
import {Button, Dimmer, Loader, Segment} from "semantic-ui-react";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import {Redirect} from "react-router";

interface BreadCrumbDetailPageProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    loggedIn: boolean;
    setLoggedIn: (loggedIn:boolean) => void;
    breadCrumbPath: string
}

interface BreadCrumbDetailPageState {
    detailData: MenuItem[];
    userAuthorized: boolean;
    pageLoading: boolean;
}

class BreadCrumbDetailPage extends React.Component<BreadCrumbDetailPageProps,BreadCrumbDetailPageState> {
    private generalClient: GeneralApi;

    constructor(props: BreadCrumbDetailPageProps) {
        super(props);
        this.state = {
            detailData: [],
            userAuthorized: false,
            pageLoading: true
        };

        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getUpgradeDetailsLinks();
    }

    getUpgradeDetailsLinks = () => {
        this.generalClient.getDetailPageInfo(this.props.breadCrumbPath)
            .then((response:MenuItem[]) => {
                this.setState({detailData:response, userAuthorized: true, pageLoading: false});
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
                    this.setState({userAuthorized:false, pageLoading: false});
                }
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                else {
                    this.props.setLoggedIn(false);
                }
            });
    };

    render() {
        return (
            <div>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                    <ul>
                        {this.state.detailData.map((item: MenuItem, index: any) => {
                            return (
                                <li>
                                    <Button className={'navLinkButton'} as={NavLink} to={item.menuLink}
                                            content={item.menuDisplayName}/>
                                </li>
                            )
                        })}
                    </ul>
                    }

                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbDetailPage);