import {Client} from "webstomp-client";
import SockJS from "sockjs-client";
import webstomp from "webstomp-client";
import OpenSourceService from "./open-source-service";
import GeneralService from "./general-services";
import {toast} from "react-toastify";

let engineUrl = 'https://trac.sppdev.org';

export default class WebsocketService {
    private apiUrl: string;
    private client: Client | undefined;
    private openClient: OpenSourceService;
    private generalClient: GeneralService;

    constructor() {
        this.apiUrl = engineUrl;
        this.openClient = new OpenSourceService();
        this.generalClient = new GeneralService();
    }

    /*subscribeUpdates = (batchEndpoint:string, logEndPoint:string, batchSocketMethod:any, logSocketMethod:any) => {
        if(this.client !== undefined) {
            this.client.subscribe(
                `/queue/${batchEndpoint}`, batchSocketMethod
            );
            this.client.subscribe(
                `/queue/${logEndPoint}`, logSocketMethod
            );
        }
    };*/

    subscribeUpdates = (notificationEndpoint:string, notificationSocketMethod:any) => {
        if(this.client !== undefined) {
            this.client.subscribe(
                `/user/queue/${notificationEndpoint}`, notificationSocketMethod
            );
        }
    };

    connectSocket = (notificationEndpoint:string, notificationSocketMethod:any) => {
        this.openClient.getEnvironment()
            .then((response: string) => {
                let urlBasedOnEnvironment = "";
                switch(response.toUpperCase()) {
                    case "LOCAL":
                        urlBasedOnEnvironment = 'http://localhost:9003';
                        break;
                    case "DEV":
                        urlBasedOnEnvironment = 'https://trac.sppdev.org';
                        break;
                    case "ITE":
                        urlBasedOnEnvironment = 'https://tracite.itespp.org';
                        break;
                    case "MTE":
                        urlBasedOnEnvironment = 'https://tracmte.itespp.org';
                        break;
                    case "QA":
                        urlBasedOnEnvironment = 'https://tracqa.sppcorp.org';
                        break;
                    case "PROD":
                        urlBasedOnEnvironment = 'https://trac.spp.org';
                        break;
                }

                this.apiUrl = urlBasedOnEnvironment;

                //pure accessToken without 'Bearer' part
                //Adding comment so can build ui, and test websockets in ITE
                const sockjs = new SockJS(
                    `${this.apiUrl}/ws`, null
                );
                this.client = webstomp.over(sockjs, { debug: false });
                //this.client.connect({}, (e: any) => this.subscribeUpdates(batchEndPoint, logEndPoint, webSocketMethod, logSocketMethod));
                this.client.connect({}, (e: any) => this.subscribeUpdates(notificationEndpoint, notificationSocketMethod));

                /*this.generalClient.getCsrf()
                    .then((response:string) => {
                        toast.info(response);
                        //pure accessToken without 'Bearer' part
                        //Adding comment so can build ui, and test websockets in ITE
                        const sockjs = new SockJS(
                            `${this.apiUrl}/ws`, null
                        );
                        this.client = webstomp.over(sockjs, { debug: false });
                        let headers:any = {};
                        headers['X-XSRF-TOKEN'] = response;
                        //this.client.connect({}, (e: any) => this.subscribeUpdates(batchEndPoint, logEndPoint, webSocketMethod, logSocketMethod));
                        this.client.connect(headers, (e: any) => this.subscribeUpdates(notificationEndpoint, notificationSocketMethod));
                    });*/
            }).catch(() =>{
            console.log("Failed to get environment for setting up websockets.");
        });
    };

    disconnectWebStomp = () => {
        if(this.client !== undefined && this.client != null && this.client.connected) {
            this.client.disconnect();
            console.log('Disconnected webstomp client');
        }
    };
}