import axios from "axios";
import {toast} from "react-toastify";

export default class GridService {

    getGridData = (url:string, params:URLSearchParams) => {
        return new Promise((resolve, reject) => {
            axios.get(url,{params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching grid data'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    getCrudAbilities = () => {
        let url = '/api/auth/getCrudAbilities';
        return new Promise<CrudAbilities>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching crud abilities'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    saveGridRecord = (url:string, gridRecord:any) => {
        return new Promise<GridFormSaveResponse>((resolve, reject) => {
            axios.post(url, gridRecord)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving data to backend'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    updateGridRecordBasedOnCheckBoxUpdate = (url: string, updateRecord:any) => {
        return new Promise((resolve, reject) => {
            axios.patch(url, updateRecord)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error updating data to backend'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    updateGridCheckbox = (url:string, checkBoxUpdateRecord:any) => {
      
        return new Promise((resolve, reject) => {
            axios.post(url, checkBoxUpdateRecord)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error updating data to backend'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };
    uploadBulkCsv = (url:string, formData:any) => {
        return new Promise((resolve, reject) => {
            axios.post(url, formData)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving data to backend'));
                    }
                });
        });
    };

    updateGridRecord = (url:string, gridRecord:any) => {
        return new Promise((resolve, reject) => {
            axios.patch(url, gridRecord)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error updating data in backend'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    deleteGridRecord = (url:string, gridRecord:any) => {
        return new Promise((resolve, reject) => {
            axios.delete(url, {data: gridRecord})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error deleting data from backend'));
                    }
                });
        });
    };

    getPaginatedGridData = (url:string, filter:any, params:URLSearchParams) => {
        return new Promise((resolve, reject) => {
            axios.post(url,{filter: filter}, {params})
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching paginated grid data'));
                    }
                });
                /*.catch((error:any) => {
                    reject(error);
                });*/
        });
    };

    export = (exportType:string,resource:string,queryString?:string) => {
        console.log(resource);
        let url = '';
        if(queryString != null && queryString !== undefined) {
            //url = `/api/auth/${resource}/?${queryString}`;
            url = `/api/auth/${resource}?${queryString}`;
        }
        else {
            url = `/api/auth/${resource}`;
        }
        let params = new URLSearchParams();
        params.append("export",exportType);
        return new Promise((resolve, reject) => {
            axios.get(url,{
                responseType: 'arraybuffer',
                params: params
            }).then(response => {
                resolve(response);
            }).catch(error => {
                toast.error(`Error exporting data - ${error.response.data.message}`)
            })
        })
    };

    uploadFile = (formData:FormData) => {
        let url = '';

        return new Promise((resolve, reject) => {
            let url = '/api/auth/upload';
            axios.post(url, formData)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error uploading data'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                });
        });
    };

    uploadUpgradeFile = (formData:FormData) => {
        let url = '';

        //todo create DTO object for file output
        return new Promise<any>((resolve, reject) => {
            let url = '/api/auth/uploadUpgrades';
            axios.post(url, formData,{
                responseType: 'arraybuffer'
            }).then(resp => {
                if (resp.status === 200) {
                    resolve(resp);
                } else {
                    reject(new Error('error uploading data'));
                }
            }).catch((error:any) => {
                    reject(error);
                });
        });
    };
}