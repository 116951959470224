import * as React from 'react';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import {
    Button,
    Container, Dropdown, Icon, Label,
    Menu, Popup,Image
} from "semantic-ui-react";
import logo from '../../images/SPPLogo.png';
import generalApi from "../../services/general-services";
import history from '../../history';
import {toast} from "react-toastify";
import {connect} from "react-redux";
import * as actionTypes from "../../store/action";
import NotificationFeedComponent from "../Notification/NotificationFeed";

interface NavMenuProps {
    location: any;
    loggedIn: boolean
    visibleColumnCount: number
    utilityColumnCount: number
    setLoggedIn: (loggedIn:boolean) => void;
}

interface NavMenuState {
    loggedOut: boolean
    appLongName: string
    userLongName: string
    sideNavOpen: boolean
    componentLoaded: boolean
    loggedIn: boolean
    notificationFeedRecordCount: number
    notificationDropdownStatus: boolean
}

class NavMenu extends React.Component<NavMenuProps, NavMenuState> {
    private client: generalApi;

    constructor(props: NavMenuProps) {
        super(props);
        this.state = {
            loggedOut: false,
            appLongName: '',
            userLongName: '',
            sideNavOpen: true,
            componentLoaded: false,
            loggedIn: false,
            notificationFeedRecordCount: 0,
            notificationDropdownStatus: false
        };

        this.client = new generalApi();
    }

    componentDidMount(): void {
        console.log("componentDidMount -> NavMenu " +this.state.notificationDropdownStatus)
        this.getAppName();
        this.getUserLongName();
    }

    /*componentDidUpdate(prevProps: Readonly<NavMenuProps>, prevState: Readonly<NavMenuState>) {
        if(prevProps.loggedIn !== this.props.loggedIn) {
            this.getAppName();
            this.getUserLongName();
        }
    }*/

    getUserLongName = () => {
        this.client.getUserLongName()
            .then((resp:string) => {
                if(resp !== null && resp !== undefined) {
                    let navMessage = 'Hello ' + resp + '!';
                    this.props.setLoggedIn(true);
                    this.setState({userLongName: navMessage, componentLoaded: true});
                }
            })
            .catch((error:any) => {
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                this.props.setLoggedIn(false);
                this.setState({componentLoaded: true});
                //this.setState({componentLoaded: false});
            });
    };

    getAppName = () => {
        this.client.getAppLongName()
            .then((response:string) => {
                this.setState({appLongName:response});
            })
    };

    updateRecordCount = (notificationCount: number) => {
        this.setState({notificationFeedRecordCount: notificationCount});
    };

    changeNotificationDropdownStatus = (dropdownStatus: boolean) => {        
        this.setState({notificationDropdownStatus: dropdownStatus})
    }
    resetDropdown=()=>{
        this.setState({notificationDropdownStatus: false})  
    }

    resizeGridWithContentSmallerThanWidth = () => {
        let gridWidth = 0;
        let gridContainer = document.querySelectorAll(".gridContainer");
        if (gridContainer.length > 0) {
            gridWidth = (gridContainer.item(0) as HTMLElement).offsetWidth;
            if(this.state.sideNavOpen) {
                gridWidth += 250;
            }
            else {
                gridWidth -= 250;
            }
            if(Math.floor(gridWidth) > ((this.props.visibleColumnCount * 150) + (this.props.utilityColumnCount * 75))) {
                let tableHeaderCell = document.querySelectorAll(".tableHeaderCell");
                if(tableHeaderCell.length > 0) {
                    for(let i = 0; i < tableHeaderCell.length; i++) {
                        (tableHeaderCell.item(i) as HTMLElement).style.width = ((Math.floor(gridWidth)-(this.props.utilityColumnCount * 75))/this.props.visibleColumnCount) + 'px';
                    }
                }
            }
        }
    };

    openCloseNav = () => {
        let { sideNavOpen } = this.state;
        let sideNav = document.querySelectorAll("#sideNav");
        let topNav = document.querySelectorAll("#topNav");
        let mainContent = document.querySelectorAll("#mainContent");
        let sideMenuNav = document.querySelectorAll(".sideMenuNav");
        let navCalendar = document.querySelectorAll(".navCalendar");
        let footerPadding = document.querySelectorAll(".footerPadding");

        if(this.state.sideNavOpen) {
            if (sideNav.length > 0) {
                (sideNav.item(0) as HTMLElement).style.width = '0';
            }

            if (topNav.length > 0) {
                (topNav.item(0) as HTMLElement).style.marginLeft = '0';
            }

            if (mainContent.length > 0) {
                (mainContent.item(0) as HTMLElement).style.marginLeft = '0';
            }

            if(sideMenuNav.length > 0) {
                (sideMenuNav.item(0) as HTMLElement).style.display = 'none';
            }

            if(navCalendar.length > 0) {
                (navCalendar.item(0) as HTMLElement).style.display = 'none';
            }

            if(footerPadding.length > 0) {
                (footerPadding.item(0) as HTMLElement).style.paddingLeft = '0';
            }

            sideNavOpen = false;
        }
        else {
            if (sideNav.length > 0) {
                (sideNav.item(0) as HTMLElement).style.width = '250px';
            }

            if (topNav.length > 0) {
                (topNav.item(0) as HTMLElement).style.marginLeft = '250px';
            }

            if (mainContent.length > 0) {
                (mainContent.item(0) as HTMLElement).style.marginLeft = '250px';
            }

            if(sideMenuNav.length > 0) {
                (sideMenuNav.item(0) as HTMLElement).style.display = 'block';
            }

            if(navCalendar.length > 0) {
                (navCalendar.item(0) as HTMLElement).style.display = 'block';
            }

            if(footerPadding.length > 0) {
                (footerPadding.item(0) as HTMLElement).style.paddingLeft = '250px';
            }

            sideNavOpen = true;
        }

        this.resizeGridWithContentSmallerThanWidth();

        this.setState({sideNavOpen});
    };

    /*logout = () => {
        this.client.logout();
    };

    handleLogOut = () => {
        //const currentPath = this.props.location.pathname;
        this.client.logout()
            .then((response: string) => {
                console.log("successfully logged out");
                history.push({pathname: '/login', state: {from: this.props.location}});
            }).catch(() => {
            console.log("logout failed");
        })
    };*/

    public render() {
        let {componentLoaded} = this.state;
        return (
            <Container fluid={true}>
                <Menu inverted={true} borderless={true} fixed={'top'} fluid={true} className={'topNavMenu'} >
                    
                        <img className="img-responsive" src={logo} alt={"logo"} height={'60px'} />
                    

                    <Menu.Item id="appLongName">{this.state.appLongName}</Menu.Item>
                    <Menu.Item id="appShortName">TRAC</Menu.Item>
                    <Menu.Item>
                        <Popup content={this.state.sideNavOpen ? 'Hide SideNav' : 'Show SideNav'} trigger={<Button className={'transparentButton'} icon={'bars'} onClick={this.openCloseNav}/>} />
                    </Menu.Item>
                    {componentLoaded &&
                        <Menu.Menu position='right'>
                            {this.props.loggedIn &&
                            <React.Fragment>
                                <Dropdown
                                    className={'notificationDropDown'}
                                    item={true}
                                    open={this.state.notificationDropdownStatus}
                                    onClick={()=>this.changeNotificationDropdownStatus(!this.state.notificationDropdownStatus)}
                                    onMouseLeave={this.resetDropdown}
                                
                                    icon={
                                        <Icon.Group size={'large'}>
                                            <Icon name='bell outline' size={'large'} color={'yellow'} />
                                            {this.state.notificationFeedRecordCount > 0 &&
                                            <Label color='red' size={'tiny'} floating={true} circular={true}>
                                                {this.state.notificationFeedRecordCount}
                                            </Label>
                                            }
                                        </Icon.Group>
                                    }
                                >
                                    <Dropdown.Menu className={'notificationFeedMenu'} direction={'left'} id="myDivId">
                                        <NotificationFeedComponent cardClass={'notificationCard'} cardContentClass={'notificationCardContent'} feedTitle={'Recent Activity'} historyFeed={false} updateNotificationCount={this.updateRecordCount} changeNotificationDropdownStatus={this.changeNotificationDropdownStatus} />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown className={'profileDropDown'} item={true} text={this.state.userLongName}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={NavLink} to={'/profile'} icon='edit' text='User Profile'/>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </React.Fragment>
                            }
                            {!this.props.loggedIn &&
                            <Menu.Item>Unauthenticated</Menu.Item>
                            }
                        </Menu.Menu>
                    }
                </Menu>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn,
        visibleColumnCount: state.defaultReducer.visibleColumnCount,
        utilityColumnCount: state.defaultReducer.utilityColumnCount
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);