import axios from "axios";

export default class ApprovalService {

    constructor() {
    }

    updateRecordApprovalStatus = (approvalRequest: ApprovalRequest) => {
        const url = `/api/auth/updateApprovalStatus`;

        return new Promise((resolve, reject) => {
            axios.post(url, approvalRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error updating approval flow status'));
                    }
                })
                .catch((error:any) => {
                    reject(error);
                })
        });
    };

    checkUserCanUpdate = (approvalTrackerId:number) => {
        const url = `/api/auth/userHasNeededRole/${approvalTrackerId}`;

        return new Promise<NtcDetailsResponse>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error checking if user can update record'));
                    }
                });
        });
    };
}