import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {ButtonProps, Container} from "semantic-ui-react";
import TracLetterRecordPage from "../TracLetters/TracLetterRecordPage";
import {toast} from "react-toastify";
import ExternalApi from "../../services/external-service";
import history from "../../history";

interface ExternalNtcTrackingProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
}

interface ExternalNtcTrackingState {
    tracLetterRecordPageOpen: boolean;
    gridVisible: boolean;
    tracRecord: any;
    tracRecordIndex: number;
    letterType: string;
}

class ExternalNtcTracking extends React.Component<ExternalNtcTrackingProps,ExternalNtcTrackingState> {
    private client: ExternalApi;

    constructor(props: ExternalNtcTrackingProps) {
        super(props);
        this.state = {
            tracLetterRecordPageOpen: false,
            gridVisible: true,
            tracRecord: null,
            tracRecordIndex: -1,
            letterType: 'ntc'
        };

        this.client = new ExternalApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));

        //Logic to pull off letterId and load specific letter if user searches url
        let urlParts = window.location.pathname.split('/');
        if(urlParts[urlParts.length - 1] !== 'NTC') {
            this.getNtcRecordInQuestion(urlParts[urlParts.length - 1]);
        }
    }

    componentDidUpdate(prevProps: Readonly<ExternalNtcTrackingProps>, prevState: Readonly<ExternalNtcTrackingState>) {
        if(window.location.pathname === '/transmissionOwnerNtcs') {
            this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
            if(!this.state.gridVisible && this.state.tracLetterRecordPageOpen) {
                this.setNtcGridOpen(true, false);
            }
        }
    }

    getNtcRecordInQuestion = (ntcId:string) => {
        this.client.getNtcRecordById(ntcId)
            .then((response:NtcRecordResponse) => {
                if(response.status === 'Not_Found') {
                    toast.error(response.message);
                    history.push({
                        pathname: '/transmissionOwnerNtcs'
                    });
                }
                else {
                    this.setState({tracRecord:response.ntc, tracLetterRecordPageOpen:true, gridVisible: false, tracRecordIndex: 0});
                }
            })
            .catch((error:any) => {
                toast.error(error);
            });
    }

    viewRecord = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        console.log(event);
        console.log(data);
        if(this.state.letterType === 'ntc') {
            const currentPath = this.props.location.pathname;
            let stateObj = {from: currentPath};
            window.history.replaceState(stateObj, "NTC Letter", `/transmissionOwnerNtcs/${data.row.ntcId}`);
        }
        this.setState({tracLetterRecordPageOpen:true, gridVisible: false, tracRecord:data.row, tracRecordIndex: data.rowIndex});
    };

    getViewButton = () => {
        return {
            iconName: 'eye',
            iconColor: 'blue',
            onClick: this.viewRecord,
            popupContent: 'View/Approve NTC'
        }
    };

    getExtraUtilityColumns = () => {
        return [
            this.getViewButton()
        ];
    };

    setNtcGridOpen = (gridVisible:boolean, tracLetterRecordPageOpen:boolean) => {
        this.setState({gridVisible, tracLetterRecordPageOpen});
    };

    setLetterType = (letterType:string) => {
        this.setState({letterType});
    };

    render() {
        return (
            <React.Fragment>
                <GridContainer
                    getGridDataUrl={'/api/auth/viewToNtcs'}
                    gridPaginated={false}
                    gridTitle={'Transmission Owner NTCs'}
                    gridId={'toNtcGrid'}
                    extraUtilityColumns={this.getExtraUtilityColumns()}
                    gridVisible={this.state.gridVisible}
                />
                <TracLetterRecordPage
                    TracLetterRecordPageOpen={this.state.tracLetterRecordPageOpen}
                    tracRecord={this.state.tracRecord}
                    tracRecordIndex={this.state.tracRecordIndex}
                    letterType={this.state.letterType}
                    backToGrid={this.setNtcGridOpen}
                    setLetterType={this.setLetterType}
                    location={this.props.location}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ExternalNtcTracking);