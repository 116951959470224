import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import {ButtonProps, Container} from "semantic-ui-react";
import TracLetterRecordPage from "../TracLetters/TracLetterRecordPage";
import {Redirect} from "react-router";
import history from "../../history";
import {toast} from "react-toastify";
import LetterApi from "../../services/letter-service";

interface NtcTrackingProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
}

interface NtcTrackingState {
    ntcLetterEditPageOpen: boolean;
    tracLetterRecordPageOpen: boolean;
    gridVisible: boolean;
    tracRecord: any;
    tracRecordIndex: number;
    letterType: string;
}

class NtcTracking extends React.Component<NtcTrackingProps,NtcTrackingState> {
    private client: LetterApi;

    constructor(props: NtcTrackingProps) {
        super(props);
        this.state = {
            ntcLetterEditPageOpen:false,
            tracLetterRecordPageOpen: false,
            gridVisible: true,
            tracRecord: null,
            tracRecordIndex: -1,
            letterType: 'ntc'
        };

        this.client = new LetterApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));

        //Logic to pull off letterId and load specific letter if user searches url
        let urlParts = window.location.pathname.split('/');
        if(urlParts[urlParts.length - 1] !== 'NTC') {
            this.getNtcRecordInQuestion(urlParts[urlParts.length - 1]);
        }
    }

    getNtcRecordInQuestion = (ntcId:string) => {
        this.client.getNtcRecordById(ntcId)
            .then((response:NtcRecordResponse) => {
                if(response.status === 'Not_Found') {
                    toast.error(response.message);
                    history.push({
                        pathname: '/NTC'
                    });
                }
                else {
                    this.setState({tracRecord:response.ntc, tracLetterRecordPageOpen:true, gridVisible: false, tracRecordIndex: 0});
                }
            })
            .catch((error:any) => {
                toast.error(error);
            });
    }

    componentDidUpdate(prevProps: Readonly<NtcTrackingProps>, prevState: Readonly<NtcTrackingState>) {
        if(window.location.pathname === '/NTC') {
            this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
            if(!this.state.gridVisible && this.state.tracLetterRecordPageOpen) {
                this.setNtcGridOpen(true, false);
            }
        }
    }

    viewRecord = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        console.log(event);
        console.log(data);
        if(this.state.letterType === 'ntc') {
            const currentPath = this.props.location.pathname;
            let stateObj = {from: currentPath};
            window.history.replaceState(stateObj, "NTC Letter", `/NTC/${data.row.ntcId}`);
        }
        this.setState({tracLetterRecordPageOpen:true, gridVisible: false, tracRecord:data.row, tracRecordIndex: data.rowIndex});
    };

    editTemplate = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        console.log(event);
        console.log(data);
        this.setState({ntcLetterEditPageOpen:true, tracLetterRecordPageOpen:true, tracRecord:data.row});
    };

    getViewButton = () => {
        return {
            iconName: 'eye',
            iconColor: 'blue',
            onClick: this.viewRecord,
            popupContent: 'View/Approve NTC'
        }
    };

    getEditTemplateButton = () => {
        return {
            onClick: this.editTemplate,
            popupContent: 'Edit NTC Letter Template',
        }
    };

    getExtraUtilityColumns = () => {
        return [
            this.getViewButton(),
        ];
    };

    setNtcGridOpen = (gridVisible:boolean, tracLetterRecordPageOpen:boolean) => {
        this.setState({gridVisible, tracLetterRecordPageOpen});
    };

    setLetterType = (letterType:string) => {
        this.setState({letterType});
    };

    render() {
        return (
            <React.Fragment>
                <GridContainer
                    getGridDataUrl={'/api/auth/viewGridData/ntc'}
                    addRecordUrl={'/api/auth/saveGridRecord/ntc'}
                    editRecordUrl={'/api/auth/editGridRecord/ntc'}
                    deleteRecordUrl={'/api/auth/removeGridRecord/ntc'}
                    gridPaginated={true}
                    formEditing={true}
                    showUtilityColumns={true}
                    exportLink={'viewGridData/ntc'}
                    gridTitle={'View NTCs'}
                    gridId={'ntcGrid'}
                    extraUtilityColumns={this.getExtraUtilityColumns()}
                    typeForSuperClass={'ntc'}
                    gridVisible={this.state.gridVisible}
                    complexAddForm={'/createNtc'}
                />
                <TracLetterRecordPage
                    TracLetterRecordPageOpen={this.state.tracLetterRecordPageOpen}
                    tracRecord={this.state.tracRecord}
                    tracRecordIndex={this.state.tracRecordIndex}
                    letterType={this.state.letterType}
                    backToGrid={this.setNtcGridOpen}
                    setLetterType={this.setLetterType}
                    location={this.props.location}
                />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(NtcTracking);