import * as React from 'react';
import GridContainer from "../../GenericComponents/Grids/GridContainer";
import {toast} from "react-toastify";
import * as actionTypes from "../../../store/action";
import {connect} from "react-redux";
import {createBreadCrumbBasedOnPage} from "../../../Utils/HelperFunctions";
import {Button, Container, Dimmer, Dropdown, DropdownProps, Header, Input, Loader, Segment} from "semantic-ui-react";
import ReactDatePicker from "react-datepicker";
import UpgradeApi from "../../../services/upgrade-service";
import GeneralApi from "../../../services/general-services";
import {SyntheticEvent} from "react";
import TracWebFormElement from "../../GenericComponents/TracWebFormElement";
import TracWebForm from "../../GenericComponents/TracWebForm";
import ReliabilityUpgradeForm from "./ReliabilityUpgradeForm";
import GenerationInterconnectionUpgradeForm from "./GenerationInterconnectionUpgradeForm";
import TransmissionServicesUpgradeForm from "./TransmissionServicesUpgradeForm";
import history from "../../../history";
import {create} from "domain";

interface TopLevelUpgradeFormProps {
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    setLoggedIn: (loggedIn:boolean) => void;
    location: any;
}

interface TopLevelUpgradeFormState {
    pageLoading: boolean
    userAuthorized: boolean
    createFormObject: InitialUpgradeCreateResponse;
    formMetaData: any
    nextUpgradeFormReady: boolean
}

const find = require('lodash/find');
class TopLevelUpgradeForm extends React.Component<TopLevelUpgradeFormProps,TopLevelUpgradeFormState> {
    private upgradeClient: UpgradeApi;
    private generalClient: GeneralApi;

    constructor(props: TopLevelUpgradeFormProps) {
        super(props);
        this.state = {
            pageLoading: true,
            userAuthorized: false,
            createFormObject: {
                upgradeName: '',
                previousUpgradeId: '',
                upgradeType: '',
                approvalStatus: '',
                projectStatus: '',
                subcategoryMiles: '',
                inServiceDate: null,
                upgradeDescription: '',
                upgradeStatusComment: '',
                modComments: '',
                reasonForUpgradeIdChange: '',
                relatedProject: '',
                relatedStudy: '',
                relatedTsrs: ''
            },
            formMetaData: null,
            nextUpgradeFormReady: false
        };

        this.upgradeClient = new UpgradeApi();
        this.generalClient = new GeneralApi();
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));

        this.getTopLevelUpgradeFormDetails();
    }

    /*componentDidUpdate(prevProps: Readonly<TopLevelUpgradeFormProps>, prevState: Readonly<TopLevelUpgradeFormState>) {
        let hey = this.state.nextUpgradeFormReady;
        if (this.props.location.state !== undefined && this.props.location.state.topLevelUpgradeFormObject !== undefined && prevState.nextUpgradeFormReady !== this.state.nextUpgradeFormReady) {
            this.setState({createFormObject: this.props.location.state.topLevelUpgradeFormObject, nextUpgradeFormReady: false});
        }
    }*/

    getTopLevelUpgradeFormDetails = () => {
        if(this.props.location.state !== undefined && this.props.location.state.topLevelUpgradeFormObject !== undefined) {
            this.setState({
                createFormObject: this.props.location.state.topLevelUpgradeFormObject,
                formMetaData: this.props.location.state.topLevelFormMetaData,
                userAuthorized: true,
                pageLoading: false
            });
        }
        else {
            this.upgradeClient.getInitialUpgradeCreateForm()
                .then((response: any) => {
                    this.setState({
                        createFormObject: response.data[0],
                        formMetaData: response.columnMetaDataList,
                        userAuthorized: true,
                        pageLoading: false
                    });
                })
                .catch((error: any) => {
                    if (error.response.status === 403) {
                        this.setState({userAuthorized: false, pageLoading: false});
                    }
                    if (!error.response.data.badToken) {
                        toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                    } else {
                        toast.error(error.response);
                        this.props.setLoggedIn(false);
                    }
                });
        }
    };

    /*fetchDropDownData = (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        let dropDownOptions:DropDownItem[];
        this.generalClient.getDropDownData(data.metaData.DropDown.callbackRoute)
            .then((response:DropDownItem[]) => {
                this.setState({dropDownOptions:response,dropDownLoading:false});
            });
        this.setState({dropDownLoading:true})
    };*/

    onDateChange = (e:any, c:any) => {

    };

    updateForm = () => {
        const { createFormObject, formMetaData } = this.state;
        //toast.success("successfully save form");
        let finalPartOfUrlBasedOnUpgradeType = '';
        switch(createFormObject.upgradeType) {
            case 'Generation Interconnection':
                finalPartOfUrlBasedOnUpgradeType = 'generationInterconnection';
                break;
            case 'Transmission Service':
                finalPartOfUrlBasedOnUpgradeType = 'transmissionService';
                break;
            case 'Reliability/Economic':
                finalPartOfUrlBasedOnUpgradeType = 'reliability';
                break;
        }
        const currentPath = this.props.location.pathname;
        history.push({
            //pathname: this.props.location.state !== undefined ? this.props.location.state.from + finalPartOfUrlBasedOnUpgradeType : '/upgrades/createUpgrade/' + finalPartOfUrlBasedOnUpgradeType,
            pathname: currentPath !== undefined ? currentPath + '/' + finalPartOfUrlBasedOnUpgradeType : '/upgrades/createUpgrade/',
            state: {from: currentPath, topLevelUpgradeFormObject: createFormObject, topLevelFormMetaData: formMetaData}
        });
        this.setState({nextUpgradeFormReady: true});
    };

    resetWebForm = () => {
        let createFormObject: InitialUpgradeCreateResponse = {
            upgradeName: '',
            previousUpgradeId: '',
            upgradeType: '',
            approvalStatus: '',
            projectStatus: '',
            subcategoryMiles: '',
            inServiceDate: null,
            upgradeDescription: '',
            upgradeStatusComment: '',
            modComments: '',
            reasonForUpgradeIdChange: '',
            relatedProject: '',
            relatedStudy: '',
            relatedTsrs: ''
        };

        this.setState({createFormObject});
        toast.info('Cleared All Top Level Form Fields');
    };

    handleSpecializedClickLogic = (metaDataClicked:any) => {
        if(metaDataClicked.accessor === 'upgradeType') {
            if(this.state.createFormObject['upgradeType'] === 'Transmission Service') {
                let { formMetaData } = this.state;
                formMetaData.map((metaData:any) => {
                    if(metaData.accessor === 'relatedTsrs') {
                        metaData.visible = true;
                        metaData.required = true;
                    }
                });
                this.setState({formMetaData})
            }
            else {
                let { formMetaData } = this.state;
                formMetaData.map((metaData:any) => {
                    if(metaData.accessor === 'relatedTsrs') {
                        metaData.visible = false;
                    }
                });
                this.setState({formMetaData})
            }
        }
    };

    setupForTopLevelReturn = (nextUpgradeFormReady:boolean) => {
        const currentPath = this.props.location.pathname;
        history.push({
            pathname: this.props.location.state !== undefined ? this.props.location.state.from : '/upgrades/createUpgrade/',
            state: {from: currentPath}
        });
        this.setState({nextUpgradeFormReady});
    }

    render() {
        return (
            <Container fluid={true}>
                <Segment basic={true}>
                    {this.props.loggedIn && this.state.userAuthorized &&
                        <React.Fragment>
                            {!this.state.nextUpgradeFormReady &&
                                <React.Fragment>
                                    <Header as='h2' block={true}>
                                        Add New Upgrade
                                    </Header>
                                    <TracWebForm
                                        formColumnSize={2}
                                        data={this.state.createFormObject}
                                        formMetaData={this.state.formMetaData}
                                        saveFormRecord={this.updateForm}
                                        resetWebForm={this.resetWebForm}
                                        handleSpecializedClickLogic={this.handleSpecializedClickLogic}
                                        showButtons={true}
                                    />
                                </React.Fragment>
                            }
                            {this.state.createFormObject.upgradeType === 'Reliability/Economic' && this.state.nextUpgradeFormReady &&
                                <ReliabilityUpgradeForm
                                location={this.props.location}
                                backToMainUpgradeForm={this.setupForTopLevelReturn}
                                topLevelUpgradeFormObject={this.state.createFormObject}
                                />
                            }
                            {this.state.createFormObject.upgradeType === 'Generation Interconnection' && this.state.nextUpgradeFormReady &&
                                <GenerationInterconnectionUpgradeForm
                                location={this.props.location}
                                backToMainUpgradeForm={this.setupForTopLevelReturn}
                                topLevelUpgradeFormObject={this.state.createFormObject}
                                />
                            }
                            {this.state.createFormObject.upgradeType === 'Transmission Services' && this.state.nextUpgradeFormReady &&
                                <TransmissionServicesUpgradeForm
                                location={this.props.location}
                                backToMainUpgradeForm={this.setupForTopLevelReturn}
                                topLevelUpgradeFormObject={this.state.createFormObject}
                                />
                            }
                        </React.Fragment>
                    }
                    <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
            </Container>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopLevelUpgradeForm);