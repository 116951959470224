import axios from "axios";

export default class LetterService {

    constructor() {
    }

    getLetter = (letterType:string, letterId:number) => {
        const url = `/api/auth/getLetterObject/${letterType}/${letterId}`;

        return new Promise<string>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting letter'));
                    }
                });
        });
    };

    getLetterEdit = (letterType:string, letterId:number, revertLetter:boolean) => {
        const url = `/api/auth/getEditLetterObject/${letterType}/${letterId}?revert=${revertLetter}`;
        return new Promise<EditLetterDto>((resolve, reject) => {
            axios.get(url)
                .then(({status, data}) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting edit letter'));
                    }
                })
                .catch((error:any) => {
                    if(!error.response.data.badToken) {
                        reject(error);
                    }
                });
        });
    };

    previewLetter = (previewRequest: LetterRequest) => {
        const url = `/api/auth/previewLetter`;
        return new Promise<string>((resolve, reject) => {
            axios.post(url, previewRequest)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error previewing letter'));
                    }
                });
        });
    };

    saveLetter = (letter:LetterRequest) => {
        const url = `/api/auth/updateLetterContent`;
        return new Promise<string>((resolve, reject) => {
            axios.patch(url, letter)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error saving letter'));
                    }
                });
        });
    };

    downloadLetter = (letterType:string, letterId:number, downloadType:string) => {
        const url = `/api/auth/downloadLetter/${letterType}/${letterId}?type=${downloadType}`;

        return new Promise((resolve, reject) => {
            axios.get(url, {
                responseType: 'arraybuffer'
            }).then(resp => {
                resolve(resp);
            }).catch(err => {
                if(!err.response.data.badToken) {
                    reject(new Error(`Error downloading file - ${err.response.data.message}`));
                }
            });
        });
    };

    getLetterTemplateOptions = () => {
        const url = `/api/auth/ntcTemplateOptions`;

        return new Promise<DropDownItem[]>((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching template options for NTC'));
                    }
                });
        });
    };

    getNtcInitialFormDetails = (study: string) => {
        const url = `/api/auth/getNtcInitialFormDetails/${study}`;

        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(({ status, data} ) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error fetching data to build form'));
                    }
                });
        });
    };

    getNtcRecordById = (ntcId:string) => {
        const url = `/api/auth/getNtcRecord/${ntcId}`;

        return new Promise<NtcRecordResponse>((resolve, reject) => {
            axios.get(url)
                .then(({status, data}) => {
                    if (status === 200) {
                        resolve(data);
                    } else {
                        reject(new Error('error getting ntc record for NtcId ' + ntcId));
                    }
                })
                .catch((error:any) => {
                    if(!error.response.data.badToken) {
                        reject(error);
                    }
                });
        });
    };
}