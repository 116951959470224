import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";

interface AssocStudyUpgradeProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface AssocStudyUpgradeState {
}

class AssocStudyUpgrade extends React.Component<AssocStudyUpgradeProps,AssocStudyUpgradeState> {

    constructor(props: AssocStudyUpgradeProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/assocStudyUpgrade'}
                addRecordUrl={'/api/auth/saveGridRecord/assocStudyUpgrade'}
                editRecordUrl={'/api/auth/editGridRecord/assocStudyUpgrade'}
                deleteRecordUrl={'/api/auth/removeGridRecord/assocStudyUpgrade'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/assocStudyUpgrade'}
                gridTitle={'View Study and Upgrade Associations'}
                gridId={'assocStudyUpgradeGrid'}
                typeForSuperClass={'assocStudyUpgrade'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(AssocStudyUpgrade);