import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";

interface ViewOasisTsrsProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface ViewOasisTsrsState {
}

class ViewOasisTsrs extends React.Component<ViewOasisTsrsProps,ViewOasisTsrsState> {

    constructor(props: ViewOasisTsrsProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/oasisTsr'}
                /*addRecordUrl={'/api/auth/saveGridRecord/oasisTsr'}
                editRecordUrl={'/api/auth/editGridRecord/oasisTsr'}
                deleteRecordUrl={'/api/auth/removeGridRecord/oasisTsr'}*/
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/oasisTsr'}
                gridTitle={'View TSRs'}
                gridId={'oasisTsrGrid'}
                typeForSuperClass={'oasisTsr'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ViewOasisTsrs);