import * as React from 'react';
import {toast} from "react-toastify";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";
import history from "../../history";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import {
    Button,
    ButtonProps,
    Container,
    Dimmer,
    Form,
    Grid,
    Icon, Input,
    Loader,
    Message,
    Segment,
    Tab,
    Radio, Label, InputOnChangeData, TextArea, TextAreaProps, CheckboxProps, Dropdown, DropdownProps, Header
} from "semantic-ui-react";
import ExternalApi from "../../services/external-service";
import StationAssumptions from "./Assumptions/StationAssumptions";
import LineAssumptions from "./Assumptions/LineAssumptions";
import CostAssumptions from "./Assumptions/CostAssumptions";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

interface ScertFormProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    location: any;
}

interface ScertFormState {
    scertRequest: ScertRequest;
    scertResponse: any;
    activeAssumptionTab: number;
    activeMainTab: number;
    formLoading: boolean;
    scertForm: ScertFormObject;
    uploadFile:any
    uploading: any
    mitigationCloseoutStatusOptions: DropDownItem[]
    mitigationCloseoutDropdownLoading: boolean
    pendingMitigationCloseOutData: any
    modProjectIdExists: boolean
    modProjectIdCheckInProgress: boolean
}

const includes = require('lodash/includes');
const cloneDeep = require('lodash/cloneDeep');
let fileDownload = require('js-file-download');
class ScertForm extends React.Component<ScertFormProps,ScertFormState> {
    private externalClient: ExternalApi;

    constructor(props: ScertFormProps) {
        super(props);
        this.state = {
            scertRequest: {
                upgradeId: 0,
                scertOwnerGuid: '',
                scertType: '',
                scertStage: '',
                scertStatus: ''
            },
            scertForm: {
                scert: null,
                summaryLineAssumption: null,
                costAssumption: null,
                lineAssumption: null,
                segment: null,
                stationAssumption: null,
                mitigationPlanCloseout: null,
                isSppAdmin: false,
                scertActionsResponse: {
                    canSubmit: false,
                    canSave: false,
                    canApprove: false,
                    canReject: false,
                    notification: ''
                },
                scertActionStatus: ''
            },
            scertResponse: null,
            activeAssumptionTab: 0,
            activeMainTab: 0,
            formLoading: true,
            uploadFile: {},
            uploading: {
                mitigationPlan: false,
                loco: false,
                gisInfo: false
            },
            mitigationCloseoutStatusOptions: [],
            mitigationCloseoutDropdownLoading: false,
            pendingMitigationCloseOutData: null,
            modProjectIdExists: true,
            modProjectIdCheckInProgress: false
        };

        this.externalClient = new ExternalApi();
    }

    componentDidMount(): void {
        this.getParamsAndGetForm();
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    getParamsAndGetForm = () => {
        let params, upgradeId, scertOwnerGuid;
        if(this.props.location !== undefined) {
            params = new URLSearchParams(this.props.location.search);
            upgradeId = params.get('upgradeId');
            scertOwnerGuid = params.get('scertOwnerGuid');

            let scertRequest:ScertRequest = {
                upgradeId: upgradeId !== null ? Number(upgradeId) : -1,
                scertOwnerGuid: scertOwnerGuid !== null ? scertOwnerGuid : '',
                scertType: this.state.scertRequest.scertType !== null ? this.state.scertRequest.scertType : '',
                scertStatus: this.state.scertRequest.scertStatus !== null ? this.state.scertRequest.scertStatus : '',
                scertStage: this.state.scertRequest.scertStage !== null ? this.state.scertRequest.scertStage : '',
            }

            this.externalClient.getScertForm(scertRequest)
                .then((response:ScertFormObject) => {
                    let pendingMitigationCloseOutData = cloneDeep(response.mitigationPlanCloseout);
                    this.setState({scertForm: response, formLoading: false, pendingMitigationCloseOutData});
                    this.getStatusOptions();
                    //this.setState({scertResponse:response, formLoading: false});
                });
            this.setState({scertRequest});
        }
    };

    backToGrid = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        const currentPath = this.props.location.pathname;
        history.push({
            pathname: this.props.location.state !== undefined ? this.props.location.state.from : '/scert',
            state: {from: currentPath}
        });
    };

    takeActionOnScert = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        if(data.status !== '' && data.status !== null && data.status !== undefined) {
            let scertForm = this.state.scertForm;
            scertForm.scertActionStatus = data.status;
            this.externalClient.updateScertForm(scertForm)
                .then((response:ScertFormObject) => {
                    toast.success('Successfully performed ' + data.status + ' on SCERT form');
                    this.setState({scertForm:response, formLoading:false});
                })
                .catch((error:any) => {
                    toast.error(error);
                    this.setState({formLoading: false});
                });
            this.setState({formLoading:true});
        }
    };

    saveMitigationCloseOutInfo = (event: React.MouseEvent<HTMLButtonElement>, data:ButtonProps) => {
        if(this.state.pendingMitigationCloseOutData !== this.state.scertForm.mitigationPlanCloseout
        && (this.state.modProjectIdExists && !this.state.modProjectIdCheckInProgress)) {
            this.externalClient.saveMitigationCloseoutChanges(this.state.scertRequest.scertOwnerGuid, this.state.pendingMitigationCloseOutData)
                .then((response:any) => {
                    let { scertForm, pendingMitigationCloseOutData } = this.state;
                    scertForm.mitigationPlanCloseout = response;
                    pendingMitigationCloseOutData = cloneDeep(scertForm.mitigationPlanCloseout);
                    toast.success("Successfully saved Mitigation/Closeout Changes");
                    this.setState({scertForm, pendingMitigationCloseOutData, formLoading: false});
                }).catch((error:any) => {
                    this.setState({formLoading:false});
            });

            this.setState({formLoading: true});
        }
    };

    //getStatusOptions = (event: React.KeyboardEvent<HTMLElement>, data:DropdownProps) => {
    getStatusOptions = () => {
        this.externalClient.getMitigationCloseoutStatusOptions()
            .then((response:DropDownItem[]) => {
                this.setState({mitigationCloseoutStatusOptions: response, mitigationCloseoutDropdownLoading: false});
            });
        this.setState({mitigationCloseoutDropdownLoading: true});
    };

    onDateChange = (e:any,c:any,recordName:string) => {
        let scertForm = this.state.scertForm;

        scertForm.scert.data[recordName] = e;

        this.setState({scertForm});
    };

    handleRadioChange = (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
        let scertForm = this.state.scertForm;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertForm, data.value, data.inputType, data.name);
        }

        this.setState({scertForm});
    };

    setFieldBasedOnFormSectionType = (scertForm:any, value:any, inputType:string, name:string) => {
        switch(inputType) {
            case 'scert':
                scertForm.scert.data[name] = value;
                break;
            case 'summaryLineAssumption':
                scertForm.summaryLineAssumption.data[name] = value;
                break;
            case 'costAssumption':
                scertForm.costAssumption.data[name] = value;
                break;
            case 'lineAssumption':
                scertForm.lineAssumption.data[name] = value;
                break;
            case 'segment1':
                scertForm.segment.segment1Data[name] = value;
                break;
            case 'segment2':
                scertForm.segment.segment2Data[name] = value;
                break;
            case 'stationAssumption':
                scertForm.stationAssumption.data[name] = value;
                break;
            case 'mitigationPlanCloseout':
                scertForm.mitigationPlanCloseout.data[name] = value;
                break;
        }
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputOnChangeData) => {
        let scertForm = this.state.scertForm;
        let { pendingMitigationCloseOutData } = this.state;

        this.setFieldBasedOnFormSectionType(scertForm, event.target.value, data.inputType, data.name);

        if(data.inputType === 'mitigationPlanCloseout') {
            pendingMitigationCloseOutData.data[data.name] = event.target.value;
        }

        this.setState({scertForm, pendingMitigationCloseOutData});
    };

    handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, data:TextAreaProps) => {
        let scertForm = this.state.scertForm;
        let { pendingMitigationCloseOutData } = this.state;

        if(data.name !== undefined) {
            this.setFieldBasedOnFormSectionType(scertForm, event.target.value, data.inputType, data.name);
        }

        if(data.inputType === 'mitigationPlanCloseout') {
            pendingMitigationCloseOutData.data[data.name] = event.target.value;
        }

        this.setState({scertForm, pendingMitigationCloseOutData});
    };

    handleTabChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        this.setState({ activeAssumptionTab: data.activeIndex });
    };

    handleMainTabChanges = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        this.setState({ activeMainTab: data.activeIndex });
    };

    chooseUploadFile = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        switch(data.uploadType) {
            case 'mitigationPlan':
                document.getElementById('mitigationPlanUpload')!.click();
                break;
            case 'loco':
                document.getElementById('locoUpload')!.click();
                break;
            case 'gisInfo':
                document.getElementById('gisInfoUpload')!.click();
                break;
        }
    };

    fileUploaded = (e: any, uploadType: string) => {
        let { uploading } = this.state;
        if (e.target.files.length > 0) {
            let fileExtension = e.target.files[0].name.toLowerCase();
            if (e.target.files.length !== 0) {
                let allowedFileExtensionBasedOnUploadType = '';
                let fileTypeSeen = false;
                switch(uploadType) {
                    case 'mitigationPlan':
                        allowedFileExtensionBasedOnUploadType = 'idv';
                        break;
                    case 'loco':
                        allowedFileExtensionBasedOnUploadType = 'doc,pdf';
                        break;
                    case 'gisInfo':
                        allowedFileExtensionBasedOnUploadType = 'kmz,shp';
                        break;
                }

                allowedFileExtensionBasedOnUploadType.split(',').map((value:string, key:any) => {
                    if(fileExtension.endsWith(value)) {
                        fileTypeSeen = true;
                    }
                });

                if(fileTypeSeen) {
                    uploading[uploadType] = true;
                    this.setState({uploadFile: e.target.files[0], uploading});
                    this.uploadFile(e, uploadType);
                }
                else {
                    toast.warn('You can only upload ' + allowedFileExtensionBasedOnUploadType + ' files')
                }
            }
        }
    };

    uploadFile = (event:any, uploadType: string) => {
        //let currentTarget = event.currentTarget;
        let { uploading, scertForm, pendingMitigationCloseOutData } = this.state;
        let fileName = event.target.files[0].name;
        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('upgradeId', this.state.scertRequest.upgradeId.toString());
        formData.append('fileType', uploadType);
        this.externalClient.uploadMitigationCloseoutDoc(this.state.scertRequest.scertOwnerGuid, formData, fileName)
            .then((response: any) => {
                let pendingResponse = cloneDeep(response);
                toast.success("Successfully uploaded document: " + fileName);
                uploading[uploadType] = false
                if(scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty(uploadType)) {
                    scertForm.mitigationPlanCloseout?.fileData[uploadType].push(response);
                    pendingMitigationCloseOutData.fileData[uploadType].push(pendingResponse);
                }
                else {
                    let array: any[] = new Array(response);
                    let pendingArray: any[] = new Array(pendingResponse);
                    scertForm.mitigationPlanCloseout.fileData[uploadType] = array;
                    pendingMitigationCloseOutData.fileData[uploadType] = pendingArray;
                }
                this.setState({uploading, scertForm, pendingMitigationCloseOutData});
            })
            .catch((error:any) => {
                uploading[uploadType] = false
                this.setState({uploading});
            });

        event.target.value = '';
    };

    downloadFile = (e:any) => {
        this.externalClient.downloadMitigationCloseoutFile(e.currentTarget.dataset.file_id)
            .then((response:any) => {
                fileDownload(response.data,response.headers["content-disposition"].split("=")[1]);
            });
    };

    deleteFile = (e:any) => {
        let { scertForm, pendingMitigationCloseOutData } = this.state;
        let uploadType = e.currentTarget.dataset.upload_type;
        let fileId = e.currentTarget.dataset.file_id;
        this.externalClient.deleteMitigationCloseoutFile(this.state.scertRequest.scertOwnerGuid, fileId)
            .then((response:any) => {
                toast.success("Successfully removed file with ID: " + fileId);
                for(let i = 0; i < scertForm.mitigationPlanCloseout?.fileData[uploadType].length; i++) {
                    if(scertForm.mitigationPlanCloseout?.fileData[uploadType][i].id === Number(fileId)) {
                        scertForm.mitigationPlanCloseout?.fileData[uploadType].splice(i, 1);
                        pendingMitigationCloseOutData.fileData[uploadType].splice(i,1);
                        i--;
                    }
                }

                this.setState({scertForm, pendingMitigationCloseOutData});
            });
    };

    mitigationCloseoutStatusChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        //this.setState({[data.name.toString()]: data.value} as any);
        let changeDetected = false;
        let { pendingMitigationCloseOutData } = this.state;
        switch(data.name.toString()) {
            case 'mitigationUploadStatus':
                if(pendingMitigationCloseOutData.fileData.hasOwnProperty('mitigationPlan')) {
                    for (let i = 0; i < pendingMitigationCloseOutData.fileData['mitigationPlan'].length; i++) {
                        if (pendingMitigationCloseOutData.fileData['mitigationPlan'][i].fileStatus.type !== data.value) {
                            changeDetected = true;
                            pendingMitigationCloseOutData.fileData['mitigationPlan'][i].fileStatus.type = data.value;
                            pendingMitigationCloseOutData.fileData['mitigationPlan'][i].fileStatus.changed = true;
                        }
                    }
                }
                break;
            case 'locoUploadStatus':
                if(pendingMitigationCloseOutData.fileData.hasOwnProperty('loco')) {
                    for (let i = 0; i < pendingMitigationCloseOutData.fileData['loco'].length; i++) {
                        if (pendingMitigationCloseOutData.fileData['loco'][i].fileStatus.type !== data.value) {
                            changeDetected = true;
                            pendingMitigationCloseOutData.fileData['loco'][i].fileStatus.type = data.value;
                            pendingMitigationCloseOutData.fileData['loco'][i].fileStatus.changed = true;
                        }
                    }
                }
                break;
            case 'gisInfoUploadStatus':
                if(pendingMitigationCloseOutData.fileData.hasOwnProperty('gisInfo')) {
                    for (let i = 0; i < pendingMitigationCloseOutData.fileData['gisInfo'].length; i++) {
                        if (pendingMitigationCloseOutData.fileData['gisInfo'][i].fileStatus.type !== data.value) {
                            changeDetected = true;
                            pendingMitigationCloseOutData.fileData['gisInfo'][i].fileStatus.type = data.value;
                            pendingMitigationCloseOutData.fileData['gisInfo'][i].fileStatus.changed = true;
                        }
                    }
                }
                break;
            case 'mitigationDescStatus':
                if(pendingMitigationCloseOutData.data['mitigationStatus'].type !== data.value) {
                    changeDetected = true;
                    pendingMitigationCloseOutData.data['mitigationStatus'].type = data.value;
                    pendingMitigationCloseOutData.data['mitigationStatus'].changed = true;
                }
                break;
            case 'costAmountStatus':
                if(pendingMitigationCloseOutData.data['costAmountStatus'].type !== data.value) {
                    changeDetected = true;
                    pendingMitigationCloseOutData.data['costAmountStatus'].type = data.value;
                    pendingMitigationCloseOutData.data['costAmountStatus'].changed = true;
                }
                break;
            case 'modProjectStatus':
                if(pendingMitigationCloseOutData.data['modProjectStatus'].type !== data.value) {
                    changeDetected = true;
                    pendingMitigationCloseOutData.data['modProjectStatus'].type = data.value;
                    pendingMitigationCloseOutData.data['modProjectStatus'].changed = true;
                }
                break;
        }

        if(changeDetected) {
            this.setState({pendingMitigationCloseOutData});
        }
    };

    isFileSectionApproved = (fileList:any[]) => {
        let retValue = '';
        if(fileList !== undefined) {
            if (fileList !== null && fileList.length > 0) {
                retValue = fileList[fileList.length - 1].fileStatus.type;
                /*fileList?.map((value: any, key: number) => {
                    if (value.fileStatus.type === 'PENDING' && retValue !== 'REJECTED') {
                        retValue = 'PENDING';
                    }
                    if (value.fileStatus.type === 'REJECTED') {
                        retValue = 'REJECTED';
                    }
                    if (value.fileStatus.type === 'APPROVED' && (retValue === '' || retValue === 'APPROVED')) {
                        retValue = 'APPROVED';
                    }
                });*/
            }
        }
        return retValue;
    };

    updateTotalNetworkUpgradeCostEstimate = (value: number) => {
        let { scertForm } = this.state;
        scertForm.scert.data['upgradeEstCostAmt'] = value;
        this.setState({scertForm});
    }

    getPanes = (scertForm:any) => {
        return [
            {
                menuItem: "Cost Assumptions",
                render: () => (
                    <Tab.Pane >
                        <div>
                            <CostAssumptions
                                scertState={scertForm}
                                inputChange={this.handleInputChange}
                                textAreaChange={this.handleTextAreaChange}
                                radioChange={this.handleRadioChange}
                                updateTotalNetworkUpgradeCostEstimate={this.updateTotalNetworkUpgradeCostEstimate}
                            />
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Line Assumptions",
                render: () => (
                    <Tab.Pane>
                        <div>
                            <LineAssumptions
                                scertState={scertForm}
                                inputChange={this.handleInputChange}
                                textAreaChange={this.handleTextAreaChange}
                                radioChange={this.handleRadioChange}
                            />
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Station Assumptions",
                render: () => (
                    <Tab.Pane>
                        <div>
                            <StationAssumptions
                                scertState={scertForm}
                                inputChange={this.handleInputChange}
                                textAreaChange={this.handleTextAreaChange}
                                radioChange={this.handleRadioChange}
                            />
                        </div>
                    </Tab.Pane>
                )
            }
        ];
    }

    modProjectIdExists = () =>{
        this.setState({modProjectIdCheckInProgress: true});
        if(this.state.scertForm.mitigationPlanCloseout?.data['modProjectId']) {
            this.externalClient.modProjectIdExists(this.state.scertForm.mitigationPlanCloseout?.data['modProjectId'])
                .then((response: boolean) => {
                    this.setState({modProjectIdExists: response, modProjectIdCheckInProgress: false});
                })
                .catch((error:any) => {
                    this.setState({modProjectIdCheckInProgress: false});
                });
        } else {
            this.setState({modProjectIdExists: true, modProjectIdCheckInProgress: false});
        }
    }

    getMainTabPart = (scertForm:any, activeAssumptionTab:number) => {
        return [
            {
                menuItem: "SCERT",
                render: () => (
                    <Tab.Pane >
                        <div>
                            <Grid columns={'equal'} celled={true}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Network Upgrade Name'} fluid={true} disabled={!scertForm.scert?.columnMetaData['upgradeName'].editable} value={scertForm.scert?.data['upgradeName']} inputType={'scert'} onChange={this.handleInputChange} name={'upgradeName'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {scertForm.scert !== null &&
                                        <React.Fragment>
                                            <Label content={'Estimate Creation Date'} className={!scertForm.scert?.columnMetaData['estimateCreationDate'].editable ? 'ui disabled': ''}/>
                                            <ReactDatePicker
                                                disabled={!scertForm.scert?.columnMetaData['estimateCreationDate'].editable}
                                                className={'datepickerinput'}
                                                selected={moment(scertForm.scert?.data['estimateCreationDate']).isValid() ? moment(scertForm.scert?.data['estimateCreationDate'], "MM/DD/YYYY").toDate() : null}
                                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'estimateCreationDate')}
                                                placeholderText={'Select Date'}
                                                autoComplete={'off'}
                                                isClearable={!!scertForm.scert?.data['estimateCreationDate'] && scertForm.scert?.columnMetaData['estimateCreationDate'].editable}
                                                todayButton={'Today'}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                            />
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'High Voltage'} fluid={true} disabled={!scertForm.scert?.columnMetaData['highVoltageKvRate'].editable} value={scertForm.scert?.data['highVoltageKvRate']} inputType={'scert'} onChange={this.handleInputChange} name={'highVoltageKvRate'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {scertForm.scert !== null &&
                                        <React.Fragment>
                                            <Label content={'Estimate Request Date'} className={!scertForm.scert?.columnMetaData['estimateRequestDate'].editable ? 'ui disabled': ''}/>
                                            <ReactDatePicker
                                                disabled={!scertForm.scert?.columnMetaData['estimateRequestDate'].editable}
                                                className={'datepickerinput'}
                                                selected={moment(scertForm.scert?.data['estimateRequestDate']).isValid() ? moment(scertForm.scert?.data['estimateRequestDate'], "MM/DD/YYYY").toDate() : null}
                                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'estimateRequestDate')}
                                                placeholderText={'Select Date'}
                                                autoComplete={'off'}
                                                isClearable={!!scertForm.scert?.data['estimateRequestDate'] && scertForm.scert?.columnMetaData['estimateRequestDate'].editable}
                                                todayButton={'Today'}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                            />
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Low Voltage'} fluid={true} disabled={!scertForm.scert?.columnMetaData['lowVoltageKvRate'].editable} value={scertForm.scert?.data['lowVoltageKvRate']} inputType={'scert'} onChange={this.handleInputChange} name={'lowVoltageKvRate'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {scertForm.scert !== null &&
                                        <React.Fragment>
                                            <Form.Group disabled={true}>
                                            <Label content={'RTO Determined Need Date'} className={!scertForm.scert?.columnMetaData['rtoDeterminedNeedDate'].editable ? 'ui disabled': ''} />
                                            <ReactDatePicker
                                                disabled={!scertForm.scert?.columnMetaData['rtoDeterminedNeedDate'].editable}
                                                className={'datepickerinput'}
                                                selected={moment(scertForm.scert?.data['rtoDeterminedNeedDate']).isValid() ? moment(scertForm.scert?.data['rtoDeterminedNeedDate'], "MM/DD/YYYY").toDate() : null}
                                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'rtoDeterminedNeedDate')}
                                                placeholderText={'Select Date'}
                                                autoComplete={'off'}
                                                isClearable={!!scertForm.scert?.data['rtoDeterminedNeedDate'] && scertForm.scert?.columnMetaData['rtoDeterminedNeedDate'].editable}
                                                todayButton={'Today'}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                            />
                                            </Form.Group>
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Project ID'} fluid={true} disabled={!scertForm.scert?.columnMetaData['projectId'].editable} value={scertForm.scert?.data['projectId']} inputType={'scert'} onChange={this.handleInputChange} name={'projectId'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {scertForm.scert !== null &&
                                        <React.Fragment>
                                            <Label content={'In-Service Date'} className={!scertForm.scert?.columnMetaData['inServiceDate'].editable ? 'ui disabled': ''}/>
                                            <ReactDatePicker
                                                disabled={!scertForm.scert?.columnMetaData['inServiceDate'].editable}
                                                className={'datepickerinput'}
                                                selected={moment(scertForm.scert?.data['inServiceDate']).isValid() ? moment(scertForm.scert?.data['inServiceDate'], "MM/DD/YYYY").toDate() : null}
                                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'inServiceDate')}
                                                placeholderText={'Select Date'}
                                                autoComplete={'off'}
                                                isClearable={!!scertForm.scert?.data['inServiceDate'] && scertForm.scert?.columnMetaData['inServiceDate'].editable}
                                                todayButton={'Today'}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                            />
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Upgrade ID'} fluid={true} disabled={!scertForm.scert?.columnMetaData['upgradeId'].editable} value={scertForm.scert?.data['upgradeId']} inputType={'scert'} onChange={this.handleInputChange} name={'upgradeId'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        {scertForm.scert !== null &&
                                        <React.Fragment>
                                            <Label content={'Network Upgrade Start Date'} className={!scertForm.scert?.columnMetaData['projectStartDate'].editable ? 'ui disabled': ''}/>
                                            <ReactDatePicker
                                                disabled={!scertForm.scert?.columnMetaData['projectStartDate'].editable}
                                                className={'datepickerinput'}
                                                selected={moment(scertForm.scert?.data['projectStartDate']).isValid() ? moment(scertForm.scert?.data['projectStartDate'], "MM/DD/YYYY").toDate() : null}
                                                onChange={(event: any, c: any) => this.onDateChange(event, c, 'projectStartDate')}
                                                placeholderText={'Select Date'}
                                                autoComplete={'off'}
                                                isClearable={!!scertForm.scert?.data['projectStartDate'] && scertForm.scert?.columnMetaData['projectStartDate'].editable}
                                                todayButton={'Today'}
                                                peekNextMonth={true}
                                                showMonthDropdown={true}
                                                showYearDropdown={true}
                                                dropdownMode="select"
                                            />
                                        </React.Fragment>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Project Estimate Stage'} fluid={true} disabled={!scertForm.scert?.columnMetaData['projectEstimateStageName'].editable} value={scertForm.scert?.data['projectEstimateStageName']} inputType={'scert'} onChange={this.handleInputChange} name={'projectEstimateStageName'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input label={'Mitigation Plan'} fluid={true} disabled={!scertForm.scert?.columnMetaData['mitigationPlanDesc'].editable} value={scertForm.scert?.data['mitigationPlanDesc']} inputType={'scert'} onChange={this.handleInputChange} name={'mitigationPlanDesc'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Label content={'Baseline Estimate (Y/N)'}  className={!scertForm.scert?.columnMetaData['baselineEstimateInd'].editable ? 'ui disabled': ''}/>
                                        <Radio className={'scertRadioStyles'} name={'baselineEstimateInd'} label={'Y'} disabled={!scertForm.scert?.columnMetaData['baselineEstimateInd'].editable} checked={scertForm.scert?.data['baselineEstimateInd'] === 'Y'} value={'Y'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        <Radio className={'scertRadioStyles'} name={'baselineEstimateInd'} label={'N'} disabled={!scertForm.scert?.columnMetaData['baselineEstimateInd'].editable} checked={scertForm.scert?.data['baselineEstimateInd'] === 'N'} value={'N'} inputType={'scert'} onChange={this.handleRadioChange} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input label={'Network Upgrade Lead Time (Months)'} fluid={true} disabled={!scertForm.scert?.columnMetaData['upgradeLeadTimeMosCnt'].editable} value={scertForm.scert?.data['upgradeLeadTimeMosCnt']} inputType={'scert'} onChange={this.handleInputChange} name={'upgradeLeadTimeMosCnt'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Total Network Upgrade Cost Estimate'} fluid={true} disabled={!scertForm.scert?.columnMetaData['upgradeEstCostAmt'].editable} value={scertForm.scert?.data['upgradeEstCostAmt']} inputType={'scert'} onChange={this.handleInputChange} name={'upgradeEstCostAmt'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input label={'Estimate Provider (Company)'} fluid={true} disabled={!scertForm.scert?.columnMetaData['facilityOwnerCode'].editable} value={scertForm.scert?.data['facilityOwnerCode']} inputType={'scert'} onChange={this.handleInputChange} name={'facilityOwnerCode'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Final Cost'} fluid={true} disabled={!scertForm.scert?.columnMetaData['upgradeFinalCostAmt'].editable} value={scertForm.scert?.data['upgradeFinalCostAmt']} inputType={'scert'} onChange={this.handleInputChange} name={'upgradeFinalCostAmt'} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Input label={'Point of Contact'} fluid={true} disabled={!scertForm.scert?.columnMetaData['pointOfContactName'].editable} value={scertForm.scert?.data['pointOfContactName']} inputType={'scert'} onChange={this.handleInputChange} name={'pointOfContactName'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Label content={'Network Upgrade Description'} className={!scertForm.scert?.columnMetaData['upgradeDesc'].editable ? 'ui disabled': ''}/>
                                        <TextArea className={'scertTextArea'} disabled={!scertForm.scert?.columnMetaData['upgradeDesc'].editable} value={scertForm.scert?.data['upgradeDesc']} inputType={'scert'} onChange={this.handleTextAreaChange} name={'upgradeDesc'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Label content={'Network Upgrade Scope'} className={!scertForm.scert?.columnMetaData['projectScopeDesc'].editable ? 'ui disabled': ''}/>
                                        <TextArea className={'scertTextArea'} disabled={!scertForm.scert?.columnMetaData['projectScopeDesc'].editable} value={scertForm.scert?.data['projectScopeDesc']} inputType={'scert'} onChange={this.handleTextAreaChange} name={'projectScopeDesc'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Label content={'Estimate Provider Comments'} className={!scertForm.scert?.columnMetaData['estimateProviderCommentsDesc'].editable ? 'ui disabled': ''}/>
                                        <TextArea className={'scertTextArea'} disabled={!scertForm.scert?.columnMetaData['estimateProviderCommentsDesc'].editable} value={scertForm.scert?.data['estimateProviderCommentsDesc']} inputType={'scert'} onChange={this.handleTextAreaChange} name={'estimateProviderCommentsDesc'} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Input label={'Type of SCERT'} fluid={true} disabled={!scertForm.scert?.columnMetaData['typeOfScertName'].editable} value={scertForm.scert?.data['typeOfScertName']} inputType={'scert'} onChange={this.handleInputChange} name={'typeOfScertName'} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <div className={'constructionStatusScertSection'}>
                                <Grid columns={'equal'} celled={true}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={{textAlign:'center'}}>Construction Status</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['engnrDesignStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Engineering/Design Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'engnrDesignStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['engnrDesignStatusDesc'].editable} checked={scertForm.scert?.data['engnrDesignStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'engnrDesignStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['engnrDesignStatusDesc'].editable} checked={scertForm.scert?.data['engnrDesignStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'engnrDesignStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['engnrDesignStatusDesc'].editable} checked={scertForm.scert?.data['engnrDesignStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'engnrDesignStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['engnrDesignStatusDesc'].editable} checked={scertForm.scert?.data['engnrDesignStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['sitingRoutingStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Siting/Routing Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'sitingRoutingStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['sitingRoutingStatusDesc'].editable} checked={scertForm.scert?.data['sitingRoutingStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'sitingRoutingStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['sitingRoutingStatusDesc'].editable} checked={scertForm.scert?.data['sitingRoutingStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'sitingRoutingStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['sitingRoutingStatusDesc'].editable} checked={scertForm.scert?.data['sitingRoutingStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'sitingRoutingStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['sitingRoutingStatusDesc'].editable} checked={scertForm.scert?.data['sitingRoutingStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['envrnmntStudiesStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Environmental Study Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'envrnmntStudiesStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['envrnmntStudiesStatusDesc'].editable} checked={scertForm.scert?.data['envrnmntStudiesStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'envrnmntStudiesStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['envrnmntStudiesStatusDesc'].editable} checked={scertForm.scert?.data['envrnmntStudiesStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'envrnmntStudiesStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['envrnmntStudiesStatusDesc'].editable} checked={scertForm.scert?.data['envrnmntStudiesStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'envrnmntStudiesStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['envrnmntStudiesStatusDesc'].editable} checked={scertForm.scert?.data['envrnmntStudiesStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['permitsStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Permits Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'permitsStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['permitsStatusDesc'].editable} checked={scertForm.scert?.data['permitsStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'permitsStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['permitsStatusDesc'].editable} checked={scertForm.scert?.data['permitsStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'permitsStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['permitsStatusDesc'].editable} checked={scertForm.scert?.data['permitsStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'permitsStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['permitsStatusDesc'].editable} checked={scertForm.scert?.data['permitsStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['materialProcurementStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Material Procurement Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'materialProcurementStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['materialProcurementStatusDesc'].editable} checked={scertForm.scert?.data['materialProcurementStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'materialProcurementStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['materialProcurementStatusDesc'].editable} checked={scertForm.scert?.data['materialProcurementStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'materialProcurementStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['materialProcurementStatusDesc'].editable} checked={scertForm.scert?.data['materialProcurementStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'materialProcurementStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['materialProcurementStatusDesc'].editable} checked={scertForm.scert?.data['materialProcurementStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['constructionStatusDesc'].editable ? {color: 'lightgrey'}: {color: 'black'}}>Construction Phase</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'constructionStatusDesc'} label={'N/A'} disabled={!scertForm.scert?.columnMetaData['constructionStatusDesc'].editable} checked={scertForm.scert?.data['constructionStatusDesc'] === 'N/A'} value={'N/A'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'constructionStatusDesc'} label={'Not Started'} disabled={!scertForm.scert?.columnMetaData['constructionStatusDesc'].editable} checked={scertForm.scert?.data['constructionStatusDesc'] ==='Not Started'} value={'Not Started'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'constructionStatusDesc'} label={'In Progress'} disabled={!scertForm.scert?.columnMetaData['constructionStatusDesc'].editable} checked={scertForm.scert?.data['constructionStatusDesc'] === 'In Progress'} value={'In Progress'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'constructionStatusDesc'} label={'Complete'} disabled={!scertForm.scert?.columnMetaData['constructionStatusDesc'].editable} checked={scertForm.scert?.data['constructionStatusDesc'] === 'Complete'} value={'Complete'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <h4 style={!scertForm.scert?.columnMetaData['inServiceInd'].editable ? {color: 'lightgrey'}: {color: 'black'}}>In-Service:</h4>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Radio className={'scertRadioStyles'} name={'inServiceInd'} label={'Y'} disabled={!scertForm.scert?.columnMetaData['inServiceInd'].editable} checked={scertForm.scert?.data['inServiceInd'] === 'Y'} value={'Y'} inputType={'scert'} onChange={this.handleRadioChange} />
                                            <Radio className={'scertRadioStyles'} name={'inServiceInd'} label={'N'} disabled={!scertForm.scert?.columnMetaData['inServiceInd'].editable} checked={scertForm.scert?.data['inServiceInd'] === 'N'} value={'N'} inputType={'scert'} onChange={this.handleRadioChange} />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                            <div className={'scertAssumptionsDiv'}>
                                <Tab
                                    panes={this.getPanes(scertForm)}
                                    activeIndex={activeAssumptionTab}
                                    onTabChange={this.handleTabChange}
                                />
                            </div>
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "Mitigation Plan/Closeout Info",
                render: () => (
                    <Tab.Pane>
                        <div>
                            <div className={'mitigationPlanSectionDiv'}>
                                <Grid celled={true}>
                                    <Grid.Row>
                                        <Grid.Column width={12}>
                                            <h4 style={{textAlign:'center'}}>Mitigation Plan Information</h4>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <h4 style={{textAlign:'center'}}>Status</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Mitigation Plan Description</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <TextArea className={'scertTextArea'} disabled={!scertForm.mitigationPlanCloseout?.infoMetaData['mitigationPlanDesc'].editable} value={scertForm.mitigationPlanCloseout?.data['mitigationPlanDesc']} inputType={'mitigationPlanCloseout'} onChange={this.handleTextAreaChange} name={'mitigationPlanDesc'} />
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.data['mitigationStatus'] !== null && scertForm.mitigationPlanCloseout?.data['mitigationStatus'] !== undefined &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || scertForm.mitigationPlanCloseout?.data['mitigationStatus'].type === 'APPROVED') &&
                                                <h4 style={{textAlign: 'center'}}>{scertForm.mitigationPlanCloseout?.data['mitigationStatus'].type}</h4>
                                                }
                                                {scertForm.sppAdmin && scertForm.mitigationPlanCloseout?.data['mitigationStatus'].type !== 'APPROVED' &&
                                                <div>
                                                    <Dropdown
                                                        fluid={true}
                                                        name={'mitigationDescStatus'}
                                                        placeholder={'Update Status'}
                                                        loading={this.state.mitigationCloseoutDropdownLoading}
                                                        selection={true}
                                                        value={this.state.pendingMitigationCloseOutData?.data['mitigationStatus'].type}
                                                        options={this.state.mitigationCloseoutStatusOptions}
                                                        onChange={this.mitigationCloseoutStatusChange}
                                                    />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Upload Mitigation Plan (.idv)</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <React.Fragment>
                                                <Button onClick={this.chooseUploadFile} uploadType={'mitigationPlan'} type="button" loading={this.state.uploading['mitigationPlan']} className={"blue"}>
                                                    Upload File
                                                </Button>
                                                <input type="file" id="mitigationPlanUpload" hidden={true} onChange={(e) => this.fileUploaded(e, 'mitigationPlan')} accept={'.idv'}/>
                                                {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('mitigationPlan') ?
                                                    <div>
                                                        {scertForm.mitigationPlanCloseout?.fileData['mitigationPlan']?.map((value: any, key: number) => {
                                                            return (
                                                                    <span className={'mitigationDownloadLinksSpan'}>
                                                                        <span onClick={this.downloadFile}
                                                                              style={{cursor: 'pointer', color: 'blue'}}
                                                                              data-file_id={value.id}>
                                                                            {value.fileName}
                                                                        </span>
                                                                        <span>
                                                                            <Icon name={'times'} onClick={this.deleteFile}
                                                                                  data-file_id={value.id}
                                                                                  data-upload_type={'mitigationPlan'}
                                                                                  style={{cursor: 'pointer', color: 'blue'}} />
                                                                        </span>
                                                                    </span>
                                                            );
                                                        })}
                                                    </div>
                                                    : <div />
                                                }
                                            </React.Fragment>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('mitigationPlan') &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['mitigationPlan']) === 'APPROVED') &&
                                                    <h4 style={{textAlign:'center'}}>{this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['mitigationPlan'])}</h4>
                                                }
                                                {scertForm.sppAdmin && this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['mitigationPlan']) !== 'APPROVED' &&
                                                    <div>
                                                        <Dropdown
                                                            fluid={true}
                                                            name={'mitigationUploadStatus'}
                                                            placeholder={'Update Status'}
                                                            loading={this.state.mitigationCloseoutDropdownLoading}
                                                            selection={true}
                                                            value={this.isFileSectionApproved(this.state.pendingMitigationCloseOutData?.fileData['mitigationPlan'])}
                                                            options={this.state.mitigationCloseoutStatusOptions}
                                                            onChange={this.mitigationCloseoutStatusChange}
                                                        />
                                                    </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                            <div className={'closeOutSectionDiv'}>
                                <Grid celled={true}>
                                    <Grid.Row>
                                        <Grid.Column width={12}>
                                            <h4 style={{textAlign:'center'}}>Close Out Information</h4>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <h4 style={{textAlign:'center'}}>Status</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Upload Letter of Commercial Operation</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <React.Fragment>
                                                <Button onClick={this.chooseUploadFile} uploadType={'loco'} type="button" loading={this.state.uploading['loco']} className={"blue"}>
                                                    Upload File
                                                </Button>
                                                <input type="file" id="locoUpload" hidden={true} onChange={(e) => this.fileUploaded(e, 'loco')} accept={'.doc,.pdf'}/>
                                                {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('loco') ?
                                                    <div>
                                                        {scertForm.mitigationPlanCloseout?.fileData['loco']?.map((value: any, key: number) => {
                                                            return (
                                                                <span className={'mitigationDownloadLinksSpan'}>
                                                                    <span onClick={this.downloadFile}
                                                                          style={{cursor: 'pointer', color: 'blue'}}
                                                                          data-file_id={value.id}>
                                                                        {value.fileName}
                                                                    </span>
                                                                    <span>
                                                                        <Icon name={'times'} onClick={this.deleteFile}
                                                                              data-file_id={value.id}
                                                                              data-upload_type={'loco'}
                                                                              style={{cursor: 'pointer', color: 'blue'}}/>
                                                                    </span>
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                    : <div />
                                                }
                                            </React.Fragment>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('loco') &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['loco']) === 'APPROVED') &&
                                                <h4 style={{textAlign:'center'}}>{this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['loco'])}</h4>
                                                }
                                                {scertForm.sppAdmin && this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['loco']) !== 'APPROVED' &&
                                                <div>
                                                    <Dropdown
                                                        fluid={true}
                                                        name={'locoUploadStatus'}
                                                        placeholder={'Update Status'}
                                                        loading={this.state.mitigationCloseoutDropdownLoading}
                                                        selection={true}
                                                        value={this.isFileSectionApproved(this.state.pendingMitigationCloseOutData?.fileData['loco'])}
                                                        options={this.state.mitigationCloseoutStatusOptions}
                                                        onChange={this.mitigationCloseoutStatusChange}
                                                    />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Cost Amount to-date</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Input fluid={true} type={'number'} disabled={!scertForm.mitigationPlanCloseout?.infoMetaData['costAmountToDate'].editable} value={scertForm.mitigationPlanCloseout?.data['costAmountToDate']} inputType={'mitigationPlanCloseout'} onChange={this.handleInputChange} name={'costAmountToDate'} />
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.data['costAmountStatus'] !== null && scertForm.mitigationPlanCloseout?.data['costAmountStatus'] !== undefined &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || scertForm.mitigationPlanCloseout?.data['costAmountStatus'].type === 'APPROVED') &&
                                                <h4 style={{textAlign: 'center'}}>{scertForm.mitigationPlanCloseout?.data['costAmountStatus'].type}</h4>
                                                }
                                                {scertForm.sppAdmin && scertForm.mitigationPlanCloseout?.data['costAmountStatus'].type !== 'APPROVED' &&
                                                <div>
                                                    <Dropdown
                                                        fluid={true}
                                                        name={'costAmountStatus'}
                                                        placeholder={'Update Status'}
                                                        loading={this.state.mitigationCloseoutDropdownLoading}
                                                        selection={true}
                                                        value={this.state.pendingMitigationCloseOutData?.data['costAmountStatus'].type}
                                                        options={this.state.mitigationCloseoutStatusOptions}
                                                        onChange={this.mitigationCloseoutStatusChange}
                                                    />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Models on Demand (MOD) project ID</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <Input error={!this.state.modProjectIdExists} onBlur={this.modProjectIdExists} fluid={true} type={'number'} disabled={!scertForm.mitigationPlanCloseout?.infoMetaData['modProjectId'].editable} value={scertForm.mitigationPlanCloseout?.data['modProjectId']} inputType={'mitigationPlanCloseout'} onChange={this.handleInputChange} name={'modProjectId'} />
                                            {!this.state.modProjectIdExists && <div style={{color: '#FF7F7F'}} >MOD Project Id Does Not Exist</div>}
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.data['modProjectStatus'] !== null && scertForm.mitigationPlanCloseout?.data['modProjectStatus'] !== undefined &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || scertForm.mitigationPlanCloseout?.data['modProjectStatus'].type === 'APPROVED') &&
                                                <h4 style={{textAlign: 'center'}}>{scertForm.mitigationPlanCloseout?.data['modProjectStatus'].type}</h4>
                                                }
                                                {scertForm.sppAdmin && scertForm.mitigationPlanCloseout?.data['modProjectStatus'].type !== 'APPROVED' &&
                                                <div>
                                                    <Dropdown
                                                        fluid={true}
                                                        name={'modProjectStatus'}
                                                        placeholder={'Update Status'}
                                                        loading={this.state.mitigationCloseoutDropdownLoading}
                                                        selection={true}
                                                        value={this.state.pendingMitigationCloseOutData?.data['modProjectStatus'].type}
                                                        options={this.state.mitigationCloseoutStatusOptions}
                                                        onChange={this.mitigationCloseoutStatusChange}
                                                    />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={6}>
                                            <h4>Upload GIS info (shape or .kmz)</h4>
                                        </Grid.Column>
                                        <Grid.Column width={6}>
                                            <React.Fragment>
                                                <Button onClick={this.chooseUploadFile} uploadType={'gisInfo'} type="button" loading={this.state.uploading['gisInfo']} className={"blue"}>
                                                    Upload File
                                                </Button>
                                                <input type="file" id="gisInfoUpload" hidden={true} onChange={(e) => this.fileUploaded(e, 'gisInfo')} accept={'.kmz,.shp'}/>
                                                {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('gisInfo') ?
                                                    <div>
                                                        {scertForm.mitigationPlanCloseout?.fileData['gisInfo']?.map((value: any, key: number) => {
                                                            return (
                                                                <span className={'mitigationDownloadLinksSpan'}>
                                                            <span onClick={this.downloadFile}
                                                                  style={{cursor: 'pointer', color: 'blue'}}
                                                                  data-file_id={value.id}>
                                                                {value.fileName}
                                                            </span>
                                                            <span>
                                                                <Icon name={'times'} onClick={this.deleteFile}
                                                                      data-file_id={value.id}
                                                                      data-upload_type={'gisInfo'}
                                                                      style={{cursor: 'pointer', color: 'blue'}}/>
                                                            </span>
                                                        </span>
                                                            );
                                                        })}
                                                    </div>
                                                    : <div />
                                                }
                                            </React.Fragment>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            {scertForm.mitigationPlanCloseout?.fileData !== null && scertForm.mitigationPlanCloseout?.fileData !== undefined && Object.keys(scertForm.mitigationPlanCloseout?.fileData).length > 0 && scertForm.mitigationPlanCloseout?.fileData.hasOwnProperty('gisInfo') &&
                                            <React.Fragment>
                                                {(!scertForm.sppAdmin || this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['gisInfo']) === 'APPROVED') &&
                                                <h4 style={{textAlign:'center'}}>{this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['gisInfo'])}</h4>
                                                }
                                                {scertForm.sppAdmin && this.isFileSectionApproved(scertForm.mitigationPlanCloseout?.fileData['gisInfo']) !== 'APPROVED' &&
                                                <div>
                                                    <Dropdown
                                                        fluid={true}
                                                        name={'gisInfoUploadStatus'}
                                                        placeholder={'Update Status'}
                                                        loading={this.state.mitigationCloseoutDropdownLoading}
                                                        selection={true}
                                                        value={this.isFileSectionApproved(this.state.pendingMitigationCloseOutData?.fileData['gisInfo'])}
                                                        options={this.state.mitigationCloseoutStatusOptions}
                                                        onChange={this.mitigationCloseoutStatusChange}
                                                    />
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>
                    </Tab.Pane>
                )
            }
        ];
    }

    render() {
        const { activeAssumptionTab, activeMainTab, scertForm } = this.state;

        return (
            <React.Fragment>
                <Button className={'scertFormBackButton'} icon={true} labelPosition='left' onClick={this.backToGrid}>
                    <Icon name={'arrow left'} />
                    Back to SCERT Grid
                </Button>
                <div className={'scertStatusClass'}>{scertForm.scertActionsResponse.notification}</div>
                <Segment raised={true}>
                    <Tab
                        panes={this.getMainTabPart(scertForm, activeAssumptionTab)}
                        activeIndex={activeMainTab}
                        onTabChange={this.handleMainTabChanges}
                    />
                    <Dimmer className={'pageLoader'} active={this.state.formLoading} inverted={true}>
                        <Loader size='large'>Loading</Loader>
                    </Dimmer>
                </Segment>
                <div className={'scertFormButtonsDiv'}>
                    {scertForm.scertActionsResponse.canApprove && activeMainTab === 0 &&
                        <Button floated={'left'} icon={true} labelPosition='left' status={'Approve'} onClick={this.takeActionOnScert}>
                            <Icon name={'thumbs up'}/>
                            Approve
                        </Button>
                    }
                    {scertForm.scertActionsResponse.canReject && activeMainTab === 0 &&
                        <Button floated={'left'} icon={true} labelPosition='left' status={'Reject'} onClick={this.takeActionOnScert}>
                            <Icon name={'thumbs down'}/>
                            Reject
                        </Button>
                    }
                    {scertForm.scertActionsResponse.canSave && activeMainTab === 0 &&
                        <Button floated={'right'} icon={true} labelPosition='left' status={'Save'} onClick={this.takeActionOnScert}>
                            <Icon name={'save'}/>
                            Save
                        </Button>
                    }
                    {scertForm.scertActionsResponse.canSubmit && activeMainTab === 0 &&
                        <Button floated={'right'} icon={true} labelPosition='left' status={'Submit'} onClick={this.takeActionOnScert}>
                            <Icon name={'save'}/>
                            Submit
                        </Button>
                    }
                    {activeMainTab === 1 &&
                        <Button floated={'right'} icon={true} labelPosition='left' onClick={this.saveMitigationCloseOutInfo} disabled={!this.state.modProjectIdExists || this.state.modProjectIdCheckInProgress}>
                            <Icon name={'save'}/>
                            Save Mitigation/Closeout Info
                        </Button>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(ScertForm);