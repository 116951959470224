import * as React from 'react';
import {Container, Header, Segment} from "semantic-ui-react";
//import Cookies from "universal-cookie";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import OpenApi from "../services/open-source-service";
import {createBreadCrumbBasedOnPage} from "../Utils/HelperFunctions";
import * as actionTypes from "../store/action";

interface HomeProps {
    loggedIn: boolean;
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface HomeState {
    portalLink: string;
    componentLoaded: boolean;
}

//const cookie = new Cookies();
class Home extends React.Component<HomeProps, HomeState> {
    private client: OpenApi;

    constructor(props: HomeProps) {
        super(props);
        this.state = {
            portalLink: '',
            componentLoaded: false
        };

        this.client = new OpenApi();
    }

    componentDidMount(): void {
        /*if(!cookie.get('sppauthzdev')) {
            toast.info('cannot find cookie');
        }*/
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        if(!this.props.loggedIn) {
            this.getMarketPlacePortalLink();
        }
    }

    componentDidUpdate(prevProps: Readonly<HomeProps>, prevState: Readonly<HomeState>) {
        if(prevProps.loggedIn !== this.props.loggedIn) {
            if(!this.props.loggedIn) {
                this.getMarketPlacePortalLink();
            }
        }
    }

    getMarketPlacePortalLink = () => {
        this.client.getPortalLink()
            .then((response:string) => {
                this.setState({portalLink:response, componentLoaded: true});
            });
    };

    public render() {

        return (
            <Container fluid={true}>
                <Header as={'h1'} content={'Transmission Reporting And Communication'} />
                <Container fluid={true}>
                    <div>
                        Welcome to TRAC Home Page
                    </div>
                    {this.state.componentLoaded && !this.props.loggedIn &&
                        <div className={'unAuthenticatedHomeMessage'}>
                            <p>User not currently authenticated.  Please visit <a className={'marketPlacePortalLink'} target={'_blank'} href={this.state.portalLink}>MarketPlace Portal</a> to login.  Then refresh TRAC browser page to be authenticated.</p>
                        </div>
                    }
                </Container>
            </Container>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);