import * as React from 'react';
import {connect} from "react-redux";
import {
    Button,
    Container,
    Grid,
    Input,
    InputProps,
    Segment,
} from "semantic-ui-react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import {ReactHTML} from "react";
import {toast} from "react-toastify";

interface ServiceAgreementComponentProps {
    updateServiceAgreement: (newAgreements:any) => void
    startingAgreement:any
}

interface ServiceAgreementComponentState {
    serviceAgreements: NtcServiceAgreement[];
    newAgreementDate: any
    newAgreementName: string
}

class ServiceAgreementComponent extends React.Component<ServiceAgreementComponentProps,ServiceAgreementComponentState> {

    constructor(props: ServiceAgreementComponentProps) {
        super(props);
        this.state = {
            serviceAgreements: this.props.startingAgreement,
            newAgreementDate: null,
            newAgreementName: ''

        };
    }

    componentDidMount(): void {

    }

    componentDidUpdate(prevProps: Readonly<ServiceAgreementComponentProps>): void {
        if (this.props.startingAgreement && this.props.startingAgreement !== prevProps.startingAgreement) {
            debugger;
            this.setState({serviceAgreements:this.props.startingAgreement})
            debugger;
        }
    }

    removeServiceAgreement = (e: any, buttonState: any) =>{
        let newServiceAgreements = this.state.serviceAgreements;
        newServiceAgreements.splice(buttonState.index, 1)
        this.setState({serviceAgreements:  newServiceAgreements});
        this.props.updateServiceAgreement(this.state.serviceAgreements);
    }

    createAgreementObject = () =>{
        if(this.state.newAgreementName == '' || this.state.newAgreementDate == null){
            toast.info("You must provide an agreement name and date to continue...");
            return
        }
        let newAgreementList: NtcServiceAgreement[] = [];
        let newServiceAgreement: NtcServiceAgreement = {
            agreementName: this.state.newAgreementName,
            agreementEffectiveDate: this.state.newAgreementDate
        };
        /*newAgreementObject.push(this.state.newAgreementName);
        newAgreementObject.push(this.state.newAgreementDate);*/
        let prevServiceAgreementList = this.state.serviceAgreements;
        prevServiceAgreementList.push(newServiceAgreement);
        this.setState({serviceAgreements: prevServiceAgreementList})
        this.props.updateServiceAgreement(prevServiceAgreementList);

        //clear state so new agreement information box is empty
        this.setState({newAgreementName: '', newAgreementDate: ''})
    }

    nameChange = (event: React.ChangeEvent<HTMLInputElement>, data:InputProps) =>{
        this.setState({newAgreementName: data.value})
    }

    onDateChange = (e:any) => {
        this.setState({newAgreementDate: e} as any);
    };

    render() {
        return (
            <div>
                <Segment>
                <h4>Service Agreement Information</h4>
                <div>
                    {this.state.serviceAgreements?.map((agreement:NtcServiceAgreement, index:any) => {
                        return (
                            <Grid key={index}>
                                <Grid.Column width={3}>
                                    <Input type={'string'} index={index}
                                           value={agreement.agreementName} disabled={true}/>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <ReactDatePicker
                                        className={'datepickerinput'}
                                        selected={moment(agreement.agreementEffectiveDate, "MM/DD/YYYY").toDate()}
                                        onChange={(event: any) => this.onDateChange(event)}
                                        placeholderText={'Select Agreement Date'}
                                        autoComplete={'off'}
                                        todayButton={'Today'}
                                        peekNextMonth={true}
                                        showMonthDropdown={true}
                                        showYearDropdown={true}
                                        dropdownMode="select"
                                        disabled={true}
                                    />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    <React.Fragment>
                                        <Button type={'button'} size={"mini"} compact={true} basic={true}
                                                icon={'minus'} color={'red'} onClick={this.removeServiceAgreement} index={index}/>
                                    </React.Fragment>
                                </Grid.Column>
                            </Grid>
                        )
                    })
                    }


                </div>
            <Container fluid={true}>
            <Grid>
                <Grid.Column width={3}>
                    <Input placeholder={'Agreement Name'} onChange={this.nameChange} value={this.state.newAgreementName}/>
                </Grid.Column>
                <Grid.Column width={3}>
                    <ReactDatePicker
                        className={'datepickerinput'}
                        selected={this.state.newAgreementDate? moment(this.state.newAgreementDate, "MM/DD/YYYY").toDate(): null}
                        onChange={(event: any) => this.onDateChange(event)}
                        placeholderText={'Select Agreement Date'}
                        autoComplete={'off'}
                        todayButton={'Today'}
                        peekNextMonth={true}
                        showMonthDropdown={true}
                        showYearDropdown={true}
                        dropdownMode="select"
                    />
                </Grid.Column>
                <Grid.Column width={1}>
                        <Button type={'button'} size={"mini"} compact={true} basic={true}
                                icon={'plus'} color={'green'} onClick={this.createAgreementObject}/>

                </Grid.Column>
            </Grid>
                <br/>
                <p style={{color: 'red'}}>* Click the "+" to add the service agreement.</p>
            </Container>
                </Segment>
        </div>
        )
    }
}

export default connect(null, null)(ServiceAgreementComponent);