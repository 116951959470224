import * as React from 'react';
import AdminService from "../../services/admin-service";
import {Container, Dimmer, Dropdown, DropdownProps, Header, Loader, Segment,Tab,Grid,Button,Checkbox, GridColumn, Icon} from "semantic-ui-react";
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import GridApi from "../../services/grid-service";

import {connect} from "react-redux";
import {toast} from "react-toastify";
import {Redirect} from "react-router";

import TracModal from "../GenericComponents/TracModal";

interface AdminPageProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
    loggedIn: boolean;
    setLoggedIn: (loggedIn:boolean) => void;
}

interface AdminPageState {
    typeTableOptions: DropDownItem[];
    typeTable: string;
    userAuthorized: boolean;
    pageLoading: boolean;
    activeIndex:number;
    data:[];
    originalData: [];
    metaData: [];
    modelOpen:boolean;
    selectedCheckboxes:any
}

class AdminPage extends React.Component<AdminPageProps,AdminPageState> {
    private client: AdminService;
    private gridClient: GridApi;

    constructor(props: AdminPageProps) {
        super(props);
        this.state = {
            typeTableOptions: [],
            typeTable: '',
            userAuthorized: false,
            pageLoading: true,
            activeIndex:0,
            data: [],
            originalData: [],
            metaData: [],
            modelOpen:false,
            selectedCheckboxes:'',
        };

        this.client = new AdminService();
        this.gridClient = new GridApi();
    }

    componentDidMount() {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
        this.getTypeTableOptions();
      //  this.getData();
    };

    getTypeTableOptions = () => {
        this.client.getTypeTableOptions()
            .then((response:DropDownItem[]) => {
                this.setState({typeTableOptions:response, userAuthorized: true, pageLoading: false});
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
                    this.setState({userAuthorized:false, pageLoading: false});
                }
                if(!error.response.data.badToken) {
                    toast.error(`Error retrieving data from the server - ${error.response.data.message}`)
                }
                else {
                    this.props.setLoggedIn(false);
                }
        });
    };

    dropDownChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
        this.setState({[data.name.toString()]: data.value} as any);
    };

    render() {

        const { activeIndex } = this.state;
     
        const panes = [
            {
                menuItem: "Admin",
                render: () => (
                    <Tab.Pane >

                        <div>
                            {this.adminPage()}
                        </div>
                    </Tab.Pane>
                )
            },
            {
                menuItem: "SCERT Admin Tasks",
                render: () => (
                    <Tab.Pane>
                        <div>
                            {this.scertAdminTaskPage()}
                        </div>

                    </Tab.Pane>
                )
            }
        ];
        return (
            <div>
                <Tab
                    panes={panes}
                    activeIndex={activeIndex}
                    onTabChange={this.handleTabChange}
                />
                <div >
                <TracModal className="modalProp" header={this.ModalHeader('Update Quarterly Review Checkboxes')} content={this.modelContent} actions={this.modalActions}
                    open={this.state.modelOpen} closeOnDimmerClick={true} closeIcon={true}
                    onClose={this.ModalClose}
                />
                </div>
              
            </div>
        );
    }

    handleTabChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
   

        this.setState({ activeIndex: data.activeIndex });
    }
    scertAdminTaskPage = () => {
         return (
            <Grid >
               
                <div className="quarterlyReviewClearBtn">
                    <Button onClick={this.updateQuarterlyReviewCheckboxes} primary>Clear All Quarterly Reviews Checkboxes</Button>
                   
                </div>

            </Grid>
        )
    }
    submitQuarterlyReviewCheckboxes=()=>{
     this.gridClient.updateGridCheckbox("/api/auth/admin/updateQuarterlyReviewChecboxes", [])
            .then((response:any) => {
                              
                    this.setState({
                        selectedCheckboxes:'',
                        userAuthorized: true,
                        pageLoading: false
                    });
                    this.ModalClose();
                    toast.success(response + ' records updated');
            })
            .catch((error:any) => {
                if(error.response.status === 403) {
             
                }
                if(error.response.data.badToken) {
                    this.props.setLoggedIn(false);
                }
           
            });
    }
    modalActions = () => {
        return (
            <div className={'gridFormActionDiv'}>
                <Button content={'Ok'} color={'green'}  onClick={this.submitQuarterlyReviewCheckboxes}/>
                <Button content={'Cancel'} color={'red'} onClick={this.ModalClose} />
            </div>
        )
    }
    modelContent = () => {
        return (
            <div id="contentId">
                <p>Are you sure you want to clear all checkboxes ?</p>
            </div>
        )
    }

    ModalClose = () => {
        this.setState({ modelOpen: false })
    }
    updateQuarterlyReviewCheckboxes = () => {
        this.setState({modelOpen:true})
    }

    ModalHeader = (header: string) => {
        return (
            <Header as='h3'>
                <Icon name={'comment'} />
                {header}
            </Header>
        )
    };  
    filterArrayElementByEdit = (array :any, field :any,value:any) => {
        let indexValue;
        return array.filter((element :any,index:any) => {
            console.log(index)
          if(element.upgradeId === field){
              indexValue=index;
             
          }
        })
      }
    // handleChange = (e :any, tempData :any) => {
      
    //     const {name,value,checked}=tempData;
    //     this.setState({
    //         selectedCheckboxes: [
    //           ...this.state.selectedCheckboxes,
    //           {"upgradeId":name,"isChecked":checked}
    //         ]
    //       })
    //    console.log(this.state.selectedCheckboxes)
        
    //     let shallowCopyTemp :any= [...this.state.data] ;
    //     let indexVal;
    //     shallowCopyTemp.filter((element :any,index:any) => {
         
    //       if(element.upgradeId === name){
    //           indexVal=index;            
    //           element.quarterlyReviewInd=checked;
    //           shallowCopyTemp[indexVal]=element;
    //           return;
             
             
    //       }
    //     })
       
    //     this.setState({ data: shallowCopyTemp})
     
    //   }

    adminPage=()=>{
    return (
        <Container fluid={true}>
        <Segment basic={true}>
            {this.props.loggedIn && this.state.userAuthorized &&
                <React.Fragment>
                    <Header content={'Admin'}/>
                        <Dropdown className={'typeTableDropDown'} options={this.state.typeTableOptions} name={'typeTable'}
                        value={this.state.typeTable} onChange={this.dropDownChange} search={true}
                        selection={true} clearable={true} placeholder={'Select Type Table'}/>
                    {this.state.typeTable !== '' &&
                        <GridContainer getGridDataUrl={'/api/auth/admin/typetable/' + this.state.typeTable}
                        gridPaginated={false} exportLink={'admin/typetable/' + this.state.typeTable}
                        gridTitle={this.state.typeTable} gridId={this.state.typeTable + 'Grid'}
                        showUtilityColumns={true}
                        addRecordUrl={'/api/auth/admin/saveTypeRecord/' + this.state.typeTable}
                        editRecordUrl={'/api/auth/admin/editTypeRecord/' + this.state.typeTable}
                        deleteRecordUrl={'/api/auth/admin/removeTypeRecord/' + this.state.typeTable}
                        typeForSuperClass={this.state.typeTable}
                        />
                    }
                </React.Fragment>
            }

            <Dimmer className={'pageLoader'} active={this.state.pageLoading} inverted={true}>
                <Loader size='large'>Loading</Loader>
            </Dimmer>
        </Segment>
    </Container>
    )
}

}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoggedIn: (loggedIn: boolean) => dispatch(
            {type: actionTypes.LOGGED_IN, payload: loggedIn}),
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);