import * as React from 'react';
import {
    Header,
    Comment,
    Loader,
    Dimmer,
    Card,
    Container,
    Label,
    Segment,
    Divider,
    Button,
    Icon, ButtonProps
} from "semantic-ui-react";
import NotificationPost from "./NotificationPost";
import {toast} from "react-toastify";
import generalApi from "../../services/general-services";
import InitialsSegment from "../GenericComponents/InitialsSegment";
import moment from "moment";
import {Redirect} from "react-router";
import {connect} from "react-redux";

interface NotificationPageProps {
    loggedIn: boolean;
}

interface NotificationPageState {
    notificationFeed: NotificationFeed
    recordsSeen: number
    feedPage: number
    feedSize: number
    recordsComeInSinceLastFetch: number
    loadingRecords: boolean;
    parentNotificationId: number | null;
    toUser: string;
    postNotificationOpen: boolean
}

class NotificationPage extends React.Component<NotificationPageProps, NotificationPageState> {
    private client: generalApi;

    constructor(props: NotificationPageProps) {
        super(props);
        this.state = {
            notificationFeed: {
                notificationFeedRecords: [],
                notificationRecordCount: 0
            },
            feedPage: 0,
            feedSize: 10,
            recordsSeen: 0,
            recordsComeInSinceLastFetch: 0,
            loadingRecords: true,
            parentNotificationId: null,
            postNotificationOpen: false,
            toUser: ''
        };
        this.client = new generalApi();
    }

    componentDidMount(): void {
        this.getNotificationRecords(null);
    }

    getNotificationRecords = (e:any) => {
        if(e !== null) {
            e.stopPropagation();
        }
        /*const params = new URLSearchParams();
        params.append('page', this.state.feedPage !== null ? this.state.feedPage.toString() : '0');
        params.append('size', this.state.feedSize !== null ? this.state.feedSize.toString() : '10');
        params.append("ui","true");
        let filter = {};*/
        let page = this.state.feedPage !== null ? this.state.feedPage : 0;
        let size = this.state.feedSize !== null ? this.state.feedSize : 10;
        this.client.getNotificationRecords(page, size, 'page')
            .then((resp:NotificationFeed) => {
                console.log(resp);
                let currentFeed = this.state.notificationFeed;
                let recordsSeenSoFar = this.state.recordsSeen;
                if(currentFeed.notificationFeedRecords.length > 0) {
                    if(resp.notificationFeedRecords.length === size) {
                        page += 1;
                    }
                    for(let i = 0; i < resp.notificationFeedRecords.length; i++) {
                        if(i >= this.state.recordsComeInSinceLastFetch) {
                            currentFeed.notificationFeedRecords.push(resp.notificationFeedRecords[i]);
                        }
                    }

                    currentFeed.notificationRecordCount = resp.notificationRecordCount;
                    recordsSeenSoFar += resp.notificationFeedRecords.length;
                    this.setState({notificationFeed:currentFeed, recordsSeen: recordsSeenSoFar, feedPage: page, feedSize: size, loadingRecords:false});
                }
                else {
                    if(resp.notificationFeedRecords !== null) {
                        if (resp.notificationFeedRecords.length === size) {
                            page += 1;
                        }
                    }
                    this.setState({
                        notificationFeed: resp.notificationFeedRecords !== null ? resp : currentFeed,
                        recordsSeen: resp.notificationFeedRecords !== null ? resp.notificationFeedRecords.length : 0,
                        feedPage: page,
                        feedSize: size,
                        loadingRecords:false
                    });
                }
            })
            .catch((error:any) => {
                toast.error(error);
                this.setState({loadingRecords: false});
            });
        this.setState({loadingRecords:true});
    };

    setPostModalState = (modalOpen:boolean) => {
        this.setState({postNotificationOpen:modalOpen});
    };

    replyToNotification = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        if(event !== null) {
            event.stopPropagation();
        }

        this.setState({parentNotificationId:data.parentNotificationId, toUser:data.toUser, postNotificationOpen: true});
    };

    displayNotifications = () => {
        let notifications;
        if(this.state.notificationFeed.notificationFeedRecords?.length > 0) {
            notifications = this.state.notificationFeed.notificationFeedRecords.map((item: NotificationFeedRecord, key: any) => {
                return (
                    <Comment>
                        <div>
                            <InitialsSegment
                                className={'inlineSegment avatarAtTop'}
                                avatarColor={item.userAvatar}
                                userInitials={item.notificationFromInitials}
                                size={'mini'}
                            />
                        </div>
                        <Comment.Content className={'commentContent'}>
                            <Comment.Author as='a'>{item.notificationAuthor}</Comment.Author>
                            <Comment.Metadata>
                                <span>{moment(item.notificationDate).format("MM/DD/YYYY h:mm:ss a")}</span>
                            </Comment.Metadata>
                            <Comment.Text>
                                <p>{item.notificationText}</p>
                            </Comment.Text>
                            <Comment.Actions>
                                <Button className={'markReadButton'} onClick={this.replyToNotification}
                                        parentNotificationId={item.notificationId} toUser={item.notificationAuthor} content={'Reply'}>
                                </Button>
                            </Comment.Actions>
                        </Comment.Content>
                        {item.childrenNotifications.length > 0 &&
                            this.recursivelyGetReplies(item, true)
                        }
                    </Comment>
                )
            })
        }

        return notifications;
    };

    recursivelyGetReplies = (item:NotificationFeedRecord, topLevel:boolean) => {
        return (
            <Comment.Group>
                {item.childrenNotifications.map((reply: NotificationFeedRecord, key: any) => {
                    return (
                        <Comment>
                            <div>
                                <InitialsSegment
                                    className={'inlineSegment avatarAtTop'}
                                    avatarColor={reply.userAvatar}
                                    userInitials={reply.notificationFromInitials}
                                    size={'mini'}
                                />
                            </div>
                            <Comment.Content className={'commentContent'}>
                                <Comment.Author as='a'>{reply.notificationAuthor}</Comment.Author>
                                <Comment.Metadata>
                                    <span>{moment(reply.notificationDate).format("MM/DD/YYYY h:mm:ss a")}</span>
                                </Comment.Metadata>
                                <Comment.Text>
                                    <p>{reply.notificationText}</p>
                                </Comment.Text>
                                <Comment.Actions>
                                    <Button className={'markReadButton'} onClick={this.replyToNotification}
                                            parentNotificationId={reply.notificationId} toUser={reply.notificationAuthor} content={'Reply'}>
                                    </Button>
                                </Comment.Actions>
                            </Comment.Content>
                            {reply.childrenNotifications.length > 0 &&
                            this.recursivelyGetReplies(reply, true)
                            }
                        </Comment>
                    )
                    /*if(reply.childrenNotifications.length === 0) {
                        return (
                            <Comment>
                                <div>
                                    <InitialsSegment
                                        className={'inlineSegment avatarAtTop'}
                                        avatarColor={reply.userAvatar}
                                        userInitials={reply.notificationFromInitials}
                                        size={'mini'}
                                    />
                                </div>
                                <Comment.Content className={'commentContent'}>
                                    <Comment.Author as='a'>{reply.notificationAuthor}</Comment.Author>
                                    <Comment.Metadata>
                                        <span>{moment(reply.notificationDate).format("MM/DD/YYYY h:mm:ss a")}</span>
                                    </Comment.Metadata>
                                    <Comment.Text>
                                        <p>{reply.notificationText}</p>
                                    </Comment.Text>
                                    <Comment.Actions>
                                        <Button className={'markReadButton'} onClick={this.replyToNotification}
                                                parentNotificationId={reply.notificationId} toUser={reply.notificationAuthor} content={'Reply'}>
                                        </Button>
                                    </Comment.Actions>
                                </Comment.Content>
                                {reply.childrenNotifications.length > 0 &&
                                    this.recursivelyGetReplies(reply, true)
                                }
                            </Comment>
                        )
                    }
                    else {
                        return this.recursivelyGetReplies(reply, false);
                    }*/
                })}
            </Comment.Group>
        )
    };

    public render() {
        /*if(!this.props.loggedIn) {
            return <Redirect to={'/'} />
        }*/

        return (
            <Segment basic={true}>
                <Header as='h3' dividing>
                    Notifications
                </Header>
                <Comment.Group className={'notificationPage disable-scrollbars'} threaded={true}>

                    {this.displayNotifications()}

                    {this.state.recordsSeen < this.state.notificationFeed.notificationRecordCount && this.state.notificationFeed.notificationFeedRecords.length > 0 &&
                        <div>
                            <Divider />
                            <Segment textAlign={'center'} basic={true} className={'showMoreNotificationsSegment'}>
                                <Button onClick={(e:any) => this.getNotificationRecords(e)} content={'Show More'} />
                            </Segment>
                        </div>
                    }
                    {this.state.recordsSeen === this.state.notificationFeed.notificationRecordCount && this.state.notificationFeed.notificationFeedRecords.length > 0 &&
                        <div>
                            <Divider />
                            <Segment textAlign={'center'} basic={true} className={'showMoreNotificationsSegment'}>
                                <Label content={'No More Records Available'} />
                            </Segment>
                        </div>
                    }
                    {this.state.notificationFeed.notificationFeedRecords?.length === 0 &&
                        <Card.Content>
                            <Container textAlign={'center'} fluid={true}>
                                <Label>No Records Found.</Label>
                            </Container>
                        </Card.Content>
                    }
                </Comment.Group>
                <NotificationPost saveEndpoint={'/api/auth/saveNotification'} />
                <Dimmer active={this.state.loadingRecords} inverted={true}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <NotificationPost saveEndpoint={'/api/auth/saveNotification'}
                                  notificationInModal={true}
                                  notificationPostModalOpen={this.state.postNotificationOpen}
                                  setPostModalState={this.setPostModalState}
                                  parentNotificationId={this.state.parentNotificationId}
                                  replyNotificationModal={true}
                                  toUser={this.state.toUser}
                />
            </Segment>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps, null)(NotificationPage);
