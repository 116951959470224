import * as React from 'react';
//import {NavLink, Link} from 'react-router-dom';
import {Container, Menu} from "semantic-ui-react";
import generalApi from "../../services/general-services";
import MenuItemComponent from "./MenuItemComponent";
import NavCalendar from "./NavCalendar";
import {connect} from "react-redux";

interface SideNavProps {
    loggedIn: boolean
    activeMenuItem: string
    handleActiveOnMenuItemClick: (activeProp:string) => void;
}

interface SideNavState {
    activeItem: string
    appName: any,
    scrolledTo: any
}

class SideNav extends React.Component<SideNavProps, SideNavState> {
    private client: generalApi;

    constructor(props: SideNavProps) {
        super(props);
        this.state = {
            activeItem: '',
            appName: '',
            scrolledTo: null
        };

        this.client = new generalApi();
    }

    componentDidMount(): void {
        this.getAppName();
    }

    /*componentDidUpdate(prevProps: Readonly<SideNavProps>, prevState: Readonly<SideNavState>) {
        if(prevProps.loggedIn !== this.props.loggedIn) {
            this.getAppName();
        }
    }*/

    getAppName = () => {
        this.client.getAppShortName()
            .then((response:string) => {
                this.setState({appName:response});
            })
    };

    /*handleItemClick = (e:any, { name }:any) => {
        this.setState({ activeItem: name });
    };

    appLinkClick = () => {
        this.setState({activeItem: 'home'});
    };

    userHasPermission = (page:string) => {
        console.log(this.props.roles);
        if(this.props.roles.size > 0) {
            switch (page) {
                case 'upgrades':
                    return (this.props.roles.has('Read-Only') || this.props.roles.has('Read-Write')
                        || this.props.roles.has('Admin') || this.props.roles.has('IT-Admin'));
                default:
                    return false;
            }
        }
        else {
            return false;
        }
    };*/

    onScroll = (event: any) => {
        this.setState({ scrolledTo: !this.state.scrolledTo})
    }

    public render() {
        return (
            <React.Fragment>
                <div className={'sideMenuNav disable-scrollbars'} id="sideNavMenu" onScroll={this.onScroll}>
                    <div className={'sideNavContainer'}>
                        <MenuItemComponent scrolledTo={this.state.scrolledTo} handleActiveOnMenuItemClick={this.props.handleActiveOnMenuItemClick} activeMenuItem={this.props.activeMenuItem} appName={this.state.appName}/>
                    </div>
                </div>
                <div className={'navCalendar'}>
                    {this.props.loggedIn &&
                        <NavCalendar/>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loggedIn: state.defaultReducer.loggedIn
    }
};

export default connect(mapStateToProps)(SideNav);