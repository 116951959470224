import * as React from 'react';
import GridContainer from "../GenericComponents/Grids/GridContainer";
import {createBreadCrumbBasedOnPage} from "../../Utils/HelperFunctions";
import * as actionTypes from "../../store/action";
import {connect} from "react-redux";

interface NtcContactsProps {
    setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => void;
}

interface NtcContactsState {
}

class NtcContacts extends React.Component<NtcContactsProps,NtcContactsState> {

    constructor(props: NtcContactsProps) {
        super(props);
    }

    componentDidMount(): void {
        this.props.setBreadCrumbPath(createBreadCrumbBasedOnPage(window.location.pathname.split('/').filter(x => x)));
    }

    render() {
        return (
            <GridContainer
                getGridDataUrl={'/api/auth/viewGridData/ntcContact'}
                addRecordUrl={'/api/auth/saveGridRecord/ntcContact'}
                editRecordUrl={'/api/auth/editGridRecord/ntcContact'}
                deleteRecordUrl={'/api/auth/removeGridRecord/ntcContact'}
                gridPaginated={true}
                formEditing={true}
                showUtilityColumns={true}
                exportLink={'viewGridData/ntcContact'}
                gridTitle={'NTC Contacts'}
                gridId={'ntcContactGrid'}
                typeForSuperClass={'ntcContact'}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setBreadCrumbPath: (breadCrumbPath:BreadCrumb[]) => dispatch(
            {type: actionTypes.SET_BREADCRUMB_PATH, payload: breadCrumbPath})
    }
};

export default connect(null, mapDispatchToProps)(NtcContacts);